import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-agronomist',
  templateUrl: './add-agronomist.component.html',
  styleUrls: ['./add-agronomist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddAgronomistComponent implements OnInit {
  agroForm!: FormGroup;
  relationships: any[] = ['Μισθωτός', 'Με δελτίο παροχής υπηρεσιών', 'Μέτοχος', 'Άλλο (διευκρινίστε)'];
  entityTypes: any[] = ['Φυσικό', 'Νομικό']
  dialogTitle: any;
  actionButton: any;
  closeButton: any;
  specifyInput: boolean = false;
  constructor(private fb: FormBuilder, private req: RequestService<any>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {
    if (this.editData) {
      if (this.editData.employmentRelationship === 'Άλλο (διευκρινίστε)') {
        this.specifyInput = true;
      }
    }

    this.agroForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      fatherName: ['', Validators.required],
      vat: ['', Validators.required],
      employmentRelationship: ['', Validators.required],
      geoteNumber: ['', Validators.required],
      entityType: ['', Validators.required],
      companyName: [''],
      specify: ['']
    })

    if (this.editData) {
      this.agroForm.controls['firstName'].setValue(this.editData.firstName);
      this.agroForm.controls['lastName'].setValue(this.editData.lastName);
      this.agroForm.controls['fatherName'].setValue(this.editData.fatherName);
      this.agroForm.controls['vat'].setValue(this.editData.vat);
      this.agroForm.controls['employmentRelationship'].setValue(this.editData.employmentRelationship);
      this.agroForm.controls['geoteNumber'].setValue(this.editData.geoteNumber);
      this.agroForm.controls['entityType'].setValue(this.editData.entityType);
      this.agroForm.controls['companyName'].setValue(this.editData.companyName);
      this.agroForm.controls['specify'].setValue(this.editData.specify);

    };

    this.statusLanguage();

    this.agroForm.get('employmentRelationship')!.valueChanges.subscribe((value) => {
      if (value !== 'Άλλο (συνοπτική περιγραφή)') {
        this.agroForm.get('specify')!.setValue('');
      }
    });
  }

  statusLanguage() {
    if (!this.editData) {
      this.dialogTitle = 'REPORT.ADDAGRO';
      this.actionButton = 'PRODUCT.ADD_BUTTON';
      this.closeButton = 'PRODUCT.CANCEL';
    } else if (this.editData) {
      this.dialogTitle = 'REPORT.EDITAGRO';
      this.actionButton = 'PRODUCT.UPDATE_BUTTON';
      this.closeButton = 'PRODUCT.CANCEL';
    }
  }

  onRelationshipChange(event: any): void {
    const selectedValue = event.value;
    if (selectedValue === 'Άλλο (διευκρινίστε)') {
      this.specifyInput = true;
    } else {
      this.specifyInput = false;
    }
  }

  addAgronomist() {
    if (!this.editData) {
      let agronomistData = {
        first_name: this.agroForm.controls['firstName'].value,
        last_name: this.agroForm.controls['lastName'].value,
        father_name: this.agroForm.controls['fatherName'].value,
        vat_number: this.agroForm.controls['vat'].value,
        employment_relationship: this.agroForm.controls['employmentRelationship'].value,
        geote_number: this.agroForm.controls['geoteNumber'].value,
        entity_type: this.agroForm.controls['entityType'].value,
        company_name: this.agroForm.controls['companyName'].value,
        specify_employment_relationship: this.agroForm.controls['specify'].value,
      }

      this.req.postAgronomist(agronomistData).subscribe(res => {
        const title = "REPORT.TITLE_ADD";
        const text = "REPORT.TEXT_ADD";
        this.translate.get([title, text])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              text: translations[text],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
            });
          })
      })
    }
    else {
      this.updateAgronomist();
    }
  }

  updateAgronomist() {
    let updatedData = {
      first_name: this.agroForm.value.firstName,
      last_name: this.agroForm.value.lastName,
      father_name: this.agroForm.value.fatherName,
      vat_number: this.agroForm.value.vat,
      employment_relationship: this.agroForm.value.employmentRelationship,
      geote_number: this.agroForm.value.geoteNumber,
      entity_type: this.agroForm.value.entityType,
      company_name: this.agroForm.value.companyName,
      specify_employment_relationship: this.agroForm.value.specify,
    };

    this.req.updateAgronomist(this.editData.id, updatedData).subscribe({
      next: (res) => {
        const title = "REPORT.TITLE_EDIT";
        const text = "REPORT.TEXT_UPDATE";
        this.translate.get([title, text])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              text: translations[text],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
            });
          })
      },
      error: (err) => {
        console.log(err)
      }
    });
  }

}