import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-seedingplanting',
  templateUrl: './seedingplanting.component.html',
  styleUrls: ['./seedingplanting.component.scss']
})
export class SeedingPlantingComponent implements OnInit {

  id: any = '';
  plotCodes: any = [];

  //seeding body
  seedingDate: any = '';
  seedingLotNumber: any = '';
  seedingPlotCode: any = '';

  seedingQuantity: any = '';

  //planting body
  plantingDate: any = '';
  plantingLotNumber: any = '';
  plantingPlotCode: any = '';
  plantingQuantity: any = '';
  unitkg: any = 'kg'
  unitItem: any = 'item'
  @ViewChild('select') select!: MatSelect;
  selectedItems = new FormControl(<any>[]);
  allComplete: boolean = false;
  selectedOptions: any[] = [];
  code_id: any;
  arraySend: any[] = [];

  constructor(private req: RequestService<any>, public _location: Location) { }

  ngOnInit() {
    this.getId()
  }

  async getId() {
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCodes.push([element.plot_code, element.size, element.crop_info[0].prod_tree_number,
        element.location, element.crop_info, element.crop_info.variety]);
      });
    });
  }

  addSeeding() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });
    const updatedData = {
      datetime: this.seedingDate ? this.seedingDate : '',
      lot_number: this.seedingLotNumber ? this.seedingLotNumber : '',
      crop_info_id: this.arraySend ? this.arraySend : '',
      quantity: this.seedingQuantity ? this.seedingQuantity : '',
      unit: this.unitkg ? this.unitkg : ''
    };
    let dataObj = JSON.stringify(updatedData)
    this.req.postSeed(dataObj).subscribe(() => {
      Swal.fire(
        '',
        'Seeding information updated successfully!',
        'success'
      )
      setTimeout(() => {
        this._location.back();
      }, 1000);
    })
  }

  addPlanting() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });
    const updatedData = {
      datetime: this.plantingDate ? this.plantingDate : '',
      lot_number: this.plantingLotNumber ? this.plantingLotNumber : '',
      crop_info_id: this.arraySend ? this.arraySend : '',
      quantity: this.plantingQuantity ? this.plantingQuantity : '',
      unit: this.unitItem ? this.unitItem : ''
    };
    let dataObj = JSON.stringify(updatedData)
    this.req.postPlant(dataObj).subscribe(() => {
      Swal.fire(
        '',
        'Planting information updated successfully!',
        'success'
      )
      setTimeout(() => {
        this._location.back();
      }, 1000);
    })
  }


  toggleCodeSelection(checked: boolean, code: any) {
    if (checked) {
      code[4].forEach((subtask: any) => {
        if (!this.selectedOptions.includes(subtask)) {
          this.selectedOptions.push(subtask);
        }
      });
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => !code[4].includes(option));
    }
    this.updateAllComplete();
  }

  isCodeSelected(code: any): boolean {
    return code[4].every((subtask: any) => this.selectedOptions.includes(subtask));
  }

  toggleSelection(subtask: any, event: any) {
    const checked = event.target.checked;
    if (checked) {
      if (!this.selectedOptions.includes(subtask)) {
        this.selectedOptions.push(subtask);
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
    }
    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allComplete = this.plotCodes.every((code: any) =>
      code[4].every((task: any) => this.selectedOptions.includes(task))
    );
  }

  setAll(checked: boolean) {
    if (checked) {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          if (!this.selectedOptions.includes(subtask)) {
            this.selectedOptions.push(subtask);
          }
        });
      });
    } else {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
        });
      });
    }
    this.updateAllComplete();
  }
}
