import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RequestService } from 'src/app/services/request.service';
import { ChartOptions } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-carbon-footprint',
  templateUrl: './carbon-footprint.component.html',
  styleUrls: ['./carbon-footprint.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class CarbonFootprintComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  id: any = '';
  plotCode: any = [];
  plotCodes: any = [];
  startingDate: any;
  endingDate: any;
  langStatus: any;
  pieChartLabels: any;
  startingDateString: any;
  endingDateString: any;
  pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
  };
  pieChartLegend = true;
  clicked = false;
  testCarbonFootprint: any = {}

  constructor(private req: RequestService<any>, public translate: TranslateService,) { }

  ngOnInit() {
    this.getId()
    this.statusLanguage()
  }

  async getId() {
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCodes.push([element.plot_code]);
      })
    })
  }

  statusLanguage() {
    this.langStatus = this.translate.store.currentLang
    if (this.langStatus == 'gr') {
      this.pieChartLabels = ['Φυτοπροστασία', 'Λίπασμα', 'Υπόλειμμα'];
    } else if (this.langStatus == 'en') {
      this.pieChartLabels = ['CFpest', 'CFfert', 'CFresidue'];
    }
  }

  onClick() {
    this.applyDate()
    this.clicked = true;
    this.testCarbonFootprint = '';
    const result: string[] = [];

    for (const innerArray of this.plotCode) {
      for (const value of innerArray) {
        result.push(value);
      }
    }
    const updatedData = {
      plots: result ? result : [],
      start: this.startingDateString,
      end: this.endingDateString
    };

    let dataObj = JSON.stringify(updatedData);

    this.req.postCarbonFootprint(dataObj).subscribe((res) => {
      this.testCarbonFootprint = res;
    })
  }

  private getDateString(date: Date): string {
    const year = date.getFullYear();
    const month = this.formatNumber(date.getMonth() + 1);
    const day = this.formatNumber(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatNumber(value: number): string {
    return value < 10 ? '0' + value : '' + value;
  }

  applyDate() {
    this.startingDateString = this.getDateString(this.startingDate);
    this.endingDateString = this.getDateString(this.endingDate);
  }

  getPlotPieChartDataset(plotCode: string) {
    if (this.testCarbonFootprint && this.testCarbonFootprint.plots && this.testCarbonFootprint.plots[plotCode]) {
      const plotData = this.testCarbonFootprint.plots[plotCode];
      const data = [
        plotData.carbon_foot_print.pest_results ? plotData.carbon_foot_print.pest_results.p_final : 0,
        plotData.carbon_foot_print.fert_results ? plotData.carbon_foot_print.fert_results.f_final : 0,
        plotData.carbon_foot_print.res_results ? plotData.carbon_foot_print.res_results.r_final : 0,
      ];
      return [{ data }];
    } else {
      return [];
    }
  }

  getTotalPieChartDataset(totalResults: any) {
    if (totalResults && totalResults.total_pest_carbon_footprint !== undefined &&
      totalResults.total_fest_carbon_footprint !== undefined &&
      totalResults.total_res_carbon_footprint !== undefined) {
      const data = [
        totalResults.total_pest_carbon_footprint,
        totalResults.total_fest_carbon_footprint,
        totalResults.total_res_carbon_footprint,
      ];
      return [{ data }];
    } else {
      return [];
    }
  }

}
