import { Component } from '@angular/core';

interface TableRow {
  crop: string,
  mediumSoil: number,
  heavySoil: number
}

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})
export class RecommendedComponent {

  headers: string[] = [
    'Καλλιέργειες',
    'Εδάφη μέσης σύστασης (kg/στρ.)',
    'Βαριάς σύστασης εδάφη (kg/στρ.)'
  ];

  rows: TableRow[] = [
    { crop: 'Πορτοκαλιές', mediumSoil: 10.8, heavySoil: 13.5 },
    { crop: 'Μανταρινιές', mediumSoil: 10.8, heavySoil: 13.5 },
    { crop: 'Λεμονιές', mediumSoil: 7.6, heavySoil: 9.5 },
    { crop: 'Ελιά (Ε)', mediumSoil: 9.3, heavySoil: 11.6 },
    { crop: 'Ελιά (Β)', mediumSoil: 10.5, heavySoil: 13.1 },
    { crop: 'Ρύζι', mediumSoil: 10.2, heavySoil: 12.8 },
    { crop: 'Ζαχαρότευτλα', mediumSoil: 15.1, heavySoil: 19 },
    { crop: 'Σιτάρι μαλακό', mediumSoil: 8.1, heavySoil: 10 },
    { crop: 'σιταρι σκληρό', mediumSoil: 6.2, heavySoil: 7.8 },
    { crop: 'Βαμβάκι', mediumSoil: 17.1, heavySoil: 21.5 },
    { crop: 'Κριθάρι', mediumSoil: 2.7, heavySoil: 3.2 },
    { crop: 'Κριθάρι Χ', mediumSoil: 3.9, heavySoil: 4.7 },
    { crop: 'Βρώμη', mediumSoil: 4.4, heavySoil: 5.3 },
    { crop: 'ΒΡΩΜΗ Χ', mediumSoil: 4.8, heavySoil: 5.8 },
    { crop: 'Σίκαλη', mediumSoil: 3, heavySoil: 3.6 },
    { crop: 'Σίκαλη Χ', mediumSoil: 4.3, heavySoil: 5.2 },
    { crop: 'Ηλίανθος', mediumSoil: 8.5, heavySoil: 10.7 },
    { crop: 'ΜΗΛΙΑ', mediumSoil: 11.6, heavySoil: 14.4 },
    { crop: 'ΑΧΛΑΔΙΑ', mediumSoil: 9.1, heavySoil: 11.4 },
    { crop: 'ΕΛΑΙΟΚΡΑΜΒΗ', mediumSoil: 7.4, heavySoil: 9.2 },
    { crop: 'ΡΟΔΑΚΙΝΙΑ-ΝΕΚΤΑΡΙΝΙΑ', mediumSoil: 14.5, heavySoil: 18.1 },
    { crop: 'Βερικοκιές', mediumSoil: 10.2, heavySoil: 12.7 },
    { crop: 'ΦΥΣΤΙΚΙΑ', mediumSoil: 13.2, heavySoil: 16.4 },
    { crop: 'ΜΗΔΙΚΗ', mediumSoil: 3.8, heavySoil: 4.9 },
    { crop: 'ΑΡΑΒΟΣΙΤΟΣ', mediumSoil: 23.3, heavySoil: 29.1 },
    { crop: 'ΑΡΑΒΟΣΙΤΟΣ (ΕΝΣΙΡ)', mediumSoil: 20.4, heavySoil: 25.4 },
    { crop: 'ΚΑΠΝΟΣ', mediumSoil: 5.2, heavySoil: 6.4 },
    { crop: 'ΤΟΜΑΤΑ(Μ)', mediumSoil: 14.7, heavySoil: 18.6 },
    { crop: 'ΓΕΩΜΗΛΑ(Α)', mediumSoil: 20.7, heavySoil: 26.1 },
    { crop: 'ΓΕΩΜΗΛΑ(Χ)', mediumSoil: 20.4, heavySoil: 25.5 },
    { crop: 'ΑΜΠΕΛΙ(ΟΙΝΟΠ)', mediumSoil: 9.3, heavySoil: 11.6 },
    { crop: 'ΑΜΠΕΛΙ(ΕΠΙΤΡ)', mediumSoil: 13.3, heavySoil: 16.6 },
    { crop: 'ΑΜΠΕΛΙ(ΣΤΑΦΙΔ)', mediumSoil: 12.6, heavySoil: 15.6 },
    { crop: 'ΚΑΡΟΤΑ', mediumSoil: 13.6, heavySoil: 17.1 },
    { crop: 'ΒΙΚΟΣ', mediumSoil: 4, heavySoil: 4.8 },
    { crop: 'ΒΙΚΟΣ(Χ)', mediumSoil: 3.6, heavySoil: 4.3 },
  ];

}

