<h1 mat-dialog-title class="text-center">{{ dialogTitle | translate }}</h1>
<form class="example-form" id="form" [formGroup]="sprayForm">
    <div *ngIf="this.superuser && this.editData.isEdit && this.editData.row.status === 'pending'" class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.STATUS'| translate }}</mat-label>
                    <mat-select formControlName="status" [(value)]="status">
                        <mat-option value="pending">{{ 'PRODUCT.PENDING'| translate }}</mat-option>
                        <mat-option value="approved">{{ 'PRODUCT.APPROVE'| translate }}</mat-option>
                        <mat-option value="declined">{{ 'PRODUCT.DECLINE'| translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.COMMERCIAL'| translate }}</mat-label>
                    <input matInput formControlName="commercialName" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.CROP_TYPE'| translate }}</mat-label>
                    <input matInput formControlName="cropType" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center" [hidden]="this.superuser && this.editData.isEdit && this.editData.row.status === 'pending'">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.COMMERCIAL'| translate }}</mat-label>
                    <input matInput formControlName="commercialName" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.CROP_TYPE'| translate }}</mat-label>
                    <input matInput formControlName="cropType" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.SUBSTANCE'| translate }}</mat-label>
                    <input matInput formControlName="substance" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.PERCENT'| translate }}</mat-label>
                    <input matInput formControlName="percentage" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.PRODUCER'| translate }}</mat-label>
                    <input matInput formControlName="producer" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.APPROVAL_NUM'| translate }}</mat-label>
                    <input matInput formControlName="approvalNum" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.DOSAGE'| translate }}</mat-label>
                    <input matInput formControlName="dosage" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.AUTHORITY'| translate }}</mat-label>
                    <input matInput formControlName="authority" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'PRODUCT.APPROVAL_DATE' | translate }}</mat-label>
                    <input matInput formControlName="approvalDate" [matDatepicker]="approvalPicker">
                    <mat-datepicker-toggle matIconSuffix [for]="approvalPicker"></mat-datepicker-toggle>
                    <mat-datepicker #approvalPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'PRODUCT.APPROVAL_EXP' | translate }}</mat-label>
                    <input matInput formControlName="approvalExpiration" [matDatepicker]="approvalExpPicker">
                    <mat-datepicker-toggle matIconSuffix [for]="approvalExpPicker"></mat-datepicker-toggle>
                    <mat-datepicker #approvalExpPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'PRODUCT.SUPPLY_EXP' | translate }}</mat-label>
                    <input matInput formControlName="supplyExpiration" [matDatepicker]="supplyExpPicker">
                    <mat-datepicker-toggle matIconSuffix [for]="supplyExpPicker"></mat-datepicker-toggle>
                    <mat-datepicker #supplyExpPicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'PRODUCT.COUNTRY' | translate }}</mat-label>
                    <mat-select formControlName="country">
                        <mat-option *ngFor="let country of countries" value="{{country.name}}">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.APPROVAL_OWNER'| translate }}</mat-label>
                    <input matInput formControlName="approvalOwner" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">Efpest</mat-label>
                    <input matInput type="number" min="0" formControlName="efpest" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">PHI</mat-label>
                    <input matInput formControlName="phi" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'STORAGE_TRANSLATE.CATEGORY'| translate }}</mat-label>
                    <input matInput formControlName="category" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.PRODUCER'| translate }}</mat-label>
                    <input matInput formControlName="producer" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="col-12">
            <mat-form-field class="example-full-width">
                <mat-label style="font-size: 14px;">{{'PRODUCT.JUSTIFY' | translate }}</mat-label>
                <textarea matInput formControlName="justification"></textarea>
            </mat-form-field>
        </div>
    </div>
</form>
<div id="buttons_group" class="mt-2 p-3 mx-1 float-right">
    <div class="row">
        <div class="col-5">
            <button mat-raised-button mat-dialog-close="Close"
                style="background-color: red; color: white;">{{ closeButton | translate }}</button>
        </div>
        <div class="col-5">
            <button mat-raised-button mat-dialog-close (click)="addSpray()"
                style="background-color: #3fb56d; color: white;">{{ actionButton | translate }}</button>
        </div>
    </div>
</div>