<h1 mat-dialog-title id="addEquipmentTitle" class="text-center">{{dialogTitle}}</h1>
<form class="example-form" id="form" [formGroup]="maintenanceForm">
    <div class="row">
        <div class="col-5">
            <mat-form-field>
                <mat-label>{{'MANAGEMENT_MAINTENANACE.MAINTENANACE_EQUIPMENT' | translate}}</mat-label>
                <mat-select formControlName="equipment" [(value)]="selectedTitle" (selectionChange)="onSelectTitle()">
                    <mat-option *ngFor="let equipment of equipmentList" [value]="equipment.title">
                        {{ equipment.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6 ml-10">
            <div *ngIf="titleOptions && titleOptions.length > 0 else noOption">
                <mat-form-field>
                    <mat-label>{{'MANAGEMENT_MAINTENANACE.MAINTENANACE_TYPE_CHOOSE' | translate}}</mat-label>
                    <mat-select formControlName="equipment_type" [(value)]="selectedEquipmentType">
                        <mat-option *ngFor="let type of titleOptions" [value]="type">
                            {{ type }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-template #noOption>
                <div>
                    <mat-form-field>
                        <mat-label style="font-size: 14px;">{{'MANAGEMENT_MAINTENANACE.MAINTENANACE_TYPE' | translate}}</mat-label>
                        <input matInput formControlName="equipment_type" placeholder="Equipment Type" />
                    </mat-form-field>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="row">
        <div class="col-5">
            <mat-form-field>
                <mat-label style="font-size: 14px;">{{'MANAGEMENT_MAINTENANACE.MAINTENANACE' | translate}}</mat-label>
                <mat-select formControlName="maintenance_type" placeholder="{{selectedOptionType}}">
                    <mat-option *ngFor="let equipment of maintenanceType" value="{{equipment}}">
                        {{equipment}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-5 ml-10">
            <mat-form-field>
                <mat-label style="font-size: 14px;">{{'MANAGEMENT_MAINTENANACE.RESPONSIBLE_PERSON' | translate}}</mat-label>
                <input matInput formControlName="responsible_person" placeholder="Responsible Person" />
            </mat-form-field>
        </div>
    </div>
    <div class="col-12">
        <mat-form-field>
            <mat-label style="font-size: 14px;">{{'MANAGEMENT_MAINTENANACE.DATE' | translate}}</mat-label>
            <input matInput formControlName="maintenance_datetime" [matDatepicker]="picker">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-12">
        <mat-form-field class="example-full-width">
            <mat-label style="font-size: 14px;">{{'MANAGEMENT_MAINTENANACE.COMMENT' | translate}}</mat-label>
            <textarea matInput formControlName="paratirisis" placeholder="Ex. It makes me feel..."></textarea>
        </mat-form-field>
    </div>
</form>
<div id="buttons_group" class="mt-2 p-3 float-right">
    <div class="row">
        <div class="col-5">
            <button mat-raised-button mat-dialog-close="Close"
                style="background-color: red; color: white;">Close</button>
        </div>
        <div class="col-5">
            <button mat-raised-button mat-dialog-close style="background-color: #3fb56d; color: white;"
                (click)="addMaintenanceLogs()">{{actionButton}}</button>
        </div>
    </div>
</div>