import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})

export class ManagementComponent implements OnInit {

  superuser: boolean = false;
  admin: boolean = false;
  subadmin: boolean = false;

  constructor(private req: RequestService<any>,private authorizationService: AuthorizationService) {}
  
  ngOnInit() {
    this.req.getUserProfile().subscribe((res: any) => {
      if (res.roles.includes('superuser')) {
        this.authorizationService.setSuperuser(true);
        this.authorizationService.superuser$.subscribe(superuser => {
          this.superuser = superuser;
        });
      }
      if (res.roles.includes('admin')) {
        this.authorizationService.setAdmin(true);
        this.authorizationService.admin$.subscribe(admin => {
          this.admin = admin;
        });
      }
      if (res.roles.includes('subadmin')) {
        this.authorizationService.setSubadmin(true);
        this.authorizationService.subadmin$.subscribe(subadmin => {
          this.subadmin = subadmin;
        });
      }
    })
  }
}
