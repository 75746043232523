import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { RequestService } from 'src/app/services/request.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { MatMenu } from '@angular/material/menu';
import { tap, switchMap, of } from 'rxjs';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  screenWidth: number;
  admin: boolean = false;
  subadmin: boolean = false;
  superuser: boolean = false;
  farmers: any[] = [];
  kydNames: string[] = [];
  farmersByKyd: { [key: string]: any[] } = {};
  menus: { [key: string]: MatMenu } = {};
  isRecommendOpen: boolean = false;
  selectedFarmer: string = '';

  @ViewChild('sidenav') public sidebar!: MatSidenav;
  @ViewChildren(MatMenu) matMenus!: QueryList<MatMenu>;

  constructor(private sidebarService: SidebarService, private authorizationService: AuthorizationService, private req: RequestService<any>, public featureFlags: FeatureFlagsService) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
  }

  ngOnInit() {
    this.req.getUserRelationship().pipe(
      tap(res => {
        if (Array.isArray(res)) {
          this.processFarmers(res[0].related_farmers);
        }
      }),
    
      switchMap(() => this.authorizationService.superuser$),
      tap(superuser => this.superuser = superuser),
      switchMap(superuser => {
        if (superuser) return of(true); 
        return this.authorizationService.admin$;
      }),
      tap(admin => this.admin = admin),
      switchMap(admin => {
        if (admin) return of(true);
        return this.authorizationService.subadmin$;
      }),
      tap(subadmin => this.subadmin = subadmin),
      switchMap(subadmin => {
        if (subadmin) return of(true);
        return of(false); 
      })
    ).subscribe(hasRole => {
      if (hasRole) {
        this.initializeFarmerData();
      }
    });
  }

  ngAfterViewInit() {
    this.sidebarService.setSidenav(this.sidebar);
    
  }

  ngAfterViewChecked() {
    this.matMenus.forEach(menu => {
      const kyd = menu['_elementRef'].nativeElement.getAttribute('id');
      if (kyd) {
        this.menus[kyd] = menu;
      }
    });
  }

  initializeFarmerData() {
    this.req.getUserRelationship().subscribe(res => {
      if (Array.isArray(res)) {
        this.processFarmers(res[0].related_farmers);
        if (res[0].selected_keycloak_id) {
          const selectedFarmer = res[0].related_farmers.find((farmer: any) => farmer.keycloak_id === res[0].selected_keycloak_id);
          if (selectedFarmer) {
            this.selectedFarmer = `${selectedFarmer.first_name} ${selectedFarmer.last_name}`;
            localStorage.setItem('selectedFarmer', this.selectedFarmer); // Store in localStorage
          }
        }
      }
    });
  }

  processFarmers(farmers: any[]) {
  this.farmers = farmers;
  this.kydNames = [...new Set(farmers.map(f => f.kyd_name))]; // Extract unique kyd_names

  this.kydNames.forEach(kyd => {
    this.farmersByKyd[kyd] = this.farmers.filter(farmer => farmer.kyd_name === kyd);
  });
}

  getFarmers(kyd: string) {
    return this.farmersByKyd[kyd] || [];
  }

  toggleRecommendations() {
    this.isRecommendOpen = !this.isRecommendOpen;
  }

  selectFarmer(farmer: any) {
    this.selectedFarmer = `${farmer.first_name} ${farmer.last_name}`;
    localStorage.setItem('selectedFarmer', this.selectedFarmer); 
    let updatedData = {
      selected_keycloak_id: farmer.keycloak_id,
    };

    this.req.updateSelectedFarmer(updatedData).subscribe({
      next: () => {
        location.reload();
      }
    });
  }
}