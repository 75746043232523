<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} /  {{ 'SIDEBAR.PLOTS'| translate}}
</h1>
<div class="container mx-auto px-4">
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                A1. {{'PLOTS.REGISTER' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'MANAGEMENT.CODE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="fieldCode" name="fieldCode" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.MUNICIPALCOM' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="municipalCommunity" name="municipalCommunity" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.LOCATION' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="location" name="location" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.UNIT' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="unit" name="unit" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.COOWN' | translate }} (%)
                        </label>
                        <input type="number" min="0" step="1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="coOwnership" name="coOwnership" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.SIZE' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="size" name="size" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.SPECIES' | translate }}
                        </label>
                        <div
                            class="border-0 px-3 pt-2 pb-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <mat-select [(ngModel)]="selectedSpecies" [ngModelOptions]="{standalone: true}">
                                <ng-container *ngFor="let speciesGroup of speciesOptions">
                                    <mat-optgroup [label]="speciesGroup.group">
                                        <ng-container *ngFor="let subgroup of speciesGroup.subgroups">
                                            <mat-optgroup [label]="subgroup.subgroup">
                                                <mat-option *ngFor="let option of subgroup.options" [value]="option">
                                                    {{ option }}
                                                </mat-option>
                                            </mat-optgroup>
                                        </ng-container>
                                    </mat-optgroup>
                                </ng-container>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap w-full">
                    <div *ngFor="let coordinate of parcelCoordinates; let i = index" class="flex flex-row w-full">
                        <div class="w-full lg:w-1/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-600 text-xs font-bold mb-2" [for]="'node' + i">
                                    {{'PLOTS.NODE' | translate }}
                                </label>
                                <input type="text" autocomplete="off" readonly
                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    [(ngModel)]="coordinate.kombos" [name]="'node' + i" [id]="'node' + i" />
                            </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                    [for]="'latitude' + i">
                                    {{'PLOTS.LAT' | translate }}
                                </label>
                                <input type="number" min="0" step="0.00001" autocomplete="off"
                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    [(ngModel)]="coordinate.lat" [name]="'latitude' + i" [id]="'latitude' + i" />
                            </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4 mr-8">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                    [for]="'longitude' + i">
                                    {{'PLOTS.LON' | translate }}
                                </label>
                                <input type="number" min="0" step="0.00001" autocomplete="off"
                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    [(ngModel)]="coordinate.lon" [name]="'longitude' + i" [id]="'longitude' + i" />
                            </div>
                        </div>
                        <button type="button" (click)="removeNode(i)" [hidden]="isDeleteDisabled()" class="m-2 mt-4"><i
                                class="fas fa-minus text-md"></i>
                        </button>
                    </div>
                    <button type="button" (click)="addNode()" class="m-2 mt-4"><i class="fas fa-plus text-md"></i>
                    </button>
                </div>
            </div>
        </form>

    </div>
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                A2. {{'PLOTS.INFO' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.COMMUNITYTYPE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="communityType" name="communityType" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.MAPPED' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="mappedBackground" name="mappedBackground" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.ELIGIBLE' | translate }}
                        </label>
                        <input type="number" min="0" step="1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="protocolNumberEligibleArea" name="protocolNumberEligibleArea" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.EA' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="eligibleArea" name="eligibleArea" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.GRASSLAND' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="grassland" name="grassland" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.TOTALEA' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="totalEligibleArea" name="totalEligibleArea" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.APPLIED' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="appliedArea" name="appliedArea" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.COMPUBLIC' | translate }}
                        </label>
                        <select [(ngModel)]="communityPublic" name="communityPublic"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.>8%' | translate }}
                        </label>
                        <select [(ngModel)]="slope8" name="slope8"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.DIGAREA' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="digitalArea" name="digitalArea" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.DIGPERIMETER' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="perimeter" name="perimeter" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.NOTAVAILABLE' | translate }}
                        </label>
                        <select [(ngModel)]="notAvailableAB" name="notAvailableAB"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.REP' | translate }}
                        </label>
                        <select [(ngModel)]="ape" name="ape"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.DRILL' | translate }}
                        </label>
                        <select [(ngModel)]="drill" name="drill"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.RIGHTS' | translate }}
                        </label>
                        <select [(ngModel)]="enabledRights" name="enabledRights"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.834/2007' | translate }}
                        </label>
                        <select [(ngModel)]="regulation8342007" name="regulation8342007"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.IRRIGATED' | translate }}
                        </label>
                        <select [(ngModel)]="irrigated" name="irrigated"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.WATERBORDER' | translate }}
                        </label>
                        <select [(ngModel)]="borderingWater" name="borderingWater"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.WATERBORDERLENGTH' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="borderingWaterLength" name="borderingWaterLength" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.WATERUSAGE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="waterUsage" name="waterUsage" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.ENERGYTYPE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="energyType" name="energyType" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.GRAZELAND' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="grazelandEc" name="grazelandEc" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.SUPPLY' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="supplyNumber" name="supplyNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.RELATEDC' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="relatedCode" name="relatedCode" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.RELATEDL' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="relatedList" name="relatedList" />
                    </div>
                </div>
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.LANDDESC' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="landUsageDscr" name="landUsageDscr" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="landUsageCode" name="landUsageCode" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.NATURA' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelNatura" name="parcelNatura" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.AEGEAN' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelAegean" name="parcelAegean" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.HIGHVALUE' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelValue" name="parcelValue" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.NITRATE' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelNitrate" name="parcelNitrate" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.WETLAND' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelWetland" name="parcelWetland" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.GROUNDWATER' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelGroundwater" name="parcelGroundwater" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.NATIONAL' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parcelNational" name="parcelNational" />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                B. {{'PLOTS.OWNERS' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.TITLETYPE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="titleType" name="titleType" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.PROOF' | translate }}
                        </label>
                        <input type="number" min="0" step="1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="ownershipProof" name="ownershipProof" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.PROPERTYTYPE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="propertyType" name="propertyType" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.AREAATAK' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="atakArea" name="atakArea" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.STARTDATE' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="startDate" name="startDate" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.ENDDATE' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="expiryDate" name="expiryDate" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.EXCEPTATAK' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="atakException" name="atakException" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.ATAKCODE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="atakCode" name="atakAatakCoderea" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.CADASTRE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="kaek" name="kaek" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.OWNERCONTRACTNO' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="ownerContractNumber" name="ownerContractNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.OWNERCONTRACTDATE' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="ownerContractDate" name="ownerContractDate" />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                {{'PLOTS.CROPINFO' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.INTERCROP' | translate }}
                        </label>
                        <select [(ngModel)]="intercropping" name="intercropping"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.CROPTYPE' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="cropType" name="cropType" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.M/X' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="dryXeric" name="dryXeric" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.ORGANIC' | translate }}
                        </label>
                        <select [(ngModel)]="organicFarming" name="organicFarming"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.PEF' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="pef" name="pef" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.SUBSIDISED' | translate }}
                        </label>
                        <input type="number" min="0" step="0.01" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="subsidised" name="subsidised" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.INTERSEEDED' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="interseeded" name="interseeded" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.UNHARVEST' | translate }}
                        </label>
                        <select [(ngModel)]="unharvested" name="unharvested"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.>=4' | translate }} 
                        </label>
                        <input type="number" min="0" step="1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="treesLess" name="treesLess" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.<4' | translate }}
                        </label>
                        <input type="number" min="0" step="1"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="treesMore" name="treesMore" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.PROD' | translate }}
                        </label>
                        <input type="number" min="0" step="1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="prodTreeNumber" name="prodTreeNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.NONPROD' | translate }}
                        </label>
                        <input type="number" min="0" step="1"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="nonProdTreeNumber" name="nonProdTreeNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.HIVES' | translate }}
                        </label>
                        <input type="number" min="0" step="1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="hiveNumber" name="hiveNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.EPMET' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="epMetEpi" name="epMetEpi" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.ECOFOCUS' | translate }}
                        </label>
                        <select [(ngModel)]="diatPer" name="diatPer"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            <option [ngValue]="false" disabled selected>Select</option>
                            <option [ngValue]="true">YES</option>
                            <option [ngValue]="false">NO</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.PARALLEL' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="parallelActivities" name="parallelActivities" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.CROPCONTRACTNO' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="cropContractNumber" name="cropContractNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.CROPCONTRACTDATE' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="cropContractDate" name="cropContractDate" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.REGIMES' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="linkedRegimes" name="linkedRegimes" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.SEED' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seedProduce" name="seedProduce" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{'PLOTS.INTEGRATION' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="integrationData" name="integrationData" />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-raised-button id="saveButton" class="float-right m-2" (click)="addPlot()">
        <mat-icon>done</mat-icon>
        {{ 'MANAGEMENT.SAVE' | translate }}
    </button>
</div>