import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthorizationService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.authService.superuser$.pipe(
      switchMap(superuser => {
        if (superuser) {
          return of(true);
        } else {
          return this.authService.admin$.pipe(
            switchMap(admin => {
              if (admin) {
                return of(true);
              } else {
                return this.authService.subadmin$;
              }
            })
          );
        }
      }),
      map(subadmin => {
        if (subadmin) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}