import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarService } from 'src/app/services/sidebar.service';
import { KeycloakInitOptions } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { createPopper } from "@popperjs/core";
import { RequestService } from 'src/app/services/request.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

const url = environment.apiType + '://' + environment.apiUrl;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  dropdownPopoverShow = false;

  @ViewChild("btnDropdownRef", { static: false })
  btnDropdownRef!: ElementRef;

  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef!: ElementRef;

  @ViewChild(MatSidenav) sidebar!: MatSidenav;

  showLogin: boolean = true;

  firstName: any;
  lastName: any;

  default: KeycloakInitOptions = {
    redirectUri: url
    //redirectUri: 'http://localhost:4200'

  }
  userId: any = '';
  superuser: boolean = false;
  admin: boolean = false;
  subadmin: boolean = false;

  constructor(private sidebarService: SidebarService, private keycloak: KeycloakService, private renderer: Renderer2, private req: RequestService<any>, public translate: TranslateService, private authorizationService: AuthorizationService) {
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const isClickedInside = this.popoverDropdownRef.nativeElement.contains(event.target);
      const isButtonClicked = this.btnDropdownRef.nativeElement.contains(event.target);

      if (!isClickedInside && !isButtonClicked) {
        this.dropdownPopoverShow = false;
      }
    });
  }

  ngOnInit() {
    this.req.getUserProfile().subscribe((res: any) => {
      if (res.roles.includes('superuser')) {
        this.authorizationService.setSuperuser(true);
        this.authorizationService.superuser$.subscribe(superuser => {
          this.superuser = superuser;
        });
      }
      if (res.roles.includes('admin')) {
        this.authorizationService.setAdmin(true);
        this.authorizationService.admin$.subscribe(admin => {
          this.admin = admin;
        });
      }
      if (res.roles.includes('subadmin')) {
        this.authorizationService.setSubadmin(true);
        this.authorizationService.subadmin$.subscribe(subadmin => {
          this.subadmin = subadmin;
        });
      }
    })
    this.keycloak.isLoggedIn().then(res => {
      if (res == true) {
        this.showLogin = false;
        this.getInitials();
      }
      else {
        this.showLogin = true;
      }
    });
  }

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-end",
      }
    );
  }

  getInitials() {
    this.req.getFarmer().subscribe((res:any) => {
      this.firstName = res.first_name.substring(0, 1).toUpperCase();
      this.lastName = res.last_name.substring(0, 1).toUpperCase();
    })
  }

  logout() {
    this.keycloak.logout(this.default.redirectUri);
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

  toggleDropdown(event: any) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }
}
