import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-recommend-fertiliser',
  templateUrl: './recommend-fertiliser.component.html',
  styleUrls: ['./recommend-fertiliser.component.scss']
})
export class RecommendFertiliserComponent implements OnInit {

  questions: any[] = [];
  isValueChangeInternal = new BehaviorSubject<boolean>(false);
  constructor(private req: RequestService<any>, private fb: FormBuilder, public translate: TranslateService, private cdr: ChangeDetectorRef) {
    this.recommendationForm = this.fb.group({
      cropType: ['', Validators.required],
      yield: [0, Validators.required],
      deepSand: [null, Validators.required],
      otherSoils: [null, Validators.required],
      spl: [null, Validators.required],
      stalkSize: [null, Validators.required],
      regionType: ['', Validators.required],
      lowInP: [null, Validators.required],
      hps: [null, Validators.required],
    })

  }

  recommendationForm!: FormGroup;
  cropTypes: any = ['RECOMMEND.COTTON', 'RECOMMEND.OLIVES', 'RECOMMEND.SOYBEANS'];
  regionTypes: { value: string, translateKey: string }[] = [
    { value: 'Coast', translateKey: 'RECOMMEND.COAST' },
    { value: 'Pied', translateKey: 'RECOMMEND.PIED' }
  ];
  cottonStepper: boolean = false;
  otherStepper: boolean = false;
  recommendations: any = {};
  currentStep: number = 1;
  user: any = '';
  crop: any = '';
  cottonDetails: any = {};
  otherDetails: any = '';
  recommendedN: any = '';
  recommendedP: any = '';
  recommendedK: any = '';
  recommendedUnits: any = '';
  chartData!: any[];
  chartLabels: string[] = ['N', 'P₂O₅', 'K₂O'];
  barChartOptions: any = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'lbs/acre'
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };
  
  ngOnInit(): void {
    this.req.getFarmer().subscribe((res: any) => {
      this.user = res.first_name + ' ' + res.last_name;
    })
    this.recommendationForm.get('cropType')?.valueChanges.subscribe(value => {
      this.crop = value;
      this.crop.toLowerCase();
      if (value === 'RECOMMEND.COTTON') {
        this.cottonStepper = true;
        this.otherStepper = false;
      }

      else if ((value === 'RECOMMEND.OLIVES') || (value === "RECOMMEND.SOYBEANS")) {
        this.cottonStepper = false;
        this.otherStepper = true;
      }
      this.fetchQuestions(this.mapValue(value));
    });

  }

  fetchQuestions(crop: string): void {
    this.req.getRecommendationQuestions(crop).subscribe((res: any) => {
      this.questions = res;
      this.createFormControls();
      this.cdr.detectChanges();
    });
  }

  createFormControls(): void {
    this.questions.forEach(question => {
      let control: FormControl<any>;
      if (question.answer_type === 'checkbox') {
        control = new FormControl(false);
      } else {
        control = new FormControl(0);
      }
      this.recommendationForm.addControl(question._id, control);
    });
  }

  isRegionQuestion(question: any): boolean {
    return question.answers.includes('Coast') && question.answers.includes('Pied');
  }

  recommendFert() {
    let stepperData: any = {
      ct: this.mapValue(this.recommendationForm.value.cropType),
      yg: 0,
      ds: false,
      os: false,
      spl: false,
      esz: false,
      rt: '',
      ng: false,
      hps: false,
    };

    this.questions.forEach((question, index) => {
      const key = this.mapKey(index);
      stepperData[key] = this.mapRegionValueIfNeeded(question, index);
    });
    if (this.otherStepper) {
      const regionTypeValue = this.recommendationForm.value.regionType;
      const regionType = this.regionTypes.find(rt => rt.value === regionTypeValue);
      stepperData.rt = regionType ? this.mapValue(regionType.translateKey) : regionTypeValue;
      this.otherDetails = regionType ? regionType.translateKey : '';
    }

    this.req.postFertiliserRecommendation(stepperData).subscribe((res) => {
      console.log(res)
      this.recommendedN = res.n.toFixed(3);
      this.recommendedK = res.k2o.toFixed(3);
      this.recommendedP = res.p2o5.toFixed(3);
      this.recommendedUnits = res.n_units;

      this.currentStep++;

      this.chartData = [
        {
          data: [this.recommendedN, this.recommendedP, this.recommendedK]
          
        },
      ];
    });

    this.cottonDetails = stepperData;
  }

  mapKey(index: number): string {
    const keys = ['yg', 'ds', 'os', 'spl', 'esz', 'rt', 'ng', 'hps'];
    return keys[index];
  }

  mapValue(value: any): string {
    if (typeof value === 'string') {
      return value.replace('RECOMMEND.', '').toLowerCase();
    }
    return value;
  }

  mapRegionValueIfNeeded(question: any, index: number): any {
    const value = this.recommendationForm.value[question._id];
    if (this.isRegionQuestion(question)) {
      return this.mapValue(value);
    }
    return value;
  }

  getAnswerDisplayValue(questionId: string): string {
    const value = this.recommendationForm.value[questionId];

    const regionQuestion = this.questions.find(q => q._id === questionId && this.isRegionQuestion(q));
    if (regionQuestion) {
      const regionType = this.regionTypes.find(rt => rt.value === value);
      return regionType ? this.translate.instant(regionType.translateKey) : value;
    }

    if (typeof value === 'boolean') {
      return value ? 'RECOMMEND.Y' : 'RECOMMEND.N';
    }
    return value ? value.toString() : '';
  }

}
