import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'agnes-dashboard';

  constructor(public router: Router, public translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
   }

}
