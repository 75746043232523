<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.STORAGE'| translate}}
</h1>
<div class="container">
    <div class="row">
        <mat-card *ngFor="let card of storageProduct" id="cards_storagePage" class="example-card"
            routerLink="/products-vegetables">
            <div class="icon icon-lg icon-shape shadow-success text-center mt-n4 position-absolute"
                *ngIf="card.category.toLowerCase().includes('prote')">
                <img src="../../../assets/agro_protection.png" id="icon_protection" />
            </div>
            <div class="icon icon-lg icon-shape shadow-success text-center mt-n4 position-absolute"
                *ngIf="card.category.toLowerCase().includes('fertil')">
                <img src="../../../assets/wheat-grain.png" id="icon_fertilizer" />
            </div>
            <div class="icon icon-lg icon-shape shadow-success text-center mt-n4 position-absolute"
                *ngIf="!card.category.toLowerCase().includes('fertil') && !card.category.toLowerCase().includes('prote')">
                <img src="../../../assets/no_category.png" id="icon_noCategory" />
            </div>
            <mat-card-header id="category-titleStorage">
                <mat-card-title>
                    <div class="row" id="category" style="margin-left: 73%;">
                        <div class="row" style="color:#b1b1b1; font-size: small; font-weight: 700;">
                            <!-- <div > -->
                            {{'STORAGE_TRANSLATE.CATEGORY' | translate}}
                            <div class="row">
                                <h3 *ngIf="card.category.toLowerCase().includes('prote')"
                                    style="font-weight: 700; color: #a1ae5d; font-size: small; margin-left: -36%; margin-top: -18%;">
                                    <!-- {{card.category.charAt(0).toUpperCase() + card.category.slice(1).toLowerCase()}} -->
                                    {{'STORAGE_TRANSLATE.PROTECTION' | translate}}
                                </h3>
                                <h3 *ngIf="card.category.toLowerCase().includes('fertil')"
                                    style="font-weight: 700; color: rgb(86, 168, 50); font-size: small; margin-left: -36%; margin-top: -18%;">
                                    <!-- {{card.category.charAt(0).toUpperCase() + card.category.slice(1).toLowerCase()}} -->
                                    {{'STORAGE_TRANSLATE.FERTILIZER' | translate}}
                                </h3>
                                <h3 *ngIf="!card.category.toLowerCase().includes('fertil') && !card.category.toLowerCase().includes('prote')"
                                    style="font-weight: 700; color: #9c9c9c; margin-top: -23%">
                                    N/A
                                </h3>
                            </div>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <mat-card-content>
                        <div class="row">
                            <div class="row" style="
                                color: #b1b1b1;
                                margin-left: -3%; 
                                font-size: small; 
                                font-weight: 800;
                                margin-top: -7%;
                                ">
                                {{'STORAGE_TRANSLATE.NAME' | translate}}:
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="row">
                            <div class="col productName" id="productName"
                                *ngIf="card.category.toLowerCase().includes('prote')" style="
                                font-weight: 500; 
                                color: #9c9c9c;
                                font-size: 12px;
                                margin-left: -3%;
                                margin-top: -1%;
                                ">
                                {{card.commercial_name}}
                            </div>
                            <div class="col productName" id="productName"
                                *ngIf="card.category.toLowerCase().includes('fertil')" style="
                                font-weight: 500;                                
                                width: 200px;
                                color: #9c9c9c;
                                font-size: 12px;
                                margin-left: -3%;
                                margin-top: -1%;                                                       
                                ">
                                {{card.commercial_name}}
                            </div>
                            <div class="col productName" id="productName" [title]="card.commercial_name" *ngIf="!card.category.toLowerCase().includes('prote') && card.category.toLowerCase().includes('fertil') && card.commercial_name.length <= 0 
                            || card.commercial_name.length == null || card.commercial_name.length == undefined" style="
                            font-weight: 500;                        
                            color: #9c9c9c;
                            font-size: 12px;
                            margin-left: -3%;                        
                            margin-top: -1%;
                            ">
                                N/A
                            </div>
                            <div class="col productName" id="productName"
                                *ngIf="!card.category.toLowerCase().includes('fertil') && !card.category.toLowerCase().includes('prote') && card.commercial_name.length > 1"
                                style="
                            font-weight: 500;                        
                            color: #9c9c9c;
                            font-size: 12px;
                            margin-left: -3%;
                            margin-top: -1%;                        
                            ">
                                {{card.commercial_name}}
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </mat-card-content>
            <mat-card-content >
                <div class="row" style="margin-left: 67%;">
                    <div class="col col-lg-4" style="color: #9c9c9c; min-width: 140px; font-size: 12px;">
                        {{formatNumber(card.quantity) }}/{{card.initial_quantity}} (kg)
                    </div>
                    <div class="col col-lg-4" style=" width: 159px;">
                        <mat-progress-bar id="progress_bar" mode="determinate"
                            [value]="card.percentage"></mat-progress-bar>
                    </div>
                </div>
            </mat-card-content>

            <div class="row">
                <div class="col">
                    <mat-card-content *ngIf="card.percentage >= 80" id="mat_card">
                        <div id="mat_circle">
                            <circle-progress [percent]="card.percentage" [radius]="70" [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8" [outerStrokeColor]="'#4cc715'" [innerStrokeColor]="'white'"
                                [animation]="true" [animationDuration]="300" [subtitle]="quantity">
                            </circle-progress>
                        </div>
                    </mat-card-content>
                    <mat-card-content *ngIf="card.percentage < 80 && card.percentage > 50 " id="mat_card">
                        <circle-progress [percent]="card.percentage" [radius]="70" [outerStrokeWidth]="16"
                            [innerStrokeWidth]="8" [outerStrokeColor]="'#8ac900'" [innerStrokeColor]="'white'"
                            [animation]="true" [animationDuration]="300" [subtitle]="quantity">
                        </circle-progress>
                    </mat-card-content>
                    <mat-card-content *ngIf="card.percentage <= 50" id="mat_card">
                        <div>
                            <circle-progress [percent]="card.percentage" [radius]="70" [outerStrokeWidth]="16"
                                [innerStrokeWidth]="8" [outerStrokeColor]="'#f09000'" [innerStrokeColor]="'white'"
                                [animation]="true" [animationDuration]="300" [subtitle]="quantity">
                            </circle-progress>
                        </div>
                    </mat-card-content>
                </div>
                <div class="col" id="group_circles">
                    <mat-card-content id="group_details_info">
                        <div class="container small text-muted">
                            <div class="col-md-12">
                                <div class="row">
                                    <mat-icon id="mat_icons_details">date_range</mat-icon>
                                    {{card.datetime | date: 'mediumDate'}}
                                    <mat-icon id="mat_icons_details">date_range</mat-icon>
                                    <mat-icon id="error_icon">error</mat-icon>
                                    N/A<br>
                                    {{card.expiration_date | date: 'mediumDate'}}<br>
                                </div>
                                <div class="row">
                                    <mat-icon id="mat_icons_details">nfc</mat-icon>
                                    {{card.batch_number}}
                                </div>
                            </div>

                        </div>
                    </mat-card-content>
                </div>
            </div>
        </mat-card>

    </div>

</div>