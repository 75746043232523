import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidenav!: MatSidenav

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  /**
   * @returns Boolean
   */

  public open(): void {
    this.sidenav.open();
  }

  /**
   * @returns Boolean
   */

  public close(): void {
    this.sidenav.close();
  }

  /**
   * @returns Boolean
   */

  public toggle(): void {
    this.sidenav.toggle();
  }
}