import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';

export interface Coordinates {
  kombos: any;
  lat: any;
  lon: any;
}

@Component({
  selector: 'app-manage-plots',
  templateUrl: './manage-plots.component.html',
  styleUrls: ['./manage-plots.component.scss']
})

export class ManagePlotsComponent implements OnInit {

  id: any = '';
  fieldCode: any = '';
  municipalCommunity: any = '';
  location: any = '';
  unit: any = '';
  coOwnership: any = '';
  size: any = '';
  species: any = '';
  parcelCoordinates: Coordinates[] = [{
    kombos: '1',
    lat: '',
    lon: ''
  }];

  speciesOptions = [
    {
      group: 'Ελιά',
      subgroups: [
        {
          subgroup: 'Ελαιοπαραγωγική',
          options: [
            'Αρμπεκίνα', 'Κορωνέικη', 'Αθηνολία (Τσουνάτη)', 'Μεγαρίτικη', 'Καλαμών (Καλαμάτα)',
            'Αμφίσσης', 'Μανάκι', 'Θρουμπόλια', 'Βαλανόλια (Θάσος)', 'Μάκρη', 'Αδραμυτίνη', 'Πικουάλ', 'Φραντόιο', 'Λετσίνο', 'Μανζανίλια',
          ],
        },
        {
          subgroup: 'Επιτραπέζια',
          options: [
            'Καλαμών', 'Μανζανίλια', 'Χοχιμπλάνκα', 'Σεβιγιάνο', 'Πικολίν', 'Νοτσελάρα ντελ Μπελίτσε',
            'Γκαέτα', 'Λιγκούρια', 'Τσερίνιολα', 'Κονσερβολέα', 'Θάσος', 'Χαλκιδική', 'Γκορντάλ', 'Αλορένα', 'Εμπέλτρε'
          ],
        },
      ],
    },
    {
      group: 'Πορτοκαλιά',
      subgroups: [
        {
          subgroup: 'Κοινή',
          options: [
            'Κοινό Άρτας', 'Μποτσάτο', 'Κοινό Χανίων', 'Λαϊνσάτο Χανίων', 'Κυπραίικο (Γιάφφας) Χανίων',
            'Σουλτανί του Φόδελε (Ηρακλείου)', 'Barao', 'Belladonna', 'Berna', 'Cadenera', 'Calabrese (Ovale)', 'Clanor', 'Hamlin', 'Jaffa (Florida Jaffa)',
            'Khettmali (Khatmali, Hitmali)', 'Macetera', 'Mosambi (Mosambique)', 'Parson (Parson Brown)', 'Pineapple', 'Salustiana (Salus)', 'Shamouti',
            'Shamouti Marsy', 'Valencia'
          ],
        },
        {
          subgroup: 'Ομφαλόρα',
          options: [
            'Μέρλιν', 'Frost Washignton', 'Baianinha Piracicaba', 'Australian', 'Atwood', 'Navelina', 'Navelate', 'Thomson',
            'Leng, Oberholzer', 'Newhall', 'Suzuki', 'Tange', 'Skaggs Bonanza'
          ],
        },
        {
          subgroup: 'Αιματόχρωμη',
          options: [
            'Moro', 'Tarocco', 'Tarocco Liscio', 'Sanguinello Comune', 'Sanguinello Moscato', 'Doblefina',
            'Doblefine Amelioree', 'Entrefina', 'Spanish Sanguinelli', 'Maltaise Sanguine', 'Ruby', 'Tomango', 'Αιματόχρωμη ποικιλία Γουρίτσης (Τριχωνίδας)'
          ],
        },
        {
          subgroup: 'Γλυκόχυμη',
          options: [
            'Lima', 'Succari (Sukkari)', 'Sucrena', 'Vainiglia'
          ],
        },
      ],
    },
  ];

  selectedSpecies: string = '';

  communityType: any = '';
  mappedBackground: any = '';
  eligibleArea: any = '';
  protocolNumberEligibleArea: any = '';
  grassland: any = '';
  totalEligibleArea: any = '';
  appliedArea: any = '';
  communityPublic: boolean = false;
  slope8: boolean = false;
  digitalArea: any = '';
  perimeter: any = '';
  notAvailableAB: boolean = false;
  ape: boolean = false;
  drill: boolean = false;
  enabledRights: boolean = false;
  regulation8342007: boolean = false;
  irrigated: boolean = false;
  borderingWater: boolean = false;
  borderingWaterLength: any = '';
  waterUsage: any = '';
  energyType: any = '';
  supplyNumber: any = '';
  relatedCode: any = '';
  relatedList: any = '';
  landUsageCode: any = '';
  landUsageDscr: any = '';
  grazelandEc: any = '';
  parcelNatura: any = '';
  parcelAegean: any = '';
  parcelValue: any = '';
  parcelNitrate: any = '';
  parcelWetland: any = '';
  parcelGroundwater: any = '';
  parcelNational: any = '';

  titleType: any = '';
  ownershipProof: any = '';
  propertyType: any = '';
  atakArea: any = '';
  startDate: any = '';
  expiryDate: any = '';
  atakException: any = '';
  atakCode: any = '';
  kaek: any = '';
  ownerContractNumber: any = '';
  ownerContractDate: any = '';

  intercropping: boolean = false;
  cropType: any = '';
  dryXeric: any = '';
  organicFarming: boolean = false;
  pef: any = '';
  subsidised: any = '';
  interseeded: any = '';
  treesLess: any = '';
  treesMore: any = '';
  prodTreeNumber: any = '';
  nonProdTreeNumber: any = '';
  hiveNumber: any = '';
  cropContractNumber: any = '';
  cropContractDate: any = '';
  unharvested: boolean = false;
  epMetEpi: any = '';
  diatPer: boolean = false;
  linkedRegimes: any = '';
  seedProduce: any = '';
  integrationData: any = '';
  parallelActivities: any = '';

  constructor(private req: RequestService<any>, public _location: Location) { }

  ngOnInit() {
  }

  addNode(): void {
    const newNode: Coordinates = {
      kombos: '' + (this.parcelCoordinates.length + 1),
      lat: '',
      lon: '',
    };
    this.parcelCoordinates.push(newNode);
    console.log(newNode)
  }

  removeNode(index: number): void {
    if (index >= 0 && index < this.parcelCoordinates.length) {
      this.parcelCoordinates.splice(index, 1);
      this.updateKombosValues();
    }
  }

  updateKombosValues(): void {
    for (let i = 0; i < this.parcelCoordinates.length; i++) {
      this.parcelCoordinates[i].kombos = '' + (i + 1);
    }
  }

  isDeleteDisabled() {
    return this.parcelCoordinates.length <= 1;
  }

  addPlot() {
    const updatedData = {
      co_ownership: this.coOwnership ? this.coOwnership : 0,
       
      location: this.location ? this.location : '',
      municipal_community: this.municipalCommunity ? this.municipalCommunity : '',
      parcel_coordinates: this.parcelCoordinates || [],
      plot_code: this.fieldCode ? this.fieldCode : '',
      regional_unit: this.unit ? this.unit : '',
      size: this.size ? this.size : 0,
      species: this.selectedSpecies ? this.selectedSpecies : '',
      type_of_community: this.communityType ? this.communityType : '',
      mapped_background: this.mappedBackground ? this.mappedBackground : '',
      eligible_area: this.eligibleArea ? this.eligibleArea : 0,
      protocol_number_eligible_area: this.protocolNumberEligibleArea ? this.protocolNumberEligibleArea : 0,
      grassland_for_livestock: this.grassland ? this.grassland : '',
      total_eligible_area: this.totalEligibleArea ? this.totalEligibleArea : 0,
      applied_area: this.appliedArea ? this.appliedArea : 0,
      community_public: this.communityPublic,
      slope_8: this.slope8,
      digital_area: this.digitalArea ? this.digitalArea : 0,
      digitized_perimeter: this.perimeter ? this.perimeter : 0,
      not_available_ab: this.notAvailableAB,
      renewable_energy_sources: this.ape,
      drilling: this.drill,
      enabled_rights: this.enabledRights,
      regulation_834_2007: this.regulation8342007,
      irrigated: this.irrigated,
      bordering_water: this.borderingWater,
      length_bordering_water: this.borderingWaterLength ? this.borderingWaterLength : 0,
      water_usage: this.waterUsage ? this.waterUsage : '',
      energy_type: this.energyType ? this.energyType : '',
      supply_number: this.supplyNumber ? this.supplyNumber : '',
      related_code: this.relatedCode ? this.relatedCode : '',
      related_list: this.relatedList ? this.relatedList : '',
      land_usage_code: this.landUsageCode ? this.landUsageCode : '',
      land_usage_dscr: this.landUsageDscr ? this.landUsageDscr : '',
      grazeland_ec: this.grazelandEc ? this.grazelandEc : '',
      ref_parcel_natura: this.parcelNatura ? this.parcelNatura : 0,
      ref_parcel_aegean: this.parcelAegean ? this.parcelAegean : 0,
      ref_parcel_value: this.parcelValue ? this.parcelValue : 0,
      ref_parcel_nitrate: this.parcelNitrate ? this.parcelNitrate : 0,
      ref_parcel_wetland: this.parcelWetland ? this.parcelWetland : 0,
      ref_parcel_groundwater: this.parcelGroundwater ? this.parcelGroundwater : 0,
      ref_parcel_national: this.parcelNational ? this.parcelNational : 0,
      title_type: this.titleType ? this.titleType : '',
      legal_parcel_ownership_proof: this.ownershipProof ? this.ownershipProof : 0,
      property_type: this.propertyType ? this.propertyType : '',
      atak_area: this.atakArea ? this.atakArea : 0,
      start_date: this.startDate ? this.startDate : '',
      expiry_date: this.expiryDate ? this.expiryDate : '',
      atak_exception: this.atakException ? this.atakException : '',
      atak_code: this.atakCode ? this.atakCode : '',
      kaek: this.kaek ? this.kaek : '',
      owner_contract_number: this.ownerContractNumber ? this.ownerContractNumber : '',
      owner_contract_date: this.ownerContractDate ? this.ownerContractDate : '',
      intercropping: this.intercropping,
      crop_type: this.cropType ? this.cropType : '',
      dry_xeric: this.dryXeric ? this.dryXeric : '',
      organic_farming: this.organicFarming,
      pef: this.pef ? this.pef : 0,
      subsidised_area: this.subsidised ? this.subsidised : 0,
      interseeded: this.interseeded ? this.interseeded : '',
      trees_less: this.treesLess ? this.treesLess : 0,
      trees_more: this.treesMore ? this.treesMore : 0,
      prod_tree_number: this.prodTreeNumber ? this.prodTreeNumber : 0,
      non_prod_tree_number: this.nonProdTreeNumber ? this.nonProdTreeNumber : 0,
      hive_number: this.hiveNumber ? this.hiveNumber : 0,
      crop_contract_number: this.cropContractNumber ? this.cropContractNumber : '',
      crop_contract_date: this.cropContractDate ? this.cropContractDate : '',
      unharvested: this.unharvested,
      ep_met_epi: this.epMetEpi ? this.epMetEpi : '',
      diat_per: this.diatPer,
      linked_regimes: this.linkedRegimes ? this.linkedRegimes : '',
      seed_produce: this.seedProduce ? this.seedProduce : '',
      integration_data: this.integrationData ? this.integrationData : '',
      parallel_activities: this.parallelActivities ? this.parallelActivities : ''
    };

    let dataObj = JSON.stringify(updatedData)
    console.log(updatedData)
    console.log(dataObj)
    this.req.postPlot(dataObj).subscribe(() => {
      Swal.fire(
        '',
        'Plot information updated successfully!',
        'success'
      )
      setTimeout(() => {
        this._location.back();
      }, 1000);
    })
  }
}


