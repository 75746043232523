<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
  {{'SIDEBAR.RECOMMEND' | translate}} / {{'SIDEBAR.FERT'| translate}}
</h1>
<ng-container *ngIf="currentStep === 1">
  <h1 class="text-md text-justify font-normal m-6 mt-12 text-gray-600">
    {{'RECOMMEND.DESCRIPTION' | translate}}
  </h1>
  <form class="m-6 mt-12 mb-4" [formGroup]="recommendationForm">
    <h2 class="text-md font-semibold text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
      {{'RECOMMEND.TYPE' | translate}}
    </h2>
    <mat-form-field>
      <mat-label>{{'RECOMMEND.SELECT' | translate}}</mat-label>
      <mat-select formControlName="cropType">
        <mat-option *ngFor="let type of cropTypes" [value]="type">{{ type | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="cottonStepper">
      <mat-vertical-stepper [linear]="false" #stepper>
        <mat-step *ngFor="let question of questions; let i = index">
          <ng-template matStepLabel>{{ question['question_body_' + translate.currentLang] }}</ng-template>
          <div [ngSwitch]="question.answer_type">
            <mat-form-field *ngSwitchCase="'dropdown'">
              <mat-label>{{'RECOMMEND.SELECT' | translate}}</mat-label>
              <mat-select [formControlName]="question._id">
                <ng-container *ngIf="isRegionQuestion(question); else defaultOptions">
                  <mat-option *ngFor="let region of regionTypes" [value]="region.value">
                    {{ region.translateKey | translate }}
                  </mat-option>
                </ng-container>
                <ng-template #defaultOptions>
                  <mat-option *ngFor="let answer of question.answers" [value]="+answer">
                    {{ answer | translate }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
            <mat-radio-group *ngSwitchCase="'checkbox'" [formControlName]="question._id">
              <mat-radio-button [value]="true">{{ 'RECOMMEND.Y' | translate }}</mat-radio-button>
              <mat-radio-button [value]="false">{{ 'RECOMMEND.N' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <button mat-button matStepperPrevious>{{ 'RECOMMEND.BACK' | translate }}</button>
            <button mat-button matStepperNext>{{ 'RECOMMEND.NEXT' | translate }}</button>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ 'RECOMMEND.FINISH' | translate }}</ng-template>
          <div>
            <button mat-button matStepperPrevious>{{ 'RECOMMEND.BACK' | translate }}</button>
            <button mat-raised-button id="saveButton" class="float-right m-2" (click)="recommendFert()">
              <mat-icon>done</mat-icon>
              {{ 'RECOMMEND.FINISH' | translate }}
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
    <div *ngIf="otherStepper">
      <mat-vertical-stepper [linear]="false" #stepper>
        <mat-step *ngFor="let question of questions; let i = index">
          <ng-template matStepLabel>{{ question['question_body_' + translate.currentLang] }}</ng-template>
          <mat-form-field>
            <mat-label>{{ 'RECOMMEND.SELECT' | translate }}</mat-label>
            <mat-select formControlName="regionType">
              <mat-option *ngFor="let region of regionTypes" [value]="region.value">
                {{ region.translateKey | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button mat-button matStepperNext>{{ 'RECOMMEND.NEXT' | translate }}</button>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ 'RECOMMEND.FINISH' | translate }}</ng-template>
          <div>
            <button mat-button matStepperPrevious>{{ 'RECOMMEND.BACK' | translate }}</button>
            <button mat-raised-button id="saveButton" class="float-right m-2" (click)="recommendFert()">
              <mat-icon>done</mat-icon>
              {{ 'RECOMMEND.FINISH' | translate }}
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="currentStep === 2">
  <div class="container mx-auto px-4">
    <div class="flex-auto mt-2 p-2 lg:px-10 bg-white rounded-lg" id="main_container">
      <div class="text-center text-gray-600 font-semibold text-lg uppercase"
        style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
        {{ 'RECOMMEND.RECOMMENDED_FERT_TITLE' | translate }}
      </div>
      <div class="flex flex-col justify-center">
        <mat-card class="w-full lg:w-3/4 my-2 p-2">
          <div class="p-2 text-lg uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
            {{ 'RECOMMEND.DETAILS' | translate }}
          </div>
          <mat-card-content>
            <div class="flex flex-col justify-center items-center text-justify text-gray-600">
              <div class="flex flex-col">
                <div *ngIf="cottonStepper">
                  <div *ngFor="let question of questions">
                    <div class="flex flex-row mt-4">
                      <div class="font-bold mr-2">{{ question['question_body_' + translate.currentLang] }}:</div>
                      <div>{{ getAnswerDisplayValue(question._id) | translate }}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="otherStepper">
                  <div *ngFor="let question of questions">
                    <div class="flex flex-row mt-4">
                      <div class="font-bold mr-2">{{ question['question_body_' + translate.currentLang] }}:</div>
                      <div>{{ otherDetails | translate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="my-6 text-justify text-lg">
        {{ 'RECOMMEND.RECOMMENDED_FERT_TEXT_1' | translate }} <b>{{user}}</b> {{ 'RECOMMEND.RECOMMENDED_FERT_TEXT_2' |
        translate }} <b>{{ crop | translate }}</b> {{ 'RECOMMEND.RECOMMENDED_FERT_TEXT_3' | translate }}
      </div>
      <div class="mt-12 flex flex-row justify-around">
        <mat-card class="w-full lg:w-1/3 text-center m-4 p-4 shadow-md">
          <mat-card-header>
            <mat-card-title>
              <div id="nutrientTab" class="rounded text-3xl text-semibold text-gray-800 px-10 py-8"
                style="text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);">
                N
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="text-lg mt-6 text-gray-800" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
              {{ recommendedN }} {{ recommendedUnits }}
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="w-full lg:w-1/3 text-center m-4 p-4 shadow-md">
          <mat-card-header>
            <mat-card-title>
              <div id="nutrientTab" class="rounded text-3xl text-semibold text-gray-800 px-3 py-8"
                style="text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);">
                P<sub>2</sub>O<sub>5</sub>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="text-lg mt-6 text-gray-800" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
              {{ recommendedP }} {{ recommendedUnits }}
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="w-full lg:w-1/3 text-center m-4 p-4 shadow-md">
          <mat-card-header>
            <mat-card-title>
              <div id="nutrientTab" class="rounded text-3xl text-semibold text-gray-800 px-6 py-8"
                style="text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);">
                K<sub>2</sub>O
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="text-lg mt-6 text-gray-800" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
              {{ recommendedK }} {{ recommendedUnits }}
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="mt-12 flex flex-col items-center justify-center">
      <div class="w-full lg:w-1/3">
        <canvas baseChart [type]="'bar'" [datasets]="chartData" [labels]="chartLabels" [options]="barChartOptions"
          [legend]="false">
        </canvas>
      </div>
    </div>
  </div>
</ng-container>