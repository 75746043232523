<div *ngIf="isSeeding" class="flex justify-center mt-2" style="height: 70%;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [columnDefs]="seedingColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="seedingData" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
<div *ngIf="isPlanting" class="flex justify-center mt-2" style="height: 70%;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [columnDefs]="plantingColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="plantingData" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
<div class="row mt-3" *ngIf="isSeeding">
    <div class="col-10">
    </div>
    <div class="col-2">
        <div class="card radius-10 border-0 border-3 border-info">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div>
                        <p class="mb-0 text-secondary">{{'SEEDPLANT.SEEDING_QUANTITY' | translate}}</p>
                        <h4 class="my-1 " style="font-size: x-large; color: #ffb82b ; font-weight: 600;">
                            {{seedingQuantity}}
                            <span style="font-size: small;">
                                {{'SEEDPLANT.KILOS' | translate}}
                            </span>
                        </h4>
                        <!-- <p class="mb-0 font-13">+2.5% from last week</p> -->
                    </div>
                    <div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                        <!-- <i class="fa fa-shopping-cart"></i> -->
                        <!-- <i class="fa-solid fa-calculator"></i>  -->
                        <i class="fa-solid fa-seedling" style="color: #5e5e5e; font-size: x-large; "></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3" *ngIf="isPlanting">
    <div class="col-10">
    </div>
    <div class="col-2">
        <div class="card radius-10 border-0 border-3 border-info">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div>
                        <p class="mb-0 text-secondary">{{'SEEDPLANT.PLANTING_QUANTITY' | translate}}</p>
                        <h4 class="my-1 " style="font-size: x-large; color:#ffb82b; font-weight: 600;">
                            {{plantingQuantity}}
                            <span style="font-size: small;">
                                {{'SEEDPLANT.ITEMS' | translate}}
                            </span>
                        </h4>
                        <!-- <p class="mb-0 font-13">+2.5% from last week</p> -->
                    </div>
                    <div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                        <!-- <i class="fa fa-shopping-cart"></i> -->
                        <!-- <i class="fa-solid fa-calculator"></i>  -->
                        <i class="fa-brands fa-pagelines" style="color: #5e5e5e; font-size: x-large; "></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>