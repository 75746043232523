<div id="scroll">
    <table border="1">
        <thead>
            <tr>
                <th *ngFor="let header of headers">{{ header }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of rows">
                <td>{{ row.crop }}</td>
                <td>{{ row.mediumSoil }}</td>
                <td>{{ row.heavySoil }}</td>
            </tr>
        </tbody>
    </table>
</div>
