<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} /  {{ 'MANAGEMENT.SEEDPLANT'| translate}}
</h1>
<div class="container mx-auto px-4">
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end">
            <mat-tab label="{{ 'SEEDPLANT.SEED' | translate }}">
                <div class="container mx-auto px-4">
                    <form>
                        <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                            {{ 'SEEDPLANT.SEEDING' | translate }}
                        </h6>
                        <hr class="my-4 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'HOME.CODE' | translate }}
                                    </label>
                                    <div
                                        class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <mat-select [formControl]="selectedItems" [(ngModel)]="selectedOptions" #select
                                            name="plotCode" placeholder="{{ 'CARBON.PLOTS' | translate }}"
                                            id="selectPlot">
                                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                                (change)="setAll($event.checked)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let code of plotCodes" [value]="code">
                                                <mat-checkbox (change)="toggleCodeSelection($event.checked, code)"
                                                    (click)="$event.stopPropagation()" [checked]="isCodeSelected(code)">
                                                    {{ code[0] }} - {{ code[3] }}
                                                </mat-checkbox>
                                                <hr style="color: gray;">
                                                <span class="row  ml-4 pt-1">
                                                    <div *ngFor="let subtask of code[4]"
                                                        (click)="$event.stopPropagation()">
                                                        <input type="checkbox" id="{{ subtask.id }}" class="check_round"
                                                            name="{{ subtask.id }}" [value]="subtask"
                                                            [checked]="selectedOptions.includes(subtask)"
                                                            (change)="toggleSelection(subtask, $event)">
                                                        <label for="{{ subtask.id }}">
                                                            <span style="font-size: 9px; color: gray;" class="pl-2">{{
                                                                subtask.crop_type }} /
                                                                {{ subtask.variety.split('(')[0]}}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Lot Number
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="seedingLotNumber" name="seedingLotNumber" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'CROP.DATETIME' | translate }}
                                    </label>
                                    <input type="date"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="seedingDate" name="seedingDate" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'SEEDPLANT.QUANTITY' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.1" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="seedingQuantity" name="seedingQuantity" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'SEEDPLANT.UNIT' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off" readonly
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="unitkg" name="unitkg" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mat-raised-button id="saveButton" class="float-right m-2" (click)="addSeeding()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="{{ 'SEEDPLANT.PLANT' | translate }}">
                <div class="container mx-auto px-4">
                    <form>
                        <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                            {{ 'SEEDPLANT.PLANTING' | translate }}
                        </h6>
                        <hr class="my-4 border-b-1 border-gray-300" />
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'HOME.CODE' | translate }}
                                    </label>
                                    <div
                                        class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                        <mat-select [formControl]="selectedItems" [(ngModel)]="selectedOptions" #select
                                            name="plotCode" placeholder="{{ 'CARBON.PLOTS' | translate }}"
                                            id="selectPlot">
                                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                                (change)="setAll($event.checked)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let code of plotCodes" [value]="code">
                                                <mat-checkbox (change)="toggleCodeSelection($event.checked, code)"
                                                    (click)="$event.stopPropagation()" [checked]="isCodeSelected(code)">
                                                    {{ code[0] }} - {{ code[3] }}
                                                </mat-checkbox>
                                                <hr style="color: gray;">
                                                <span class="row  ml-4 pt-1">
                                                    <div *ngFor="let subtask of code[4]"
                                                        (click)="$event.stopPropagation()">
                                                        <input type="checkbox" id="{{ subtask.id }}" class="check_round"
                                                            name="{{ subtask.id }}" [value]="subtask"
                                                            [checked]="selectedOptions.includes(subtask)"
                                                            (change)="toggleSelection(subtask, $event)">
                                                        <label for="{{ subtask.id }}">
                                                            <span style="font-size: 9px; color: gray;" class="pl-2">{{
                                                                subtask.crop_type }} /
                                                                {{ subtask.variety.split('(')[0]}}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Lot Number
                                    </label>
                                    <input type="text" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="plantingLotNumber" name="plantingLotNumber" />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'SEEDPLANT.DATETIME' | translate }}
                                    </label>
                                    <input type="date"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="plantingDate" name="plantingDate" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'SEEDPLANT.QUANTITY' | translate }}
                                    </label>
                                    <input type="number" min="0" step="0.00001" autocomplete="off"
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="plantingQuantity" name="plantingQuantity" />
                                </div>
                            </div>
                            <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        {{ 'SEEDPLANT.UNIT' | translate }}
                                    </label>
                                    <input type="text" autocomplete="off" readonly
                                        class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        [(ngModel)]="unitItem" name="unitkg" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button mat-raised-button id="saveButton" class="float-right m-2" (click)="addPlanting()">
                        <mat-icon>done</mat-icon>
                        {{ 'MANAGEMENT.SAVE' | translate }}
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>