<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.REPORTS'| translate}}
</h1>
<div class="container mx-auto px-4">
    <div class="flex-auto mt-16 p-4 bg-white rounded-lg">
        <form>
            <div class="row">
                <div class="lg:w-9/12">
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password">
                                    {{'REPORT.SELAGRO' | translate}}
                                </label>
                                <mat-select
                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm"
                                    [(ngModel)]="agronomist" [ngModelOptions]="{standalone: true}">
                                    <mat-option value="" disabled selected></mat-option>
                                    <mat-option *ngFor="let agronomist of agronomists" [value]="agronomist.id">
                                        {{ agronomist.firstName }} {{ agronomist.lastName }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password">
                                    {{ 'SEEDPLANT.STARTDATETIME' | translate }}
                                </label>
                                <input type="date"
                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    [(ngModel)]="startingDate" name="startDate" />

                            </div>
                        </div>
                        <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-gray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password">
                                    {{ 'SEEDPLANT.ENDDATETIME' | translate }}
                                </label>
                                <input type="date"
                                    class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    [(ngModel)]="endingDate" name="startDate" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:w-3/12 float-right pt-4 mt-1 px-5">
                    <button mat-raised-button id="button_excel" class="" (click)="downloadExcel()">
                        <i class="fa-solid fa-download"></i>
                        Excel
                    </button>
                    <button mat-raised-button id="button_pdf" class="ml-4" (click)="downloadPdf()">
                        <i class="fa-solid fa-download"></i>
                        PDF
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-md text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400" id="tableHead">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ 'REPORT.FNAME' | translate }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ 'REPORT.LNAME' | translate }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ 'REPORT.VAT' | translate }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ 'REPORT.ENTITY' | translate }}
                    </th>
                    <th scope="col" class="px-6 py-3 text-center">
                        {{ 'REPORT.RELATIONSHIP' | translate }}
                    </th>
                    <th scope="col" class="text-right px-12 py-3">
                        {{ 'MANAGEMENT_EQUIPMENT.ACTION' | translate }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let agronomist of agronomists" class=" m-1"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    id="border_table">
                    <th class="px-6 py-4" id="tableValues">
                        {{agronomist.firstName}}
                    </th>
                    <td class="px-6 py-4" id="tableValues">
                        {{agronomist.lastName}}
                    </td>
                    <td class="px-6 py-4" id="tableValues">
                        {{agronomist.vat}}
                    </td>
                    <td class="px-6 py-4" id="tableValues">
                        {{agronomist.entityType}}
                    </td>
                    <td class="px-6 py-4 text-center" id="tableValues">
                        {{agronomist.employmentRelationship}}
                    </td>
                    <td class="px-6 py-4 text-right">
                        <button mat-icon-button color="warn" (click)="deleteAgronomist(agronomist.id)">
                            <mat-icon style="color: red;">delete</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" (click)="editAgronomist(agronomist)">
                            <mat-icon style="color: #6aabff;">edit</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="flex flex-col justify-end items-end">
        <button mat-raised-button class="m-2" id="addButton" (click)="addAgronomist()">
            <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
            <span style="font-size: small; color: rgb(65, 65, 65);">
                {{ 'REPORT.ADDAGRO' | translate }}
            </span>
        </button>
    </div>
</div>