<nav class="flex justify-between bg-gradient-to-b from-green-100 to-green-200 py-2">
  <button (click)="toggleSidebar()" class="text-gray-700 hover:text-gray-500 mx-4">
    <i class="fas fa-bars text-lg"></i>
  </button>
  <div class="flex items-center justify-between mx-4">
    <a class="hover:cursor-pointer" (click)="toggleDropdown($event)" #btnDropdownRef>
      <div class="items-center flex">
        <span *ngIf="showLogin"
          class="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
          <i class="fas fa-user text-xl"></i>
        </span>
        <span *ngIf="!showLogin" [ngClass]="{
          'bg-green-300': superuser,
          'bg-orange-300': admin,
          'bg-blue-300': subadmin,
          'bg-gray-300': !(superuser || admin || subadmin)
        }" class="w-12 h-12 text-2xl text-white font-semibold inline-flex items-center justify-center rounded-full">
          {{firstName}}{{lastName}}
        </span>
      </div>
    </a>
    <div class="min-w-48 z-50" #popoverDropdownRef>
      <div class="bg-white text-base z-50 py-2 list-none rounded shadow-lg mt-1 min-w-48"
        [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'">
        <div class="flex justify-center">
          <button *ngIf="translate.currentLang === 'en'" (click)="changeLanguage('gr')"
            class="text-md py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-gray-500">
            <div class="flex items-center">
              GR
              <img src="assets/flag-gr.webp" class="w-6 ml-2">
            </div>
          </button>
          <button *ngIf="translate.currentLang === 'gr'" (click)="changeLanguage('en')"
            class="text-md py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-gray-500">
            <div class="flex items-center">
              EN
              <img src="../../../assets/flag-en.webp" class="w-6 ml-2">
            </div>
          </button>
        </div>
        <button *ngIf="!showLogin"
          class="text-md py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-gray-500"
          (click)="logout()">
          {{ 'SIDEBAR.LOGOUT' | translate }}
        </button>
      </div>
    </div>
  </div>
</nav>