<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.HISTORICAL'| translate}}
  </h1>
<div class="px-4">
    <div class="flex-auto mt-2 p-2 lg:px-10 rounded-lg" id="main_container">
        <div class="row mt-3">
            <div class="lg:w-3/12">
                <div class="lg:w-full mat-card img-fluid float-left border-info" id="cardPlot">
                    <div class="mat-card-body">
                        <div class="row">
                            <div class="lg:w-9/12 d-flex align-items-left justify-content-left">
                                <mat-form-field>
                                    <mat-label class="text-muted">
                                        {{'HISTORY.PLOTS_CHOOSE' | translate}}
                                    </mat-label>
                                    <mat-select [formControl]="selectedItems" [(ngModel)]="plotChoose" name="plotCode"
                                        placeholder="{{ 'CARBON.PLOTS' | translate }}" id="selectPlot">
                                        <input type="checkbox" id="all" class="example-margin" [checked]="allSelected"
                                            (change)="setAll($event)">
                                        <span class="text-muted" style="font-weight: 600;">
                                            Select All
                                        </span>
                                        <mat-option *ngFor="let code of plotCodes" [value]="code" style="color: grey;">
                                            <input type="checkbox" id="parent" [checked]="isCodeSelected(code)"
                                                (change)="toggleCodeSelection($event, code)"
                                                (click)="$event.stopPropagation()" (click)="MatCheck(code , $event)">
                                            <span class="text-muted text-xs">
                                                {{ code[0] }} - {{ code[3] }}
                                            </span>
                                            <hr style="color: gray;">
                                            <span class="row ml-4 pt-1">
                                                <div *ngFor="let subtask of code[4]" (click)="$event.stopPropagation()">
                                                    <input type="checkbox" id="child" [value]="subtask"
                                                        [checked]="plotChoose.includes(subtask)"
                                                        (change)="toggleSelection(subtask, $event, code)"
                                                        (click)="$event.stopPropagation()"
                                                        (click)="MatCheckSub(subtask, $event)">
                                                    <label for="{{ subtask.id }}">
                                                        <span style="font-size: 9px;" class="pl-2 text-muted">{{
                                                            subtask.crop_type }} /
                                                            {{ subtask.variety ? subtask.variety.split('(')[0] :
                                                            ''}}</span>
                                                    </label>
                                                </div>
                                            </span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="lg:w-3/12">
                                <img class="img-fluid mx-auto d-block mt-3
                                d-flex align-items-right justify-content-right" src="../../../../assets/land.png"
                                    width="50">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="farmerSelectBoolean" class="lg:w-3/12">
                <div class="lg:w-full mat-card img-fluid float-left">
                    <div class="mat-card">
                        <div class="mat-card-body">
                            <!-- <div class="row">
                                <div class="lg:w-6/12">
                                    <mat-form-field>
                                        <mat-label>{{'HISTORY.SELECT_TYPE' | translate}}</mat-label>
                                        <mat-select [(ngModel)]="plotTypeCrop">
                                            <mat-option *ngFor="let select of selectType" [value]="select"
                                                (click)="onTabChange($event)">
                                                {{ select}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="lg:w-6/12">
                                    <img class="img-fluid d-flex mx-auto align-items-right justify-content-right
                                     mt-2 " src="../../../../assets/agriculture.png" width="50">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="cropSelectBoolean" class="lg:w-3/12">
                <div class="lg:w-full mat-card img-fluid float-left border-info" id="cardPlot">
                    <div class="mat-card">
                        <div class="mat-card-body">
                            <div class="row">
                                <div class="lg:w-6/12">
                                    <mat-form-field>
                                        <mat-label>{{'HISTORY.SELECT_TYPE' | translate}}</mat-label>
                                        <mat-select [(ngModel)]="plotTypeCrop">
                                            <mat-option *ngFor="let select of selectType" [value]="select"
                                                (click)="onTabChange($event)">
                                                {{ select}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="lg:w-6/12">
                                    <img class="img-fluid d-flex mx-auto align-items-right justify-content-right
                                     mt-2 " src="../../../../assets/agriculture.png" width="50">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="manageSelectBoolean" class="lg:w-3/12">
                <div class="lg:w-full mat-card img-fluid float-left border-info" id="cardPlot">
                    <div class="mat-card">
                        <div class="mat-card-body">
                            <div class="row">
                                <div class="lg:w-6/12">
                                    <mat-form-field>
                                        <mat-label>{{'HISTORY.SELECT_TYPE' | translate}}</mat-label>
                                        <mat-select [(ngModel)]="plotTypeTreatments">
                                            <mat-option *ngFor="let select of selectTypeTreatments" [value]="select"
                                                (click)="onTabChange($event)">
                                                {{ select}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="lg:w-6/12 align-items-right justify-content-right">
                                    <img class="img-fluid mx-auto d-block mt-3" src="../../../../assets/farmer_tool.png"
                                        width="50">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="seedSelectBoolean" class="lg:w-3/12">
                <div class="lg:w-full  mat-card img-fluid float-left border-info" id="cardPlot">
                    <div class="mat-card">
                        <div class="mat-card-body">
                            <div class="row">
                                <div class="lg:w-6/12">
                                    <mat-form-field>
                                        <mat-label>{{'HISTORY.SELECT_TYPE' | translate}}</mat-label>
                                        <mat-select [(ngModel)]="plotTypePlantSeed">
                                            <mat-option *ngFor="let select of selectTypeSeed" [value]="select"
                                                (click)="onTabChange($event)">
                                                {{ select}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="lg:w-6/12">
                                    <img class="img-fluid mx-auto d-block mt-3"
                                        src="../../../../assets/seeding_planting.png" alt="" width="50"
                                        style="max-width: 100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Date -->
            <div class="lg:w-3/12 mat-card img-fluid float-center border-info" id="cardPlot">
                <div class="mat-card">
                    <div class="mat-card-body">
                        <div class="lg:w-6/12">
                            <mat-form-field class="example-form-field">
                                <mat-label>Enter a date range</mat-label>
                                <mat-date-range-input [rangePicker]="rangePicker">
                                    <input matStartDate placeholder="Start date" [(ngModel)]="startingDate">
                                    <input matEndDate placeholder="End date" [(ngModel)]="endingDate">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePicker>
                                    <mat-date-range-picker-actions>
                                        <button mat-button matDateRangePickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDateRangePickerApply
                                            (click)="applyDate()">Apply</button>
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="farmerSelectBoolean" class="lg:w-3/12 text-right mat-card"
                style="border: 0px solid grey;border-radius: 12px;">
                <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                    <div class="d-flex justify-content-center align-items-center mt-2">
                        <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvHisory()"
                            id="button_csv">
                            <i class="fa-solid fa-download"></i>
                            CSV
                        </button>
                        <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelFarmer()"
                            id="button_excel">
                            <i class="fa-solid fa-download"></i>
                            Excel
                        </button>
                        <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfFarmer()"
                            id="button_pdf">
                            <i class="fa-solid fa-download"></i>
                            Pdf
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="cropSelectBoolean" class="lg:w-3/12 text-right mat-card" style="border-radius: 12px;">
                <div *ngIf="isIrrigation" class="float-right text-right mat-card"
                    style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent"
                                (click)="exportCsvCropIrrigation()" id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent"
                                (click)="exportExcelCropIrrigation()" id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent"
                                (click)="exportPdfCropIrrigation()" id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFertilisation" class="float-right text-right mat-card"
                    style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvFertil()"
                                id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelFertil()"
                                id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfFertil()"
                                id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isSpraying" class="float-right text-right mat-card"
                    style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvSpraying()"
                                id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelSpray()"
                                id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfSpray()"
                                id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isHarvest" class="float-right text-right mat-card"
                    style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvHarvest()"
                                id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelHarvest()"
                                id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfHarvest()"
                                id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="manageSelectBoolean" class="lg:w-3/12 text-right mat-card" style="border-radius: 12px;">
                <div class="float-right text-right mat-card" style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvTreatment()"
                                id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelTreatment()"
                                id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfTreatment()"
                                id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="seedSelectBoolean" class="lg:w-3/12 text-right mat-card" style="border-radius: 12px;">
                <div *ngIf="isPlanting" class="float-right text-right mat-card"
                    style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvPlanting()"
                                id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelPlanting()"
                                id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfPlanting()"
                                id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isSeeding" class="float-right text-right mat-card"
                    style="border: 0px solid grey;border-radius: 12px;">
                    <div class="row align-items-right justify-content-right float-right" id="exportButtons">
                        <div class="d-flex justify-content-center align-items-center mt-2">
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportCsvSeeding()"
                                id="button_csv">
                                <i class="fa-solid fa-download"></i>
                                CSV
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportExcelSeeding()"
                                id="button_excel">
                                <i class="fa-solid fa-download"></i>
                                Excel
                            </button>
                            <button class="mx-2 mt-3" mat-raised-button color="accent" (click)="exportPdfSeeding()"
                                id="button_pdf">
                                <i class="fa-solid fa-download"></i>
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="lg:w-6/12 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/farmer_noColor.png" alt=""
                                style="background-color: #f4d998;
                             border: 3px solid rgb(253, 255, 252);
                             box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="lg:w-6/12 d-flex align-items-center justify-content-center">
                            {{ 'HISTORY.FARMER' | translate }}
                        </div>
                    </div>
                </ng-template>
                <app-farmerhistory [plotChoose]="plotChoose" [parentCode]="parentCode" [childCode]="childCode"
                    [setAllStatus]="setAllStatus" [parentCodeBoolean]="parentCodeBoolean"
                    [childCodeBoolean]="childCodeBoolean" [parentBox]="parentBox" [childBox]="childBox"
                    [afmFarmer]="afmFarmer" [firstName]="firstName" [lastName]="lastName">
                </app-farmerhistory>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="lg:w-3/12 d-flex align-items-center justify-content-center">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/agriculture.png" alt="" style="background-color: #ffa589;
                             border: 3px solid rgb(253, 255, 252);
                             box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
                              rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="lg:w-9/12 d-flex align-items-center justify-content-center">
                            {{ 'HISTORY.CROP' | translate }}
                        </div>
                    </div>
                </ng-template>
                <app-crophistory [plotValueCrop]="plotValueCrop" [plotTypeCrop]="plotTypeCrop" [plotChoose]="plotChoose"
                    [eventParent]="eventParent" [eventChild]="eventChild" [setAllStatus]="setAllStatus"
                    [parentCodeBoolean]="parentCodeBoolean" [childCodeBoolean]="childCodeBoolean"
                    [startingDateString]="startingDateString" [endingDateString]="endingDateString"
                    [tabStatus]="tabStatus" [afmFarmer]="afmFarmer" [firstName]="firstName" [lastName]="lastName">
                </app-crophistory>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="lg:w-6/12 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/farmer_tool.png"
                                style="background-color: #dfe95f;
                             border: 3px solid rgb(253, 255, 252);
                             box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="lg:w-6/12 d-flex align-items-center justify-content-center">
                            {{ 'HISTORY.TREAT' | translate }}
                        </div>
                    </div>
                </ng-template>
                <app-treatmentshistory [plotTypeTreatments]="plotTypeTreatments" [plotChoose]="plotChoose"
                    [setAllStatus]="setAllStatus" [parentCodeBoolean]="parentCodeBoolean"
                    [childCodeBoolean]="childCodeBoolean" [eventParent]="eventParent" [eventChild]="eventChild"
                    [startingDateString]="startingDateString" [endingDateString]="endingDateString"
                    [tabStatus]="tabStatus" [afmFarmer]="afmFarmer" [firstName]="firstName" [lastName]="lastName"
                    >
                </app-treatmentshistory>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="row">
                        <div class="lg:w-6/12 d-flex align-items-left justify-content-left">
                            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                                src="../../../../assets/seeding_planting.png" alt=""
                                style="background-color: #b0e9ff;
                             border: 3px solid rgb(253, 255, 252);
                             box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
                        </div>
                        <div class="lg:w-6/12 d-flex align-items-center justify-content-center">
                            {{ 'HISTORY.SEEDPLANT' | translate }}
                        </div>
                    </div>
                </ng-template>
                <app-seedhistory [plotValueSeed]="plotValueSeed" [plotTypePlantSeed]="plotTypePlantSeed"
                    [plotChoose]="plotChoose" [setAllStatus]="setAllStatus" [parentCodeBoolean]="parentCodeBoolean"
                    [childCodeBoolean]="childCodeBoolean" [eventParent]="eventParent" [eventChild]="eventChild"
                    [startingDateString]="startingDateString" [endingDateString]="endingDateString"
                    [tabStatus]="tabStatus" [afmFarmer]="afmFarmer" [firstName]="firstName"
                    [lastName]="lastName"></app-seedhistory>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>