<div *ngIf="isIrrigation" class="flex justify-center mt-2" style="height: 70%;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [columnDefs]="irrigationColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="irrigationData" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
<div *ngIf="isFertilisation" class="flex justify-center mt-2" style="height: 70%;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [columnDefs]="fertilisationColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="fertilisationData" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
<div *ngIf="isSpraying" class="flex justify-center mt-2" style="height: 70%;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [columnDefs]="sprayingColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="sprayingData" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
<div *ngIf="isHarvest" class="flex justify-center mt-2" style="height: 70%;">
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [columnDefs]="harvestColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="harvestData" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>

<div class="row mt-3" *ngIf="isIrrigation">
    <div class="col-8">
    </div>
    <div class="col-2">
        <div class="card radius-10 border-0 border-3 border-info">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div>
                        <p class="mb-0 text-secondary">Irrigation Quantity</p>
                        <h4 class="my-1 " style="font-size: x-large; color: rgb(61, 169, 97); font-weight: 600;">
                            {{irrigationQuantity.toFixed(2)}}
                            <span style="font-size: small;">
                                m³
                            </span>
                        </h4>
                        <!-- <p class="mb-0 font-13">+2.5% from last week</p> -->
                    </div>
                    <div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                        <img class="img-fluid mx-auto d-block" src="../../../../assets/irrigation_manag.png" alt=""
                            width="50px" style="max-width: 100%; padding: 4px; ">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-2">
        <div class="card radius-10 border-0 border-3 border-info">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div>
                        <p class="mb-0 text-secondary">Irrigation Hours</p>
                        <h4 class="my-1 " style="font-size: x-large; color: rgb(61, 169, 97); font-weight: 600;">
                            {{irrigationHours}}
                            <span style="font-size: small;">
                                Hours
                            </span>
                        </h4>
                        <!-- <p class="mb-0 font-13">+2.5% from last week</p> -->
                    </div>
                    <div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                        <!-- <i class="fa fa-shopping-cart"></i> -->
                        <!-- <i class="fa-solid fa-calculator"></i>  -->
                        <i class="fa-regular fa-clock" style="color: #5e5e5e; font-size: x-large; "></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="isFertilisation">
    <div class="col-7">
    </div>
    <div class="col-5">
        <!-- <div class="row">
            <div class="col-10 mt-2"
                style="background-color: #a1c6af; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="class text-muted pl-2 pr-2 pt-1 pb-0" style="font-size:large; font-weight: 700;">
                    Total Fertilisation
                </div>
                <div class="mat-card text-end">
                    <form class="example-form">
                        <div class="row">
                            <div class="col d-flex align-items-center justify-content-end">
                                <span class="text-muted" style="font-size: medium; font-weight: 600">
                                    Total Dossage:
                                </span>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width">
                                    <input matInput [value]="fertilisationDossage" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex align-items-center justify-content-end">
                                <span class="text-muted" style="font-size: medium; font-weight: 600">Total
                                    Amount:</span>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width">
                                    <input matInput [value]="fertilisationAmount.toFixed(2)" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="row" *ngIf="isSpraying">
    <div class="col-7">
    </div>
    <div class="col-5">
        <div class="row">
            <!-- <div class="col-10 mt-2"
                style="background-color: #a1c6af; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="class text-muted pl-2 pr-2 pt-1 pb-0" style="font-size:large; font-weight: 700;">
                    Total Spraying
                </div>
                <div class="mat-card text-end">
                    <form class="example-form">
                        <div class="row">
                            <div class="col d-flex align-items-center justify-content-end">
                                <span class="text-muted" style="font-size: medium; font-weight: 600">
                                    Total Dossage:
                                </span>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width">
                                    <input matInput [value]="sprayingDosage" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex align-items-center justify-content-end">
                                <span class="text-muted" style="font-size: medium; font-weight: 600">
                                    Total Amount:
                                </span>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width">
                                    <input matInput [value]="sprayingAmount" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex align-items-center justify-content-end">
                                <span class="text-muted" style="font-size: medium; font-weight: 600">
                                    Total Solution:
                                </span>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width">
                                    <input matInput [value]="sprayingSolution" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div> -->
        </div>
    </div>
</div>
<div class="row" *ngIf="isHarvest">
    <div class="col-7">
    </div>
    <!-- <div class="col-5">
        <div class="row">
            <div class="col-10 mt-2"
                style="background-color: #a1c6af; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="class text-muted pl-2 pr-2 pt-1 pb-0" style="font-size:large; font-weight: 700;">
                    Total Harvest
                </div>
                <div class="mat-card text-end">
                    <form class="example-form">
                        <div class="row">
                            <div class="col d-flex align-items-center justify-content-end">
                                <span class="text-muted" style="font-size: medium; font-weight: 600">
                                    Total Quantity:
                                </span>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width">
                                    <input matInput [value]="harvestSum" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> -->
</div>