<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} /  {{ 'SIDEBAR.STORAGE'| translate}}
</h1>
<div class="container mx-auto px-4">
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                {{ 'STORAGE.STORAGE' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'STORAGE.CATEGORY' | translate }}
                        </label>
                        <select
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="category" [ngModelOptions]="{standalone: true}" (change)="onProductCategoryChange()">
                            <option value="" disabled selected></option>
                            <option *ngFor="let category of productCategories" [ngValue]="category">{{category}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'CROP.COMMERCIAL' | translate }}
                        </label>
                        <select
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="commercialName" name="commercialName">
                            <option value="" disabled selected></option>
                            <option *ngFor="let name of commercialNames" [value]="name">{{ name }}</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'CROP.BATCH' | translate }}
                        </label>
                        <input type="text" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="batchNumber" name="batchNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'CROP.DATE_IMPORT' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="datetime" name="datetime" />
                    </div>
                </div>
                <div class="w-full lg:w-8/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'STORAGE.INITIAL' | translate }}
                        </label>
                        <input type="number" min="0" step="0.1" autocomplete="off"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="initialQuantity" name="initialQuantity" />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-raised-button id="saveButton" class="float-right m-2" (click)="addProduct()">
        <mat-icon>done</mat-icon>
        {{ 'MANAGEMENT.SAVE' | translate }}
    </button>
</div>