import { RequestService } from 'src/app/services/request.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StorageComponent implements OnInit {

  constructor(private req: RequestService<any>, public translate: TranslateService,) {
  }

  public storageProduct: any;
  public percentProduct: any;
  farmerId: any = '';
  plotCodes: any = [];
  quantity: any;
  public langStatus: any

  ngOnInit() {
    this.getStorageProducts();
    this.statusLanguage()
  }

  statusLanguage() {
    this.langStatus = this.translate.store.currentLang
    console.log(this.langStatus)
    if (this.langStatus == 'gr') {
      this.quantity = 'Ποσότητα'
    } else if (this.langStatus == 'en') {
      this.quantity = 'Quantity'
    }
  }

  async getStorageProducts() {
    this.req.getProducts().subscribe((res) => {
      console.log(res)
      this.storageProduct = res
      res.forEach(el => {
        el.percentage = parseFloat((el.quantity / el.initial_quantity * 100).toFixed(1))
      })
    })
  }
  
  formatNumber(number: any): string {
    const parsedNumber = parseFloat(number);

    // Format the number to have a comma as the decimal separator and a period as the thousands separator
    return parsedNumber.toFixed(1).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
