import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestService } from 'src/app/services/request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addequipment',
  templateUrl: './addequipment.component.html',
  styleUrls: ['./addequipment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddequipmentComponent implements OnInit {
  equipmentForm!: FormGroup;
  equipmentTypes = ['Tractors', 'Soil Preparation Equipment', 'Planting Equipment', 'Harvesting Equipment', 'Irrigation Equipment']
  equipmentNames = ['Hydraulic Sprayer.', 'Olive Harvester.', 'Spraying Turbine', 'otherEquipment', "Combine Harvesters",
    "Forage Harvesters", "Cotton Pickers", "Potato Harvesters", "Planters", "Seed Drills", "Utility Tractors", "Row Crop Tractors", "Orchard Tractors", "Garden Tractors"];
  selectedOptionName: any;
  selectedOptionType: any;
  otherEquipmentName: boolean = false;
  otherEquipmentType: boolean = false;
  equipment_type: any
  title: any;
  dialogTitle: string = "Add Equipment"
  actionButton: string = "Add"
  closeButton: string = "Close"
  actionOtherEquipmentName: string = "Type Equipment Name"
  actionOtherEquipmentService: string = "Type Equipment Type"
  public langStatus: any

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private http: RequestService<any>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public editData: any,
  ) { }

  ngOnInit() {
    this.http.getEquipments().subscribe(res => { })
    this.equipmentForm = this.fb.group({
      title: ['', Validators.required],
      date: ['', Validators.required],
      description: ['', Validators.required],
      equipment_type: ['', Validators.required],
      otherEquipmentService: ['', Validators.required],
      otherEquipmentName: ['', Validators.required]
    });
    if (this.editData) {
      this.dialogTitle = "Update Equipment"
      this.actionOtherEquipmentName = this.editData.title
      this.actionOtherEquipmentService = this.editData.equipment_type
      this.selectedOptionType = this.editData.equipment_type
      this.selectedOptionName = this.editData.title
      this.actionButton = "Update"
      this.equipmentForm.controls['title'].setValue(this.editData.title);
      this.equipmentForm.controls['date'].setValue(this.editData.purchase_datetime);
      this.equipmentForm.controls['description'].setValue(this.editData.description);
      this.equipmentForm.controls['equipment_type'].setValue(this.editData.equipment_type);
    };
    this.equipmentForm.get('equipment_type')?.valueChanges.subscribe((value) => {
      if (value === 'otherService') {
        this.otherEquipmentType = true;
      } else {
        this.otherEquipmentType = false
      }
    });
    this.equipmentForm.get('title')?.valueChanges.subscribe((value) => {
      if (value === 'otherEquipment') {
        this.otherEquipmentName = true;
      } else {
        this.otherEquipmentName = false
      }
    });
    this.statusLanguage()
  }

  statusLanguage() {
    this.langStatus = this.translate.store.currentLang
    if (this.langStatus === 'gr' && !this.editData) {
      this.dialogTitle = "Προσθήκη Εξοπλισμού"
      this.actionButton = "Προσθήκη"
      this.closeButton = "Άκυρο"
      this.equipmentTypes = ['Τρακτέρ', 'Εξοπλισμός Προετοιμασίας Εδάφους', 'Εξοπλισμός Φύτευσης', 'Εξοπλισμός Συγκομιδής', 'Εξοπλισμός Άρδευσης']
      this.equipmentNames = ['Υδρολιπαντήρας', 'Ελαιοραβδιστης', 'Ραντιστική Τουρμπίνα Ψεκασμού', 'Άλλος Εξοπλισμός', 'Θεριστικές Μηχανές',
        'Συλλεκτές Αγρού', 'Μηχανές Τρυγητικής Βάμβακος', 'Θεριστικές Πατατών', 'Φυτευτικά Μηχανήματα', 'Μηχανές Σποράς',
        'Πολυχρηστικοί Τρακτέρ', 'Τρακτέρ Με Καμπίνα', 'Τρακτέρ Καρυδάκι', 'Τρακτέρ Κήπου'];

    } else if (this.langStatus === 'gr' && this.editData) {
      this.dialogTitle = "Επεξεργασία Εξοπλισμού"
      this.actionButton = "Αλλαγή"
      this.closeButton = "Άκυρο"
      this.equipmentTypes = ['Τρακτέρ', 'Εξοπλισμός Προετοιμασίας Εδάφους', 'Εξοπλισμός Φύτευσης', 'Εξοπλισμός Συγκομιδής', 'Εξοπλισμός Άρδευσης']
      this.equipmentNames = ['Υδρολιπαντήρας', 'Ελαιοραβδιστης', 'Ραντιστική Τουρμπίνα Ψεκασμού', 'Άλλος Εξοπλισμός', 'Θεριστικές Μηχανές',
        'Συλλεκτές Αγρού', 'Μηχανές Τρυγητικής Βάμβακος', 'Θεριστικές Πατατών', 'Φυτευτικά Μηχανήματα', 'Μηχανές Σποράς',
        'Πολυχρηστικοί Τρακτέρ', 'Τρακτέρ Με Καμπίνα', 'Τρακτέρ Καρυδάκι', 'Τρακτέρ Κήπου'];
    }
  }

  addEquipment(event: any) {
    if (!this.editData) {
      if (this.equipmentForm.controls['equipment_type'].value === 'otherService') {
        this.equipment_type = this.equipmentForm.controls['otherEquipmentService'].value
      } else {
        this.equipment_type = this.equipmentForm.controls['equipment_type'].value
      }

      if (this.equipmentForm.controls['title'].value === 'otherEquipment') {
        this.title = this.equipmentForm.controls['otherEquipmentName'].value
      } else {
        this.title = this.equipmentForm.controls['title'].value
      }
      let updatedData = {
        // farmer_id: this.id,
        title: this.title,
        purchase_datetime: this.equipmentForm.controls['date'].value,
        description: this.equipmentForm.controls['description'].value,
        equipment_type: this.equipment_type
      };
      this.http.postEquipment(updatedData).subscribe(res => {
        if (this.langStatus === 'en') {
          Swal.fire({
            title: 'Equipment Added',
            text: 'Equipment information added successfully!',
            icon: 'success',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            timer: 3000,
          });
        } else if (this.langStatus === 'gr') {
          Swal.fire({
            title: 'Προστέθηκε Εξοπλισμός',
            text: 'Επιτυχής προσθήκη εξοπλισμού!',
            icon: 'success',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            timer: 3000,
          });
        }

        this.dialog.afterAllClosed.subscribe(result => {

        })
      })
    } else {
      this.updateEquipment()
    }
  }

  updateEquipment() {
    if (this.equipmentForm.controls['equipment_type'].value === 'otherService') {
      this.equipment_type = this.equipmentForm.controls['otherEquipmentService'].value
    } else {
      this.equipment_type = this.equipmentForm.controls['equipment_type'].value
    }
    if (this.equipmentForm.controls['title'].value === 'otherEquipment') {
      this.title = this.equipmentForm.controls['otherEquipmentName'].value
    } else {
      this.title = this.equipmentForm.controls['title'].value
    }
    let updatedData = {
      title: this.title ? this.title : "",
      purchase_datetime: this.equipmentForm.value.date ? this.equipmentForm.value.date : "",
      description: this.equipmentForm.value.description ? this.equipmentForm.value.description : "",
      equipment_type: this.equipment_type ? this.equipment_type : ""
    };
    this.http.updateEquipment(this.editData._id, updatedData).subscribe({
      next: (res) => {
        if (this.langStatus === 'en') {
          Swal.fire({
            title: 'Update Equipment',
            text: 'Equipment information updated successfully!',
            icon: 'success',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            timer: 3000
          });
        } else if (this.langStatus === 'gr') {
          Swal.fire({
            title: 'Επεξεργασία Εξοπλισμού',
            text: 'Επιτυχής αλλαγή πληροφοριών εξοπλισμού!',
            icon: 'success',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            timer: 3000
          });
        }
      },
      error: (err) => {
        console.log(err)
      }
    });
  }

}
