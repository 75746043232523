import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import Swiper from 'swiper';


@Component({
  selector: 'app-widgetshome',
  templateUrl: './widgetshome.component.html',
  styleUrls: ['./widgetshome.component.scss']
})
export class WidgetshomeComponent implements OnInit {
  site_lat: any = 36.819225990572
  site_lng: any = 22.763612994143
  farmerId: any = '';
  plotCodes: any = [];
  harvestDate: any;
  plotUsed: any;
  plotSpecies: any;
  plotCapacity: any;
  storage: any;
  plotCode: any;
  residueMethods: any = 0;
  sumFertilizerPercent: any;
  sumFertilizerQuant: any = 0;
  sumFertilizerQuantInit: any = 0;
  sumNoCategory: any = 0;
  sumPlotSize: any = 0;
  sumProtectionPercent: any;
  sumProtectionQuant: any = 0;
  sumProtectionQuantInit: any = 0;
  mobile = true;
  laptop = false;
  desktop = false;
  name = 'Angular with Swiper';
  mySwiper!: Swiper;
  slides = [
    'https://via.placeholder.com/300x200/FF5733/ffffff',
    'https://via.placeholder.com/300x200/C70039/ffffff',
    'https://via.placeholder.com/300x200/900C3F/ffffff'
  ];
  titleButtonsActive: any = [
    'Συσσώρευση σε σωρούς ή λακκούβες',
    'Κομπόστ μη εξαναγκασμένου αερισμού',
    'Κομπόστ εξαναγκασμένου αερισμού',
    'Ενσωμάτωση στον αγρό ή εδαφοκάλυψη',
    'Κάψιμο',
    'Απομάκρυνση από τον αγρό'
  ]
  plotsHarvest: any[] = [];
  plotsResidues: any[] = [];
  last_residue_datetime: any;
  last_residue_plot: any;
  res_categories_amount: any;
  total_residue_amount: any;
  public plotsData: any[] = []
  public harvestData: any[] = []
  public residueData: any[] = []
  public storageData: any[] = []
  public widgetData: any[] = []
  storageCategories: any;

  residuePiles: any = 0;
  residueCompostNonForced: any = 0;
  residueCompostForced: any = 0;
  residueGround: any = 0;
  residueBurn: any = 0;
  residueRemove: any = 0;



  constructor(
    private req: RequestService<any>,
    private http: HttpClient,
    private keycloak: KeycloakService) {

  }

  ngOnInit(): void {
    this.getWidgetsData()
    this.getPlotData()
    this.harvestDate = new Date
    if (window.screen.width < 600) {
      this.mobile = true;
      this.laptop = false;
      this.desktop = false;
    }
    if (window.screen.width > 600 && window.screen.width < 1200) {
      this.mobile = false;
      this.laptop = true
      this.desktop = false;
    }
    if (window.screen.width > 1200 && window.screen.width < 1500) {
      this.laptop = true;
      this.mobile = false;
      this.desktop = false;
    }
    if (window.screen.width > 1500) {
      this.laptop = false;
      this.mobile = false;
      this.desktop = true;
    }
  }

  formatNumber(number: any): string {
    const parsedNumber = parseFloat(number);

    // Format the number to have a comma as the decimal separator and a period as the thousands separator
    return parsedNumber.toFixed(1).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  async getPlotData() {
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCode = element.plot_code
        this.plotCodes.push(element);
        this.sumPlotSize += parseFloat(element.size)
        this.req.getPlotResidues(this.plotCode).subscribe(res => {
          res.forEach(el => {
            this.plotsResidues.push(el)
          })
          res.forEach(el => {
            if (el.total_amount === null || el.total_amount === undefined ||
              el.total_amount === 0) {
              this.residueMethods = 0
            } else {
              this.residueMethods += parseFloat(el.total_amount)
            }
          })
        })
      });

    });
  }

  async getWidgetsData() {
    this.req.getOverviewWidgets().subscribe(res => {
      this.widgetData.push(res)
      this.widgetData.forEach((element: any) => {
        element.residue_data.res_categories_amount.forEach((residue: any) => {
          if (residue._id.includes('Removed; left untreated in heaps or pits')) {
            this.residuePiles = residue.total_sum ? residue.total_sum : 0
          } else if (residue._id.includes('Removed; non-forced-aeration compost')) {
            this.residueCompostNonForced = residue.total_sum ? residue.total_sum : 0
          } else if (residue._id.includes('Removed; forced-aeration compost')) {
            this.residueCompostForced = residue.total_sum ? residue.total_sum : 0
          } else if (residue._id.includes('Left on field; incorporated or mulch')) {
            this.residueGround = residue.total_sum ? residue.total_sum : 0
          } else if (residue._id.includes('Burned')) {
            this.residueBurn = residue.total_sum ? residue.total_sum : 0
          } else if (residue._id.includes('Exported off farm')) {
            this.residueRemove = residue.total_sum ? residue.total_sum : 0
          }
        });
        element.storage_data.storage_categories_amount.forEach((el: any) => {
          if (el._id.toLowerCase().includes('prote')) {
            this.sumProtectionQuant = el.total_sum ? el.total_sum : 0
            this.sumProtectionQuantInit = el.initial_total_sum ? el.initial_total_sum : 0
          } else if (el._id.toLowerCase().includes('fertil')) {
            this.sumFertilizerQuant += parseFloat(el.total_sum)
            this.sumFertilizerQuantInit += parseFloat(el.initial_total_sum)
          }
        });
        this.sumProtectionPercent = ((this.sumProtectionQuant / this.sumProtectionQuantInit) * 100).toFixed(1)
        this.sumFertilizerPercent = ((this.sumFertilizerQuant / this.sumFertilizerQuantInit) * 100).toFixed(1)
      });
    });

  }
}
