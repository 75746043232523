import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meteo',
  templateUrl: './meteo.component.html',
  styleUrls: ['./meteo.component.scss']
})
export class MeteoComponent implements OnInit {

  stations: any[] = []

  constructor(private req: RequestService<any>, private router: Router) {}
 
  ngOnInit() {
    this.getDevices();
  }

  getDevices() {
    this.req.getWeatherStations().subscribe(res => {
      this.stations = res;
    })
  }

  goTo(id: any) {
    this.router.navigate(['/station-single', id])
  }

}
