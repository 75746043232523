import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService } from 'src/app/services/request.service';
import { KeycloakService } from 'keycloak-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addmaintenancelogs',
  templateUrl: './addmaintenancelogs.component.html',
  styleUrls: ['./addmaintenancelogs.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AddmaintenancelogsComponent implements OnInit {
  token: any;
  public id: any = '';
  maintenanceForm!: FormGroup;
  maintenanceType = [
    'Engine oil Check ',
    'Engine oil Change',
    'Transmission fluid Check',
    'Transmission fluid Change',
    'Coolants Check',
    'Coolants Change'];
  maintenanceDataLogs: any;
  equipmentList: any;
  selectedOptionType: any;
  dialogTitle: string = "Add Equipment Maintenance";
  actionButton: string = "Add";
  closeButton: string = "Close"
  actionOtherEquipmentName: string = "Type Equipment Name";
  actionOtherEquipmentService: string = "Type Equipment Service";
  selectedType: any;
  selectedTitle: any;
  selectedEquipmentType: any;
  selectedEquipment: any;
  titleOptions!: any;
  langStatus: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private http: RequestService<any>,
    private keycloak: KeycloakService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public editDataMain: any,
  ) {
    this.token = this.keycloak.getToken();
    this.token = this.token.__zone_symbol__value;
  }

  ngOnInit() {
    this.getId()
    this.maintenanceForm = this.fb.group({
      equipment: ['', Validators.required],
      equipment_type: ['', Validators.required],
      maintenance_type: ['', Validators.required],
      maintenance_datetime: ['', Validators.required],
      paratirisis: ['', Validators.required],
      responsible_person: ['', Validators.required],
      otherEquipmentService: ['', Validators.required],
      otherEquipmentName: ['', Validators.required]
    });
    if (this.editDataMain) {
      this.dialogTitle = "Update Equipment Maintenance"
      this.actionButton = "Update"
      this.maintenanceForm.controls['equipment'].setValue(this.editDataMain.equipment)
      this.maintenanceForm.controls['equipment_type'].setValue(this.editDataMain.equipment_type)
      this.maintenanceForm.controls['maintenance_datetime'].setValue(this.editDataMain.maintenance_datetime)
      this.maintenanceForm.controls['maintenance_type'].setValue(this.editDataMain.maintenance_type)
      this.maintenanceForm.controls['paratirisis'].setValue(this.editDataMain.paratirisis)
      this.maintenanceForm.controls['responsible_person'].setValue(this.editDataMain.responsible_person)
    }
    this.statusLanguage()
  }

  async getId() {
    this.http.getMaintenanceLogs().subscribe(res => {
      this.maintenanceDataLogs = res
    });
    this.http.getEquipments().subscribe(res => {
      this.equipmentList = res
    });
  }

  statusLanguage() {
    this.langStatus = this.translate.store.currentLang
    if (this.langStatus === 'gr' && !this.editDataMain) {
      this.dialogTitle = "Προσθήκη Συντήρησης"
      this.actionButton = "Προσθήκη"
      this.closeButton = "Άκυρο"
      this.maintenanceType = [
        'Έλεγχος Λαδιού Κινητήρα',
        'Αλλαγή Λαδιού Κινητήρα',
        'Έλεγχος Λαδιού Σασμάν',
        'Αλλαγή Λαδιού Σασμάν',
        'Έλεγχος Ψυκτικού Υγρού',
        'Αλλαγή Ψυκτικού Υγρού'];
    } else if (this.langStatus === 'gr' && this.editDataMain) {
      this.dialogTitle = "Αλλαγή Συντήρησης"
      this.actionButton = "Αλλαγή"
      this.closeButton = "Άκυρο"
      this.maintenanceType = [
        'Έλεγχος Λαδιού Κινητήρα',
        'Αλλαγή Λαδιού Κινητήρα',
        'Έλεγχος Λαδιού Σασμάν',
        'Αλλαγή Λαδιού Σασμάν',
        'Έλεγχος Ψυκτικού Υγρού',
        'Αλλαγή Ψυκτικού Υγρού'];
    }
  }

  onSelectTitle() {
    const selectedEquipment = this.equipmentList.find((equipment: any) => equipment.title === this.selectedTitle);
    this.titleOptions = selectedEquipment ? this.equipmentList.filter((equipment: any) => equipment.title === this.selectedTitle).map((equipment: any) => equipment.equipment_type) : [];
  }

  addMaintenanceLogs() {
    if (!this.editDataMain) {
      let updatedData = {
        equipment: this.maintenanceForm.controls['equipment'].value ? this.maintenanceForm.controls['equipment'].value : '',
        equipment_type: this.maintenanceForm.controls['equipment_type'].value ? this.maintenanceForm.controls['equipment_type'].value : '',
         
        maintenance_datetime: this.maintenanceForm.controls['maintenance_datetime'].value ? this.maintenanceForm.controls['maintenance_datetime'].value : '',
        maintenance_type: this.maintenanceForm.controls['maintenance_type'].value ? this.maintenanceForm.controls['maintenance_type'].value : '',
        paratirisis: this.maintenanceForm.controls['paratirisis'].value ? this.maintenanceForm.controls['paratirisis'].value : '',
        responsible_person: this.maintenanceForm.controls['responsible_person'].value ? this.maintenanceForm.controls['responsible_person'].value : '',
      };
      this.http.postEquipmentMaintenanceLogs(updatedData).subscribe((res) => {
        Swal.fire({
          title: 'Maintenance Logs Added',
          text: 'Maintenance Logs information Add successfully!',
          icon: 'success',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          timer: 3000
        });
      })
      this.dialog.afterAllClosed.subscribe(result => { })
    } else {
      this.updateMaintenanceLogs()
    }
  }

  updateMaintenanceLogs() {
    const updatedData = {
      equipment: this.maintenanceForm.controls['equipment'].value ? this.maintenanceForm.controls['equipment'].value : '',
      equipment_type: this.maintenanceForm.controls['equipment_type'].value ? this.maintenanceForm.controls['equipment_type'].value : '',
       
      maintenance_datetime: this.maintenanceForm.controls['maintenance_datetime'].value ? this.maintenanceForm.controls['maintenance_datetime'].value : '',
      maintenance_type: this.maintenanceForm.controls['maintenance_type'].value ? this.maintenanceForm.controls['maintenance_type'].value : '',
      paratirisis: this.maintenanceForm.controls['paratirisis'].value ? this.maintenanceForm.controls['paratirisis'].value : '',
      responsible_person: this.maintenanceForm.controls['responsible_person'].value ? this.maintenanceForm.controls['responsible_person'].value : '',
    };
    this.http.updateMaintenance(this.editDataMain._id, updatedData).subscribe({
      next: (res) => {
        Swal.fire({
          title: 'Update Maintenance',
          text: 'Maintenance information updated successfully!',
          icon: 'success',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          timer: 3000
        });
      },
      error: (err) => {
        console.log(err)
      }
    });
  }

}





