import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-storage',
  templateUrl: './manage-storage.component.html',
  styleUrls: ['./manage-storage.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ManageStorageComponent implements OnInit {

  id: any = '';

  //product body
  batchNumber: any = '';
  category: any = '';
  commercialName: any = '';
  datetime: any = '';
  expirationDate: any = '';
  initialQuantity: any = '';
  quantity: any = '';
  productCategories: any = [
    'fertiliser',
    'protection',
    'any'
  ];
  commercialNames: any = [];
  productId: { [key: string]: string } = {};

  constructor(private req: RequestService<any>, public _location: Location) { }

  ngOnInit() {
    // this.getId()
  }

  // async getId() {
  //   this.req.getFarmer().subscribe((res) => {
  //     this.id = res[0]._id;
  //   })
  // }

  onProductCategoryChange() {
    this.commercialNames = [];
    this.commercialName = '';
    this.productId = {};

    if (!this.category) {
      return;
    }
    if (this.category === 'fertiliser') {
      this.req.getRawProductsFertiliser().subscribe((data: any[]) => {
        this.commercialNames = data.map((item) => item.commercial_name);
        data.forEach((item) => {
          this.productId[item.commercial_name] = item._id;
        });
      });
    } else if (this.category === 'protection') {
      this.req.getRawProductsSpraying().subscribe((data: any[]) => {
        this.commercialNames = data.map((item) => item.commercial_name);
        data.forEach((item) => {
          this.productId[item.commercial_name] = item._id;
        });
      });
    } else {
      this.req.getRawProducts().subscribe((data: any[]) => {
        this.commercialNames = data.map((item) => item.commercial_name);

        data.forEach((item) => {
          this.productId[item.commercial_name] = item._id;
        });
      });
    }
  }

  addProduct() {
    const updatedData = {
       
      batch_number: this.batchNumber ? this.batchNumber : '',
      datetime: this.datetime ? this.datetime : '',
      category: this.category ? this.category : '',
      commercial_name: this.commercialName ? this.commercialName : '',
      initial_quantity: this.initialQuantity ? this.initialQuantity : '',
      quantity: this.initialQuantity ? this.initialQuantity : '',
      raw_product_id: this.productId[this.commercialName] ? this.productId[this.commercialName] : '',
    };
    let dataObj = JSON.stringify(updatedData)
    this.req.postProduct(dataObj).subscribe(() => {
      Swal.fire(
        '',
        'Storage product information updated successfully!',
        'success'
      )
      setTimeout(() => {
        this._location.back();
      }, 1000);
    })
  }
}
