<div class="container">
    <div class="row" style="margin-top:3%; margin-bottom: 3%; ">
        <div class="col-lg-6 col-md-6 col-sm-8 " *ngFor="let plot of plots">
            <mat-card id="cards_storagePlot" routerLink="/plot/{{plot._id}}">
                <div class="col">
                    <div class="row">
                        <div class="col-0">
                            <div
                                class="col d-flex justify-content-start; icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute right-0">
                                <img id="weather_icon" [src]="plot.icon" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-8 col-xs-8">
                            <mat-card-content class="align-items-center justify-content-center text-center">
                                <img id="plot_field" src="../../../../assets/plot5_img.png" alt="">
                            </mat-card-content>
                            <mat-card-content id="category-title">
                                <div class="col align-items-center justify-content-center text-center">
                                    <mat-card-title style="color: #b1b1b1; font-size: 14px;">
                                        {{'HOME.CODE' | translate }}: {{plot.plot_code}}
                                    </mat-card-title>
                                </div>
                            </mat-card-content>
                        </div>
                        <div class="col-md-9 col-sm-12 col-xs-12">
                            <mat-card-content>
                                <div id="info_bg">
                                    <div class="col">
                                        <div class="row">
                                            <mat-card-content>
                                                <div class="row ">
                                                    <div class="col-3 align-items-center justify-content-center"
                                                        id="info_icons">
                                                        <img src="../../../../assets/central-park.png">
                                                    </div>
                                                    <div
                                                        class="col d-flex text-secondary text-sm align-items-center justify-content-right">
                                                        <div class="font-weight-bold text-left" id="text_titles">
                                                            {{'HOME.MUNICIPAL' | translate }}:
                                                        </div>
                                                        <div id="text-info">
                                                            {{ plot.municipal_community.split('ΤΚ')[1]}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <mat-card-content>
                                                <div class="row mt-2">
                                                    <div class="col-3 align-items-center justify-content-center"
                                                        id="info_icons">
                                                        <img src="../../../../assets/location.png">
                                                    </div>
                                                    <div
                                                        class="col d-flex text-secondary text-sm align-items-center justify-content-right">
                                                        <div class="font-weight-bold text-left" id="text_titles">
                                                            {{'HOME.LOCATION' | translate }}:
                                                        </div>
                                                        <div id="text-info">
                                                            {{ plot.location}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <mat-card-content>
                                                <div class="row mt-2">
                                                    <div class="col-3 align-items-center justify-content-center"
                                                        id="info_icons">
                                                        <img src="../../../../assets/measure.png">
                                                    </div>
                                                    <div
                                                        class="col d-flex text-secondary text-sm align-items-center justify-content-right">
                                                        <div class="font-weight-bold text-left" id="text_titles">
                                                            {{'HOME.SIZE_PLOT' | translate }}:
                                                        </div>
                                                        <div id="text-info">
                                                            {{ plot.size | number: '1.2-2'}}{{'HOME.ACRES' | translate
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <mat-card-content>
                                                <div class="row mt-2">
                                                    <div class="col-3 align-items-center justify-content-center"
                                                        id="info_icons">
                                                        <img src="../../../../assets/trees.png">
                                                    </div>
                                                    <div
                                                        class="col d-flex text-secondary text-sm align-items-center justify-content-right">
                                                        <div class="font-weight-bold text-left" id="text_titles">
                                                            {{'HOME.DIFFERENT_SPECIES' | translate }}:
                                                        </div>
                                                        <div id="text-info">
                                                            {{ plot.cropsCount }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <mat-card-content>
                                                <div class="row mt-2">
                                                    <div class="col-3 align-items-center justify-content-center"
                                                        id="info_icons">
                                                        <img src="../../../../assets/numeric.png">
                                                    </div>
                                                    <div class="col text-secondary text-sm">
                                                        <div class="font-weight-bold text-left" id="text_titles">
                                                            {{'HOME.MAPPED' | translate }}:
                                                        </div>
                                                        <div id="text-info">
                                                            {{ plot.mapped_background }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <mat-card-content class="mt-2" id="weather">
                                                <div class="row mt-2" id="weather">
                                                    <span class="mb-1"
                                                        style="font-size: x-small; font-weight: 700; color: #808080ab;">
                                                        {{plot.weather}}
                                                    </span>
                                                    <div
                                                        class="col d-flex text-secondary text-sm align-items-center justify-content-right">
                                                        <div class="col-3" style="font-size: 10px;">
                                                            {{'HOME.TEMPERATURE' | translate}}: <br>
                                                            <span class="font-weight-bold text-left"
                                                                id="weather_titles">
                                                                <i class="fa-solid fa-temperature-three-quarters"
                                                                    style="font-size: 14px;">
                                                                </i>
                                                                {{plot.temperature}}
                                                                °C
                                                            </span>
                                                        </div>
                                                        <span
                                                            style="padding-left: 1%; padding-right: 1%; font-size:x-large; margin-top:3%">
                                                            |
                                                        </span>
                                                        <div class="col-2" style="font-size: 10px;">
                                                            {{'HOME.HUMIDITY' | translate}}: <br>
                                                            <span class="font-weight-bold text-left"
                                                                id="weather_titles">
                                                                <i class="fa-solid fa-droplet">
                                                                </i>
                                                                {{plot.humidity}}
                                                                %
                                                            </span>
                                                        </div>
                                                        <span
                                                            style="padding-left: 1%; padding-right: 1%; font-size:x-large; margin-top:3%">
                                                            |
                                                        </span>
                                                        <div class="col-2" style="font-size: 10px;">
                                                            {{'HOME.WIND' | translate}}: <br>
                                                            <span class="font-weight-bold text-left"
                                                                id="weather_titles">
                                                                <i class="fa-solid fa-wind">
                                                                </i>
                                                                {{plot.wind}}
                                                                bft
                                                            </span>
                                                        </div>
                                                        <span
                                                            style="padding-left: 1%; padding-right: 1%; font-size:x-large; margin-top:3%">
                                                            |
                                                        </span>
                                                        <div class="col-3" style="font-size: 10px;">
                                                            {{'HOME.RAIN' | translate}}: <br>
                                                            <span class="font-weight-bold text-left"
                                                                id="weather_titles">
                                                                <i class="fa-solid fa-cloud-rain">
                                                                </i>
                                                                {{plot.rain}}
                                                                mm
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </div>
                        <div class="col-1">
                            <mat-card-content>
                                <div *ngIf="plot.organic_farming == true"
                                    class="col-0 d-flex justify-content-end;
                            icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute" style="color: #6ed06e;">
                                    <button mat-raised-button
                                        style="background-color: #6ed06e; height: 30px; width: 55%;margin-top: 7%; margin-left: -70%; ">
                                        <i class="fa-brands fa-pagelines"
                                            style="color: #fdfdfd; font-size: 25px; margin-top: 4px; margin-left: 7%;">
                                        </i>
                                    </button>
                                </div>
                            </mat-card-content>
                        </div>
                    </div>
                    <mat-card-footer>
                        <div class="btn-group btn-group-lg d-flex" role="group" aria-label="..." id="buttons_group">
                            <button *ngIf="plot.crop_info[0].unharvested === false" type="button"
                                class="btn btn-secondary" id="buttons_cards"
                                style="background-color: #b1b1b1; color: white; border-bottom-left-radius: 0.75rem !important;"
                                class="avatar" id="avatars" [title]="titleButtonsinActive[0]">
                                <i class="fa-solid fa-wheat-awn"></i>
                            </button>
                            <button *ngIf="plot.crop_info[0].unharvested === true" type="button"
                                class="btn btn-secondary" id="buttons_cards"
                                style="background-color: rgb(219, 199, 93); color: white; border-bottom-left-radius: 0.75rem !important;"
                                class="avatar" id="avatars" [title]="titleButtonsActive[0]">
                                <i class="fa-solid fa-wheat-awn"></i>
                            </button>
                            <button *ngIf="plot.irrigated === false" type="button" class="btn btn-secondary"
                                id="buttons_cards" style="background-color: #b1b1b1; color: white;" class="avatar"
                                id="avatars" [title]="titleButtonsinActive[1]">
                                <i class="fa-solid fa-faucet-drip"></i>
                            </button>
                            <button *ngIf="plot.irrigated === true" type="button" class="btn btn-secondary"
                                id="buttons_cards" style="background-color: #8db9d2; color: white;" class="avatar"
                                id="avatars" [title]="titleButtonsActive[1]">
                                <i class="fa-solid fa-faucet-drip"></i>
                            </button>
                            <button *ngIf="plot.renewable_energy_sources === true" type="button"
                                class="btn btn-secondary" id="buttons_cards"
                                style="background-color: #6fe89a; color: white;" class="avatar" id="avatars"
                                [title]="titleButtonsActive[2]">
                                <img class="img-fluid" src="../../../../assets/wind_turbine.png" height="23px"
                                    width="23px">
                            </button>
                            <button *ngIf="plot.renewable_energy_sources === false" type="button"
                                class="btn btn-secondary" id="buttons_cards"
                                style="background-color: #b1b1b1; color: white;" class="avatar" id="avatars"
                                [title]="titleButtonsinActive[2]">
                                <img class="justify-content-center text-center img-fluid"
                                    src="../../../../assets/wind_turbine.png" height="23px" width="23px">
                            </button>
                            <button *ngIf="plot.drilling === false" type="button" class="btn btn-secondary"
                                id="buttons_cards"
                                style="background-color: #b1b1b1; color: white; border-bottom-right-radius: 0.75rem !important;"
                                class="avatar" id="avatars" [title]="titleButtonsinActive[3]">
                                <i class="fa-solid fa-oil-well"></i>
                            </button>
                            <button *ngIf="plot.drilling === true" type="button" class="btn btn-secondary"
                                id="buttons_cards"
                                style="background-color: #d3ec73; color: white; border-bottom-right-radius: 0.75rem !important;"
                                class="avatar" id="avatars" [title]="titleButtonsActive[3]">
                                <i class="fa-solid fa-oil-well"></i>
                            </button>
                        </div>
                    </mat-card-footer>
                </div>
            </mat-card>
        </div>
    </div>
</div>