import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2'
import { Location, formatDate } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.scss']
})
export class TreatmentComponent implements OnInit {
  @ViewChild('select') select!: MatSelect;
  allSelected = false;
  id: any = '';
  start_date: any = '';
  end_date: any = '';
  plotCode: any = '';
  plotCodes: any = [];
  treatment: any = '';
  treatments: any = [
    'TREATMENT.SEEDBED',
    'TREATMENT.1ST',
    'TREATMENT.2ND',
    'TREATMENT.WEED',
    'TREATMENT.IMPROVEMENT',
    'TREATMENT.FERT',
    'TREATMENT.RESIDUES',
    'TREATMENT.ENTOMOLOGICAL',
    'TREATMENT.FLAT',
    'TREATMENT.PRUNING',
    'TREATMENT.CLEAN',
    'TREATMENT.PLOWING',
    'TREATMENT.MILLING',
    'TREATMENT.MOWING_ROTOTILLER',
    'TREATMENT.MOWING_CULTIVATOR',
    'TREATMENT.MOWING_HARROW',
    'TREATMENT.MOWING_HOE',
    'TREATMENT.MOWING_ROTARY_DISC',
    'TREATMENT.MOWING_LAWNMOWERS'
  ];
  newPlots: any[] = [];
  selectedItems = new FormControl(<any>[]);
  allComplete: boolean = false;
  selectedOptions: any[] = [];
  code_id: any;
  arraySend: any[] = [];
  validStartDate: Date = new Date('2024-06-01T00:00:00');
  validEndDate: Date = new Date('2024-08-31T23:59:59');
  summerTreatment: boolean = false;

  constructor(private req: RequestService<any>, public _location: Location, private translate: TranslateService) { }

  ngOnInit() {
    this.getId()
    this.summerTreatment = false
  }

  async getId() {
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCodes.push([element.plot_code, element.size, element.crop_info[0].prod_tree_number,
        element.location, element.crop_info, element.crop_info.variety]);
      })
    })
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => {
        item.select()
      });
    } else {
      this.select.options.forEach((item: MatOption) => {
        item.deselect()
      });
    }

  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  toggleCodeSelection(checked: boolean, code: any) {
    if (checked) {
      code[4].forEach((subtask: any) => {
        if (!this.selectedOptions.includes(subtask)) {
          this.selectedOptions.push(subtask);
        }
      });
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => !code[4].includes(option));
    }
    this.updateAllComplete();
  }

  isCodeSelected(code: any): boolean {
    return code[4].every((subtask: any) => this.selectedOptions.includes(subtask));
  }

  toggleSelection(subtask: any, event: any) {
    const checked = event.target.checked;
    if (checked) {
      if (!this.selectedOptions.includes(subtask)) {
        this.selectedOptions.push(subtask);
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
    }
    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allComplete = this.plotCodes.every((code: any) =>
      code[4].every((task: any) => this.selectedOptions.includes(task))
    );
  }

  setAll(checked: boolean) {
    if (checked) {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          if (!this.selectedOptions.includes(subtask)) {
            this.selectedOptions.push(subtask);
          }
        });
      });
    } else {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
        });
      });
    }
    this.updateAllComplete();
  }

  addTreatment() {
    const currentLang = this.translate.currentLang;
    const fixedTime = 'T09:00';
    const startDate = new Date(this.start_date);
    const endDate = new Date(this.end_date);
    const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en') + fixedTime;
    const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en') + fixedTime;
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });

    this.translate.use('gr').subscribe(() => {
      this.translate.get(this.treatment).subscribe((translatedTreatment: string) => {
        const updatedData = {
          start_date: formattedStartDate ? formattedStartDate : '',
          end_date: formattedEndDate ? formattedEndDate : '',
          crop_info_id: this.arraySend ? this.arraySend : '',
          treatment: translatedTreatment ? translatedTreatment : '',
        };
        let dataObj = JSON.stringify(updatedData)
        this.translate.use(currentLang).subscribe(() => {
          this.req.postTreatment(dataObj).subscribe(() => {
            Swal.fire(
              '',
              'Treatment information updated successfully!',
              'success'
            )
            setTimeout(() => {
              this._location.back();
            }, 1000);
          })
        })
      })
    })
  }

  pruningCalculate(event: any) {
    const startDate = new Date(this.start_date);
    const endDate = new Date(this.end_date);
    if ((this.outOfRangeDate(startDate) || this.outOfRangeDate(endDate)) && (this.treatment == 'TREATMENT.2ND' || this.treatment == 'TREATMENT.1ST')) {
      this.summerTreatment = true
    } else {
      this.summerTreatment = false
    }
  }

  outOfRangeDate(date: Date): boolean {
    return date < this.validStartDate || date > this.validEndDate
  }
}