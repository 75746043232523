<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} / {{ 'MANAGEMENT.PRODUCT'| translate}}
</h1>
<div class="container mx-auto px-4">
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label aria-setsize="10">
                <mat-icon class="example-tab-icon" style="color: rgb(161, 198, 175);">edit</mat-icon>
                {{ 'PRODUCT.FERTTAB' | translate }}
            </ng-template>
            <div *ngIf="this.superuser" class="flex justify-between">
                <mat-checkbox [(ngModel)]="fertChange" (change)="onFertCheckboxChange()" class="p-2" color="warn">{{
                    "PRODUCT.CHECKBOX" | translate }}</mat-checkbox>
                <div class="col-2 p-3 pb-3 float-right">
                    <button class="col-12" mat-raised-button id="addButton" (click)="addFertiliser()">
                        <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
                        <span style="font-size: small; color: rgb(65, 65, 65);">
                            {{ 'PRODUCT.ADDFERT' | translate }}
                        </span>
                    </button>
                </div>
            </div>
            <div *ngIf="!this.superuser">
                <div class="col-2 p-3 pb-3 float-right">
                    <button class="col-12" mat-raised-button id="addButton" (click)="addFertiliser()">
                        <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
                        <span style="font-size: small; color: rgb(65, 65, 65);">
                            {{ 'PRODUCT.ADDFERT' | translate }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="fertDataSource" matSort id="upload-table">
                    <ng-container matColumnDef="commercial_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-left-radius: 1rem !important;">
                            {{ 'PRODUCT.COMMERCIAL'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.commercial_name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade;">
                            {{ 'PRODUCT.TYPE'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.type }}</td>
                    </ng-container>
                    <ng-container matColumnDef="substance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade;">
                            {{ 'PRODUCT.SUBSTANCE'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.substance }}</td>
                    </ng-container>
                    <ng-container matColumnDef="N">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            N
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.N }}</td>
                    </ng-container>
                    <ng-container matColumnDef="P">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            P
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.P}}</td>
                    </ng-container>
                    <ng-container matColumnDef="K">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            K
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.K}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade;">
                            {{'PRODUCT.STATUS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-icon *ngIf="row.status === 'approved'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="row.status === 'declined'" style="color: red;">cancel</mat-icon>
                            <mat-icon *ngIf="row.status === 'pending'"
                                style="color: lightslategray;">watch_later</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-right-radius: 1rem !important;">
                            {{'MANAGEMENT_EQUIPMENT.ACTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="warn" (click)="deleteProduct(row.id)"
                                [hidden]="!this.superuser">
                                <mat-icon style="color: red;">delete</mat-icon>
                            </button>
                            <button [hidden]="(this.row.status === 'approved' && !this.fertChange) || (this.row.status === 'declined' && !this.fertChange)" mat-icon-button color="primary" (click)="editFertiliser(row)"
                                *ngIf="row.status === 'pending' || this.superuser">
                                <mat-icon style="color: #6aabff;">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="fertDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: fertDisplayedColumns" (click)="onRowClicked(row)"
                        (mouseover)="onRowClicked(row)" [ngClass]="{ highlightTableColor: selectedRowIndex == row.id }">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                        </td>
                    </tr>
                </table>
                <mat-paginator #fertPaginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
                    showFirstLastButtons></mat-paginator>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" style="color: rgb(161, 198, 175);">edit</mat-icon>
                {{'PRODUCT.SPRAYTAB' | translate }}
            </ng-template>
            <div *ngIf="this.superuser" class="flex justify-between">
                <mat-checkbox [(ngModel)]="sprayChange" (change)="onSprayCheckboxChange()" class="p-2" color="warn">{{
                    "PRODUCT.CHECKBOX" | translate }}</mat-checkbox>
                <div class="col-2 p-3 pb-3 float-right">
                    <button class="col-12" mat-raised-button id="addButton" (click)="addSpray()">
                        <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
                        <span style="font-size: small; color: rgb(65, 65, 65);">
                            {{'PRODUCT.ADDSPRAY' | translate }}
                        </span>
                    </button>
                </div>
            </div>
            <div *ngIf="!this.superuser">
                <div class="col-2 p-3 pb-3 float-right">
                    <button class="col-12" mat-raised-button id="addButton" (click)="addSpray()">
                        <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
                        <span style="font-size: small; color: rgb(65, 65, 65);">
                            {{'PRODUCT.ADDSPRAY' | translate }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="sprayDataSource" matSort id="upload-table">
                    <ng-container matColumnDef="commercial_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-left-radius: 1rem !important;">
                            {{ 'PRODUCT.COMMERCIAL'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.commercial_name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="substance">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{ 'PRODUCT.SUBSTANCE'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.substance}}</td>
                    </ng-container>
                    <ng-container matColumnDef="percentage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{ 'PRODUCT.PERCENT'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.percentage}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dosage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{ 'PRODUCT.DOSAGE'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.dosage }}</td>
                    </ng-container>
                    <ng-container matColumnDef="phi">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            PHI
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.phi}}</td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{ 'STORAGE_TRANSLATE.CATEGORY'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.category}}</td>
                    </ng-container>
                    <ng-container matColumnDef="crop_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{ 'PRODUCT.CROP_TYPE'| translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.crop_type}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade;">
                            {{'PRODUCT.STATUS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-icon *ngIf="row.status === 'approved'" style="color: green;">check_circle</mat-icon>
                            <mat-icon *ngIf="row.status === 'declined'" style="color: red;">cancel</mat-icon>
                            <mat-icon *ngIf="row.status === 'pending'"
                                style="color: lightslategray;">watch_later</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-right-radius: 1rem !important;">
                            {{'MANAGEMENT_MAINTENANACE.ACTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="warn" (click)="deleteProduct(row.id)"
                                [hidden]="!this.superuser">
                                <mat-icon style="color: red;">delete</mat-icon>
                            </button>
                            <button [hidden]="(this.row.status === 'approved' && !this.sprayChange) || (this.row.status === 'declined' && !this.sprayChange)" mat-icon-button color="primary" (click)="editSpray(row)"
                                *ngIf="row.status === 'pending' || this.superuser">
                                <mat-icon style="color: #6aabff;">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="sprayDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: sprayDisplayedColumns" (click)="onRowClicked(row)"
                        (mouseover)="onRowClicked(row)" [ngClass]="{ highlightTableColor: selectedRowIndex == row.id }">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                        </td>
                    </tr>
                </table>
                <mat-paginator #sprayPaginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
                    showFirstLastButtons></mat-paginator>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>