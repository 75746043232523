<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}}
</h1>
<div class="main container">
    <div class="container-fluid center">
        <div class="row">
            <div *ngIf="(superuser || admin || subadmin)" class="my-3 lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                    [routerLink]="['/multimanagement']">
                    <mat-card-header id="category-title">
                        <div class="col-6" style="margin-top: -7%;">
                            <i class="fas fa-users text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                id="users_icon"></i>
                        </div>
                    </mat-card-header>
                    <div class="row">
                        <div class="col-12 col-md-12 pt-1 pb-1">
                            <div class="text-right d-flex flex-column align-items-right">
                                <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                    <a [routerLink]="['/multimanagement']" class="uppercase">
                                        {{'SIDEBAR.MULTI' | translate }}
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="row mt-3 align-items-left justify-content-left">
                <div class="row mb-4 align-items-left justify-content-left">
                    <div class="card p-2 lg:w-1/3 align-items-left justify-content-left sm: w-full"
                        id="categoryTitlePlot">
                        <h6 class="text-center text-white-400 text-sm font-bold uppercase" style="color: #374151;">
                            {{ 'CROP.FARMER_DETAILS' | translate }}
                        </h6>
                    </div>
                    <hr class="mb-3" id="hrBorder" />
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/farmer']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fas fa-user text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="farmer_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-3 col-md-3 pt-1 pb-1">
                                <div class="col-4 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/farmer']" class="uppercase">
                                            {{'SIDEBAR.TITLE'| translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/plots']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fas fa-map pr-4 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="plot_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-3 col-md-3 pt-1 pb-1">
                                <div class="col-4 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/plots']" class="uppercase">
                                            {{'SIDEBAR.PLOTS' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row mt-3 pt-4  align-items-left justify-content-left">
                <div class="row mb-4 align-items-left justify-content-left">
                    <div class="card p-2 lg:w-1/3 sm: w-full" id="categoryTitlePlot">
                        <h6 class="text-center text-white-400 text-sm font-bold uppercase" style="color: #374151;">
                            {{ 'CROP.CROP_MANAGEMENT' | translate }}
                        </h6>
                    </div>
                    <hr class="mb-3" id="hrBorder" />
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/seeding_planting']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fas fa-seedling text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="plant_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-12 col-md-12 pt-1 pb-1">
                                <div class="text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/seeding_planting']" class="uppercase">
                                            {{'MANAGEMENT.SEEDPLANT' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/crop']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fa-solid fa-wheat-awn text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="crop_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-12 col-md-12 pt-1 pb-1">
                                <div class="col-12 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/crop']" class="uppercase">
                                            {{'MANAGEMENT.CROP' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/treatment']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fas fa-tractor  text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="treatment_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-12 col-md-12 pt-1 pb-1">
                                <div class="col-12 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/treatment']" class="uppercase">
                                            {{'MANAGEMENT.TREAT' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row mt-4 mb-4 pt-2 align-items-left justify-content-left">
                <div class="row mb-4 align-items-left justify-content-left">
                    <div class="card p-2 mt-2 lg:w-1/3 sm:w-full " id="categoryTitlePlot">
                        <h6 class="text-center text-white-400 text-sm font-bold uppercase" style="color: #374151;">
                            {{ 'CROP.STORAGE_EQUIPMENT' | translate }}
                        </h6>
                    </div>
                    <hr class="mb-3" id="hrBorder" />
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/products']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fa-solid fa-boxes-stacked text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="product_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-12 col-md-12 pt-1 pb-1">
                                <div class="col-12 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/products']" class="uppercase">
                                            {{'MANAGEMENT.PRODUCT' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/storage']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fas fa-warehouse text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="storage_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-12 col-md-12 pt-1 pb-1">
                                <div class="col-12 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/storage']" class="uppercase">
                                            {{'SIDEBAR.STORAGE' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="lg:w-1/3 sm:w-full md:w-full lg:w-1/3 sm:w-full md:w-full">
                    <mat-card id="cards_carousel_navigation" class="example-card ml-4"
                        [routerLink]="['/management/equipment']">
                        <mat-card-header id="category-title">
                            <div class="col-6" style="margin-top: -7%;">
                                <i class="fa-solid fa-screwdriver-wrench text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-xl mt-n4"
                                    id="equipment_icon"></i>
                            </div>
                        </mat-card-header>
                        <div class="row">
                            <div class="col-12 col-md-12 pt-1 pb-1">
                                <div class="col-12 text-right d-flex flex-column align-items-right">
                                    <h3 class="text-end mt-xl-3 mt-lg-1 p-2" id="textInfo">
                                        <a [routerLink]="['/management/equipment']" class="uppercase">
                                            {{'SIDEBAR.EQUIPMENT' | translate }}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>