<!-- <h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600"
    style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); margin-top: -1px;">
    {{deviceName}}
</h1> -->
<div class="container p-1">
    <div class="row">
        <div class="col-6">
            <mat-card id="device-card" *ngFor="let device of devices">
                <div class="row">
                    <mat-card-header class="col-5" id="mat-card-header">
                        <div class="col" id="device-card-header">
                            <div class="row">
                                <img src="../../../assets/sensors/agnesDevice.png"
                                    class="img-fluid mx-3 rounded-rectangle img-thumbnail" id="deviceImg">
                            </div>
                            <div class="row text-left text-muted">
                                <mat-card-title id="truncate">
                                    {{deviceName}}
                                </mat-card-title>
                            </div>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-7 p-2 mr-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <div id="deviceTitle"> {{'SENSORS.DEVICE_NAME'| translate }}:</div>
                                                <div id="deviceTitleValues">{{ device.attributes.name }}</div>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <div id="deviceTitle">{{'SENSORS.SERIAL_NUMBER'| translate }}:</div>
                                                <div id="deviceTitleValues">{{ device.attributes.serial_no }}</div>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <div id="deviceTitle">{{'SENSORS.SENSOR'| translate }}:</div>
                                                <div id="deviceTitleValues">{{ device.attributes.components.length }}
                                                </div>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <div id="deviceTitle">{{'SENSORS.INSTALL'| translate }}:</div>
                                                <div id="deviceTitleValues">{{ device.attributes.created_at | date }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </div>
                    </mat-card-header>
                    <div class="col-6 pl-2 text-left mt-4" id="scrollable">
                        <div class='p-1 mt-1' *ngFor="let comp of device.attributes.components">
                            <div class="row">
                                <div class="col-3 p-2 mt-2" style="display: flex;
                                justify-content: right;
                                align-items: center;">
                                    <div *ngIf="comp.sensor_type.includes('NPK')">
                                        <img class="img-fluid mx-auto rounded-rectangle img-thumbnail mt-1 p-2"
                                            id="sensorsImg" src="../../../assets/sensors/npk.png" class="col p-1">
                                        <span class="ml-3" style="font-size: 12px;">
                                            {{comp.sensor_type}}
                                        </span>
                                    </div>
                                    <div *ngIf="comp.sensor_type.includes('PH')">
                                        <img class="img-fluid mx-auto rounded-rectangle img-thumbnail mt-1 p-2"
                                            id="sensorsImg" src="../../../assets/sensors/ph.png" class="col p-1">
                                        <span class="ml-4" style="font-size: 12px;">
                                            {{comp.sensor_type}}
                                        </span>
                                    </div>
                                    <div *ngIf="comp.sensor_type.includes('T1')">
                                        <img class="img-fluid mx-auto rounded-rectangle img-thumbnail mt-1 p-2"
                                            id="sensorsImg" src="../../../../assets/sensors/metering.png"
                                            class="col p-1">
                                        <span class="ml-2" style="font-size: 12px;">
                                            {{comp.sensor_type}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-9 mt-2 pl-4 text-muted" style="font-size: 12px;">
                                    <div class="row">
                                        <div class="col-6">
                                            {{'SENSORS.SENSOR_TYPE'| translate }}:
                                        </div>
                                        <div class="col-5 ml-2">
                                            {{comp.component_type}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            {{'SENSORS.STATUS'| translate }}:
                                        </div>
                                        <div class="col-5 ml-2">
                                            <div *ngIf="comp.is_active else OffStatus">
                                                <span class="d-inline-block rounded-circle" id="circleOnDevice">
                                                </span>
                                                <span class="" style="font-size: xx-small;">
                                                    {{'SENSORS.STATUS_ON'| translate }}
                                                </span>
                                            </div>
                                            <ng-template #OffStatus>
                                                <span class="d-inline-block rounded-circle" id="circleOffDevice">
                                                </span>
                                                <span class="" style="font-size: xx-small;">
                                                    {{'SENSORS.STATUS_OFF'| translate }}
                                                </span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <hr class="dark horizontal my-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-6">
            <div id="map"></div>
        </div>
    </div>
    <div class="row" *ngFor="let sens of componentsD; let groupIndex = index">
        <div class="col-5">
            <mat-card class="mt-1" id="cardButtons2" style="height: 70px;">
                <div class="row pt-2">
                    <!-- <div class="col-3  d-flex align-items-center justify-content-center"
                        style="font-weight: 600; font-size: small; color: rgb(173, 173, 173);">
                        {{'SENSORS.SENSOR_CHOOSE' | translate}}:
                    </div> -->
                    <div class="col-5">
                        <mat-button-toggle-group multiple id="buttonsGroup"
                            class="d-flex align-items-right justify-content-right">
                            <div class="col p-1 " *ngFor="let button of sens.attributes.components">
                                <mat-button-toggle class="text-center" (click)="onButtonClick($event, button)"
                                    (change)="chooseSensor($event, button, groupIndex, sens)"
                                    [ngClass]="{ 'buttonOn': button.isOn, 'buttonOff': !button.isOn }"
                                    [checked]="buttonStates2[groupIndex] && buttonStates2[groupIndex][button.sensor_type]">
                                    <div *ngIf="!spinner else hiddeButtons">
                                        <span class="mr-2">{{ button.sensor_type }}</span>
                                        <span *ngIf="button.is_active else OffStatus"
                                            class="d-inline-block rounded-circle" id="circleOnDevice">
                                        </span>
                                        <ng-template #OffStatus>
                                            <span class="d-inline-block rounded-circle" id="circleOffDevice">
                                            </span>
                                        </ng-template>
                                    </div>
                                </mat-button-toggle>
                                <ng-template #hiddeButtons></ng-template>
                            </div>
                        </mat-button-toggle-group>
                    </div>
                    <div class="col-5 d-flex align-items-center justify-content-center">
                        <mat-form-field class="example-form-field" color="warn" appearance="fill">
                            <mat-label>{{'SENSORS.DATE_RANGE' | translate}}</mat-label>
                            <mat-date-range-input [rangePicker]="rangePicker">
                                <input matStartDate placeholder="Start date" [(ngModel)]="startDate"
                                    style="color: grey; font-size: small;">
                                <input matEndDate placeholder="End date" [(ngModel)]="endDate"
                                    (ngModelChange)="onDateChange(sens)" style="color: grey; font-size: small;">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker style="font-size: small;" #rangePicker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-2 pt-1 d-flex align-items-right justify-content-right">
                        <button mat-mini-fab [matMenuTriggerFor]="menu"
                            style="background-color: #C4F99B; color: white;">
                            <mat-icon class="text-gray-700">download</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="csvExport()">
                                <span id="menuCSV">
                                    <i class="fa-solid fa-file-csv"></i>
                                    CSV
                                </span>
                            </button>
                            <button mat-menu-item (click)="excelExport()">
                                <span id="menuExcel">
                                    <i class="fa-solid fa-file-excel"></i>
                                    Excel
                                </span>
                            </button>
                            <button mat-menu-item (click)="pdfExport()">
                                <span id="menuPDF">
                                    <i class="fa-solid fa-file-pdf"></i>
                                    PDF
                                </span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-2 pt-2 text-center d-flex align-items-center justify-content-center">
            <mat-spinner *ngIf="spinner" diameter="50" color="accent"></mat-spinner>
        </div>

        <div class="col-5">
            <mat-card class="mt-1 mb-2 px-2" id="cardButtons2" style="height: 70px;">
                <div class="row">
                    <div class="col-9 pt-2 d-flex align-items-center justify-content-center">
                        <mat-form-field appearance="fill" color="warn" style="height: 100%">
                            <mat-label style="font-size:small;">
                                <i class="fa-solid fa-database" style="font-size: large;"></i>
                                {{'SENSORS.AGGREGATION' | translate}}
                            </mat-label>
                            <mat-select [(ngModel)]="selectedAggregation" style="color: grey; font-size: small;">
                                <mat-option [value]="agg" *ngFor="let agg of aggregationsArray"
                                    style="color: grey; font-size: small;">
                                    {{agg}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field color="warn" style="height: 100%">
                            <mat-label>
                                <i class="fa-solid fa-list-ol"></i>
                            </mat-label>
                            <input matInput maxlength="5" placeholder="Number" type="number"
                                style="color: grey; font-size: small;" [(ngModel)]="intervalNumber" class="text-center"
                                (change)="intervalNegative(intervalNumber)">
                        </mat-form-field>
                        <mat-form-field appearance="fill" color="warn" style="height: 100%">
                            <mat-label style="font-size:small;">
                                <i class="fa-solid fa-chart-simple" style="font-size: large;"></i>
                                {{'SENSORS.INTERVAL' | translate}}
                            </mat-label>
                            <mat-select [(ngModel)]="selectedInterval" style="color: grey; font-size: small;">
                                <mat-option [value]="inter" *ngFor="let inter of IntervalArray"
                                    style="color: grey; font-size: small;">{{inter}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 pb-3 d-flex align-items-center justify-content-center">
                        <button class="mr-3" mat-mini-fab style="background-color: #C4F99B; color: white;"
                            (click)="applyAll(sens)">
                            <mat-icon class="text-gray-700">check</mat-icon>
                        </button>
                        <button mat-mini-fab [matMenuTriggerFor]="menu"
                            style="background-color: #C4F99B; color: white;">
                            <mat-icon class="text-gray-700">download</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="csvExport()">
                                <span id="menuCSV">
                                    <i class="fa-solid fa-file-csv"></i>
                                    CSV
                                </span>
                            </button>
                            <button mat-menu-item (click)="excelExport()">
                                <span id="menuExcel">
                                    <i class="fa-solid fa-file-excel"></i>
                                    Excel
                                </span>
                            </button>
                            <button mat-menu-item (click)="pdfExport()">
                                <span id="menuPDF">
                                    <i class="fa-solid fa-file-pdf"></i>
                                    PDF
                                </span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-card id="cardsBottom">
                <div class="container my-2" id="scrollableTable">
                    <div class="py-2" id="tableValues" *ngIf="!aggFlag">
                        <table class="w-full text-sm text-left rtl:text-right">
                            <thead class="text-md">
                                <tr style="background-color: white;">
                                    <th scope="col" class="px-6 py-3 text-left" id="tableHead"
                                        style="border-top-left-radius: 8px;">{{'SENSORS.SENSOR_NAME'| translate }}</th>
                                    <th scope="col" class="px-6 py-3 text-right" id="tableHead">
                                        {{'SENSORS.SENSOR_LAST_MEASUREMENT'| translate }}</th>
                                    <th scope="col" class="px-6 py-3 text-center" id="tableHead">
                                        {{'SENSORS.SENSOR_UNIT'| translate }}</th>
                                    <th scope="col" class="px-6 py-3 text-center" id="tableHead"
                                        style="border-top-right-radius: 8px;">{{'SENSORS.SENSOR_UPDATE'| translate }}
                                    </th>
                                </tr>
                            </thead>
                            <ng-container *ngFor="let comp of lastDeviceComponents">
                                <tbody *ngIf="comp.attributes.renke">
                                    <tr *ngFor="let entry of comp.attributes.renke">
                                        <ng-container>
                                            <td class="px-2 py-1 text-left">{{ entry.name}}</td>
                                            <td class="px-2 py-1 text-right">{{ entry.value }}</td>
                                            <td class="px-2 py-1 text-center">{{ entry.unit }}</td>
                                            <td class="px-2 py-1 text-right">{{ comp.attributes.measurement_time | date:
                                                'medium'}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="comp.attributes.terros_12">
                                    <tr *ngFor="let entry of comp.attributes.terros_12">
                                        <ng-container *ngIf="isValidEntry(entry.name)">
                                            <td class="px-2 py-1 text-left">
                                                {{ getDisplayName(entry.name) }}
                                            </td>
                                            <td class="px-2 py-1 text-right">
                                                {{ getFormattedValue(entry.name, entry.value) }}
                                            </td>
                                            <td class="px-2 py-1 text-center">
                                                {{ getFormattedUnit(entry.name, entry.unit) }}
                                            </td>
                                            <td class="px-2 py-1 text-right">
                                                {{ comp.attributes.measurement_time | date: 'medium' }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>


                                <tbody *ngIf="comp.attributes.jxct_npk">
                                    <tr *ngFor="let entry of comp.attributes.jxct_npk">
                                        <ng-container>
                                            <td class="px-2 py-1 text-left">{{ entry.name.charAt(0).toUpperCase()+
                                                entry.name.slice(1).toLowerCase()}}</td>
                                            <td class="px-2 py-1 text-right">
                                                <div *ngIf="entry.name.includes('potas') else PotasValue">
                                                    {{entry.conversion_value.toFixed(2)}}
                                                </div>
                                                <ng-template #PotasValue>
                                                    {{ entry.value }}
                                                </ng-template>
                                            </td>
                                            <td class="px-2 py-1 text-center">
                                                <div *ngIf="entry.name.includes('potas') else PotasUnit">
                                                    {{entry.conversion_unit}}
                                                </div>
                                                <ng-template #PotasUnit>
                                                    {{ entry.alternative_unit }}
                                                </ng-template>
                                            </td>
                                            <td class="px-2 py-1 text-right">{{ comp.attributes.time | date:
                                                'medium'}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </ng-container>
                        </table>
                        <hr>
                    </div>
                    <div class="py-2" id="tableValues" *ngIf="aggFlag">
                        <table class="w-full text-sm text-left rtl:text-right">
                            <thead class="text-md">
                                <tr style="background-color: white;">
                                    <th scope="col" class="px-6 py-3 text-left" id="tableHead"
                                        style="border-top-left-radius: 8px;">{{'SENSORS.SENSOR_NAME'| translate }}</th>
                                    <th scope="col" class="px-6 py-3 text-right" id="tableHead">
                                        {{'SENSORS.SENSOR_LAST_MEASUREMENT'| translate }}</th>
                                    <th scope="col" class="px-6 py-3 text-center" id="tableHead">
                                        {{'SENSORS.SENSOR_UNIT'| translate }}</th>
                                    <th scope="col" class="px-6 py-3 text-center" id="tableHead"
                                        style="border-top-right-radius: 8px;">{{'SENSORS.SENSOR_UPDATE'| translate }}
                                    </th>
                                </tr>
                            </thead>
                            <ng-container *ngFor="let comp of lastDeviceComponents">
                                <tbody *ngIf="comp.renke">
                                    <tr *ngFor="let entry of comp.renke">
                                        <ng-container>
                                            <td class="px-2 py-1 text-left">{{ entry.name}}</td>
                                            <td class="px-2 py-1 text-right">{{ entry.value.toFixed(2) }}</td>
                                            <td class="px-2 py-1 text-center">{{ entry.unit }}</td>
                                            <td class="px-2 py-1 text-right">{{ comp.bucket | date:
                                                'medium'}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="comp.terros_12">
                                    <tr *ngFor="let entry of newTerros12">
                                        <ng-container>
                                            <td class="px-2 py-1 text-left">{{ entry.name}}</td>
                                            <td class="px-2 py-1 text-right">{{ entry.value.toFixed(2) }}</td>
                                            <td class="px-2 py-1 text-center">{{ entry.unit }}</td>
                                            <td class="px-2 py-1 text-right">{{ comp.bucket | date:
                                                'medium'}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="comp.jxct_npk">
                                    <tr *ngFor="let entry of comp.jxct_npk">
                                        <ng-container>
                                            <td class="px-2 py-1 text-left">{{ entry.name.charAt(0).toUpperCase()+
                                                entry.name.slice(1).toLowerCase()}}</td>
                                            <td class="px-2 py-1 text-right">
                                                <div *ngIf="entry.name.includes('potas') else PotasValue">
                                                    {{entry.conversion_value.toFixed(2)}}
                                                </div>
                                                <ng-template #PotasValue>
                                                    {{ entry.value.toFixed(2)}}
                                                </ng-template>
                                            </td>
                                            <td class="px-2 py-1 text-center">
                                                <div *ngIf="entry.name.includes('potas') else PotasUnit">
                                                    {{entry.conversion_unit}}
                                                </div>
                                                <ng-template #PotasUnit>
                                                    {{ entry.alternative_unit }}
                                                </ng-template>
                                            </td>
                                            <td class="px-2 py-1 text-right">{{ comp.bucket | date:
                                                'medium'}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </ng-container>
                        </table>
                        <hr>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-6">
            <mat-card id="cardsBottom">
                <mat-card-content>
                    <canvas #lineCanvas width="420" height="360" style="margin-top: -2%;"></canvas>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>