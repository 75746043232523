<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.WEATHER'| translate }}
</h1>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-6 mt-4 cursor-pointer" *ngFor="let station of stations">
            <mat-card (click)="goTo(station.station_id)" id="stationSensor">
                <div class="row no-gutters mt-2">
                    <div class="col-3 p-2 ml-4">
                        <img src="../../../assets/meteo"
                            class="img-fluid mx-auto rounded-rectangle img-thumbnail" id="deviceImg">
                    </div>
                </div>
                <div class="col-10 p-2 m-2 mx-auto">
                    <div class="row">
                        <div class="col-6">
                            <div class="col-12">
                                <div id="stationTitle">IMEI:</div>
                                <div id="stationTitleValue">{{ station.imei }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="stationTitle">{{ 'METEO.COMPANY_NAME' | translate }}:</div>
                                <div id="stationTitleValue">{{ station.company_name }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="stationTitle">{{ 'METEO.STATION_NAME' | translate }}:</div>
                                <div id="stationTitleValue">{{ station.station_name }}</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-12">
                                <div id="stationTitle">{{ 'HOME.CITY' | translate }}:</div>
                                <div id="stationTitleValue">{{ station.city }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="stationTitle">{{ 'FARMER.CITY_REGION' | translate }}:</div>
                                <div id="stationTitleValue">{{ station.region }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="stationTitle">{{ 'PRODUCT.COUNTRY' | translate }}:</div>
                                <div id="stationTitleValue">{{ station.country }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" style="position: absolute; top: 2%; left: 92%;">
                    <div *ngIf="station.active else offLine">
                        <span id="title">
                            Active
                        </span>
                        <div class="d-inline-block rounded-circle" id="circleOn">
                        </div>
                    </div>
                    <ng-template #offLine>
                        <span id="sensorsTitle">
                            Inactive
                        </span>
                        <div class="d-inline-block rounded-circle" id="circleOff">
                        </div>
                    </ng-template>
                </div>
            </mat-card>
        </div>
    </div>
</div>