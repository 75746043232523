<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} /  {{ 'SIDEBAR.EQUIPMENT'| translate}}
</h1>
<div class="container mx-auto px-4">
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label aria-setsize="10">
                <mat-icon class="example-tab-icon" style="color: rgb(161, 198, 175);">edit</mat-icon>
                {{'MANAGEMENT_EQUIPMENT.ADD_EQUIPMENT_BAR' | translate }}
            </ng-template>
            <div class="col-2 p-3 pb-3 float-right">
                <button class="col-12" mat-raised-button id="addButton" (click)="addEquipment()">
                    <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
                    <span style="font-size: small; color: rgb(65, 65, 65);">
                        {{'MANAGEMENT_EQUIPMENT.ADD_EQUIPMENT_BUTTON' | translate }}
                    </span>
                </button>
            </div>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource1" matSort id="upload-table">
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-left-radius: 1rem !important;">
                            {{'MANAGEMENT_EQUIPMENT.EQUIPMENT_NAME' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.title }}</td>
                    </ng-container>
                    <ng-container matColumnDef="equipment_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_EQUIPMENT.EQUIPMENT_TYPE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.equipment_type}}</td>
                    </ng-container>
                    <ng-container matColumnDef="purchase_datetime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_EQUIPMENT.DATE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.purchase_datetime | date: 'dd, MMMM, yyyy'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_EQUIPMENT.DESCRIPTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.description}}</td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-right-radius: 1rem !important;">
                            {{'MANAGEMENT_EQUIPMENT.ACTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="warn" (click)="deleteEquipment(row.id)">
                                <mat-icon style="color: red;">delete</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="editEquipment(row)">
                                <mat-icon style="color: #6aabff;">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"
                        (mouseover)="onRowClicked(row)" [ngClass]="{ highlightTableColor: selectedRowIndex == row.id }">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                            <!-- No data matching the filter "{{ input.value }}" -->
                        </td>
                    </tr>
                </table>
                <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
                    showFirstLastButtons></mat-paginator>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" style="color: rgb(161, 198, 175);">edit</mat-icon>
                {{'MANAGEMENT_MAINTENANACE.ADD_MAINTENANACE_BAR' | translate }}
            </ng-template>
            <div class="col-2 p-3 pb-3 float-right">
                <button class="col-12" mat-raised-button id="addButton" (click)="addMaintenance()">
                    <i class="fa-solid fa-plus" style="color: rgb(65, 65, 65);"></i>
                    <span style="font-size: small; color: rgb(65, 65, 65);">
                        {{'MANAGEMENT_MAINTENANACE.ADD_MAINTENANACE_BUTTON' | translate }}
                    </span>
                </button>
            </div>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource2" matSort id="upload-table">
                    <ng-container matColumnDef="equipment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-left-radius: 1rem !important;">
                            {{'MANAGEMENT_MAINTENANACE.MAINTENANACE_EQUIPMENT' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.equipment }}</td>
                    </ng-container>
                    <ng-container matColumnDef="equipment_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_MAINTENANACE.MAINTENANACE_TYPE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.equipment_type}}</td>
                    </ng-container>
                    <ng-container matColumnDef="maintenance_datetime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_MAINTENANACE.DATE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.maintenance_datetime | date: 'dd, MMMM, yyyy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="paratirisis">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_MAINTENANACE.DESCRIPTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.paratirisis}}</td>
                    </ng-container>
                    <ng-container matColumnDef="responsible_person">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #cffade">
                            {{'MANAGEMENT_MAINTENANACE.RESPONSIBLE_PERSON' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.responsible_person}}</td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            style="background-color: #cffade; border-top-right-radius: 1rem !important;">
                            {{'MANAGEMENT_MAINTENANACE.ACTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="warn" (click)="deleteMaintenance(row.id)">
                                <mat-icon style="color: red;">delete</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="editMaintenance(row)">
                                <mat-icon style="color: #6aabff;">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2" (click)="onRowClicked(row)"
                        (mouseover)="onRowClicked(row)" [ngClass]="{ highlightTableColor: selectedRowIndex == row.id }">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                        </td>
                    </tr>
                </table>
                <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
                    showFirstLastButtons></mat-paginator>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>