import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';

declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  farmerId: any = '';
  plotCodes: any = [];
  public map: any

  plot: any = [];
  displayedColumns: string[] = ['plot_code', 'municipal_community', 'location', 'regional_unit', 'co_ownership', 'size'];
  treatment: any[] = [];
  irrigation: any[] = [];
  fertilisation: any[] = [];
  seeding: any[] = [];
  planting: any[] = [];
  spraying: any[] = [];
  plotCode: any;
  drawCoords: any[] = []
  public farmerInfo: any[] = [];
  public plotLocations: any[] = [];
  public plotLocationsMap: any[] = [];
  mobile = false;
  laptop = false;
  desktop = false;
  lat: any;
  lon: any;
  zoom: any;
  comunityFarmer: any

  constructor(private router: Router, private req: RequestService<any>, public featureFlags: FeatureFlagsService) { }

  ngOnInit(): void {
    this.getPlotData()
    if (window.screen.width < 600) {
      this.mobile = true;
      this.laptop = false;
      this.desktop = false;
    }
    if (window.screen.width > 600 && window.screen.width < 1200) {
      this.mobile = false;
      this.laptop = true
      this.desktop = false;
    }
    if (window.screen.width > 1200 && window.screen.width < 1500) {
      this.laptop = true;
      this.mobile = false;
      this.desktop = false;
    }
    if (window.screen.width > 1500) {
      this.laptop = false;
      this.mobile = false;
      this.desktop = true;
    }
  }

  async getPlotData() {
    this.req.getFarmer().subscribe((res: any) => {
      this.farmerInfo.push(res)
      var regionalFarmer = res.regional_unit.split(' ')
      this.comunityFarmer = regionalFarmer.slice(2).join(' ')
    });
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotLocations.push(element)
        this.plotCodes.push(element.map_coordinates);
        this.plotLocationsMap = this.plotLocations.map((el: any) => {
          if (el.map_coordinates[0]) {
            return [{
              lat: el.map_coordinates[0].lat,
              lng: el.map_coordinates[0].lon,
            },
            el.crop_info.length,
            el.location,
            el.plot_code
            ]
          } else {
            return [{
              lat: 37.98773910747681,
              lng: 23.722049982626345,
            },
            el.crop_info.length,
            el.location,
            el.plot_code
            ]
          }
        })
      })
      this.initMap()
    });
  }

  initMap(): void {
    if (this.plotCodes[0]) {
      this.lat = this.plotCodes[0][0].lat
      this.lon = this.plotCodes[0][0].lon
      this.zoom = 13.3
    } else {
      this.lat = 37.98773910747681
      this.lon = 23.722049982626345
      this.zoom = 7
    }
    const mapOptions = {
      zoom: this.zoom,
      center: { lat: this.lat, lng: this.lon },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
    };
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      mapOptions
    );
    const plotLocations: [google.maps.LatLngLiteral, number, string, any][] = this.plotLocationsMap
    const infoWindow = new google.maps.InfoWindow();
    plotLocations.forEach(([position, species, location, plot_code], i) => {
      const marker = new google.maps.Marker({
        position,
        map,
        title:
          `  
          <div id="locard" style="overflow-x: hidden;">
          <span class="cageInfo" style="  
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: -10px;">
            <div class="imground img-fluid rounded-circle mb-3 img-thumbnail shadow-md" style="border: 3px solid #06ca5b;">
              <img id="imgcyrcle" src="../../../assets/land.png" width="45">
            </div>
          </span>
          <div id="cardlocation"class="row">
            <div class="col" style="display:flex; align-items:left; justify-content:space-between;">
              <div class="row" id="second">
                <div class="col-4">
                  <a class="text-left" style=" font-weight: bold;">Location
                    <a class="text-left" style=" font-weight: small;"><br>
                      ${location}
                    </a>
                  </a>
                </div>
                <div class="col-4 text-center">
                  <a class="text-center" style=" font-weight: bold;">Species
                    <a class="text-center" style=" font-weight: small;"><br>
                      ${species}
                    </a>
                  </a>
                </div>
                <div class="col-4 text-right">
                  <a style=" font-weight: bold;">Plot<br>
                    <a style=" font-weight: small;">
                      ${plot_code}
                    </a>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      `,
        label: `${plot_code}`,
        optimized: false,
      });
      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(marker.getTitle());
        infoWindow.open(marker.getMap(), marker);
      });
    });

    this.plotCodes.forEach((element: any) => {
      this.drawCoords = element.map((el: any) => {
        return {
          lat: el.lat,
          lng: el.lon
        }
      })
      const drowPolygons = new google.maps.Polygon({
        paths: this.drawCoords,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      drowPolygons.setMap(map);
    });
  }

  goTo() {
    this.router.navigateByUrl(`/management/farmer`)
  }
}


