import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { HomeComponent } from './views/home/home.component';
import { ManagementComponent } from './views/management/management.component';
import { FarmerComponent } from './views/management/farmer/farmer.component';
import { ManagePlotsComponent } from './views/management/manage-plots/manage-plots.component';
import { SeedingPlantingComponent } from './views/management/seeding_planting/seedingplanting.component';
import { TreatmentComponent } from './views/management/treatment/treatment.component';
import { CropComponent } from './views/management/crop/crop.component';
import { ManageStorageComponent } from './views/management/manage-storage/manage-storage.component';
import { ManageEquipmentComponent } from './views/management/manage-equipment/manage-equipment.component';
import { PlotsComponent } from './views/plots/plots.component';
import { SinglePlotComponent } from './views/single-plot/single-plot.component';
import { HistoryComponent } from './views/history/history.component';
import { UploadComponent } from './views/upload/upload.component';
import { StorageComponent } from './views/storage/storage.component';
import { CarbonFootprintComponent } from './views/carbon-footprint/carbon-footprint.component';
import { ProductComponent } from './views/management/product/product.component';
import { ReportsComponent } from './views/reports/reports.component';
import { RecommendFertiliserComponent } from './views/recommendations/recommend-fertiliser/recommend-fertiliser.component';
import { MultimanagementComponent } from './views/multimanagement/multimanagement.component';
import { DevicesComponent } from './views/devices/devices.component';
import { SingleDeviceComponent } from './views/devices/single-device/single-device.component';
import { MeteoComponent } from './views/meteo/meteo.component';
import { SingleMeteoComponent } from './views/meteo/single-meteo/single-meteo.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'plots', component: PlotsComponent, canActivate: [AuthGuard] },
  { path: 'plot/:id', component: SinglePlotComponent, canActivate: [AuthGuard] },
  { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'device-single/:id', component: SingleDeviceComponent, canActivate: [AuthGuard] },
  { path: 'weather-stations', component: MeteoComponent, canActivate: [AuthGuard] },
  { path: 'station-single/:id', component: SingleMeteoComponent, canActivate: [AuthGuard] },
  ...(!environment.uruguayFeatures ? [
    { path: 'history', component: HistoryComponent, canActivate: [AuthGuard] },
    { path: 'management', component: ManagementComponent, canActivate: [AuthGuard] },
    { path: 'carbon-footprint', component: CarbonFootprintComponent, canActivate: [AuthGuard] },
    { path: 'upload', component: UploadComponent, canActivate: [AuthGuard] },
    { path: 'storage', component: StorageComponent, canActivate: [AuthGuard] },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
    { path: 'management/farmer', component: FarmerComponent, canActivate: [AuthGuard] },
    { path: 'management/plots', component: ManagePlotsComponent, canActivate: [AuthGuard] },
    { path: 'management/seeding_planting', component: SeedingPlantingComponent, canActivate: [AuthGuard] },
    { path: 'management/treatment', component: TreatmentComponent, canActivate: [AuthGuard] },
    { path: 'management/crop', component: CropComponent, canActivate: [AuthGuard] },
    { path: 'management/storage', component: ManageStorageComponent, canActivate: [AuthGuard] },
    { path: 'management/equipment', component: ManageEquipmentComponent, canActivate: [AuthGuard] },
    { path: 'management/products', component: ProductComponent, canActivate: [AuthGuard] },
    { path: 'recommendations/fertiliser', component: RecommendFertiliserComponent, canActivate: [AuthGuard] },
    { path: 'multimanagement', component: MultimanagementComponent, canActivate: [RoleGuard] }
  ] : []),
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }