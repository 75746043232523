import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-farmerr',
  templateUrl: './farmer.component.html',
  styleUrls: ['./farmer.component.scss']
})
export class FarmerComponent implements OnInit {
  
  id: any = '';
  firstName: any = '';
  lastName: any = '';
  secLastName: any = '';
  vat: any = '';
  address: any = '';
  city: any = '';
  municipality: any = '';
  postalCode: any = '';
  contact: any = '';
  telephone: any = '';
  ggn: any = '';
  amka: any = '';
  registerNumber: any = '';
  globalGapStatus: any = '';
  doy: any = '';
  entityType: any = '';  // Possible values: "Φυσικό" or "Εταιρεία"
  legalStatus: any = '';  // Legal status value
  taxBookCategory: any = '';  // Tax book category value
  companyName: any = '';
  thirdPersonAdministration: any = '';  // Third person administration details
  anotherLegalEntityAdministration: any = '';  // Another legal entity administration details
  startingDate: any = '';  // Starting date of agricultural activity
  fatherName: any = '';
  dateOfBirth: any = '';  // Date of birth
  idNumber: any = '';  // ID number
  idType: any = '';  // ID type
  insuranceProvider: any = '';  // Insurance provider
  insuranceNumber: any = '';  // Insurance number
  creditInstitution: any = '';  // Credit institution
  creditInstitutionCode: any = '';  // Code of Credit Institution
  iban: any = '';
  regionalUnit: any = '';
  regionalUnitCode: any = '';
  region: any = '';
  regionCode: any = '';
  email: any = '';
  seatCommunity: any = '';
  seatCommunityCode: any = '';
  seatAddress: any = '';
  seatPostalCode: any = '';
  seatPhoneNumber: any = '';
  seatRegionalUnit: any = '';
  seatRegionalUnitCode: any = '';
  seatRegion: any = '';
  seatRegionCode: any = '';

  constructor(private req: RequestService<any>) { }

  ngOnInit() {
    this.getFarmerData()
  }

  async getFarmerData() {
    this.req.getFarmer().subscribe((res: any) => {
      this.id = res._id;
      this.firstName = res.first_name;
      this.lastName = res.last_name;
      this.secLastName = res.sec_last_name;
      this.vat = res.vat_number;
      this.address = res.address;
      this.city = res.town;
      this.municipality = res.municipal_district;
      this.postalCode = res.postal_code;
      this.contact = res.contact_person;
      this.ggn = res.ggn;
      this.registerNumber = res.register_number;
      this.globalGapStatus = res.global_gap_status;
      this.telephone = res.phone_number;
      this.amka = res.amka;
      this.doy = res.doy;
      this.entityType = res.entity_type;
      this.legalStatus = res.legal_status;
      this.taxBookCategory = res.tax_book_category;
      this.companyName = res.company_name;
      this.thirdPersonAdministration = res.third_person_administration;
      this.anotherLegalEntityAdministration = res.another_legal_entity_administration;
      this.startingDate = res.starting_date;
      this.fatherName = res.father_name;
      this.dateOfBirth = res.date_of_birth;
      this.idNumber = res.id_number;
      this.idType = res.id_type;
      this.insuranceProvider = res.insurance_provider;
      this.insuranceNumber = res.insurance_number;
      this.creditInstitution = res.credit_institution;
      this.creditInstitutionCode = res.credit_institution_code;
      this.iban = res.iban;
      this.regionalUnit = res.regional_unit;
      this.regionalUnitCode = res.regional_unit_code;
      this.region = res.region;
      this.regionCode = res.region_code;
      this.email = res.email;
      this.seatCommunity = res.seat_community;
      this.seatCommunityCode = res.seat_community_code;
      this.seatAddress = res.seat_address;
      this.seatPostalCode = res.seat_postal_code;
      this.seatPhoneNumber = res.seat_phone_number;
      this.seatRegionalUnit = res.seat_regional_unit;
      this.seatRegionalUnitCode = res.seat_regional_unit_code;
      this.seatRegion = res.seat_region;
      this.seatRegionCode = res.seat_region_code;
    })
  }

  updateData() {
    const updatedData = {
      first_name: this.firstName ? this.firstName : '',
      last_name: this.lastName ? this.lastName : '',
      vat_number: this.vat ? this.vat : '',
      address: this.address ? this.address : '',
      town: this.city ? this.city : '',
      municipal_district: this.municipality ? this.municipality : '',
      postal_code: this.postalCode ? this.postalCode : '',
      contact_person: this.contact ? this.contact : '',
      ggn: this.ggn ? this.ggn : '',
      register_number: this.registerNumber ? this.registerNumber : '',
      global_gap_status: this.globalGapStatus ? this.globalGapStatus : '',
      phone_number: this.telephone ? this.telephone : '',
      amka: this.amka ? this.amka : '',
      doy: this.doy ? this.doy : '',
      sec_last_name: this.secLastName ? this.secLastName : '',
      entity_type: this.entityType ? this.entityType : '',
      legal_status: this.legalStatus ? this.legalStatus : '',
      tax_book_category: this.taxBookCategory ? this.taxBookCategory : '',
      company_name: this.companyName ? this.companyName : '',
      third_person_administration: this.thirdPersonAdministration ? this.thirdPersonAdministration : '',
      another_legal_entity_administration: this.anotherLegalEntityAdministration ? this.anotherLegalEntityAdministration : '',
      starting_date: this.startingDate ? this.startingDate : '',
      father_name: this.fatherName ? this.fatherName : '',
      date_of_birth: this.dateOfBirth ? this.dateOfBirth : '',
      id_number: this.idNumber ? this.idNumber : '',
      id_type: this.idType ? this.idType : '',
      insurance_provider: this.insuranceProvider ? this.insuranceProvider : '',
      insurance_number: this.insuranceNumber ? this.insuranceNumber : '',
      credit_institution: this.creditInstitution ? this.creditInstitution : '',
      credit_institution_code: this.creditInstitutionCode ? this.creditInstitutionCode : '',
      iban: this.iban ? this.iban : '',
      regional_unit: this.regionalUnit ? this.regionalUnit : '',
      regional_unit_code: this.regionalUnitCode ? this.regionalUnitCode : '',
      region: this.region ? this.region : '',
      region_code: this.regionCode ? this.regionCode : '',
      email: this.email ? this.email : '',
      seat_community: this.seatCommunity ? this.seatCommunity : '',
      seat_community_code: this.seatCommunityCode ? this.seatCommunityCode : '',
      seat_address: this.seatAddress ? this.seatAddress : '',
      seat_postal_code: this.seatPostalCode ? this.seatPostalCode : '',
      seat_phone_number: this.seatPhoneNumber ? this.seatPhoneNumber : '',
      seat_regional_unit: this.seatRegionalUnit ? this.seatRegionalUnit : '',
      seat_regional_unit_code: this.seatRegionalUnitCode ? this.seatRegionalUnitCode : '',
      seat_region: this.seatRegion ? this.seatRegion : '',
      seat_region_code: this.seatRegionCode ? this.seatRegionCode : '',
    };

    let dataObj = JSON.stringify(updatedData)
    this.req.updateFarmer(dataObj).subscribe(() =>
      Swal.fire(
        '',
        'Farmer information updated successfully',
        'success'
      ));
  }
}