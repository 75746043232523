<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.DEVICES'| translate }}
</h1>
<div class="container">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 mt-4" *ngFor="let device of devices">
            <mat-card class="text-muted" style="height: 250px;" (click)="goTo(device.id)" id="cardSensor">
                <div class="row no-gutters mt-2">
                    <div class="col-3 p-2 ml-4">
                        <img src="../../../assets/sensors/agnesDevice.png"
                            class="img-fluid mx-auto rounded-rectangle img-thumbnail" id="deviceImg">
                    </div>
                    <div class="col-7 p-2 mr-2 mt-4">
                        <div class="row">
                            <div class="col-12">
                                <div id="deviceTitle"> {{'SENSORS.DEVICE_NAME'| translate }}:</div>
                                <div id="deviceTitleValues">{{ device.attributes.name }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="deviceTitle">{{'SENSORS.SERIAL_NUMBER'| translate }}:</div>
                                <div id="deviceTitleValues">{{ device.attributes.serial_no }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="deviceTitle">{{'SENSORS.SENSOR'| translate }}:</div>
                                <div id="deviceTitleValues">{{ device.attributes.components.length }}</div>
                            </div>
                            <div class="col-12 mt-1">
                                <div id="deviceTitle">{{'SENSORS.INSTALL'| translate }}:</div>
                                <div id="deviceTitleValues">{{ device.attributes.created_at | date }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="color: rgb(167, 167, 167);">
                <div class="row pt-2 pb-5" id="align">
                    <div class="col-3" style="color: rgb(59, 59, 59); font-size: small;"
                        *ngFor="let sens of device.attributes.components">
                        <div class="img-fluid rounded-rectangle img-thumbnail" id="sensors">
                            <p id="sensorsTitle">
                                {{sens.sensor_type}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col" style="position: absolute; top: 1%; left: 85%;">
                    <div *ngIf="device.attributes.is_online else offLine">
                        <span id="sensorsTitle">
                            {{'SENSORS.DEVICE_STATUS_ON'| translate }}
                        </span>
                        <div class="d-inline-block rounded-circle" id="circleOn">
                        </div>
                    </div>
                    <ng-template #offLine>
                        <span id="sensorsTitle">
                            {{'SENSORS.DEVICE_STATUS_OFF'| translate }}
                        </span>
                        <div class="d-inline-block rounded-circle" id="circleOff">
                        </div>
                    </ng-template>
                </div>
            </mat-card>
        </div>
    </div>
</div>