import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  devices: any[] = []

  constructor(
    private route: Router,
    private req: RequestService<any>
  ) {
  }

  ngOnInit() {
    this.getDevices();
  }

  getDevices() {
    this.req.getDevices().subscribe(res => {
      this.devices = res;
    })
  }

  goTo(id: any) {
    this.route.navigate(['/device-single', id])
  }

}
