import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AddequipmentComponent } from './addequipment/addequipment.component';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2'
import { AddmaintenancelogsComponent } from './addmaintenancelogs/addmaintenancelogs.component';

@Component({
  selector: 'app-manage-equipment',
  templateUrl: './manage-equipment.component.html',
  styleUrls: ['./manage-equipment.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ManageEquipmentComponent implements OnInit {

  displayedColumns: string[] = ['title', 'equipment_type', 'purchase_datetime', 'description', 'action'];
  displayedColumns2: string[] = ['equipment', 'equipment_type', 'maintenance_datetime', 'paratirisis', 'responsible_person', 'action'];
  dataSource1!: MatTableDataSource<any>;
  dataSource2!: MatTableDataSource<any>;

  @ViewChild('paginator1') paginator1!: MatPaginator;
  @ViewChild('sort1') sort1!: MatSort;

  @ViewChild('paginator2') paginator2!: MatPaginator;
  @ViewChild('sort2') sort2!: MatSort;

  public selectedRow: any;
  public selectedRowIndex: any;
  public id: any = '';
  equipmentDescription: any = '';
  equipmentType: any = '';
  equipmentDate: any = '';
  title: any = '';
  equipment: any = '';
  maintenanceType: any = '';
  logsDate: any = '';
  responsibePerson: any = '';
  logsDescription: any = '';

  constructor(private req: RequestService<any>, private dialog: MatDialog) { }

  ngOnInit() {
    this.getId()
  }

  async getId() {
    this.req.getEquipments().subscribe((res) => {
      this.dataSource1 = new MatTableDataSource(res);
      this.dataSource1.paginator = this.paginator1;
      this.dataSource1.sort = this.sort1;
    });
    this.req.getMaintenanceLogs().subscribe(res => {
      this.dataSource2 = new MatTableDataSource(res);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource2.sort = this.sort2;
    })
  }

  onRowClicked(row: any) {
    this.selectedRow = row.file_id;
    this.selectedRowIndex = row.id;
    this.selectedRow = row
  }

  addEquipment() {
    const dialogRef = this.dialog.open(AddequipmentComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getId()
    });
  }

  editEquipment(row: any) {
    const dialogRef = this.dialog.open(AddequipmentComponent, {
      data: row
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.getId()
    });
  }

  deleteEquipment(id: any) {
    Swal.fire({
      title: 'Are you sure you want to delete this?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'rgb(147, 163, 160)',
      confirmButtonText: 'Yes, delete it!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          timer: 3000
        });
        this.req.deleteFileEquipment(this.selectedRow._id).subscribe(res => {
        this.getId()
        })
      }
    });
  }

  addMaintenance() {
    const dialogRef = this.dialog.open(AddmaintenancelogsComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getId()
    });
  }

  editMaintenance(row: any) {
    const dialogRef = this.dialog.open(AddmaintenancelogsComponent, {
      data: row
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.getId()
    });
  }

  deleteMaintenance(id: any) {
    Swal.fire({
      title: 'Are you sure you want to delete this?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'rgb(147, 163, 160)',
      confirmButtonText: 'Yes, delete it!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          icon: 'success',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          timer: 3000
        });
        this.req.deleteFileMaintenance(this.selectedRow._id).subscribe(res => {
          this.dialog.afterAllClosed.subscribe(result => {
            this.getId()
          })
        })
      }
    });
  }

}
