<h1 mat-dialog-title class="text-center">{{ dialogTitle | translate }}</h1>
<form class="example-form" id="form" [formGroup]="agroForm">
    <div class="flex justify-center">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.FNAME'| translate }}</mat-label>
                    <input matInput formControlName="firstName" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.LNAME'| translate }}</mat-label>
                    <input matInput formControlName="lastName" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.FATHER'| translate }}</mat-label>
                    <input matInput formControlName="fatherName" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.VAT'| translate }}</mat-label>
                    <input matInput formControlName="vat" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.RELATIONSHIP'| translate }}</mat-label>
                    <mat-select matInput formControlName="employmentRelationship" (selectionChange)="onRelationshipChange($event)">
                        <mat-option *ngFor="let relationship of relationships"
                            [value]="relationship">{{relationship}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.GEOTE'| translate }}</mat-label>
                    <input matInput formControlName="geoteNumber" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="specifyInput" class="flex justify-center">
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.SPECIFY'| translate }}</mat-label>
                    <input matInput formControlName="specify" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.ENTITY'| translate }}</mat-label>
                    <mat-select matInput formControlName="entityType">
                        <mat-option *ngFor="let entityType of entityTypes" value="{{entityType}}">
                            {{entityType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'REPORT.COMPANYNAME'| translate }}</mat-label>
                    <input matInput formControlName="companyName" />
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
<div class="mt-2 mx-1 p-3 float-right">
    <div class="row">
        <div class="col-5">
            <button mat-raised-button mat-dialog-close="Close" style="background-color: red; color: white;">{{
                closeButton | translate }}</button>
        </div>
        <div class="col-5 ml-2">
            <button mat-raised-button mat-dialog-close (click)="addAgronomist()"
                style="background-color: #3fb56d; color: white;">{{ actionButton | translate }}</button>
        </div>
    </div>
</div>