<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.CARBON'| translate}}
</h1>
<div class="container mx-auto">
    <div class="mt-8 flex flex-wrap justify-around">
        <mat-form-field>
            <mat-label>{{'CARBON.PLOTS' | translate}}</mat-label>
            <mat-select [(ngModel)]="plotCode" name="plotCode" multiple>
                <mat-option *ngFor="let code of plotCodes" [value]="code">{{ code }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'CARBON.DATE_RANGE' | translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [(ngModel)]="startingDate">
                <input matEndDate placeholder="End date" [(ngModel)]="endingDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>
    <button
        class="m-4 p-2 bg-green-100 text-gray-400 active:bg-green-200 font-semibold uppercase text-md rounded-lg shadow-md hover:shadow-lg outline-none focus:outline-none"
        (click)="onClick()">{{'CARBON.CARBON_CALCULATE' | translate}}</button>
    <div *ngIf="clicked">
        <div class="flex flex-col-2 justify-center px-2">
            <div *ngFor="let plot of this.plotCode" class="w-full lg:w-1/4">
                <div class="text-lg text-normal text-center text-gray-600">
                    {{'CARBON.PLOT' | translate}} {{ plot }}:
                    <br>
                    <ng-container
                        *ngIf="testCarbonFootprint.plots && testCarbonFootprint.plots[plot]?.carbon_foot_print?.total_carbon_footprint !== null">
                        <b>{{ (testCarbonFootprint.plots[plot]?.carbon_foot_print?.total_carbon_footprint.toString() |
                            slice:0:8) }} /{{'CARBON.KG' | translate}} CO2e</b>
                    </ng-container>
                </div>
                <div class="w-full">
                    <canvas baseChart [type]="'pie'" [datasets]="getPlotPieChartDataset(plot)" [labels]="pieChartLabels"
                        [options]="pieChartOptions" [legend]="pieChartLegend">
                    </canvas>
                </div>
            </div>
        </div>
        <div class="mt-8 flex flex-col items-center justify-center">
            <div class="text-lg text-normal text-gray-600">
                {{'CARBON.FOOTPRINT' | translate}} :
                <br>
                <ng-container *ngIf="testCarbonFootprint.total_results?.total_carbon_footprint !== null">
                    <b>{{ (testCarbonFootprint.total_results?.total_carbon_footprint.toString() | slice:0:8) }}
                        /{{'CARBON.KG' | translate}} CO2e</b>
                </ng-container>
            </div>
            <div class="w-full lg:w-1/3">
                <canvas baseChart [type]="'pie'" [datasets]="getTotalPieChartDataset(testCarbonFootprint.total_results)"
                    [labels]="pieChartLabels" [options]="pieChartOptions" [legend]="pieChartLegend">
                </canvas>
            </div>
        </div>
    </div>
</div>