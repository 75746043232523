import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () => 
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm, //'agnes' - Change it to environment.realm
        clientId: environment.keycloakClientId //'soilguard-client' - Change it to environment.clientId
      },
      initOptions: {
        checkLoginIframe: false,
        checkLoginIframeInterval: 25,
      },
      shouldUpdateToken(request) {
        return !request.headers.get('token-update') === false;
      }
    });
}
