<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
  {{ 'SIDEBAR.MULTI'| translate}}
</h1>
<ng-container *ngIf="currentStep === 1">
  <h2 class="text-md font-normal m-6 text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'MULTI.SELECT_FARMER' | translate }}
  </h2>
  <ag-grid-angular
    class="ag-theme-alpine px-4"
    style="width: auto; height: auto;"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)">
  </ag-grid-angular>
  <button mat-raised-button id="saveButton" class="float-right m-2" (click)="nextStep()">
    <mat-icon>arrow_forward</mat-icon>
    {{ 'RECOMMEND.NEXT' | translate }}
  </button>
</ng-container>
<ng-container *ngIf="currentStep === 2">
  <h2 class="text-md font-normal m-6 text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'MULTI.SELECT_ACTION' | translate }}</h2>
  <div class="container p-4">
    <div class="flex-auto mt-2 p-2 lg:px-10 bg-white rounded-lg" id="main_container">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end" (selectedTabChange)="onTabChangeManage($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="row">
              <div class="col-6 d-flex align-items-left justify-content-left">
                <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail" src="../../../../assets/farmer_tool.png"
                  style="background-color: #dfe95f;
             border: 3px solid rgb(253, 255, 252);
             box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
                  width="40">
              </div>
              <div class="lg:w-6/12 d-flex align-items-center justify-content-center">
                {{ 'MULTI.TREATMENTS' | translate }}
              </div>
            </div>
          </ng-template>
          <div class="container mx-auto px-4">
            <form style="margin-top: 1%;">
              <hr class="my-1 border-b-1 border-gray-300" />
              <div class="flex flex-wrap">
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'SEEDPLANT.STARTDATETIME' | translate }}
                    </label>
                    <input type="date"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="treatmentStartDate" name="date" (ngModelChange)="pruningCalculate($event)" />
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'SEEDPLANT.ENDDATETIME' | translate }}
                    </label>
                    <input type="date"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="treatmentEndDate" name="date" (ngModelChange)="pruningCalculate($event)" />
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'TREATMENT.TREATMENT' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="treatment" [ngModelOptions]="{standalone: true}">
                      <option value="" disabled selected></option>
                      <option *ngFor="let treatment of treatments" [ngValue]="treatment">{{treatment | translate}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="mt-2 mb-3 text-center" *ngIf="summerTreatment" id="pruningMsg">
                  <div class="col">
                    <div class="row">
                      <i class=" fa-solid fa-triangle-exclamation"></i>
                      <span style="font-weight: 600;">
                        {{'TREATMENT.PRUNINNG_MSG_TITLE' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <span class="text-muted text-xs" style="color: rgb(152, 152, 152);">
                        {{'TREATMENT.PRUNINNG_MSG_DESC' | translate}}
                      </span>
                    </div>
                    <div class="row">
                      <span class="text-muted text-xs" style="color: rgb(152, 152, 152);">
                        {{'TREATMENT.PRUNINNG_MSG_DATE' | translate}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="row">
              <div class="col-6 d-flex align-items-left justify-content-left">
                <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                  src="../../../../assets/fertilizer_manage.png" alt=""
                  style="background-color: #eac406;
                           border: 3px solid rgb(253, 255, 252);
                           box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
              </div>
              <div class="col-6 d-flex align-items-center justify-content-center">
                {{ 'CROP.FERT' | translate }}
              </div>
            </div>
          </ng-template>
          <div class="container mx-auto px-4">
            <form style="margin-top: 1%;">
              <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                {{ 'CROP.FIELD' | translate }}
              </h6>
              <hr class="my-1 border-b-1 border-gray-300" />
              <div class="flex flex-wrap">
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.CULTSTAGE' | translate }}
                    </label>
                    <select
                      class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="cultivationStage" [ngModelOptions]="{standalone: true}">
                      <option value="" disabled selected></option>
                      <option *ngFor="let stage of cultivationStages" [value]="stage">{{ stage | translate  }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.METHOD' | translate }}
                    </label>
                    <select
                      class="card border-1 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="applicationMethod" [ngModelOptions]="{standalone: true}">
                      <option value="" disabled selected></option>
                      <option *ngFor="let method of applicationMethods" [value]="method">{{ method | translate  }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.WEATHER' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="weatherCondition" [ngModelOptions]="{standalone: true}">
                      <option value="" disabled selected></option>
                      <option *ngFor="let condition of weatherConditions" [ngValue]="condition">
                        {{condition | translate }}</option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'PLOTS.STARTDATE' | translate }}
                    </label>
                    <input type="datetime-local"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="fertilisationStartDate" name="fertilisationStartDate" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'PLOTS.ENDDATE' | translate }}
                    </label>
                    <input type="datetime-local"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="fertilisationEndDate" name="fertilisationEndDate" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.AGRONOMIST' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="fertilisationAgronomist" name="fertilisationAgronomist" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.OPERATOR' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="fertilisationOperator" name="fertilisationOperator" />
                  </div>
                </div>
              </div>
            </form>
            <form>
              <h6 class="text-gray-400 text-sm my-6 font-bold uppercase" id="fertilizer">
                {{ 'CROP.FERTILIZER' | translate }}
              </h6>
              <hr class="my-1 border-b-1 border-gray-300" />
              <div class="flex flex-wrap">
                <div class="w-full lg:w-2/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" disable htmlFor="grid-password">
                      {{ 'CROP.BATCH' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="fertilisationBatchNumber" name="fertilisationBatchNumber" />
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.COMMERCIAL' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="fertilisationCommercialName" name="fertilisationCommercialName"
                      (change)="onFertCommercialNameChange()">
                      <option value="" disabled selected></option>
                      <option *ngFor="let name of commercialNames" [value]="name">{{ name }}</option>
                      <option value="other">{{ 'CROP.OTHER' | translate }}</option>
                    </select>
                    <input *ngIf="fertilisationCommercialName === 'other'"
                      [(ngModel)]="otherFertilisationCommercialName" name="otherFertilisationCommercialName"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.NPK' | translate }}
                    </label>
                    <div
                      class="flex flex-row  border-2 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <div class="mx-1">
                        <input type="text"
                          class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="totalN" name="totalN" />
                      </div>
                      <div class="mx-1">
                        <input type="text"
                          class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="totalP" name="totalP" />
                      </div>
                      <div class="mx-1">
                        <input type="text"
                          class="border-0 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="totalK" name="totalK" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.COMPOSITION' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="composition" name="composition" />
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.RELATED_ACTION' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="relatedActionUnit" name="solutionUnit" (ngModelChange)="onChangeFert()">
                      <option *ngFor="let unit of relatedActionFert" [value]="unit">
                        {{ unit | translate  }}
                      </option>
                    </select>
                    <span *ngIf="relatedActionEmpty" style="color: red; font-size: 14px; font-weight: 600;">
                      {{ 'CROP.RELATED_ACTION_MSG' | translate }}
                    </span>
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.RELATED_ACTION_CATEGORY' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="relatedActionCategoryUnit" name="solutionUnit">
                      <option *ngFor="let unit of relatedActionCategoryFert" [value]="unit">
                        {{ unit | translate  }}
                      </option>
                    </select>
                    <span *ngIf="relatedActionCategoryEmpty" style="color: red; font-size: 14px; font-weight: 600;">
                      {{ 'CROP.RELATED_ACTION_CATEGORY_MSG' | translate }}
                    </span>
                  </div>
                </div>
                <div class="w-full lg:w-5/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.DOSAGE' | translate }}
                    </label>
                    <div class="flex flex-row">
                      <div class="w-3/12 mx-1">
                        <input type="number" min="0" autocomplete="off"
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="fertilisationDosage" name="fertilisationDosage" />
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="fertilisationDosageUnit" name="fertilisationDosageUnit">
                          <option value="unit" disabled selected>{{ 'CROP.UNIT' | translate }}
                          </option>
                          <option *ngFor="let unit of dosageUnits" [value]="unit">
                            {{ unit }}
                          </option>
                        </select>
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="fertilisationDosageAppliedBy" name="fertilisationDosageAppliedBy">
                          <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                          <option *ngFor="let unit of dosageBy" changeSelectPlots [value]="unit">
                            {{ unit | translate  }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-5/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.AMOUNT' | translate }}
                    </label>
                    <div class="flex flex-row">
                      <div class="w-3/12 mx-1">
                        <input type="number" autocomplete="off" min="0"
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="solutionAmount" name="solutionAmount" />
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="solutionUnit" name="solutionUnit">
                          <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                          </option>
                          <option *ngFor="let unit of solutionUnits" [value]="unit">
                            {{ unit }}
                          </option>
                        </select>
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="fertilisationDosageAppliedBy100" name="fertilisationDosageAppliedBy">
                          <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                          <option *ngFor="let unit of dosageBySpray" [value]="unit">
                            {{ unit | translate  }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="row">
              <div class="col-6 d-flex align-items-left justify-content-left">
                <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
                  src="../../../../assets/agro_protection.png" alt=""
                  style="background-color: #8deda3;
                           border: 3px solid rgb(253, 255, 252);
                           box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
              </div>
              <div class="col-6 d-flex align-items-center justify-content-center">
                {{ 'CROP.SPRAY' | translate }}
              </div>
            </div>
          </ng-template>
          <div class="container mx-auto px-4">
            <form style="margin-top: 1%;">
              <h6 class="text-center text-gray-400 text-sm my-6 font-bold uppercase" id="field">
                {{ 'CROP.FIELD' | translate }}
              </h6>
              <hr class="my-1 border-b-1 border-gray-300" />
              <div class="flex flex-wrap">
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.CULTSTAGE' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="cultivationStage" name="cultivationStage">
                      <option value="" disabled selected></option>
                      <option *ngFor="let stage of cultivationStages" [value]="stage">{{ stage | translate  }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.METHODSPR' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="implementationMethod" name="implementationMethod">
                      <option value="" disabled selected></option>
                      <option *ngFor="let method of implementationMethods" [value]="method">{{ method | translate }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.WEATHER' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="weatherCondition" [ngModelOptions]="{standalone: true}">
                      <option value="" disabled selected></option>
                      <option *ngFor="let condition of weatherConditions" [ngValue]="condition">
                        {{ condition | translate }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'PLOTS.STARTDATE' | translate }}
                    </label>
                    <input type="datetime-local"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="sprayingStartDate" name="sprayingDate" (ngModelChange)="changeSpraying()" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'PLOTS.ENDDATE' | translate }}
                    </label>
                    <input type="datetime-local"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="sprayingEndDate" name="sprayingDate" (ngModelChange)="changeSpraying()" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.DURATION' | translate }}
                    </label>
                    <input type="text" readonly
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      (ngModelChange)="changeSpraying()" />
                    <div class="overlay mt-2">
                      {{sprayingHours}} hours : {{sprayingMinutes}} minutes
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.AGRONOMIST' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="sprayingAgronomist" name="sprayingAgronomist" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.OPERATOR' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="sprayingOperator" name="sprayingOperator" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="container mx-auto px-4 mt-2">
            <form>
              <h6 class="text-center text-gray-400 text-sm my-3 font-bold uppercase" id="spraying">
                {{ 'CROP.PROTECTION' | translate }}
              </h6>
              <hr class="my-1 border-b-1 border-gray-300" />
              <div class="flex flex-wrap">
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.BATCH' | translate }}
                    </label>
                    <input type="text" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="sprayingBatchNumber" name="sprayingBatchNumber" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.COMMERCIAL' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="commercialName" name="commercialName" (change)="onSprayingCommercialNameChange()">
                      <option value="" disabled selected></option>
                      <option *ngFor="let name of sprayingCommercialNames" [value]="name">{{ name }}
                      </option>
                      <option value="other">{{ 'CROP.OTHER' | translate }}</option>
                    </select>
                    <input *ngIf="commercialName === 'other'" [(ngModel)]="otherCommercialName"
                      name="otherCommercialName" autocomplete="off"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.SUBSTANCE' | translate }}
                    </label>
                    <input type="text" autocomplete="off" [disabled]="commercialName !== 'other'"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="substance" name="substance" />
                  </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.RELATED_ACTION' | translate }}
                    </label>
                    <select
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="relatedActionSprayUnit" name="solutionUnit">
                      <option *ngFor="let unit of relatedActionSpray" [value]="unit">
                        {{ unit | translate }}
                      </option>
                    </select>
                    <span *ngIf="relatedActionSprayEmpty" style="color: red; font-size: 14px; font-weight: 600;">
                      {{ 'CROP.RELATED_ACTION_MSG' | translate }}
                    </span>
                  </div>
                </div>
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.OBJECTIVE' | translate }}
                    </label>
                    <textarea autocomplete="off" [disabled]="commercialName !== 'other'"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="justification" name="justification">
                                    </textarea>
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.DOSAGE' | translate }} <div *ngIf="this.recommendedDosage != ''" class="text-red-600">
                        Recommended: {{ recommendedDosage }} </div>
                    </label>
                    <div class="flex flex-row">
                      <div class="w-3/12 mx-1">
                        <input type="number" autocomplete="off" min="0"
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="dosage" name="dosage" />
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="dosageUnit" name="dosageUnit">
                          <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                          </option>
                          <option *ngFor="let unit of dosageUnits" [value]="unit">
                            {{ unit }}
                          </option>
                        </select>
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="dosageAppliedBy" name="dosageAppliedBy">
                          <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                          <option *ngFor="let unit of dosageBy" [value]="unit">
                            {{ unit | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.PHI' | translate }}
                    </label>
                    <input type="number" autocomplete="off" min="0"
                      class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      [(ngModel)]="phi" name="phi" />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      {{ 'CROP.AMOUNT' | translate }}
                    </label>
                    <div class="flex flex-row">
                      <div class="w-3/12 mx-1">
                        <input type="number" autocomplete="off" min="0"
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="solutionAmount" name="solutionAmount" />
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="solutionUnit" name="solutionUnit">
                          <option value="" disabled selected>{{ 'CROP.UNIT' | translate }}
                          </option>
                          <option *ngFor="let unit of solutionUnits" [value]="unit">
                            {{ unit }}
                          </option>
                        </select>
                      </div>
                      <div class="w-3/12 mx-1">
                        <select
                          class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          [(ngModel)]="amountAppliedByHundred" name="amountAppliedByHundred">
                          <option value="" disabled selected>{{ 'CROP.BY' | translate }}</option>
                          <option *ngFor="let unit of dosageBySpray" [value]="unit">
                            {{ unit | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <button mat-raised-button id="saveButton" class="float-right m-2" (click)="nextStep()">
    <mat-icon>arrow_forward</mat-icon>
    {{ 'RECOMMEND.NEXT' | translate }}
  </button>
  <button mat-raised-button id="backButton" class="float-right m-2" (click)="previousStep()">
    <mat-icon>arrow_back</mat-icon>
    {{ 'RECOMMEND.BACK' | translate }}
  </button>
</ng-container>
<ng-container *ngIf="currentStep === 3">
  <div class="container mx-auto px-4">
    <div class="flex-auto mt-2 p-2 lg:px-10 bg-white rounded-lg" id="main_container">
      <div class="text-justify text-lg">
        {{ 'MULTI.CONFIRM' | translate }} <b>{{arraySendLength}}</b> {{ 'MULTI.CROPS_NUMBER' | translate }}
      </div>
      <div class="mt-16 flex flex-col-2 justify-around">
        <mat-card class="w-full lg:w-1/3 my-2 p-2">
          <div class="mb-3 uppercase flex text-gray-600 text-lg justify-center items-center text-center"
            style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
            {{ 'MULTI.FARMERS' | translate }}
          </div>
          <mat-card-content>
            <div class="row mt-4" *ngFor="let crop of cropIds[0]; index as i;">
              <div class="my-1 flex justify-center items-center text-justify text-gray-600">
                <b>{{i+1}}</b>. {{crop.crop_type}}, {{crop.location}} - {{crop.last_name}} {{crop.first_name}}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="w-full lg:w-1/2 my-2 p-2">
          <div class="mb-3 uppercase flex text-gray-600 text-lg justify-center items-center text-center"
            style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
            {{ 'MULTI.DATA' | translate }}
          </div>
          <mat-card-content *ngIf="infoTreatment">
            <div class="flex flex-col justify-center items-center text-justify text-gray-600">
              <div class="flex flex-row mt-4">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'MULTI.ACTION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ 'HISTORY.TREAT' | translate }}
                </div>
              </div>
              <div class="flex flex-row mt-8">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'SEEDPLANT.STARTDATETIME' | translate }}
                </div>
                <div class="uppercase text-gray-600 ml-1">
                  {{ treatmentStartDate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'SEEDPLANT.ENDDATETIME' | translate }}
                </div>
                <div class="uppercase text-gray-600 ml-1">
                  {{ treatmentEndDate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'TREATMENT.TREATMENT' | translate }}
                </div>
                <div class=" text-gray-600 ml-1">
                  {{ treatment | translate }}
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-content *ngIf="infoFertil">
            <div class="flex flex-col justify-center items-center text-justify text-gray-600">
              <div class="flex flex-row mt-4">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'MULTI.ACTION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ 'CROP.FERT' | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.CULTSTAGE' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ cultivationStage | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.METHOD' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ applicationMethod | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.WEATHER' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ weatherCondition }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'SEEDPLANT.STARTDATETIME' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ fertilisationStartDate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'SEEDPLANT.ENDDATETIME' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ fertilisationEndDate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.AGRONOMIST' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ fertilisationAgronomist }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.OPERATOR' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ fertilisationOperator }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.BATCH' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ fertilisationBatchNumber }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.COMMERCIAL' | translate }}
                </div>
                <div *ngIf="this.commercialName !== 'other'" class="text-gray-600 ml-1">
                  {{ fertilisationCommercialName }}
                </div>
                <div *ngIf="this.commercialName === 'other'" class="text-gray-600 ml-1">
                  {{ otherFertilisationCommercialName }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.COMPOSITION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ composition }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.RELATED_ACTION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ relatedActionUnit | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.RELATED_ACTION_CATEGORY' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ relatedActionCategoryUnit | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.DOSAGE' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ fertilisationDosage }} {{ fertilisationDosageUnit }} {{ fertilisationDosageAppliedBy | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.AMOUNT' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ solutionAmount }} {{ solutionUnit }} {{ fertilisationDosageAppliedBy100  | translate}}
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-content *ngIf="infoSpraying">
            <div class="flex flex-col justify-center items-center text-justify text-gray-600">
              <div class="flex flex-row mt-4">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'MULTI.ACTION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ 'CROP.SPRAY' | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.CULTSTAGE' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ cultivationStage | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.METHODSPR' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ implementationMethod  | translate}}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.WEATHER' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ weatherCondition | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'SEEDPLANT.STARTDATETIME' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ sprayingStartDate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'SEEDPLANT.ENDDATETIME' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ sprayingEndDate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.DURATION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{sprayingHours}} hours : {{sprayingMinutes}} minutes
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.AGRONOMIST' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ sprayingAgronomist }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.OPERATOR' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ sprayingOperator }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.BATCH' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ sprayingBatchNumber }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.COMMERCIAL' | translate }}
                </div>
                <div *ngIf="this.commercialName !== 'other'" class="text-gray-600 ml-1">
                  {{ commercialName }}
                </div>
                <div *ngIf="this.commercialName === 'other'" class="text-gray-600 ml-1">
                  {{ otherCommercialName }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.SUBSTANCE' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ substance }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.RELATED_ACTION' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ relatedActionSprayUnit | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.OBJECTIVE' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ justification }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.PHI' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ phi }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.DOSAGE' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ dosage }} {{ dosageUnit }} {{ dosageAppliedBy | translate }}
                </div>
              </div>
              <div class="mt-8 flex flex-row">
                <div class="uppercase text-gray-600 font-semibold mr-1">
                  {{ 'CROP.AMOUNT' | translate }}
                </div>
                <div class="text-gray-600 ml-1">
                  {{ solutionAmount }} {{ solutionUnit }} {{ amountAppliedByHundred | translate }}
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <button mat-raised-button id="saveButton" class="float-right m-2" (click)="addData()">
    <mat-icon>done</mat-icon>
    {{ 'MANAGEMENT.SAVE' | translate }}
  </button>
  <button mat-raised-button id="backButton" class="float-right m-2" (click)="previousStep()">
    <mat-icon>arrow_back</mat-icon>
    {{ 'RECOMMEND.BACK' | translate }}
  </button>
</ng-container>