import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './views/home/home.component';
import { ManagementComponent } from './views/management/management.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarService } from './services/sidebar.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './utility/app.init';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RequestService } from './services/request.service';
import { FarmerComponent } from './views/management/farmer/farmer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { ManagePlotsComponent } from './views/management/manage-plots/manage-plots.component';
import { SeedingPlantingComponent } from './views/management/seeding_planting/seedingplanting.component';
import { TreatmentComponent } from './views/management/treatment/treatment.component';
import { MatSelectModule } from '@angular/material/select';
import { CropComponent } from './views/management/crop/crop.component';
import { ManageStorageComponent } from './views/management/manage-storage/manage-storage.component';
import { ManageEquipmentComponent } from './views/management/manage-equipment/manage-equipment.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PlotsComponent } from './views/plots/plots.component';
import { MatCardModule } from '@angular/material/card';
import { SinglePlotComponent } from './views/single-plot/single-plot.component';
import { MatTableModule } from '@angular/material/table';
import { HistoryComponent } from './views/history/history.component';
import { MatChipsModule } from '@angular/material/chips';
import { AgGridModule } from 'ag-grid-angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadComponent } from './views/upload/upload.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { StorageComponent } from './views/storage/storage.component';
import { CarbonFootprintComponent } from './views/carbon-footprint/carbon-footprint.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgChartsModule } from 'ng2-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WidgetshomeComponent } from './views/home/widgetshome/widgetshome.component';
import { PlotshomeComponent } from './views/home/plotshome/plotshome.component';
import { AddequipmentComponent } from './views/management/manage-equipment/addequipment/addequipment.component';
import { AddmaintenancelogsComponent } from './views/management/manage-equipment/addmaintenancelogs/addmaintenancelogs.component';
import { MatListModule } from '@angular/material/list';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FarmerhistoryComponent } from './views/history/farmerhistory/farmerhistory.component';
import { CrophistoryComponent } from './views/history/crophistory/crophistory.component';
import { TreatmentshistoryComponent } from './views/history/treatmentshistory/treatmentshistory.component';
import { SeedhistoryComponent } from './views/history/seedhistory/seedhistory.component';
import { DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProductComponent } from './views/management/product/product.component';
import { AddfertiliserComponent } from './views/management/product/addfertiliser/addfertiliser.component';
import { AddsprayComponent } from './views/management/product/addspray/addspray.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportsComponent } from './views/reports/reports.component';
import { AddAgronomistComponent } from './views/reports/add-agronomist/add-agronomist.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthorizationService } from './services/authorization.service';
import { RecommendFertiliserComponent } from './views/recommendations/recommend-fertiliser/recommend-fertiliser.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MultimanagementComponent } from './views/multimanagement/multimanagement.component';
import { RecommendedComponent } from './views/management/crop/recommended/recommended.component';
import { DevicesComponent } from './views/devices/devices.component';
import { SingleDeviceComponent } from './views/devices/single-device/single-device.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MeteoComponent } from './views/meteo/meteo.component';
import { SingleMeteoComponent } from './views/meteo/single-meteo/single-meteo.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    ManagementComponent,
    FarmerComponent,
    ManagePlotsComponent,
    SeedingPlantingComponent,
    TreatmentComponent,
    CropComponent,
    ManageStorageComponent,
    ManageEquipmentComponent,
    PlotsComponent,
    SinglePlotComponent,
    HistoryComponent,
    UploadComponent,
    StorageComponent,
    CarbonFootprintComponent,
    WidgetshomeComponent,
    PlotshomeComponent,
    AddequipmentComponent,
    AddmaintenancelogsComponent,
    PlotshomeComponent,
    FarmerhistoryComponent,
    CrophistoryComponent,
    TreatmentshistoryComponent,
    SeedhistoryComponent,
    ProductComponent,
    AddfertiliserComponent,
    AddsprayComponent,
    ReportsComponent,
    AddAgronomistComponent,
    RecommendFertiliserComponent,
    MultimanagementComponent,
    RecommendedComponent,
    DevicesComponent,
    SingleDeviceComponent,
    MeteoComponent,
    SingleMeteoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatCardModule,
    MatTableModule,
    MatChipsModule,
    AgGridModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatSortModule,
    MatPaginatorModule,
    NgxFileDropModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    NgChartsModule,
    MatProgressBarModule,
    MatListModule,
    MatRadioModule,
    MatExpansionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatCheckboxModule,
    MatTooltipModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatMenuModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    SidebarService,
    RequestService,
    AuthorizationService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}