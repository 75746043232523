declare var google: any;
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js/auto';
import { RequestService } from 'src/app/services/request.service';
import { DatePipe } from '@angular/common';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as XLSX from 'xlsx'
import { jsPDF } from "jspdf";
import Swal from 'sweetalert2';
import autoTable from 'jspdf-autotable';
import { font2 } from '../../fonts/encryption';
import { FormGroup, FormControl, FormBuilder, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OtherService } from 'src/app/services/other.service';

@Component({
  selector: 'app-single-device',
  templateUrl: './single-device.component.html',
  styleUrls: ['./single-device.component.scss']
})
export class SingleDeviceComponent implements OnInit, AfterViewInit {
  devices: any[] = [];
  @ViewChild('lineCanvas') private lineCanvas!: ElementRef;
  lineChart: any;
  chart!: Chart;
  deviceID: string = '';
  singleDevice: any[] = [];
  deviceName: string = "";
  chartData: any[] = [];
  chartOptions: any = {};
  chartLineData1: any[] = [];
  chartLineData2: any[] = [];
  plot: any = [];
  plot2: any[] = [];
  isOn: boolean = false
  sensors: any[] = [];
  deviceComponents: any[] = [];
  lastDeviceComponents: any[] = [];
  devicesSensors: any[] = [];
  phComponentPublic: any[] = [];
  t12ComponentPublic: any[] = [];
  spinner: boolean = false;
  deviceLat: any = 0;
  deviceLon: any = 0;
  buttonStates2: { [key: string]: boolean }[] = [];
  firstDeviceID: string = '';
  device_type: string = '';
  componentsD: any[] = [];
  startDate: any;
  endDate: any;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  startingDateString: string = '';
  endingDateString: string = '';
  newDeviceName: any;
  selectedButton: any = 'PH';
  exportMappedData: any[] = [];
  exportCsvMappedData: any[] = [];
  headColumns: any[] = []
  exportPdfMappedData: any[] = []
  ImeiNumber: number = 0;
  currentDateTime: any;
  npkComponentPublic: any[] = [];
  newTerros12: any[] = [];
  aggregationsArray: any[] = ['AVG', 'CNT', 'MAX', 'MIN', 'STD', 'SUM', 'VAR'];
  IntervalArray: any[] = ['HR', 'D', 'WK', 'MO', 'YR'];
  selectedAggregation!: string;
  selectedInterval!: string;
  days: number = 3;
  aggFlag: boolean | undefined;
  intervalNumber!: number;

  constructor(
    private route: Router,
    private req: RequestService<any>,
    private datepipe: DatePipe,
    private fb: FormBuilder,
    public dateRangeService: OtherService
  ) { }

  ngOnInit(): void {
    this.deviceID = this.route.url.split('/device-single/')[1]
    this.req.getDevicePlot(this.deviceID).subscribe(res => {
      this.plot = res[0]
      this.initMap();
      this.initSensor()
    });
    const currentDate = new Date();
    this.currentDateTime = this.datepipe.transform(currentDate, 'dd-MM-yyyy')
    this.initDateRange2()
  }

  ngAfterViewInit(): void {
    this.initChart();
    this.isOn = true
  }

  intervalNegative(interNumber: any) {
    if (interNumber <= 0) {
      this.intervalNumber = 0
    }
  }

  clearAll() {
    // this.startDate = ''
    // this.endDate = ''
    this.buttonStates2 = this.componentsD.map(group => {
      let groupButtonStates: { [key: string]: boolean } = {};
      group.attributes.components.forEach((button: any) => {
        groupButtonStates[button.isOn] = false;
        button.isOn = false;
      });
      return groupButtonStates;
    });
    this.lastDeviceComponents = [];
    this.lineChart.data.labels = [];
    this.lineChart.data.datasets.forEach((dataset: any) => dataset.data = []);
    this.lineChart.options.plugins.legend.labels.filter = (item: any, chart: any) => {
      return false
    }
    this.lineChart.update();
  }

  applyAll(sens?: any) {
    if (!this.phComponentPublic) {
      this.phComponentPublic = [];
    }
    if (!this.t12ComponentPublic) {
      this.t12ComponentPublic = [];
    }
    if (!this.npkComponentPublic) {
      this.npkComponentPublic = [];
    }
    this.lineChart.data.labels = [];
    this.lineChart.data.datasets.forEach((dataset: any) => dataset.data = []);
    const startDate = this.dateRangeService.formatDateStart(this.startDate);
    const endDate = this.dateRangeService.formatDateEnd(this.endDate);
    const ArraySensorType: any[] = [];
    sens.attributes.components.forEach((el: any) => {
      if (el.isOn) {
        ArraySensorType.push('sensor_type=' + el.sensor_type);
      }
    });
    if (ArraySensorType.length > 0) {
      this.req.getDevicesOverall2(this.deviceID, ArraySensorType, startDate,
        endDate, this.selectedAggregation, this.selectedInterval, this.intervalNumber).subscribe(res => {
          this.aggFlag = true
          this.lastDeviceComponents = [res.slice(-1)[0]];
          res.forEach((el: any) => {
            this.lineChart.data.labels.push(this.datepipe.transform(el.bucket, 'dd/MM/yyyy HH:mm'));
            if (el.renke) {
              this.lineChart.data.datasets[0].data.push(el.renke[0].value ? el.renke[0].value.toFixed(2) : "-");
            }
            if (el.terros_12) {
              this.newTerros12 = []
              const newTerros12 = el.terros_12.map((sensor: any) => {
                if (sensor.name.includes("temperature")) {
                  sensor.name = "Temperature";
                  sensor.unit = sensor.unit;
                  sensor.value = sensor.value;
                } else if (sensor.name == "bulk_electrical_conductivity") {
                  sensor.name = "EC";;
                  sensor.unit = sensor.unit;
                  sensor.value = sensor.value;
                } else if (sensor.name == "transformed_mineral_soil") {
                  sensor.name = "Soil Moisture";
                  sensor.unit = '%';
                  sensor.value = sensor.value;
                }
                return {
                  name: sensor.name,
                  unit: sensor.unit,
                  value: sensor.value
                };
              });
              newTerros12.forEach((el: any) => {
                if (el.name.includes("Temperature") || el.name.includes("EC") || el.name.includes("Moisture")) {
                  this.newTerros12.push(el)
                }
              })
              this.lineChart.data.datasets[1].data.push(this.newTerros12[0].value ? this.newTerros12[0].value.toFixed(2) : "-");
              this.lineChart.data.datasets[2].data.push(this.newTerros12[1].value ? this.newTerros12[1].value.toFixed(2) : "-");
              this.lineChart.data.datasets[3].data.push(this.newTerros12[2].value ? this.newTerros12[2].value.toFixed(2) : "-");
            }
            if (el.jxct_npk) {
              this.lineChart.data.datasets[4].data.push(el.jxct_npk[0].value ? el.jxct_npk[0].value.toFixed(2) : "-");
              this.lineChart.data.datasets[5].data.push(el.jxct_npk[1].value ? el.jxct_npk[1].value.toFixed(2) : "-");
              this.lineChart.data.datasets[6].data.push(el.jxct_npk[2].conversion_value ? el.jxct_npk[2].conversion_value.toFixed(2) : "-");
            }
          });
          const selectedTypes = sens.attributes.components.filter((el: any) => el.isOn).map((el: any) => el.sensor_type);
          this.lineChart.options.plugins.legend.labels.filter = (item: any) => {
            if (selectedTypes.includes("PH") && item.datasetIndex === 0) {
              return true;
            }
            if (selectedTypes.includes("T12") && item.datasetIndex >= 1 && item.datasetIndex <= 3) {
              return true;
            }
            if (selectedTypes.includes("NPK") && item.datasetIndex >= 4 && item.datasetIndex <= 6) {
              return true;
            }
            return false;
          };
          this.lineChart.update();
        });
    }
  }

  initSensor() {
    this.lineChart.data.labels = [];
    this.lineChart.data.datasets.forEach((dataset: any) => dataset.data = []);
    this.device_type = 'PH';
    const startDate = this.dateRangeService.formatDateStart(this.startDate);
    const endDate = this.dateRangeService.formatDateEnd(this.endDate);
    this.req.getDevices(this.deviceID).subscribe((res: any) => {
      this.componentsD.push(res);
      const ArraySensorType: any[] = ['sensor_type=' + 'PH'];
      this.req.getDevicesOverall2(this.deviceID, ArraySensorType, startDate, endDate).subscribe(res => {
        if (res.length > 0) {
          this.spinner = false;
          this.aggFlag = false;
          const lastObject = res.slice(-1)[0];
          this.phComponentPublic = [lastObject];
          this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic, ...this.npkComponentPublic];
          const groupedData: any = {};
          res.forEach((el: any) => {
            const measurementTime: any = this.datepipe.transform(el.attributes.measurement_time, 'dd/MM/yyyy HH:mm');
            if (!groupedData[measurementTime]) {
              groupedData[measurementTime] = { renke: [], ph: [] };
            }
            if (el.attributes.renke) {
              groupedData[measurementTime].renke.push(el.attributes.renke[0]?.value);
            }
            if (el.attributes.ph) {
              groupedData[measurementTime].ph.push(el.attributes.ph[0]?.value);
            }
          });
          Object.keys(groupedData).forEach((time) => {
            const data = groupedData[time];
            this.lineChart.data.labels.push(time);
            this.lineChart.data.datasets[0].data.push(data.renke.length > 0 ? data.renke[0] : null);
            this.lineChart.data.datasets[1].data.push(data.ph.length > 0 ? data.ph[0] : null);
          });
          this.lineChart.update();
        }
        this.buttonStates2 = this.componentsD.map(group => {
          let groupButtonStates: { [key: string]: boolean } = {};
          group.attributes.components.forEach((button: any) => {
            if (button.sensor_type === 'PH' && this.device_type === 'PH') {
              groupButtonStates[button.sensor_type] = true;
              button.isOn = true;
            } else {
              groupButtonStates[button.sensor_type] = false;
              button.isOn = false;
            }
          });
          if (!Object.values(groupButtonStates).includes(true)) {
            const firstButton = group.attributes.components[0];
            groupButtonStates[firstButton.sensor_type] = true;
            firstButton.isOn = true;
          }
          return groupButtonStates;
        });
      });
    });
  }


  initMap(): void {
    this.devices = [];
    this.req.getDevices(this.deviceID).subscribe((res: any) => {
      this.devices.push(res)
      this.deviceName = res.attributes.name
      this.ImeiNumber = res.attributes.serial_no
      this.deviceLat = parseFloat(this.devices[0].attributes.lat)
      this.deviceLon = parseFloat(this.devices[0].attributes.lon)
      if (this.plot && this.plot.map_coordinates && Array.isArray(this.plot.map_coordinates) && this.plot.map_coordinates.length > 0) {
        const mapCenter = {
          lat: parseFloat(this.devices[0]?.attributes.lat),
          lng: parseFloat(this.devices[0]?.attributes.lon)
        };
        const map = new google.maps.Map(document.getElementById("map")!, {
          zoom: 14,
          center: mapCenter,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          mapTypeControl: false,
          streetViewControl: false
        });
        const pathCoordinates: google.maps.LatLngLiteral[] = this.plot.map_coordinates.map((coord: any) => ({
          lat: +coord.lat,
          lng: +coord.lon
        }));
        if (this.plot.map_coordinates.length >= 3) {
          const firstNode = this.plot.map_coordinates[0];
          pathCoordinates.push({ lat: +firstNode.lat, lng: +firstNode.lon });
        }
        const plotPolygon = new google.maps.Polygon({
          paths: pathCoordinates,
          geodesic: true,
          strokeColor: "rgb(150, 231, 178)",
          strokeOpacity: 1.0,
          fillOpacity: 0.35,
          strokeWeight: 2,
          fillColor: "#FFC107",
        });
        this.sensors = this.devices.map(device => device.attributes.components.map((component: any, index: any) => ({
          lat: parseFloat(device.attributes.lat),
          lng: parseFloat(device.attributes.lon),
          message: `
            <div class="info-window-content" style="overflow-x: hidden; overflow-y: hidden; min-width: 280px; min-height:100px">
                <button id="closeInfoWindow" style="position: absolute; top: 5px; right: 5px; font-weight: 600;color: #626f82;">X</button>
                <h3 id="mapTitle" style="font-size: small;font-weight: 600;color: #626f82;margin-top: -3px;">${device.attributes.name}</h3>
                <mat-list>
                    <div mat-line><span id="mapTitle">Status:</span> <span id="mapKeys">${device.attributes.status ? 'Active' : 'Inactive'}</span></div>
                    <mat-list-item>
                        <div mat-line><span id="mapTitle">Location:</span> <span id="mapKeys">${this.plot.location}</span></div>
                    </mat-list-item>
                    <mat-list-item>
                        <div mat-line><span id="mapTitle">Installed:</span> <span id="mapKeys">${this.datepipe.transform(device.attributes.created_at, 'medium')}</span></div>
                    </mat-list-item>
                </mat-list>
                <div class="p-1">
                    <div class="img-fluid rounded-rectangle img-thumbnail" style="display: flex; justify-content: center; align-items: center; font-size: x-small; text-align: center; font-weight: 600; background-color: rgb(191, 248, 211); border: 1px solid rgb(255, 255, 255); box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; width: 51px; border-radius: 4px;">
                        <p id="sensorsTitle">${component.sensor_type}</p>
                    </div>
                </div>
            </div>
        `,
          color: 'rgb(150, 231, 178)',
          markerIcon: ''
        }))).flat();
        this.sensors.forEach(sensor => {
          const sensorPolyline = new google.maps.Polyline({
            path: [{ lat: sensor.lat, lng: sensor.lng }, { lat: sensor.lat, lng: sensor.lng }],
            geodesic: true,
            strokeColor: sensor.color,
            strokeOpacity: 2,
            strokeWeight: 25,
            fillColor: sensor.color,
            fillOpacity: 0.35,
          });
          const marker = new google.maps.Marker({
            position: { lat: sensor.lat, lng: sensor.lng },
            map: map,
            icon: sensor.markerIcon,
            title: 'Sensor Marker'
          });
          const infoWindow = new google.maps.InfoWindow({
            content: sensor.message,
            ariaLabel: "Uluru",
          });
          google.maps.event.addListener(infoWindow, 'domready', () => {
            document.getElementById('closeInfoWindow')?.addEventListener('click', () => {
              infoWindow.close();
            });
          });
          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });
          sensorPolyline.setMap(map);
        });
        plotPolygon.setMap(map);
      }
    });
  }

  initChart() {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'PH',
            data: [],
            borderColor: 'rgba(73, 215, 255)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(99, 216, 248, 0.455)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#1f90ec'
          },
          {
            label: 'Temperature °C',
            data: [],
            borderColor: 'rgba(205, 84, 75)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(205, 84, 75, 0.407)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(205, 84, 75)'
          },
          {
            label: 'EC',
            data: [],
            borderColor: 'rgba(67, 84, 147)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(67, 84, 147, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(67, 84, 147)'
          },
          {
            label: 'Soil Moisuture %',
            data: [],
            borderColor: 'rgba(255,199,31)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(255,199,31, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(255,199,31)'
          },
          {
            label: 'Nitrogen (PPM)',
            data: [],
            borderColor: 'rgba(40, 127, 71)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(40, 127, 71, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(40, 127, 71)'
          },
          {
            label: 'Phosphorus (PPM)',
            data: [],
            borderColor: 'rgba(40, 127, 71)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(40, 127, 71, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(40, 127, 71)'
          },
          {
            label: 'Potassium (meqk/100g)',
            data: [],
            borderColor: 'rgba(40, 127, 71)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(40, 127, 71, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(40, 127, 71)'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              // color: 'grey',
              font: {
                size: 8,
                family: 'Arial'
              }
            },
            grid: {
              color: 'lightgray',
              lineWidth: 0.1
            }
          },
          y: {
            display: true,
            ticks: {
              color: 'grey',
              font: {
                size: 8,
                family: 'Arial',
              }
            },
            grid: {
              color: 'lightgray',
              lineWidth: 0.3
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              font: {
                size: 10,
                family: 'Arial',
                // weight: 700,
              },
              boxWidth: 20,
              boxHeight: 5,
              // Filter out legend items for datasets other than 'PH'
              filter: function (item, chart) {
                return item.datasetIndex === 0; // Show only the first dataset (PH)
              }
            }
          }
        }
      }
    });
  }

  initDateRange2() {
    const { startDate, endDate } = this.dateRangeService.getOneMonthDateRange();
    this.startDate = startDate;
    this.endDate = endDate;
  }

  onButtonClick(event: any, button: any): void {
    this.selectedButton = ''
    this.selectedButton = button.sensor_type;
  }

  isValidEntry(name: string): boolean {
    const validNames = ["temperature", "bulk_electrical_conductivity", "transformed_mineral_soil"];
    return validNames.includes(name);
  }

  getDisplayName(name: string): string {
    const nameMapping: { [key: string]: string } = {
      temperature: "Temperature",
      bulk_electrical_conductivity: "EC",
      transformed_mineral_soil: "Soil Moisture",
    };
    return nameMapping[name] || "";
  }

  getFormattedValue(name: string, value: any): string {
    if (name === "transformed_mineral_soil") {
      return ((+value * 100).toFixed(1));
    }
    return value;
  }

  getFormattedUnit(name: string, unit: string): string {
    if (name === "transformed_mineral_soil") {
      return "%";
    }
    return unit;
  }

  onDateChange(sens?: any): void {
    const startDate = this.dateRangeService.formatDateStart(this.startDate);
    const endDate = this.dateRangeService.formatDateEnd(this.endDate);
    if (startDate <= endDate && this.selectedButton) {
      this.spinner = true;
      this.lineChart.data.labels = [];
      this.lineChart.data.datasets.forEach((dataset: any) => dataset.data = []);
      const ArraySensorType: any[] = [];
      sens.attributes.components.forEach((el: any) => {
        if (el.isOn) {
          ArraySensorType.push('sensor_type=' + el.sensor_type);
        }
      });
      if (ArraySensorType.length > 0) {
        this.req.getDevicesOverall2(this.deviceID, ArraySensorType, startDate, endDate).subscribe(res => {
          this.spinner = false;
          this.aggFlag = false;
          this.lastDeviceComponents = [res.slice(-1)[0]];
          const groupedData: any = {};
          res.forEach((el: any) => {
            if (el.attributes && el.attributes.time) {
              const measurementTime: any = this.datepipe.transform(
                el.attributes.time,
                'dd/MM/yyyy HH:mm'
              );
              groupedData[measurementTime] = { terros_12: [], jxct_npk: [], renke: [] };
              if (el.attributes.terros_12) {
                groupedData[measurementTime].terros_12.push(...el.attributes.terros_12);
              }
              if (el.attributes.jxct_npk) {
                groupedData[measurementTime].jxct_npk.push(...el.attributes.jxct_npk);
              }
              if (el.attributes.renke) {
                groupedData[measurementTime].renke.push(...el.attributes.renke);
              }
            }
          });
          Object.keys(groupedData).forEach((time) => {
            const data = groupedData[time];
            this.lineChart.data.labels.push(time);
            if (data.renke.length > 0) {
              this.lineChart.data.datasets[0].data.push(data.renke[0]?.value || "-");
            }
            if (data.terros_12.length > 0) {
              const tempSensor = data.terros_12.find((sensor: any) =>
                sensor.name.includes('temperature')
              );
              const ecSensor = data.terros_12.find(
                (sensor: any) => sensor.name === 'bulk_electrical_conductivity'
              );
              const soilMoistureSensor = data.terros_12.find(
                (sensor: any) => sensor.name === 'transformed_mineral_soil'
              );
              this.lineChart.data.datasets[1].data.push(tempSensor?.value || "-");
              this.lineChart.data.datasets[2].data.push(ecSensor?.value || "-");
              this.lineChart.data.datasets[3].data.push(
                soilMoistureSensor ? (soilMoistureSensor.value * 100).toFixed(1) : "-"
              );
            }
            if (data.jxct_npk.length > 0) {
              this.lineChart.data.datasets[4].data.push(data.jxct_npk[0].value ? data.jxct_npk[0]?.value.toFixed(2) : "-");
              this.lineChart.data.datasets[5].data.push(data.jxct_npk[1].value ? data.jxct_npk[1].value.toFixed(2) : "-");
              this.lineChart.data.datasets[6].data.push(data.jxct_npk[2].conversion_value ? (data.jxct_npk[2].conversion_value * 10).toFixed(2) : "-");
            }
          });
          const selectedTypes = sens.attributes.components
            .filter((el: any) => el.isOn)
            .map((el: any) => el.sensor_type);

          this.lineChart.options.plugins.legend.labels.filter = (item: any) => {
            if (selectedTypes.includes("PH") && item.datasetIndex === 0) {
              return true;
            }
            if (selectedTypes.includes("T12") && item.datasetIndex >= 1 && item.datasetIndex <= 3) {
              return true;
            }
            if (selectedTypes.includes("NPK") && item.datasetIndex >= 4 && item.datasetIndex <= 6) {
              return true;
            }
            return false;
          };
          this.lineChart.update();
        });
      } else {
        this.spinner = false;
        this.clearAll();
      }
    }
  }


  calculateCenter(coordinates: { kombos: string | number; lat: string | number; lon: string | number }[]): google.maps.LatLngLiteral {
    let totalLat = 0;
    let totalLon = 0;
    coordinates.forEach(coord => {
      totalLat += +coord.lat;
      totalLon += +coord.lon;
    });
    const averageLat = totalLat / coordinates.length;
    const averageLon = totalLon / coordinates.length;
    return { lat: averageLat, lng: averageLon };
  }

  chooseSensor(event?: any, button?: any, groupIndex?: number, sens?: any): void {
    this.spinner = true;
    this.lineChart.data.labels = [];
    this.lineChart.data.datasets.forEach((dataset: any) => (dataset.data = []));
    const startDate = this.dateRangeService.formatDateStart(this.startDate);
    const endDate = this.dateRangeService.formatDateEnd(this.endDate);
    button.isOn = event.source._checked;
    const ArraySensorType: any[] = [];
    sens.attributes.components.forEach((el: any) => {
      if (el.isOn) {
        ArraySensorType.push('sensor_type=' + el.sensor_type);
      }
    });

    if (ArraySensorType.length > 0) {
      this.req
        .getDevicesOverall2(this.deviceID, ArraySensorType, startDate, endDate)
        .subscribe((res) => {
          this.spinner = false;
          this.aggFlag = false;
          this.lastDeviceComponents = [res.slice(-1)[0]];
          const groupedData: any = {};
          res.forEach((el: any) => {
            if (el.attributes && el.attributes.time) {
              const measurementTime: any = this.datepipe.transform(
                el.attributes.time,
                'dd/MM/yyyy HH:mm'
              );
              groupedData[measurementTime] = { terros_12: [], jxct_npk: [], renke: [] };
              if (el.attributes.terros_12) {
                groupedData[measurementTime].terros_12.push(...el.attributes.terros_12);
              }
              if (el.attributes.jxct_npk) {
                groupedData[measurementTime].jxct_npk.push(...el.attributes.jxct_npk);
              }
              if (el.attributes.renke) {
                groupedData[measurementTime].renke.push(...el.attributes.renke);
              }
            }
          });
          Object.keys(groupedData).forEach((time) => {
            const data = groupedData[time];
            this.lineChart.data.labels.push(time);
            if (data.renke.length > 0) {
              this.lineChart.data.datasets[0].data.push(data.renke[0]?.value || "-");
            }
            if (data.terros_12.length > 0) {
              const tempSensor = data.terros_12.find((sensor: any) =>
                sensor.name.includes('temperature')
              );
              const ecSensor = data.terros_12.find(
                (sensor: any) => sensor.name === 'bulk_electrical_conductivity'
              );
              const soilMoistureSensor = data.terros_12.find(
                (sensor: any) => sensor.name === 'transformed_mineral_soil'
              );
              this.lineChart.data.datasets[1].data.push(tempSensor?.value || "-");
              this.lineChart.data.datasets[2].data.push(ecSensor?.value || "-");
              this.lineChart.data.datasets[3].data.push(
                soilMoistureSensor ? (soilMoistureSensor.value * 100).toFixed(1) : "-"
              );
            }
            if (data.jxct_npk.length > 0) {
              this.lineChart.data.datasets[4].data.push(data.jxct_npk[0].value ? data.jxct_npk[0]?.value.toFixed(2) : "-");
              this.lineChart.data.datasets[5].data.push(data.jxct_npk[1].value ? data.jxct_npk[1].value.toFixed(2) : "-");
              this.lineChart.data.datasets[6].data.push(data.jxct_npk[2].conversion_value ? (data.jxct_npk[2].conversion_value * 10).toFixed(2) : "-");
            }
          });
          const selectedTypes = sens.attributes.components
            .filter((el: any) => el.isOn)
            .map((el: any) => el.sensor_type);
          this.lineChart.options.plugins.legend.labels.filter = (item: any) => {
            if (selectedTypes.includes('PH') && item.datasetIndex === 0) {
              return true;
            }
            if (selectedTypes.includes('T12') && item.datasetIndex >= 1 && item.datasetIndex <= 3) {
              return true;
            }
            if (selectedTypes.includes('NPK') && item.datasetIndex >= 4 && item.datasetIndex <= 6) {
              return true;
            }
            return false;
          };

          this.lineChart.update();
        });
    } else {
      this.spinner = false;
      this.clearAll();
    }
  }



  chartMapping() {
    this.exportCsvMappedData = [];
    const labelMapping: any = {
      "PH": "pH",
      "Temperature": "Temperature (°C)",
      "EC": "EC",
      "Soil Moisture": "Soil Moisture (%)",
      "Nitrogen": "Nitrogen (PPM)",
      "Phosphorus": "Phosphorus (PPM)",
      "Potassium": "Potassium (meqk/100g)"
    }
    this.lineChart.data.datasets.forEach((dataset: any) => {
      const label = labelMapping[dataset.label] || dataset.label;
      dataset.data.forEach((dataValue: any, index: number) => {
        this.exportCsvMappedData.push({
          Name: label,
          Value: dataValue,
          Time: this.lineChart.data.labels[index]
        });
      });
    });
  }

  csvExport() {
    this.chartMapping();
    const dataStructure: { [key: string]: any[] } = {};
    const namesSet = new Set<string>();
    const timeData = new Set<string>();
    this.exportCsvMappedData.forEach((dataset) => {
      if (!dataStructure[dataset.Name]) {
        dataStructure[dataset.Name] = [];
      }
      dataStructure[dataset.Name].push(dataset.Value || '');
      timeData.add(dataset.Time || '');
      namesSet.add(dataset.Name);
    });
    const timeDataArray = Array.from(timeData);
    dataStructure["Date"] = timeDataArray;
    const headers = ["PH (pH)", "Temperature (°C)", "EC (μS/cm)", "Soil Moisture (%)", "Date"];
    const csvData: any[] = [dataStructure];
    const maxRows = timeDataArray.length;
    for (let i = 0; i < maxRows; i++) {
      const row: any[] = headers.map(header => dataStructure[header][i] || '');
      csvData.push(row);
    }
    // Use ngx-csv to generate and download the CSV file
    const options = {
      headers: headers,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      useHeader: true,
      nullToEmptyString: true,
    };
    new ngxCsv(csvData, `${this.deviceID}_${this.deviceName}`, options);
  }

  excelExport() {
    this.chartMapping();
    const dataStructure: { [key: string]: any[] } = {};
    const namesSet = new Set<string>();
    const timeData = new Set<string>();
    this.exportCsvMappedData.forEach((dataset) => {
      if (!dataStructure[dataset.Name]) {
        dataStructure[dataset.Name] = [];
      }
      dataStructure[dataset.Name].push(dataset.Value || '');
      timeData.add(dataset.Time || '');
      namesSet.add(dataset.Name);
    })
    const timeDataArray = Array.from(timeData);
    const headers = Array.from(namesSet);
    headers.push('Date');
    const excelData: any[] = [];
    excelData.push(headers);
    const maxRows = Math.max(
      ...headers.slice(0, -1).map(header => dataStructure[header]?.length || 0),
      timeDataArray.length
    );
    for (let i = 0; i < maxRows; i++) {
      const row: any[] = headers.map(header => {
        if (header === 'Date') {
          return timeDataArray[i] || '';
        }
        return dataStructure[header]?.[i] || '';
      });
      excelData.push(row);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Chart Data");

    const fileName = this.ImeiNumber + '_' + this.deviceName + '_' + this.currentDateTime + ".xlsx";
    XLSX.writeFile(workbook, fileName);
  }

  pdfExport() {
    this.chartMapping();
    const dataStructure: { [key: string]: any[] } = {};
    const namesSet = new Set<string>();
    const timeData = new Set<string>();
    this.exportCsvMappedData.forEach((dataset) => {
      if (!dataStructure[dataset.Name]) {
        dataStructure[dataset.Name] = [];
      }
      dataStructure[dataset.Name].push(dataset.Value || '');
      timeData.add(dataset.Time || '');
      namesSet.add(dataset.Name);
    });
    const timeDataArray = Array.from(timeData);
    const headers = Array.from(namesSet);
    headers.push('Date');
    const excelData: any[] = [];
    excelData.push(headers);
    const maxRows = Math.max(
      ...headers.slice(0, -1).map(header => dataStructure[header]?.length || 0),
      timeDataArray.length
    );
    for (let i = 0; i < maxRows; i++) {
      const row: any[] = headers.map(header => {
        if (header === 'Date') {
          return timeDataArray[i] || '';
        }
        return dataStructure[header]?.[i] || '';
      });
      excelData.push(row);
    }
    let pdfData: any[] = []
    pdfData = excelData.slice(1)
    const currentDate = new Date();
    const dateTime = this.datepipe.transform(currentDate, 'dd-MM-yyyy')
    const doc = new jsPDF();
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    doc.setTextColor('#9ecbae');
    doc.setFontSize(10);
    doc.text('Device Name: ' + this.deviceName, 10, 10);
    doc.text('Device id: ' + this.deviceID, 10, 15)
    doc.text('Date: ' + dateTime, 10, 20);
    doc.addImage('./assets/logo.png', 'PNG', 80, 30, 30, 20);

    autoTable(doc, {
      head: [headers],
      theme: 'grid',
      body: pdfData,
      startY: 60,
      styles: { fontSize: 8 },
      headStyles: { fillColor: '#bff8d3', textColor: '#5d6556' },
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
    doc.save(this.ImeiNumber + '_' + this.deviceName + '_' + this.currentDateTime + '.pdf');
  }

}
