import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestService } from 'src/app/services/request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { countries } from 'src/assets/countries/countries.data-store';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-addspray',
  templateUrl: './addspray.component.html',
  styleUrls: ['./addspray.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddsprayComponent implements OnInit {

  sprayForm!: FormGroup;
  title: any;
  dialogTitle: any;
  actionButton: any;
  closeButton: any;
  langStatus: any;
  superuser: boolean = false;
  productType: any;
  status: any = 'pending';
  cropType: any;
  approvalNum: any;
  approvalDate: any;
  authority: any;
  commercialName: any;
  substance: any;
  percentage: any;
  approvalExpiration: any;
  supplyExpiration: any;
  dosage: any;
  phi: any;
  justification: any;
  category: any;
  producer: any;
  country: any;
  approvalOwner: any;
  efpest: number = 0;
  countries: any = countries;

  constructor(
    private fb: FormBuilder,
    private req: RequestService<any>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public editData: any,
    private authorizationService: AuthorizationService
  ) { }

  ngOnInit() {
    this.authorizationService.superuser$.subscribe(superuser => {
      this.superuser = superuser;
    });

    this.sprayForm = this.fb.group({
      productType: ['spraying'],
      cropType: [''],
      status: ['pending'],
      approvalNum: [''],
      approvalDate: [''],
      authority: [''],
      commercialName: ['', Validators.required],
      substance: [''],
      percentage: [''],
      approvalExpiration: [''],
      supplyExpiration: [''],
      dosage: [''],
      phi: [''],
      justification: [''],
      category: [''],
      producer: [''],
      country: [''],
      approvalOwner: [''],
      efpest: [0]
    })

    if (this.editData.isEdit) {
      this.sprayForm.controls['productType'].setValue(this.editData.row.product_type);
      this.sprayForm.controls['cropType'].setValue(this.editData.row.crop_type);
      this.sprayForm.controls['status'].setValue(this.editData.row.status);
      this.sprayForm.controls['approvalNum'].setValue(this.editData.row.approval_num);
      this.sprayForm.controls['approvalDate'].setValue(this.editData.row.approval_date);
      this.sprayForm.controls['authority'].setValue(this.editData.row.authority);
      this.sprayForm.controls['commercialName'].setValue(this.editData.row.commercial_name);
      this.sprayForm.controls['substance'].setValue(this.editData.row.substance);
      this.sprayForm.controls['percentage'].setValue(this.editData.row.percentage);
      this.sprayForm.controls['approvalExpiration'].setValue(this.editData.row.approval_expiration);
      this.sprayForm.controls['supplyExpiration'].setValue(this.editData.row.supply_expiration);
      this.sprayForm.controls['dosage'].setValue(this.editData.row.dosage);
      this.sprayForm.controls['phi'].setValue(this.editData.row.phi);
      this.sprayForm.controls['justification'].setValue(this.editData.row.justification);
      this.sprayForm.controls['category'].setValue(this.editData.row.category);
      this.sprayForm.controls['producer'].setValue(this.editData.row.producer);
      this.sprayForm.controls['country'].setValue(this.editData.row.country);
      this.sprayForm.controls['approvalOwner'].setValue(this.editData.row.approval_owner);
      this.sprayForm.controls['efpest'].setValue(this.editData.row.efpest);
    }

    this.statusLanguage();
  }

  statusLanguage() {
    if (!this.editData) {
      this.dialogTitle = 'PRODUCT.ADDSPRAY';
      this.actionButton = 'PRODUCT.ADD_BUTTON';
      this.closeButton = 'PRODUCT.CANCEL';
    } else if (this.editData) {
      this.dialogTitle = 'PRODUCT.EDITSPRAY';
      this.actionButton = 'PRODUCT.UPDATE_BUTTON';
      this.closeButton = 'PRODUCT.CANCEL';
    }
  }

  addSpray() {
    if (!this.editData.isEdit) {
      let updatedData = {
        product_type: this.sprayForm.value.productType,
        crop_type: this.sprayForm.value.cropType,
        status: this.editData.sprayChange ? 'approved' : this.sprayForm.value.status,
        commercial_name: this.sprayForm.value.commercialName,
        substance: this.sprayForm.value.substance,
        approval_num: this.sprayForm.value.approvalNum,
        approval_date: this.sprayForm.value.approvalDate,
        authority: this.sprayForm.value.authority,
        percentage: this.sprayForm.value.percentage,
        approval_expiration: this.sprayForm.value.approvalExpiration,
        supply_expiration: this.sprayForm.value.supplyExpiration,
        dosage: this.sprayForm.value.dosage,
        phi: this.sprayForm.value.phi,
        justification: this.sprayForm.value.justification,
        category: this.sprayForm.value.category,
        producer: this.sprayForm.value.producer,
        country: this.sprayForm.value.country,
        approval_owner: this.sprayForm.value.approvalOwner,
        efpest: this.sprayForm.value.efpest
      };

      if(!this.editData.sprayChange) {
        this.req.postStorageProduct(updatedData).subscribe(res => {
          const title = "PRODUCT.SPRAY_TITLE_ADD";
          const text =  "PRODUCT.SPRAY_TEXT_ADD";
          this.translate.get([title, text])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              text: translations[text],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
            });
          })
        })
      }
      else {
        this.req.postRawProduct(updatedData).subscribe(res => {
          const title = "PRODUCT.SPRAY_TITLE_ADD";
          const text =  "PRODUCT.SPRAY_TEXT_ADD";
          this.translate.get([title, text])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              text: translations[text],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
            });
          })
        })
      }
    }
    else {
      this.updateSpray()
    }
  }

  updateSpray() {
    let updatedData = {
      product_type: this.sprayForm.value.productType ? this.sprayForm.value.productType : "",
      crop_type: this.sprayForm.value.cropType ? this.sprayForm.value.cropType : "",
      status: this.editData.sprayChange ? 'approved' : this.sprayForm.value.status,
      commercial_name: this.sprayForm.value.commercialName ? this.sprayForm.value.commercialName : "",
      substance: this.sprayForm.value.substance ? this.sprayForm.value.substance : "",
      approval_num: this.sprayForm.value.approvalNum ? this.sprayForm.value.approvalNum : "",
      approval_date: this.sprayForm.value.approvalDate ? this.sprayForm.value.approvalDate : "",
      authority: this.sprayForm.value.authority ? this.sprayForm.value.authority : "",
      percentage: this.sprayForm.value.percentage ? this.sprayForm.value.percentage : "",
      approval_expiration: this.sprayForm.value.approvalExpiration ? this.sprayForm.value.approvalExpiration : "",
      supply_expiration: this.sprayForm.value.supplyExpiration ? this.sprayForm.value.supplyExpiration : "",
      dosage: this.sprayForm.value.dosage ? this.sprayForm.value.dosage : "",
      phi: this.sprayForm.value.phi ? this.sprayForm.value.phi : "",
      justification: this.sprayForm.value.justification ? this.sprayForm.value.justification : "",
      category: this.sprayForm.value.category ? this.sprayForm.value.category : "",
      producer: this.sprayForm.value.producer ? this.sprayForm.value.producer : "",
      country: this.sprayForm.value.country ? this.sprayForm.value.country : "",
      approval_owner: this.sprayForm.value.approvalOwner ? this.sprayForm.value.approvalOwner : "",
      efpest: this.sprayForm.value.efpest ? this.sprayForm.value.efpest : 0
    };

    if (!this.editData.sprayChange) {
      this.req.updateStorageProduct(this.editData.row._id, updatedData).subscribe({
        next: (res) => {
          const title = "PRODUCT.SPRAY_TITLE_UPDATE";
          const text =  "PRODUCT.SPRAY_TEXT_UPDATE";
          this.translate.get([title, text])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              text: translations[text],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
            });
          })
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
    else {
      this.req.updateRawProduct(this.editData.row._id, updatedData).subscribe({
        next: (res) => {
          const title = "PRODUCT.SPRAY_TITLE_UPDATE";
          const text =  "PRODUCT.SPRAY_TEXT_UPDATE";
          this.translate.get([title, text])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              text: translations[text],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
            });
          })
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
  }
}