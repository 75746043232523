<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.HOME'| translate}}
</h1>
<div *ngIf="featureFlags.isFeatureEnabled('showUruguay')" class="flex flex-col">
    <div class="flex items-center justify-center mb-2">
        <div id="map" class="my-8" style="height: 50vh;"></div>
    </div>
</div>
<div *ngIf="featureFlags.isFeatureEnabled('showGreece')" class="main">
    <app-widgetshome id="widgetshome"></app-widgetshome>
    <div class="row justify-content-center" id="map_personalcard">
        <div class="mat-card col-md-4  bg-white rounded shadow-sm shadow-success" id="personalcard">
            <div class="row" (click)="goTo()" *ngFor="let farmer of farmerInfo">
                <div class="col-4 text-left" id="personel">
                    <div class="col-xl-12 col-sm-8">
                        <div class="py-5 px-1">
                            <div class="flex justify-center mb-2">
                                <span class="medium text-uppercase text-muted" style="font-weight: 600;">{{'HOME.FARMER'
                                    |
                                    translate }}</span>
                            </div>
                            <img src="../../../assets/farmer_color.png" alt="" width="100"
                                class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
                            <h5 class="mb-0" style="font-weight: 600; color: rgb(140, 143, 143);">{{farmer.first_name}}
                                {{farmer.last_name}}</h5>

                        </div>
                    </div>
                </div>
                <div class="text-left col-7 col-lg-8 py-2">
                    <div class="col-xl-12 col-sm-8">
                        <div class="py-lg-1 px-lg-1 py-md-2 px-md-2">
                            <table class="table table-borderless table-sm mb-0">
                                <tbody>
                                    <tr class="border-bottom">
                                        <td
                                            class="font-weight-normal fw-bold align-middle table-borderless text-muted text-sm">
                                            <span id="info_key">
                                                {{'FARMER.CITY_REGION' | translate }}:
                                            </span>
                                        </td>
                                        <td class="float-end font-weight-normal">
                                            <p class="mb-0 text-end pt-1" id="info_value" style="font-weight: 600;">
                                                <span style="font-size: 14px;">
                                                    <!-- {{farmer.town}} -->
                                                    {{farmer.region}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td
                                            class="font-weight-normal fw-bold align-middle table-borderless text-muted text-sm">
                                            <span id="info_key">

                                                {{'FARMER.CITY_COM' | translate }}
                                            </span>
                                        </td>
                                        <td class="float-end font-weight-normal">
                                            <p class="mb-0 text-end pt-1" id="info_value" style="font-weight: 600;">
                                                <span style="font-size: 14px;">
                                                    {{comunityFarmer}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td
                                            class="font-weight-normal fw-bold align-middle table-borderless text-muted text-sm">
                                            <span id="info_key">
                                                {{'HOME.TEL' | translate }}
                                            </span>
                                        </td>
                                        <td class="float-end font-weight-normal">
                                            <p class="mb-0 text-end pt-1" id="info_value" style="font-weight: 600;">
                                                <span style="font-size: 14px;">
                                                    {{farmer.phone_number}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td
                                            class="font-weight-normal fw-bold align-middle table-borderless text-muted text-sm">
                                            <span id="info_key">
                                                {{'FARMER.CITY_DOY' | translate }}
                                            </span>
                                        </td>
                                        <td class="float-end font-weight-normal">
                                            <p class="mb-0 text-end pt-1" id="info_value" style="font-weight: 600;">
                                                <span style="font-size: 14px;">
                                                    {{farmer.doy}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td
                                            class="font-weight-normal fw-bold align-middle table-borderless text-muted text-sm">
                                            <span id="info_key">
                                                <!-- {{'HOME.CONTACT' | translate }}: -->
                                                E-mail
                                            </span>
                                        </td>
                                        <td class="float-end font-weight-normal">
                                            <p class="mb-0 text-end pt-1" id="info_value" style="font-weight: 600;">
                                                <span style="font-size: 14px;">
                                                    {{farmer.email}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td
                                            class="font-weight-normal fw-bold align-middle table-borderless text-muted text-sm">
                                            <span id="info_key">
                                                {{'FARMER.CITY_ENTITY' | translate }}
                                            </span>
                                        </td>
                                        <td class="float-end font-weight-normal">
                                            <p class="mb-0 text-end pt-1" id="info_value" style="font-weight: 600;">
                                                <span style="font-size: 14px;">
                                                    {{farmer.entity_type}}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div id="map" class="my-0"></div>
        </div>
    </div>
    <div class="plots" style="margin-top: -5%;">
        <app-plotshome></app-plotshome>
    </div>
</div>