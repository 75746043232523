import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FeatureFlagsService {

  private features = {
    showUruguay: environment.uruguayFeatures,
    showGreece: environment.greekFeatures

  };

  isFeatureEnabled(feature: keyof typeof this.features): boolean {
    return this.features[feature];
  }

  setFeatureFlag(feature: keyof typeof this.features, enabled: boolean): void {
    this.features[feature] = enabled;
  }
}
