export const environment = {
  production: false,
  apiUrl: 'uruguay.agnes-wingsfarm.eu',
  apiType: "https",
  apiPort: "",
  keycloakRealm: "agnes-uruguay",
  keycloakClientId: "agnes-client",
  enableDebug: false,
  keycloakUrl: "https://sso.wingsfarm.eu",
  uruguayFeatures: true,
  greekFeatures: false,
};