import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RequestService } from 'src/app/services/request.service';
import { MatDialog } from '@angular/material/dialog';
import { AddsprayComponent } from './addspray/addspray.component';
import { AddfertiliserComponent } from './addfertiliser/addfertiliser.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProductComponent implements OnInit {

  fertChange: boolean = false;
  sprayChange: boolean = false;
  superuser: boolean = false;
  fertDisplayedColumns: string[] = ['commercial_name', 'type', 'substance', 'N', 'P', 'K', 'status', 'action'];
  sprayDisplayedColumns: string[] = ['commercial_name', 'substance', 'percentage', 'dosage', 'phi', 'category', 'crop_type', 'status', 'action'];
  public selectedRow: any;
  public selectedRowIndex: any;
  public id: any = '';
  fertDataSource!: MatTableDataSource<any>;
  sprayDataSource!: MatTableDataSource<any>;

  @ViewChild('fertPaginator') fertPaginator!: MatPaginator;
  @ViewChild('fertSort') fertSort!: MatSort;

  @ViewChild('sprayPaginator') sprayPaginator!: MatPaginator;
  @ViewChild('spraySort') spraySort!: MatSort;

  constructor(private req: RequestService<any>, private dialog: MatDialog, public translate: TranslateService, private authorizationService: AuthorizationService) { }

  ngOnInit(): void {
    this.authorizationService.superuser$.subscribe(superuser => {
      this.superuser = superuser;
    });
    this.getId();
  }

  getId() {
    this.req.getStorageFertiliser().subscribe((res) => {
      this.fertDataSource = new MatTableDataSource(res);
      this.fertDataSource.paginator = this.fertPaginator;
      this.fertDataSource.sort = this.fertSort;
    });

    this.req.getStorageSpray().subscribe((res) => {
      this.sprayDataSource = new MatTableDataSource(res);
      this.sprayDataSource.paginator = this.sprayPaginator;
      this.sprayDataSource.sort = this.spraySort;
    });
  }

  getAllFertilisers() {
    this.req.geFertiliserSuperUser().subscribe((res) => {
      this.fertDataSource = new MatTableDataSource(res);
      this.fertDataSource.paginator = this.fertPaginator;
      this.fertDataSource.sort = this.fertSort;
    });
  }

  getAllPlantProtectors() {
    this.req.getSpraySuperUser().subscribe((res) => {
      this.sprayDataSource = new MatTableDataSource(res);
      this.sprayDataSource.paginator = this.sprayPaginator;
      this.sprayDataSource.sort = this.spraySort;
    });
  }

  onFertCheckboxChange() {
    if (this.fertChange) {
      this.getAllFertilisers();
    }
    else {
      this.getId();
    }
  }

  onSprayCheckboxChange() {
    if (this.sprayChange) {
      this.getAllPlantProtectors();
    }
    else {
      this.getId();
    }
  }

  onRowClicked(row: any) {
    this.selectedRow = row.file_id;
    this.selectedRowIndex = row.id;
    this.selectedRow = row
  }

  addFertiliser() {
    const dialogRef = this.dialog.open(AddfertiliserComponent, {
      data: {
      isEdit: false,
      fertChange: this.fertChange
    }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.fertChange === true) {
        this.getAllFertilisers();
      } else {
        this.getId();
      }
    })
  }

  addSpray() {
    const dialogRef = this.dialog.open(AddsprayComponent, {
      data: {
        isEdit: false,
        sprayChange: this.sprayChange
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.sprayChange === true) {
        this.getAllPlantProtectors();
      } else {
        this.getId();
      }
    });
  }

  editFertiliser(row: any) {
    const dialogRef = this.dialog.open(AddfertiliserComponent, {
      data: {
        isEdit: true,
        fertChange: this.fertChange,
        row
      }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (this.fertChange === true) {
        this.getAllFertilisers();
      } else {
        this.getId();
      }
    });
  }

  editSpray(row: any) {
    const dialogRef = this.dialog.open(AddsprayComponent, {
      data: {
        isEdit: true,
        sprayChange: this.sprayChange,
        row
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.sprayChange === true) {
        this.getAllPlantProtectors();
      } else {
        this.getId();
      }
    });
  }

  deleteProduct(id: any) {
    const title = 'PRODUCT.DELETE_TITLE';
    const yesDelete = 'PRODUCT.YES_DELETE';
    const deletedTitle = 'PRODUCT.DELETED_TITLE';
    const deletedText = 'PRODUCT.DELETED_TEXT';
    const cancel = 'PRODUCT.CANCEL';

    this.translate.get([title, yesDelete, yesDelete, cancel, deletedTitle, deletedText])
      .subscribe(translations => {
        Swal.fire({
          title: translations[title],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: 'rgb(147, 163, 160)',
          confirmButtonText: translations[yesDelete],
          cancelButtonText: translations[cancel],
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: translations[deletedTitle],
              text: translations[deletedText],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000
            });

            if (this.fertChange && this.sprayChange === false) {
              this.req.deleteRawProduct(this.selectedRow._id).subscribe(res => {
                this.getAllFertilisers();
              });
            } else if (this.sprayChange && this.fertChange === false) {
              this.req.deleteRawProduct(this.selectedRow._id).subscribe(res => {
                this.getAllPlantProtectors();
              });
            }
            else {
              this.req.deleteStorageProduct(this.selectedRow._id).subscribe(res => {
                this.getId();
              });
            }
          }
        });
      });
  }
}