<footer class="block bg-gradient-to-b from-gray-100 to-white">
  <hr class="border-b-1 border-gray-200" />
  <div class="flex flex-wrap justify-between">
    <div class="flex flex-grow items-center justify-center">
      <a href="https://www.wings-ict-solutions.eu/">
        <img src="../../../assets/logo-wings.png" class="px-4 object-scale-down w-28">
      </a>
      <div class="text-sm text-gray-600 font-normal text-center">
        Copyright © {{ date }} Wings ICT Solutions
      </div>
    </div>
    <div class="place-self-center mr-2 mt-1">
      <a href="https://www.facebook.com/WINGS.ICT/" class="text-gray-600 hover:text-blue-600 mx-1">
        <i class="fab fa-facebook text-lg"></i>
      </a>
      <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fwings_ict" class="text-gray-600 hover:text-blue-400 mx-1">
        <i class="fab fa-twitter text-lg"></i>
      </a>
      <a href="https://www.linkedin.com/company/wings-ict-solutions" class="text-gray-600 hover:text-blue-500 mx-1">
        <i class="fab fa-linkedin-in text-lg"></i>
      </a>
      <a href="https://www.youtube.com/channel/UCPotWFhjcmvgQAbBYMYhofQ" class="text-gray-600 hover:text-red-600 mx-1">
        <i class="fab fa-youtube text-lg"></i>
      </a>
    </div>
  </div>
</footer>