import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { KeycloakService } from 'keycloak-angular';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as XLSX from 'xlsx'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { font2 } from '../../fonts/encryption';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-seedhistory',
  templateUrl: './seedhistory.component.html',
  styleUrls: ['./seedhistory.component.scss']
})
export class SeedhistoryComponent {
  farmerId: any = '';
  plotCodes: any[] = [];
  seedingData: any = [];
  seedingDataMap: any[] = [];
  plantingData: any = [];
  plantingDataMap: any[] = [];
  codes: any = [];
  code: any = '';
  gridOptions: GridOptions = {
    columnDefs: [],
    rowData: [],
    rowSelection: 'multiple',
    animateRows: true,
    pagination: true,
    paginationPageSize: 10
  };
  defaultColDef: any = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {
      buttons: ['reset', 'apply'],
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
    }
  }
  columnDefs: any[] = [
    {
      headerName: 'Date',
      field: 'datetime',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
      },
    },
  ];
  seedingColumnDefs: any[] = [];
  plantingColumnDefs: any[] = [];

  selected: any
  isSeeding: boolean = false
  isPlanting: boolean = false
  plantingQuantity: number = 0
  seedingQuantity: number = 0
  langStatus: any;
  plantSumPdf: any[] = []
  seedSumPdf: any[] = []
  headColumns: any[] = [];
  headColumnsSum: any[] = [];
  SumTable: any;
  elArray: any[] = [];
  splitArray: any

  @Input() plotValueSeed: any;
  @Input() plotTypePlantSeed: any;
  @Input() plotChoose: any;
  @Input() tabStatus: any;
  @Input() eventChild: any;
  @Input() eventParent: any;
  @Input() parentCodeBoolean: any;
  @Input() childCodeBoolean: any;
  @Input() parentCode: any;
  @Input() childCode: any;
  @Input() parentBox: any;
  @Input() childBox: any;
  @Input() setAllStatus: any;
  @Input() startingDateString: any
  @Input() endingDateString: any
  @Input() afmFarmer: any
  @Input() firstName: any
  @Input() lastName: any
  dateRange: any;
  cropType: any;


  constructor(
    private req: RequestService<any>,
    private keycloak: KeycloakService,
    private datePipe: DatePipe,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.langStatus = this.translate.store.currentLang
    this.columnsTable();
    this.getAllData();
  }

  getAllData() {
    this.req.getPlotFarmerHistory('?crop_usage=True').subscribe((res) => {
      this.plotCodes = res
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.plotChoose.length
      if (this.tabStatus == 3 && this.plotTypePlantSeed == 'Planting' || this.plotTypePlantSeed == 'Φύτευση') {
        this.isPlanting = true
        this.isSeeding = false
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getPlantingData()
        } else if (this.setAllStatus) {
          this.getAllPlantingData()
        }
      }
      else if (this.tabStatus == 3 && this.plotTypePlantSeed == 'Seeding' || this.plotTypePlantSeed == 'Σπορά') {
        this.isPlanting = false
        this.isSeeding = true
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getSeedingData();
        } else if (this.setAllStatus) {
          this.getAllSeedingData()
        }
      }
    }, 50);
  }

  columnsTable() {
    this.langStatus = this.translate.store.currentLang;
    if (this.langStatus === 'en') {
      this.seedingColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Lot Number', field: 'lot_number', resizable: true },
        { headerName: 'Date', field: 'datetime', resizable: true },
        { headerName: 'Quantity', field: 'quantity', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]

      this.plantingColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Lot Number', field: 'lot_number', resizable: true },
        { headerName: 'Date', field: 'datetime', resizable: true },
        { headerName: 'Quantity', field: 'quantity', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]
    }
    else {
      this.seedingColumnDefs = [
        { headerName: 'Αγροτεμάχια', field: 'plot_code', resizable: true },
        { headerName: 'Αριθμός Παρτίδας', field: 'lot_number', resizable: true },
        { headerName: 'Ημερομηνία', field: 'datetime', resizable: true },
        { headerName: 'Ποσότητα', field: 'quantity', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]

      this.plantingColumnDefs = [
        { headerName: 'Αγροτεμάχια', field: 'plot_code', resizable: true },
        { headerName: 'Αριθμός Παρτίδας', field: 'lot_number', resizable: true },
        { headerName: 'Ημερομηνία', field: 'datetime', resizable: true },
        { headerName: 'Ποσότητα', field: 'quantity', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]
    }
  }

  deleteButtonRenderer = (params: any) => {
    let eGui = document.createElement('div');
    eGui.innerHTML = `<button class="action-button text-md text-red-500 hover:text-red-600" data-action="delete">
    <i class="far fa-trash-alt"></i>
    </button>`;

    eGui.querySelectorAll('.action-button').forEach(button => {
      button.addEventListener('click', () => {
        const action = button.getAttribute('data-action');
        const paramsId = params.data.id;
        if (action === 'delete') {
          this.deleteRecord(paramsId);
        }
      });
    });
    return eGui;
  }

  deleteRecord(paramsId: any) {
    const title = 'HISTORY.DELETE_TITLE';
    const yesDelete = 'PRODUCT.YES_DELETE';
    const deletedTitle = 'PRODUCT.DELETED_TITLE';
    const deletedText = 'HISTORY.DELETED_TEXT';
    const cancel = 'PRODUCT.CANCEL';

    this.translate.get([title, yesDelete, yesDelete, cancel, deletedTitle, deletedText])
      .subscribe(translations => {
        Swal.fire({
          title: translations[title],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: 'rgb(147, 163, 160)',
          confirmButtonText: translations[yesDelete],
          cancelButtonText: translations[cancel],
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: translations[deletedTitle],
              text: translations[deletedText],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000
            });
            if (this.isSeeding) {
              this.req.deleteHistoricalSeeding(paramsId).subscribe(res => {
                this.getSeedingData();
              })
            }

            else if (this.isPlanting) {
              this.req.deleteHistoricalPlanting(paramsId).subscribe(res => {
                this.getPlantingData();
              })
            }
          }
        });
      });
  }

  noData() {
    this.plantingData = [];
    this.seedingData = [];
  }

  textList() {
    this.dateRange = '';
    if (this.plotChoose.length == 0) {
      this.noData()
    } else if (this.plotChoose.length > 0) {
      this.plotChoose.forEach((el: any) => {
        this.elArray.push('crop_info_id=' + el.crop_info_id + '&')
        this.splitArray = (('?' + this.elArray).replace(/,/g, '').slice(0, -1));
      });
      this.dateRange = this.splitArray + '&start_date=' + this.startingDateString + '&end_date=' + this.endingDateString
    }
  }

  getPlantingData() {
    this.plantingQuantity = 0
    this.elArray = [];
    this.plantingData = []
    this.textList()
    this.req.getPlotPlantingHistory(this.dateRange).subscribe((res) => {
      this.plantingData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy'),
          lot_number: element.lot_number,
          quantity: element.quantity
        }
      });
      const plantingQuantity = this.plantingData.map((obj: any) => obj.quantity)
      this.plantingQuantity = plantingQuantity.length > 0 ? plantingQuantity.reduce((sum: any, quantity: any) => sum + quantity) : 0
    });
  }

  getAllPlantingData() {
    this.textList()
    this.req.getPlanting().subscribe((res) => {
      this.plantingData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${element.crop_type}`,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy'),
          lot_number: element.lot_number,
          quantity: element.quantity
        }
      });
      const plantingQuantity = this.plantingData.map((obj: any) => obj.quantity)
      this.plantingQuantity = plantingQuantity.length > 0 ? plantingQuantity.reduce((sum: any, quantity: any) => sum + quantity) : 0
    });
  }

  getAllSeedingData() {
    this.textList()
    this.req.getSeeding().subscribe((res) => {
      this.seedingData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${this.cropType}`,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy'),
          lot_number: element.lot_number,
          quantity: element.quantity
        }
      });
      const seedingQuantity = this.seedingData.map((obj: any) => obj.quantity)
      this.seedingQuantity = seedingQuantity.length > 0 ? seedingQuantity.reduce((sum: any, quantity: any) => sum + quantity) : 0
    });
  }

  getSeedingData() {
    this.seedingData = [];
    this.elArray = [];
    this.seedingQuantity = 0
    this.textList()
    this.req.getPlotSeedingHistory(this.dateRange).subscribe((res) => {
      this.seedingData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy'),
          lot_number: element.lot_number,
          quantity: element.quantity
        }
      });
      const seedingQuantity = this.seedingData.map((obj: any) => obj.quantity)
      this.seedingQuantity = seedingQuantity.length > 0 ? seedingQuantity.reduce((sum: any, quantity: any) => sum + quantity) : 0
    });
  }

  plantMapped() {
    this.plantingDataMap = this.plantingData.map((el: any) => [
      el.plot_code,
      el.datetime,
      el.lot_number,
      el.quantity
    ]);
  }

  exportCsvPlanting() {
    this.plantMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Plot', 'Date', 'Lot_Number', 'Quantity'],
      };
      new ngxCsv(this.plantingDataMap, 'Planting_' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αργοτεμάχιο', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Ποσότητα'],
      };
      new ngxCsv(this.plantingDataMap, 'Φύτευση_' + dateTime, options);
    }
  }

  exportExcelPlanting() {
    this.plantMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Date', 'Lot_Number', 'Quantity'];
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.plantingDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Planting_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αργοτεμάχιο', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Ποσότητα'];
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.plantingDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Φύτευση_' + dateTime + '.xlsx')
    }
  }

  exportPdfPlanting() {
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    this.plantSumPdf = []
    this.plantSumPdf.push(this.plantingQuantity)
    if (this.langStatus.includes('en')) {
      this.headColumns = ['Plot', 'Date', 'Lot_Number', 'Quantity']
      this.headColumnsSum = ['Total Planting']
      this.SumTable = [{
        Total_Planting: this.plantSumPdf[0]
      }]
    } else if (this.langStatus.includes('gr')) {
      this.headColumns = ['Αργοτεμάχιο', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Ποσότητα']
      this.headColumnsSum = ['Συνολική Φύτευση']
      this.SumTable = [{
        Συνολική_Φύτευση: this.plantSumPdf[0]
      }]
    }
    const doc = new jsPDF();
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    const valuesArray2 = this.SumTable.map((obj: any) => Object.values(obj));
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Planting', 180, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Φύτευση', 180, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 90, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.plantingDataMap,
      startY: 45,
    });
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumnsSum],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: valuesArray2,
      startY: 250,
      margin: { left: 120 },
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Planting_" + dateTime + ".pdf");
    } else if (this.langStatus.includes('gr')) {
      doc.save("Φύτευση_" + dateTime + ".pdf");
    }
  }

  seedMapped() {
    this.seedingDataMap = this.seedingData.map((el: any) => [
      el.plot_code,
      el.datetime,
      el.lot_number,
      el.quantity
    ]);
  }

  exportCsvSeeding() {
    this.seedMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Plot', 'Date', 'Lot_Number', 'Quantity'],
      };
      new ngxCsv(this.seedingDataMap, 'Seeding_' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αργοτεμάχιο', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Ποσότητα'],
      };
      new ngxCsv(this.seedingDataMap, 'Σπορά_' + dateTime, options);
    }
  }

  exportExcelSeeding() {
    this.seedMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Date', 'Lot_Number', 'Quantity']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.seedingDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Seeding_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αργοτεμάχιο', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Ποσότητα']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.seedingDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Σπορά_' + dateTime + '.xlsx')
    }
  }

  exportPdfSeeding() {
    this.seedMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    this.seedSumPdf = []
    this.seedSumPdf.push(this.seedingQuantity)
    if (this.langStatus.includes('en')) {
      this.headColumns = ['Plot', 'Date', 'Lot_Number', 'Quantity']
      this.headColumnsSum = ['Total Seeding']
      this.SumTable = [{
        Total_Seeding: this.seedSumPdf[0]
      }]
    } else if (this.langStatus.includes('gr')) {
      this.headColumns = ['Αργοτεμάχιο', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Ποσότητα']
      this.headColumnsSum = ['Συνολική Σπορά']
      this.SumTable = [{
        Συνολική_Σπορά: this.seedSumPdf[0]
      }]
    }
    const doc = new jsPDF();
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    const valuesArray2 = this.SumTable.map((obj: any) => Object.values(obj));
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Seeding', 180, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Σπορά', 180, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 90, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.seedingDataMap,
      startY: 45,
    });
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumnsSum],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: valuesArray2,
      startY: 250,
      margin: { left: 120 },
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Seeding_" + dateTime + ".pdf");
    } else if (this.langStatus.includes('gr')) {
      doc.save("Σπορά_" + dateTime + ".pdf");
    }
  }
}
