import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private superuserSubject = new BehaviorSubject<boolean>(false);
  superuser$ = this.superuserSubject.asObservable();

  private adminSubject = new BehaviorSubject<boolean>(false);
  admin$ = this.adminSubject.asObservable();

  private subadminSubject = new BehaviorSubject<boolean>(false);
  subadmin$ = this.subadminSubject.asObservable();

  setSuperuser(superuser: boolean) {
    this.superuserSubject.next(superuser);
  }

  setAdmin(admin: boolean) {
    this.adminSubject.next(admin);
  }

  setSubadmin(subadmin: boolean) {
    this.subadminSubject.next(subadmin);
  }

}
