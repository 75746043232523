<h1 mat-dialog-title id="addEquipmentTitle" class="text-center">{{dialogTitle}}</h1>
<form class="example-form" id="form" [formGroup]="equipmentForm">
    <div class="row">
        <div class="col-6">
            <div *ngIf="!otherEquipmentName">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'MANAGEMENT_EQUIPMENT.EQUIPMENT_NAME' | translate }}</mat-label>
                    <mat-select formControlName="title" placeholder="{{selectedOptionName}}">
                        <mat-option *ngFor="let equipment of equipmentNames" value="{{equipment}}">
                            {{equipment}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="otherEquipmentName">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{actionOtherEquipmentName}}</mat-label>
                    <input matInput formControlName="otherEquipmentName" placeholder="Type Your Equipment Name Here" />
                </mat-form-field>
            </div>
        </div>        
        <div class="col-6">
            <div *ngIf="!otherEquipmentType">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'MANAGEMENT_EQUIPMENT.EQUIPMENT_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="equipment_type" placeholder="{{selectedOptionType}}">
                        <mat-option *ngFor="let equipment of equipmentTypes" value="{{equipment}}">
                            {{equipment}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="otherEquipmentType">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{actionOtherEquipmentService}}</mat-label>
                    <input matInput formControlName="otherEquipmentService" placeholder="Type Your Equipment Here" />
                </mat-form-field>
            </div>
        </div>        
        <div class="row">
            <div class="col-7">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{'MANAGEMENT_EQUIPMENT.CHOOSE_DATE' | translate }}</mat-label>
                    <input matInput formControlName="date" [matDatepicker]="picker">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> <br>
            </div>
        </div>
    </div>
    <div class="col-12">
        <mat-form-field class="example-full-width">
            <mat-label style="font-size: 14px;">{{'MANAGEMENT_EQUIPMENT.COMMENT' | translate }}</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field> <br>
    </div>
</form>
<div id="buttons_group" class="mt-2 p-3 float-right">
    <div class="row">
        <div class="col-5">
            <button mat-raised-button mat-dialog-close="Close"
                style="background-color: red; color: white;">{{closeButton}}</button>
        </div>
        <div class="col-5">
            <button mat-raised-button mat-dialog-close style="background-color: #3fb56d; color: white;"
                (click)="addEquipment($event)">{{actionButton}}</button>
        </div>
    </div>
</div>