import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { MatDialog } from '@angular/material/dialog';
import { AddAgronomistComponent } from './add-agronomist/add-agronomist.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from "jspdf";
import { font2 } from '../fonts/encryption';
import { DatePipe } from '@angular/common';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReportsComponent implements OnInit, AfterViewInit {

  startingDate: any;
  endingDate: any;
  report: string = '';
  agronomist: string = '';
  agronomistID: any;
  agronomists: any = [];
  testObj: any
  Tables: any;
  Table6: any[] = [{
    id: 1,
    appName: `   «AGNES»
    Κωδικός αδείας 58642/13-10-2023`,
    section: `    i. Ψηφιακό ημερολόγιο εργασιών και εφαρμογών παρακολούθησης γεωργικής εκμετάλλευσης
    ii. Αυτοματοποιημένη διαχείριση στοιχείων παραγωγού και αγροτεμαχίων
    iii. Παρακολούθηση μετεωρολογικών δεδομένων
    iv. Εξαγωγή δεδομένων και αναφορών`,
    descriptionApp: `    Βάσει των δεδομένων που καταχωρήθηκαν στη ψηφιακή εφαρμογή διαχείρισης εισροών & παρακολούθησης περιβαλλοντικών παραμέτρων, αντλήθηκαν όλες
     οι απαιτούμενες πληροφορίες για την έκδοση συμβουλών σχετικά με τα κάτωθι πεδία περιβαλλοντικών στοχεύσεων:
    Συμβουλές αναφορικά με την εφαρμογή των κάτωθι δράσεων
    ECO-06.01 (Ετήσια ενίσχυση για την άδεια χρήσης, συμπλήρωση στοιχείων, αναλύσεις , κατάρτιση, παρακολούθηση, βελτίωση σχεδίου διαχείρισης)
    ECO-06.11 (Ετήσια ενίσχυση για την εφαρμογή πολλαπλών καλοκαιρινών κλαδεμάτων για οπωρώνες (ελαιώνες - εσπεριδοειδή) χωρίς όψιμη φρέσκια βλάστηση)
    ECO-06.19 (Ετήσια ενίσχυση για χρήση λιπασμάτων με παρεμποδιστές στα κηπευτικά και μόνιμες καλλιέργειες)
    ECO-06.21 (Ετήσια ενίσχυση για χρήση προϊόντων με βιοδιεγέρτες στα κηπευτικά και μόνιμες καλλιέργειες)`,
    descriptionFarmer: `    Ο παραγωγός προχώρησε στις απαιτούμενες ενέργειες βάσει των συστάσεων και οδηγιών που αναφέρονται στο παρόν ΣΠΔ για τις εν λόγω δράσεις,
    λαμβάνοντας ταυτόχρονα γνώση και εκπαίδευση από τον Τεχνικό Σύμβουλο για την λειτουργία της ψηφιακής εφαρμογής.`,
  }]
  dateRange: any;
  farmerSurname: string = ''
  farmerVat: number | string = ''

  constructor(private req: RequestService<any>, private dialog: MatDialog, public translate: TranslateService, private datePipe: DatePipe) { }
  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getAgronomists();
    this.getFarmer();
  }

  async getAgronomists() {
    this.agronomists = [];
    this.req.getAgronomists().subscribe((res) => {
      res.forEach(element => {
        this.agronomists.push({
          id: element._id,
          firstName: element.first_name,
          lastName: element.last_name,
          fatherName: element.father_name,
          vat: element.vat_number,
          employmentRelationship: element.employment_relationship,
          geoteNumber: element.geote_number,
          entityType: element.entity_type,
          companyName: element.company_name,
          specify: element.specify_employment_relationship
        });
      })
    });
  }

  getFarmer() {
    this.req.getFarmer().subscribe((res: any) => {
      console.log(res)
      this.farmerSurname = res.last_name
      this.farmerVat = res.vat_number
    });
  }

  addAgronomist() {
    const dialogRef = this.dialog.open(AddAgronomistComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAgronomists();
    });
  }

  deleteAgronomist(id: string) {
    const title = 'REPORT.DELETE_TITLE';
    const yesDelete = 'PRODUCT.YES_DELETE';
    const deletedTitle = 'PRODUCT.DELETED_TITLE';
    const deletedText = 'REPORT.DELETED_TEXT';
    const cancel = 'PRODUCT.CANCEL';
    this.translate.get([title, yesDelete, yesDelete, cancel, deletedTitle, deletedText])
      .subscribe(translations => {
        Swal.fire({
          title: translations[title],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: 'rgb(147, 163, 160)',
          confirmButtonText: translations[yesDelete],
          cancelButtonText: translations[cancel],
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: translations[deletedTitle],
              text: translations[deletedText],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000
            });

            this.req.deleteAgronomist(id).subscribe(() => {
              this.agronomists = this.agronomists.filter((agronomist: { id: string; }) => agronomist.id !== id);
            });
          }
        });
      });

  }

  editAgronomist(agronomist: any) {
    const dialogRef = this.dialog.open(AddAgronomistComponent, {
      data: agronomist
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAgronomists();
      this.getPdf()
    });
  }

  downloadPdf() {
    const startDate = this.datePipe.transform(this.startingDate, 'dd-MM-yyyy')
    const endDate = this.datePipe.transform(this.endingDate, 'dd-MM-yyyy')
    this.dateRange = '?agronomist_id=' + this.agronomist + '&start_date=' + startDate + '&end_date=' + endDate
    this.req.getReportPdf(this.dateRange).subscribe((res: any) => {
      this.testObj = res
      const currentDate = new Date();
      const date = this.datePipe.transform(currentDate, 'dd-MM-yyyy');
      const yearNow = new Date().getFullYear();
      const yearPast = (yearNow).toString()
      const doc = new jsPDF();
      doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
      doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
      doc.setFont('LiberationSerif-Regular', 'normal');
      doc.setFontSize(20);
      doc.text("Σχέδιο Περιβαλλοντικής Διαχείρισης (ΣΠΔ)", 45, 15);
      doc.line(45, 16, 170, 16);
      doc.setFontSize(14);
      doc.text("στο πλαίσιο των Οικολογικών Προγραμμάτων (ecoschemes) του ΣΣ ΚΑΠ", 32, 23);
      doc.setFontSize(12);
      doc.text("Παρέμβαση Π1-31.6 Ενίσχυση παραγωγών για την παραγωγή φιλικών για το περιβάλλον πρακτικών διαχείρισης,", 8, 35);
      doc.text("με τη χρήση ψηφιακής εφαρμογής διαχείρισης εισροών και περιβαλλοντικών παραμέτρων.", 27, 40);
      doc.text("Δράση 31.6-Α Χρήση της ψηφιακής εφαρμογής και σύνταξη", 55, 55);
      doc.text("Σχεδίου Περιβαλλοντικής Διαχείρισης (ΣΠΔ)", 67, 60);
      doc.text("ΕΤΟΣ ΕΑΕ…………………………", 20, 90);
      if (yearPast) {
        doc.text(yearPast, 55, 89)
      }
      doc.text("Ημερομηνία Κατάρτισης…………………………", 105, 90);
      // if (date) {
      //   doc.text(date, 160, 89)
      // }
      doc.rect(15, 70, 180, 25);
      doc.line(100, 70, 100, 95);
      doc.rect(15, 110, 180, 7);
      doc.text("ΣΤΟΙΧΕΙΑ ΑΙΤΟΥΝΤΑ ΓΕΩΡΓΟΥ", 69, 115);
      doc.text("ΑΦΜ: ………………………………………", 20, 130);
      doc.text(this.testObj.vat_number, 45, 129)
      doc.text("Επώνυμο γεωργού/νόμιμου εκπροσώπου \n νομικού προσώπου:\n\n……………………………………………………", 105, 130);
      doc.text(this.testObj.last_name, 130, 143)
      doc.text("Τύπος προσώπου \n(Φυσικό ή Νομικό Πρόσωπο):\n\n………………………………………", 20, 143);
      doc.text(this.testObj.entity_type, 34, 156)
      doc.text("Όνομα γεωργού/νόμιμου εκπροσώπου:\n\n……………………………………………………", 105, 155);
      doc.text(this.testObj.first_name, 125, 163)
      doc.text("Επωνυμία \n(στην περίπτωση νομικού προσώπου):\n\n………………………………………", 20, 167);
      doc.text(this.testObj.company_name, 25, 180)
      doc.text("Πατρώνυμο: ………………………………………", 105, 177);
      doc.text(this.testObj.father_name, 130, 176)
      doc.rect(15, 110, 180, 85);
      doc.line(15, 117, 195, 117);
      doc.line(100, 117, 100, 195);
      doc.rect(15, 210, 180, 7);
      doc.text("ΣΤΟΙΧΕΙΑ ΕΔΡΑΣ ΕΚΜΕΤΑΛΛΕΥΣΗΣ", 69, 215);
      doc.text("Περιφέρεια έδρας εκμετάλλευσης: ………………………………………………………………………", 20, 227);
      doc.text(this.testObj.region, 83, 226)
      doc.text("Περιφέρειακή Ενότητα έδρας εκμετάλλευσης: ………………………………………………………….", 20, 239);
      doc.setFontSize(10);
      doc.text(this.testObj.regional_unit, 103, 238)
      doc.setFontSize(12);
      doc.text("Δημοτική-Τοπική Κοινότητα έδρας εκμετάλλευσης: ……………………………………………………", 20, 251);
      doc.text(this.testObj.seat_community, 123, 250)
      doc.rect(15, 217, 180, 40);
      doc.addPage("a4");
      doc.rect(15, 10, 180, 7);
      doc.text("ΣΤΟΙΧΕΙΑ ΤΕΧΝΙΚΟΥ ΣΥΜΒΟΥΛΟΥ (ΓΕΩΠΟΝΟΣ ΠΕ)", 55, 15);
      doc.text("ΑΦΜ επιβλέποντα Τεχνικού Συμβούλου:\n\n………………………………………", 20, 30);
      doc.text(this.testObj.agronomist_vat_number, 23, 39)
      doc.text("Αριθμός Μητρώου ΓΕΩΤΕΕ:……………….", 20, 53);
      doc.text(this.testObj.geote_number, 73, 52)
      doc.text("Επώνυμο: ……………………………………", 20, 65);
      doc.text(this.testObj.agronomist_last_name, 43, 64)
      doc.text("Όνομα: ……………………………………", 20, 77);
      doc.text(this.testObj.agronomist_first_name, 40, 76)
      doc.text("Σε περίπτωση απασχόλησης του Τεχνικού\nΣυμβούλου σε νομικό πρόσωπο, συμπληρώστε\nτα στοιχεία του νομικού προσώπου: ", 105, 27);
      doc.text("ΑΦΜ νομικού προσώπου:\n\n………………………………………", 105, 48);
      doc.text(this.testObj.agronomist_vat_number, 108, 57)
      doc.text("Επωνυμία νομικού προσώπου:\n\n………………………………………", 105, 68);
      doc.text(this.testObj.agronomist_company_name, 108, 77)
      doc.text("Σχέση εργασίας Τεχνικού Συμβούλου\nμε το νομικό πρόσωπο\n(τσεκάρετε με «Χ» κατά περίπτωση):", 105, 87);
      doc.rect(104, 100, 82, 40);
      doc.text("Μισθωτός", 106, 108);
      if (this.testObj.employment_relationship === 'Μισθωτός') {
        doc.text('X', 127, 108)
      } else if (this.testObj.employment_relationship === 'Μέτοχος') {
        doc.text('X', 127, 121)
      } else if (this.testObj.employment_relationship === 'Με δελτίο παροχής υπηρεσιών') {
        doc.text('X', 178, 108)
      }
      doc.rect(126, 104, 5, 5);
      doc.text("Μέτοχος", 106, 121);
      doc.rect(126, 117, 5, 5);
      doc.text("Με δελτίο παροχής\nυπηρεσιών", 142, 108);
      doc.rect(177, 104, 5, 5);
      doc.text("Άλλο (συνοπτική\nπεριγραφή)", 106, 131);
      doc.text(this.testObj.specify_employment_relationship, 142, 131);
      doc.rect(15, 10, 180, 135);
      doc.line(140, 100, 140, 140);
      doc.line(104, 115, 186, 115);
      doc.line(104, 125, 186, 125);
      doc.line(100, 17, 100, 145);
      doc.rect(100, 160, 80, 50);
      doc.line(100, 167, 180, 167);
      doc.text("Ο συντάκτης-Τεχνικός Σύμβουλος", 111, 165);
      doc.text("(Υπογραφή - σφραγίδα)", 120, 193);
      doc.line(100, 195, 180, 195);
      doc.text("Ημερομηνία", 130, 208);
      doc.save(this.farmerSurname + '_' + this.farmerVat + '_' + "ΣΠΔ.pdf");
    })
  }

  getPdf() {
    const startDate = this.datePipe.transform(this.startingDate, 'dd-MM-yyyy')
    const endDate = this.datePipe.transform(this.endingDate, 'dd-MM-yyyy')
    this.dateRange = '?agronomist_id=' + this.agronomist + '&start_date=' + startDate + '&end_date=' + endDate
    this.req.getReportPdf(this.dateRange).subscribe((res: any) => {
      this.testObj = res
    })
  }

  downloadExcel() {
    const startDate = this.datePipe.transform(this.startingDate, 'yyy-MM-dd')
    const endDate = this.datePipe.transform(this.endingDate, 'yyy-MM-dd')
    this.dateRange = '?agronomist_id=' + this.agronomist + '&start_date=' + startDate + '&end_date=' + endDate
    this.req.getReportExcel(this.dateRange).subscribe((res: any) => {
      this.Tables = res
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('ΣΠΔ');
      const instructionsheet = workbook.addWorksheet('Οδηγίες');

      worksheet.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
      });

      instructionsheet.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
      });

      function styleTitlesCell(cell: any) {
        cell.font = { name: 'Calibri', size: 9, bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8CBAD' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }

      // // // 1st Table
      worksheet.mergeCells('A2:A4');
      worksheet.mergeCells('B2:B4');
      worksheet.mergeCells('C2:C4');
      worksheet.mergeCells('D2:D4');
      worksheet.mergeCells('E2:E4');
      worksheet.mergeCells('F2:F4');
      worksheet.mergeCells('G2:G4');
      const subtitleRange = 'H2:U2';
      worksheet.mergeCells(subtitleRange);
      worksheet.mergeCells('H3:K4');
      worksheet.mergeCells('L3:N4');
      worksheet.mergeCells('O3:Q4');
      worksheet.mergeCells('R3:T4');
      worksheet.mergeCells('U3:U4');
      worksheet.mergeCells('L5:M5');
      this.Tables.table_1.forEach((el: any, index: number) => {
        const rowNumberLM = index + 6
        const columnStartL = 'L';
        const columnEndM = 'M';
        worksheet.mergeCells(`${columnStartL}${rowNumberLM}:${columnEndM}${rowNumberLM}`);
      })
      worksheet.mergeCells('A1:U1');
      const titleRow = worksheet.getRow(1);
      titleRow.getCell(1).value = 'ΠΙΝΑΚΑΣ 1 - ΣΤΟΙΧΕΙΑ ΑΓΡΟΤΕΜΑΧΙΩΝ ΑΝΑ ΠΑΡΕΜΒΑΣΗ';
      titleRow.eachCell((cell) => styleTitlesCell(cell));
      for (let col = 1; col <= 7; col++) {
        const cell = worksheet.getCell(2, col);
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
      const headerValues = [
        'Α/Α',
        'Α/Α ΕΑΕ',
        'Τοποθεσία',
        'Κωδικός Χαρτογραφικού Υποβάθρου',
        'Επιλέξιμη έκταση ΕΑΕ (ha)',
        'Καλλιέργεια',
        'ΟΝΟΜΑΣΙΑ ΠΙΣΤΟΠΟΙΗΜΕΝΗΣ ΨΗΦΙΑΚΗΣ ΕΦΑΡΜΟΓΗΣ (31.6-Α)'
      ];
      const headerRow = worksheet.getRow(2);
      headerValues.forEach((value, index) => {
        const cell = headerRow.getCell(index + 1);
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
      const setCellStyles = (cell: any) => {
        cell.font = { name: 'Calibri', size: 9, bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      };
      const setRowValuesAndStyles = (row: any, cellValues: any, cellIndices: any) => {
        cellIndices.forEach((index: any, i: any) => {
          const cell = row.getCell(index);
          cell.value = cellValues[i];
          setCellStyles(cell);
        });
      };
      const subtitleRow = worksheet.getRow(2);
      setCellStyles(subtitleRow.getCell('H'));
      subtitleRow.getCell('H').value = 'ΠΕΡΙΒΑΛΛΟΝΤΙΚΗ ΣΤΟΧΕΥΣΗ';

      for (let col = 8; col <= 21; col++) {
        const cell = worksheet.getCell(3, col);
        setCellStyles(cell);
      }
      for (let col = 8; col <= 21; col++) {
        const cell = worksheet.getCell(5, col);
        setCellStyles(cell);
      }
      const titlesData = [
        { col: 8, value: 'ΧΡΗΣΗ ΕΝΑΛΛΑΚΤΙΚΩΝ ΜΕΘΟΔΩΝ ΚΑΤΑΠΟΛΕΜΗΣΗΣ ΕΧΘΡΩΝ' },
        { col: 12, value: 'ΟΡΘΕΣ ΓΕΩΡΓΙΚΕΣ ΠΡΑΚΤΙΚΕΣ ΓΙΑ ΤΗ ΜΕΙΩΣΗ ΕΙΣΡΟΩΝ' },
        { col: 15, value: 'ΧΡΗΣΗ ΕΞΟΠΛΙΣΜΟΥ & ΑΥΤΟΜΑΤΙΣΜΩΝ ΓΙΑ ΤΗ ΜΕΙΩΣΗ ΤΗΣ ΡΥΠΑΝΣΗΣ ΚΑΙ ΤΗΝ ΕΦΑΡΜΟΓΗ ΦΥΤΟΠΡΟΣΤΑΤΕΥΤΙΚΩΝ ΠΡΟΪΟΝΤΩΝ' },
        { col: 18, value: 'ΒΕΛΤΙΩΣΗ ΤΗΣ ΔΙΑΧΕΙΡΙΣΗΣ ΤΩΝ ΘΡΕΠΤΙΚΩΝ ΣΥΣΤΑΤΙΚΩΝ' }
      ];
      titlesData.forEach((titleData) => {
        const titleRow = worksheet.getRow(3);
        titleRow.getCell(titleData.col).value = titleData.value;
        setRowValuesAndStyles(titleRow, [titleData.value], [titleData.col]);
      });
      const cellValuesRow4 = [
        '31.6-Β', '31.6-Γ', '31.6-Δ', '31.6-ΙΓ', '', '31.6-Ε', '31.6-ΣΤ', '31.6-Ζ', '31.6-Η',
        '31.6-Θ', '31.6-Ι', '31.6-ΙΑ', '31.6-ΙΒ', 'ΠΑΡΑΤΗΡΗΣΕΙΣ'
      ];
      const titlesDataValues = [
        { col: 8, value: cellValuesRow4[0] },
        { col: 9, value: cellValuesRow4[1] },
        { col: 10, value: cellValuesRow4[2] },
        { col: 11, value: cellValuesRow4[3] },
        { col: 12, value: cellValuesRow4[4] },
        { col: 13, value: cellValuesRow4[5] },
        { col: 14, value: cellValuesRow4[6] },
        { col: 15, value: cellValuesRow4[7] },
        { col: 16, value: cellValuesRow4[8] },
        { col: 17, value: cellValuesRow4[9] },
        { col: 18, value: cellValuesRow4[10] },
        { col: 19, value: cellValuesRow4[11] },
        { col: 20, value: cellValuesRow4[12] },
        { col: 21, value: cellValuesRow4[13] },
      ];
      titlesDataValues.forEach((titleData) => {
        const titleRow = worksheet.getRow(5);
        titleRow.getCell(titleData.col).value = titleData.value;
        setRowValuesAndStyles(titleRow, [titleData.value], [titleData.col]);
      });
      const fillCell = (worksheet: any, rowIndex: number, colIndex: number, value: any) => {
        const cell = worksheet.getCell(rowIndex, colIndex);
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      };
      this.Tables.table_1.forEach((row: any, rowIndex: number) => {
        const getValueByKey = (key: any) => {
          for (let i = 0; i < row.spd_actions.length; i++) {
            const action = row.spd_actions[i];
            if (action.hasOwnProperty(key)) {
              return action[key];
            }
          }
          return undefined;
        }
        const rowNum = 6 + rowIndex;
        const value1 = getValueByKey("31.6-Α");
        const spdActionValue1 = value1 ? 'X' : '-';
        const value2 = getValueByKey("31.6-Β");
        const spdActionValue2 = value2 ? 'X' : '-';
        const value3 = getValueByKey("31.6-Γ");
        const spdActionValue3 = value3 ? 'X' : '-';
        const value4 = getValueByKey("31.6-Δ");
        const spdActionValue4 = value4 ? 'X' : '-';
        const value5 = getValueByKey("31.6-ΙΓ");
        const spdActionValue5 = value5 ? 'X' : '-';
        const value6 = getValueByKey("31.6-Ε");
        const spdActionValue6 = value6 ? 'X' : '-';
        const value7 = getValueByKey("31.6-ΣΤ");
        const spdActionValue7 = value7 ? 'X' : '-';
        const value8 = getValueByKey("31.6-Ζ");
        const spdActionValue8 = value8 ? 'X' : '-';
        const value9 = getValueByKey("31.6-Η");
        const spdActionValue9 = value9 ? 'X' : '-';
        const value10 = getValueByKey("31.6-Θ");
        const spdActionValue10 = value10 ? 'X' : '-';
        const value11 = getValueByKey("31.6-Ι");
        const spdActionValue11 = value11 ? 'X' : '-';
        const value12 = getValueByKey("31.6-ΙΑ");
        const spdActionValue12 = value12 ? 'X' : '-';
        const value13 = getValueByKey("31.6-ΙΒ");
        const spdActionValue13 = value13 ? 'X' : '-';
        fillCell(worksheet, rowNum, 1, rowIndex + 1);
        fillCell(worksheet, rowNum, 2, row.plot_code);
        fillCell(worksheet, rowNum, 3, row.location);
        fillCell(worksheet, rowNum, 4, row.mapped_background);
        fillCell(worksheet, rowNum, 5, row.applied_area);
        fillCell(worksheet, rowNum, 6, row.crops);
        fillCell(worksheet, rowNum, 7, spdActionValue1);
        fillCell(worksheet, rowNum, 8, spdActionValue2);
        fillCell(worksheet, rowNum, 9, spdActionValue3);
        fillCell(worksheet, rowNum, 10, spdActionValue4);
        fillCell(worksheet, rowNum, 11, spdActionValue5);
        fillCell(worksheet, rowNum, 20, null);
        fillCell(worksheet, rowNum, 13, spdActionValue6);
        fillCell(worksheet, rowNum, 14, spdActionValue7);
        fillCell(worksheet, rowNum, 15, spdActionValue8);
        fillCell(worksheet, rowNum, 16, spdActionValue9);
        fillCell(worksheet, rowNum, 17, spdActionValue10);
        fillCell(worksheet, rowNum, 18, spdActionValue11);
        fillCell(worksheet, rowNum, 19, spdActionValue12);
        fillCell(worksheet, rowNum, 20, spdActionValue13);
        fillCell(worksheet, rowNum, 21, null);
      });
      const table1StaticRows = 5

      // // // 2nd Table    
      const rowNumberTitle = this.Tables.table_1.length + table1StaticRows + 3
      const rowNumberSubTitle = this.Tables.table_1.length + table1StaticRows + 4
      const rowHeaderValues = this.Tables.table_1.length + table1StaticRows + 5
      worksheet.mergeCells(`B${rowNumberTitle}:K${rowNumberTitle}`);
      worksheet.mergeCells(`B${rowNumberSubTitle}:K${rowNumberSubTitle}`);
      const titleRowTable2 = worksheet.getRow(rowNumberTitle);
      const titleSubRowTable2 = worksheet.getRow(rowNumberSubTitle);
      titleRowTable2.getCell(2).value = 'ΠΙΝΑΚΑΣ 2 – ΟΔΗΓΙΕΣ ΑΝΑ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗ ΣΤΟΧΕΥΣΗ';
      titleSubRowTable2.getCell(3).value = 'ΧΡΗΣΗ ΕΝΑΛΛΑΚΤΙΚΩΝ ΜΕΘΟΔΩΝ ΚΑΤΑΠΟΛΕΜΗΣΗΣ ΕΧΘΡΩΝ (31.6-Β, 31.6-Γ, 31.6-Δ, 31.6-ΙΓ)';
      titleRowTable2.eachCell((cell) => styleTitlesCell(cell));
      titleSubRowTable2.eachCell((cell) => styleTitlesCell(cell));
      const headerValues2 = [
        'A/A',
        'ΔΡΑΣΗ',
        'ΣΥΝΟΛΙΚΗ ΕΚΤΑΣΗ ΑΓΡΟΤΕΜΑΧΙΩΝ (ha)',
        'ΕΧΘΡΟΣ/-ΟΙ',
        'ΕΜΠΟΡΙΚΗ ΟΝΟΜΑΣΙΑ',
        '"ΑΡΙΘΜΟΣ ΑΑΔΑ/ ΑΠΟΦΑΣΗ ΕΓΓΡΑΦΗΣ ΕΚΣΦΜ ΣΚΕΥΑΣΜΑΤΟΣ"',
        'ΣΥΝΙΣΤΩΜΕΝΗ ΠΟΣΟΤΗΤΑ ΣΚΕΥΑΣΜΑΤΟΣ/ ΣΥΝΟΛΙΚΟΣ ΑΡΙΘΜΟΣ',
        'ΗΜΕΡΟΜΗΝΙΕΣ Ή ΕΥΡΟΣ ΗΜΕΡΟΜΗΝΙΩΝ ΕΦΑΡΜΟΓΗΣ/ΕΞΑΠΟΛΥΣΗΣ',
        'ΣΥΝΟΛΙΚΟΣ ΑΡΙΘΜΟΣ/ ΠΟΣΟΤΗΤΑ ΣΚΕΥΑΣΜΑΤΟΣ ΠΟΥ ΕΦΑΡΜΟΣΤΗΚΕ',
        'ΠΑΡΑΤΗΡΗΣΕΙΣ'
      ];
      const headerRow2 = worksheet.getRow(rowHeaderValues);
      headerValues2.forEach((value, index) => {
        const cell = headerRow2.getCell(index + 2);
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
      if (this.Tables.table_2.includes('not')) {
        this.Tables.table_2 = []
      }
      const rowHeaderValues2 = rowHeaderValues + 1
      this.Tables.table_2.forEach((row: any, rowIndex: number) => {
        const rowNum = rowHeaderValues2 + rowIndex
        const startDateFormatted = this.datePipe.transform(row.start_date, 'dd-MM-yyyy');
        const endDateFormatted = this.datePipe.transform(row.end_date, 'dd-MM-yyyy');
        const concatenatedDates = `${startDateFormatted} / ${endDateFormatted}`;
        fillCell(worksheet, rowNum, 2, rowIndex + 1);
        fillCell(worksheet, rowNum, 3, row.action);
        fillCell(worksheet, rowNum, 4, row.total_ha);
        fillCell(worksheet, rowNum, 5, row.justifications);
        fillCell(worksheet, rowNum, 6, row.commercial_name);
        fillCell(worksheet, rowNum, 7, null);
        fillCell(worksheet, rowNum, 8, row.dosage);
        fillCell(worksheet, rowNum, 9, concatenatedDates);
        fillCell(worksheet, rowNum, 10, row.total_applied);
        fillCell(worksheet, rowNum, 11, null);
      })

      //// Table 3    
      const rowNumTable2 = rowHeaderValues2 + this.Tables.table_2.length
      const rowNumberTitleTable3 = rowNumTable2 + 2
      const rowNumberSubTitleTable3 = rowNumTable2 + 3
      const rowHeaderValuesTable3 = rowNumTable2 + 4
      const rowIJ = rowNumTable2 + 4
      worksheet.mergeCells(`B${rowNumberTitleTable3}:J${rowNumberTitleTable3}`);
      worksheet.mergeCells(`B${rowNumberSubTitleTable3}:J${rowNumberSubTitleTable3}`);
      worksheet.mergeCells(`I${rowIJ}:J${rowIJ}`);
      const titleRowTable3 = worksheet.getRow(rowNumberTitleTable3);
      const titleSubRowTable3 = worksheet.getRow(rowNumberSubTitleTable3);
      titleRowTable3.getCell(2).value = 'ΠΙΝΑΚΑΣ 3  – ΟΔΗΓΙΕΣ ΑΝΑ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗ ΣΤΟΧΕΥΣΗ';
      titleSubRowTable3.getCell(3).value = 'ΟΡΘΕΣ ΓΕΩΡΓΙΚΕΣ ΠΡΑΚΤΙΚΕΣ ΓΙΑ ΤΗ ΜΕΙΩΣΗ ΕΙΣΡΟΩΝ (31.6-Ε,-ΣΤ)';
      titleRowTable3.eachCell((cell) => styleTitlesCell(cell));
      titleSubRowTable3.eachCell((cell) => styleTitlesCell(cell));
      const headerValues3 = [
        "A/A",
        "ΔΡΑΣΗ",
        "ΣΥΝΟΛΙΚΗ ΕΚΤΑΣΗ ΑΓΡΟΤΕΜΑΧΙΩΝ (ha)",
        "ΤΥΠΟΣ ΕΞΟΠΛΙΣΜΟΥ (31.6-Ε)",
        "ΤΥΠΟΣ ΦΥΤΙΚΟΥ ΥΛΙΚΟΥ ΕΔΑΦΟΚΑΛΥΨΗΣ (31.6-Ε)",
        "ΑΡΙΘΜΟΣ ΚΑΛΟΚΑΙΡΙΝΩΝ ΚΛΑΔΕΜΑΤΩΝ (31.6-ΣΤ)",
        "ΔΙΑΣΤΗΜΑ/-ΤΑ ΕΦΑΡΜΟΓΗΣ",
        '',
        "ΠΑΡΑΤΗΡΗΣΕΙΣ"
      ];
      const headerRow3 = worksheet.getRow(rowHeaderValuesTable3);
      headerValues3.forEach((value, index) => {
        const cell = headerRow3.getCell(index + 2);
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
      if (this.Tables.table_3.includes('not')) {
        this.Tables.table_3 = []
      }
      this.Tables.table_3.forEach((row: any, rowIndex: number) => {
        const rowNum = rowHeaderValuesTable3 + rowIndex + 1
        const startDateFormatted = this.datePipe.transform(row.start_date, 'dd-MM-yyyy');
        const endDateFormatted = this.datePipe.transform(row.end_date, 'dd-MM-yyyy');
        const concatenatedDates = `${startDateFormatted} / ${endDateFormatted}`;
        fillCell(worksheet, rowNum, 2, rowIndex + 1);
        fillCell(worksheet, rowNum, 3, row.action);
        fillCell(worksheet, rowNum, 4, row.total_ha);
        fillCell(worksheet, rowNum, 5, null);
        fillCell(worksheet, rowNum, 6, null);
        fillCell(worksheet, rowNum, 7, row.treatment);
        fillCell(worksheet, rowNum, 8, concatenatedDates);
        fillCell(worksheet, rowNum, 9, null);
        fillCell(worksheet, rowNum, 10, null);
      })

      //// Table 4 
      const rowNumberTitleTable4 = this.Tables.table_3.length + rowHeaderValuesTable3 + 3
      const rowNumberSubTitleTable4 = this.Tables.table_3.length + rowHeaderValuesTable3 + 4
      const rowHeaderValuesTable4 = this.Tables.table_3.length + rowHeaderValuesTable3 + 5
      worksheet.mergeCells(`B${rowNumberTitleTable4}:M${rowNumberTitleTable4}`);
      worksheet.mergeCells(`B${rowNumberSubTitleTable4}:M${rowNumberSubTitleTable4}`);
      const titleRowTable4 = worksheet.getRow(rowNumberTitleTable4);
      const titleSubRowTable4 = worksheet.getRow(rowNumberSubTitleTable4);
      titleRowTable4.getCell(2).value = 'ΠΙΝΑΚΑΣ 4 – ΟΔΗΓΙΕΣ ΑΝΑ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗ ΣΤΟΧΕΥΣΗ';
      titleSubRowTable4.getCell(3).value = 'ΧΡΗΣΗ ΕΞΟΠΛΙΣΜΟΥ & ΑΥΤΟΜΑΤΙΣΜΩΝ ΓΙΑ ΤΗ ΜΕΙΩΣΗ ΤΗΣ ΡΥΠΑΝΣΗΣ ΚΑΙ ΤΗΝ ΕΦΑΡΜΟΓΗ ΦΥΤΟΠΡΟΣΤΑΤΥΕΤΙΚΩΝ ΠΡΟΪΟΝΤΩΝ (31.6-Ζ,-Η, -Θ)';
      titleRowTable4.eachCell((cell) => styleTitlesCell(cell));
      titleSubRowTable4.eachCell((cell) => styleTitlesCell(cell));
      const headerValues4 = [
        "A/A",
        "ΔΡΑΣΗ",
        "ΣΥΝΟΛΙΚΗ ΕΚΤΑΣΗ ΑΓΡΟΤΕΜΑΧΙΩΝ (ha)",
        "ΤΥΠΟΣ ΑΚΡΟΦΥΣΙΩΝ (31.6-Ζ)",
        "ΑΡΙΘΜΟΣ ΑΚΡΟΦΥΣΙΩΝ (31.6-Ζ)",
        "ΕΤΟΣ ΑΓΟΡΑΣ ΕΞΟΠΛΙΣΜΟΥ (31.6-Ζ, -Θ)",
        "ΤΥΠΟΣ ΕΞΟΠΛΙΣΜΟΥ ΦΥΤΟΠΡΟΣΤΑΣΙΑΣ (31.6-Ζ)",
        "ΤΥΠΟΣ ΕΞΟΠΛΙΣΜΟΥ ΓΕΩΡΓΙΑΣ ΑΚΡΙΒΕΙΑΣ (31.6-Θ)",
        "ΟΝΟΜΑΣΙΑ ΠΑΡΟΧΟΥ ΥΠΗΡΕΣΙΑΣ ΑΕΙΦΟΡΙΚΗΣ ΔΙΑΧΕΙΡΙΣΗΣ ΥΠΟΛΕΙΜΜΑΤΩΝ ΨΕΚΑΣΤΙΚΩΝ ΥΓΡΩΝ Φ.Π. (31.6-Η)",
        "ΤΟΠΟΘΕΣΙΑ ΣΥΛΛΟΓΗΣ ΤΟΥ ΨΕΚΑΣΤΙΚΟΥ ΥΓΡΟΥ (31.6-Η)",
        "ΗΜΕΡΟΜΗΝΙΑ ΑΛΛΑΓΗΣ ΜΕΜΒΑΝΗΣ ΣΥΛΛΟΓΗΣ (31.6-Η)",
        "ΠΑΡΑΤΗΡΗΣΕΙΣ"
      ];
      const headerRow4 = worksheet.getRow(rowHeaderValuesTable4);
      headerValues4.forEach((value, index) => {
        const cell = headerRow4.getCell(index + 2);
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      //// Table 4
      this.Tables.table_4 = []

      //// Table 5
      const rowNumberTitleTable5 = rowHeaderValuesTable4 + 3
      const rowNumberSubTitleTable5 = rowHeaderValuesTable4 + 4
      const rowHeaderValuesTable5 = rowHeaderValuesTable4 + 5
      worksheet.mergeCells(`B${rowNumberTitleTable5}:J${rowNumberTitleTable5}`);
      worksheet.mergeCells(`B${rowNumberSubTitleTable5}:J${rowNumberSubTitleTable5}`);
      const titleRowTable5 = worksheet.getRow(rowNumberTitleTable5);
      const titleSubRowTable5 = worksheet.getRow(rowNumberSubTitleTable5);
      titleRowTable5.getCell(2).value = 'ΠΙΝΑΚΑΣ 5  – ΟΔΗΓΙΕΣ ΑΝΑ ΠΕΡΙΒΑΛΛΟΝΤΙΚΗ ΣΤΟΧΕΥΣΗ';
      titleSubRowTable5.getCell(3).value = 'ΒΕΛΤΙΩΣΗ ΤΗΣ ΔΙΑΧΕΙΡΙΣΗΣ ΤΩΝ ΘΡΕΠΤΙΚΩΝ ΣΥΣΤΑΤΙΚΩΝ (31.6-Ι, -ΙΑ, -ΙΒ)';
      titleRowTable5.eachCell((cell) => styleTitlesCell(cell));
      titleSubRowTable5.eachCell((cell) => styleTitlesCell(cell));
      const headerValues5 = [
        "A/A",
        "ΔΡΑΣΗ",
        "ΣΥΝΟΛΙΚΗ ΕΚΤΑΣΗ ΑΓΡΟΤΕΜΑΧΙΩΝ (ha)",
        "ΤΥΠΟΣ ΛΙΠΑΣΜΑΤΟΣ",
        "ΕΜΠΟΡΙΚΗ ΟΝΟΜΑΣΙΑ",
        "ΚΑΤΗΓΟΡΙΑ ΠΡΟΪΟΝΤΟΣ ΛΙΠΑΝΣΗΣ (π.χ Κατηγορία 1, 1α, 1β, 2, 3, σύμφωνα με την αριθ.2207/16-6-2023 ΥΑ/ΦΕΚ Β 3941, όπως τροποποιήθηκε και ισχύει)",
        "ΣΥΝΟΛΙΚΗ ΠΟΣΟΤΗΤΑ ΠΟΥ ΕΦΑΡΜΟΣΤΗΚΕ",
        "ΔΙΑΣΤΗΜΑ/-ΤΑ ΕΦΑΡΜΟΓΗΣ",
        "ΠΑΡΑΤΗΡΗΣΕΙΣ"
      ];
      const headerRow5 = worksheet.getRow(rowHeaderValuesTable5);
      headerValues5.forEach((value, index) => {
        const cell = headerRow5.getCell(index + 2);
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
      if (this.Tables.table_5.includes('not')) {
        this.Tables.table_5 = []
      }
      this.Tables.table_5.forEach((row: any, rowIndex: number) => {
        const rowNum = rowIndex + rowHeaderValuesTable5 + 1;
        const startDateFormatted = this.datePipe.transform(row.start_date, 'dd-MM-yyyy');
        const endDateFormatted = this.datePipe.transform(row.end_date, 'dd-MM-yyyy');
        const concatenatedDates = `${startDateFormatted} / ${endDateFormatted}`;
        fillCell(worksheet, rowNum, 2, rowIndex + 1);
        fillCell(worksheet, rowNum, 3, row.action);
        fillCell(worksheet, rowNum, 4, row.total_ha);
        fillCell(worksheet, rowNum, 5, row.fert_type);
        fillCell(worksheet, rowNum, 6, row.commercial_name);
        fillCell(worksheet, rowNum, 7, row.fert_category);
        fillCell(worksheet, rowNum, 8, row.total_applied);
        fillCell(worksheet, rowNum, 9, concatenatedDates);
        fillCell(worksheet, rowNum, 10, null);
      });
      const rowNumberTitleTable6 = rowHeaderValuesTable5 + 15
      const rowNumberSubTitleTable6 = rowHeaderValuesTable5 + 16
      const rowHeaderValuesTable6 = rowHeaderValuesTable5 + 17
      worksheet.mergeCells(`B${rowNumberTitleTable6}:G${rowNumberTitleTable6}`);
      worksheet.mergeCells(`B${rowNumberSubTitleTable6}:G${rowNumberSubTitleTable6}`);
      const titleRowTable6 = worksheet.getRow(rowNumberTitleTable6);
      const titleSubRowTable6 = worksheet.getRow(rowNumberSubTitleTable6);

      titleRowTable6.getCell(2).value = 'ΠΙΝΑΚΑΣ 6';
      titleSubRowTable6.getCell(3).value = 'ΨΗΦΙΑΚΗ ΕΦΑΡΜΟΓΗ ΔΙΑΧΕΙΡΙΣΗΣ ΕΙΣΡΟΩΝ & ΠΑΡΑΚΟΛΟΥΘΗΣΗΣ ΠΕΡΙΒΑΛΛΟΝΤΙΚΩΝ ΠΑΡΑΜΕΤΡΩΝ (31.6-Α)';
      titleRowTable6.eachCell((cell) => styleTitlesCell(cell));
      titleSubRowTable6.eachCell((cell) => styleTitlesCell(cell));

      const headerValues6 = [
        "A/A",
        "ΟΝΟΜΑΣΙΑ ΠΙΣΤΟΠΟΙΗΜΕΝΗΣ ΨΗΦΙΑΚΗΣ ΕΦΑΡΜΟΓΗΣ",
        "ΤΟΜΕΙΣ ΠΟΥ ΚΑΛΥΠΤΕΙ Η ΨΗΦΙΑΚΗ ΕΦΑΡΜΟΓΗ",
        "ΠΕΡΙΓΡΑΦΗ ΣΥΜΒΟΥΛΗΣ ΠΟΥ ΑΝΤΛΗΘΗΚΕ ΑΠΟ ΤΗΝ ΨΗΦΙΑΚΗ ΕΦΑΡΜΟΓΗ",
        "ΠΕΡΙΓΡΑΦΗ ΤΟΥ ΤΡΟΠΟΥ ΥΙΟΘΕΤΗΣΗΣ ΤΗΣ ΣΥΜΒΟΥΛΗΣ ΤΗΣ ΨΗΦΙΑΚΗΣ ΕΦΑΡΜΟΓΗΣ ΑΠΟ ΤΟΝ ΓΕΩΡΓΟ"
      ];

      headerValues6.forEach((value, index) => {
        const rowIndex = rowHeaderValuesTable6 + index;
        worksheet.mergeCells(`B${rowIndex}:C${rowIndex}`);
        const cell = worksheet.getCell(`B${rowIndex}`);
        cell.value = value;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        worksheet.getRow(rowIndex).height = 100;
      });

      this.Table6.forEach((row) => {
        const baseRowIndex = rowHeaderValuesTable6;

        // Merge cells for each data row and set the value
        const dataCells = [
          { rowOffset: 1, value: row.appName },
          { rowOffset: 2, value: row.section },
          { rowOffset: 3, value: row.descriptionApp },
          { rowOffset: 4, value: row.descriptionFarmer }
        ];

        dataCells.forEach((dataCell) => {
          const rowNum = baseRowIndex + dataCell.rowOffset;
          worksheet.mergeCells(`D${rowNum}:H${rowNum}`);
          const cell = worksheet.getCell(`D${rowNum}`);
          cell.value = dataCell.value;
          cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });

        const idCell = worksheet.getCell(`D${baseRowIndex}`);
        idCell.value = row.id;
        idCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        idCell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      // instructions Text

      const instructions = rowHeaderValuesTable6 + 10
      const instructionsFill = worksheet.getRow(instructions);
      instructionsFill.getCell(2).value = 'Οδηγίες συμπλήρωσης ';
      const cellBold = instructionsFill.getCell(2);
      cellBold.font = { bold: true };
      const instructionsFillTable1 = worksheet.getRow(instructions + 1);
      worksheet.mergeCells(`'B'${instructions + 1}:'H'${instructions + 1}`);

      instructionsFillTable1.getCell(2).value = 'Πίνακας 1:' + ' ' + "\n" +
        'Σε κάθε γραμμή καταχωρίζεται πληροφορία ανά αγροτεμάχιο. Στα πεδία που αφορούν την περιβαλλοντική στόχευση της δράσης, αυτή επιλέγεται θέτοντας √.';
      instructionsFillTable1.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      instructionsFillTable1.height = 50;

      worksheet.mergeCells(`'B'${instructions + 2}:'I'${instructions + 2}`);
      const instructionsFillTable2 = worksheet.getRow(instructions + 2);
      instructionsFillTable2.getCell(3).value = 'Πίνακας 2:' + ' ' + "\n" +
        '* Σε κάθε γραμμή καταχωρίζεται πληροφορία ανά δράση και ανά σκεύασμα για το σύνολο των αγροτεμαχίων και όχι ανά αγροτεμάχιο. Σε περίπτωση εφαρμογής περισσότερων του ενός σκευασμάτων για την ίδια δράση, η καταχώρηση γίνεται σε δύο γραμμές. Για σκευάσματα που αντιμετωπίζουν περισσότερους από έναν εχθρούς, η καταχώριση γίνεται στην ίδια γραμμή.' + ' ' + "\n" +
        '* Για φυτοπροστατευτικά προϊόντα αναγράφεται ο Αριθμός Άδειας Διάθεσης στην Αγορά (ΑΑΔΑ), για ωφέλιμα/νηματώδεις αναγράφεται η Απόφαση εγγραφής του σκευάσματος στον Εθνικό Κατάλογο Σκευασμάτων Φυτοπροστασίας που περιέχουν Μακροοργανισμούς (ΕΚΣΦΜ). ';
      instructionsFillTable2.getCell(3).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      instructionsFillTable2.height = 80;

      worksheet.mergeCells(`'B'${instructions + 3}:'I'${instructions + 3}`);
      const instructionsFillTable3 = worksheet.getRow(instructions + 3);
      instructionsFillTable3.getCell(4).value = 'Πίνακες 3 έως 5:' + ' ' + "\n" +
        'Σε κάθε γραμμή καταχωρίζεται πληροφορία ανά δράση για το σύνολο των αγροτεμαχίων και όχι ανά αγροτεμάχιο. Σε περίπτωση που σε ένα αγροτεμάχιο εφαρμόζονται δύο δράσεις της παρέμβασης 31.6, κάθε γραμμή του πίνακα αφορά αποκλειστικά μια δράση'
      instructionsFillTable3.getCell(4).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      instructionsFillTable3.height = 50;

      const instructionsFillTable4 = worksheet.getRow(instructions + 4);
      instructionsFillTable4.getCell(2).value = 'ΑΑΔΑ: Άδεια Διάθεσης του σκευάσματος στην αγορά'
      const instructionsFillTable5 = worksheet.getRow(instructions + 5);
      instructionsFillTable5.getCell(2).value = 'ΕΚΣΦΜ: Εθνικός Κατάλογος Σκευασμάτων Φυτοπροστασίας που περιέχουν Μακρο-οργανισμούς'

      const additionalInstructionsRow = 2;
      const additionalInstructions = instructionsheet.getRow(additionalInstructionsRow);
      instructionsheet.mergeCells(`B${additionalInstructionsRow}:I${additionalInstructionsRow + 15}`);
      additionalInstructions.getCell(2).value = {
        richText: [
          {
            text: 'ΠΙΝΑΚΑΣ 3-ΟΡΘΕΣ ΓΕΩΡΓΙΚΕΣ ΠΡΑΚΤΙΚΕΣ ΓΙΑ ΤΗ ΜΕΙΩΣΗ ΕΙΣΡΟΩΝ (31.6-Ε,-ΣΤ) \n\n',
            font: { bold: true, underline: false }
          },
          {
            text: 'Επιπρόσθετες οδηγίες προς τον παραγωγό αναφορικά με τη δράση 31.6-ΣΤ (ECO-06.11)\n\n',
            font: { bold: true, underline: true }
          },
          {
            text: '1. Η ενισχυόμενη καλλιεργητική πρακτική των καλοκαιρινών κλαδεμάτων συνίσταται στην αφαίρεση μικρού μόνο μέρους της βλάστησης των δέντρων που δεν είναι τόσο σημαντική κατά τη θερινή περίοδο, μέσω της αφαίρεσης της ζωηρής βλάστησης/λαίμαργων βλαστών κατά τη διάρκεια των καλοκαιρινών μηνών, ώστε να βελτιώνονται οι συνθήκες φωτισμού και αερισμού εντός της κόμης των δέντρων.\n\n' +
              'Με τον τρόπο αυτό, μειώνονται οι μυκητολογικές και άλλες προσβολές και, κατά συνέπεια, μειώνεται η ανάγκη εφαρμογής χημικής φυτοπροστασίας στους ελαιώνες και οπωρώνες της δράσης.\n\n' +
              'Επιπλέον, τα καλοκαιρινά κλαδέματα αναστέλλουν την τάση των δέντρων για δημιουργία ζωηρής βλάστησης σε βάρος της καρποφορίας και, με ιδιαίτερη σημασία για τις ανάγκες της παρούσας δράσης, αποτελούν μία από τις βασικότερες τεχνικές μείωσης των αναγκών του φυτού σε νερό, μείωσης δηλαδή της διαπνοής του φυτού μέσω της μείωσης της φυλλικής επιφάνειας των δέντρων ώστε τα τελευταία να μπορέσουν να ανταποκριθούν καλύτερα στις συνθήκες θερμικής και υδατικής καταπόνησης (στρες) με τις οποίες είναι αντιμέτωποι όλο και συχνότερα οι ελαιώνες και οπωρώνες της χώρας κατά τη διάρκεια των καλοκαιρινών μηνών λόγω της κλιματικής αλλαγής.\n\n' +
              '2. Στόχος της δράσης είναι\n\n' +
              'Ø να βελτιωθεί η προσαρμοστικότητα των δένδρων σε συνθήκες μειωμένων υδατικών αποθεμάτων και θερμικών καταπονήσεων λόγω κλιματικής αλλαγής,\n\n' +
              'Ø να βελτιωθεί η ποιότητα των υδατικών πόρων και\n\n' +
              'Ø να ανακάμψει η βιοποικιλότητα μέσω της μείωσης χρήσης χημικών φυτοπροστατευτικών προϊόντων στους ελαιώνες και οπωρώνες της δράσης.\n\n' +
              '3. Ο παραγωγός υποχρεούται\n\n' +
              'α) Να πραγματοποιεί καλοκαιρινά κλαδέματα στις καλλιέργειες του.\n\n' +
              'β) Να πραγματοποιήσει τουλάχιστον δύο (2) κλαδέματα κατά τη διάρκεια της θερινής περιόδου (Ιούνιος - Αύγουστος).\n\n'
          },
          {
            text: 'Καλοκαιρινό κλάδεμα\n\n',
            font: { bold: true }
          },
          {
            text: 'Η καλοκαιρινή απομάκρυνση λαίμαργων, βασικών αλλά και από την κώμη του δέντρου, που απαιτείται ιδιαίτερα όταν πραγματοποιείται έντονο χειμερινό κλάδεμα , πραγματοποιείται τον Ιούλιο-Αύγουστο, όταν τα λαίμαργα μπορούν εύκολα να αφαιρεθούν χωρίς κόψιμο αλλά με το χέρι (καλύτερα με τη χρήση γαντιών εργασίας).'
          }
        ]
      };

      additionalInstructions.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      additionalInstructions.height = 500;

      const additionalInstructionsRow2 = additionalInstructionsRow + 18;
      const additionalInstructions2 = instructionsheet.getRow(additionalInstructionsRow2);
      instructionsheet.mergeCells(`B${additionalInstructionsRow2}:I${additionalInstructionsRow2 + 22}`);

      additionalInstructions2.getCell(2).value = {
        richText: [
          {
            text: 'ΠΙΝΑΚΑΣ 5-ΒΕΛΤΙΩΣΗ ΤΗΣ ΔΙΑΧΕΙΡΙΣΗΣ ΤΩΝ ΘΡΕΠΤΙΚΩΝ ΣΥΣΤΑΤΙΚΩΝ (31.6-Ι, -ΙΑ, -ΙΒ) \n\n',
            font: { bold: true, underline: false }
          },
          {
            text: 'Επιπρόσθετες οδηγίες προς τον παραγωγό αναφορικά με τη δράση 31.6-Ι (ECO-06.17)\n\n',
            font: { bold: true, underline: true }
          },
          {
            text: '«Χρήση λιπασμάτων βραδείας αποδέσμευσης»\n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Τα λιπάσματα βραδείας ή ελεγχόμενης αποδέσμευσης αποτελούν ειδικού τύπου λιπάσματα τα οποία εναρμονίζουν την απελευθέρωση των ανόργανων θρεπτικών από το λίπασμα, σύμφωνα με τις ανάγκες του φυτού, βελτιώνοντας έτσι την αποδοτικότητά τους και μειώνοντας το περιβαλλοντικό και κλιματικό αποτύπωμα της γεωργικής εκμετάλλευσης. και κυκλοφορούν είτε με επικάλυψη με κάποιο υλικό είτε χωρίς επικάλυψη. \n\n' +
              'Περιέχουν θρεπτικά στοιχεία, κυρίως μακροστοιχεία, (άζωτο, φώσφορο) σε μορφή, κατά την οποία καθυστερείται η αρχική διαθεσιμότητα ή παρατείνεται η διαθεσιμότητα του θρεπτικού στοιχείου στο φυτό καθόλη τη διάρκεια της καλλιέργειας. Αυτό μπορεί να προκύψει με διάφορους μηχανισμούς όπως ημιπερατές επικαλύψεις, απόφραξη, πρωτεϊνικά υλικά ή άλλες χημικές μορφές με αργή υδρόλυση υδατοδιαλυτών ενώσεων χαμηλού μοριακού βάρους ή και με πολυμερή. Με αυτό τον τρόπο, τα λιπάσματα αυτού του τύπου εξασφαλίζουν συνεχή παροχή και καλύτερη αξιοποίηση του θρεπτικού στοιχείου από το φυτό για μεγάλα χρονικά διαστήματα που το εύρος τους εξαρτάται μεταξύ άλλων από παράγοντες όπως η θερμοκρασία, το πάχος της επικάλυψης. \n\n'
          },
          {
            text: 'α) Κατηγορία 1: \n\n',
            font: { bold: true, underline: true }
          },
          {
            text:
              'Προϊόντα λίπανσης της ΕΕ, σύμφωνα με τον Καν. (ΕΕ) 2019/1009 (προϊόντα λίπανσης που φέρουν σήμανση CE), τα οποία ανήκουν στην Κατηγορία Λειτουργίας Προϊόντων ΚΛΠ 1 (Λίπασμα) ή ΚΛΠ 7 (Σύμμεικτο προϊόν λίπανσης) και αποτελούνται από Συστατικά Υλικά ΚΣΥ 1 (Ουσίες και μείγματα παρθένων υλικών) μαζί με ΚΣΥ 8 (Πολυμερή θρεπτικών στοιχείων) ή/και ΚΣΥ 9 (Πολυμερή άλλα από τα πολυμερή θρεπτικών στοιχείων).\n\n'
          },
          {
            text: 'β) Κατηγορία 2: \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Λιπάσματα νέου τύπου τα οποία κυκλοφορούν στην αγορά με δράση βραδείας αποδέσμευσης, βάσει της υπ’ αρ. 291180/11034/19-9-2002 (Β’ 1274) κοινής υπουργικής απόφασης, όπως τροποποιήθηκε με την υπ’ αρ. 257921/15-6-2004 (Β’ 955), όμοια και ισχύει. Τα λιπάσματα νέου τύπου με δράση βραδείας αποδέσμευσης πρέπει να περιλαμβάνονται υποχρεωτικά στο «Μητρώο Αδειών Κυκλοφορίας Νέου Τύπου Λιπασμάτων», διαθέσιμο στην ιστοσελίδα του ΥπΑΑΤ: http://www.minagric.gr/index.php/el/for-farmer-2/crop-production/lipasmata/278-mitroa ή να έχει υποβληθεί φάκελος προς έγκριση στην αρμόδια υπηρεσία. \n\n' +
              'Οι δεσμεύσεις των γεωργών ακολουθούν το άρθρο 71 της με αριθμ. 2207/17.06.2023 (B’ 3941/17.06.2023)υ.α. ως κάτωθι:\n\n'
          },
          {
            text: '1. Στο πλαίσιο της δράσης, οι γεωργοί δεσμεύονται: \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'α) Nα κάνουν αποκλειστική χρήση των λιπασμάτων βραδείας ή ελεγχόμενης αποδέσμευσης ',
            font: { bold: true, underline: false },
          },
          {
            text: 'του άρθρου 69 στα αγροτεμάχια της δράσης. Για τον υπολογισμό της ποσότητας προϊόντος λίπανσης ανά εκτάριο λαμβάνεται υπόψη η συμβουλή θρέψης που παρέχεται από την ψηφιακή εφαρμογή της παρ. 1 του άρθρου 32, σύμφωνα με τη δράση 31.6-Α καθώς και η συνιστώμενη αζωτούχος λίπανση ανά καλλιέργεια του Παραρτήματος Χ της υπ’ αρ. 1848/278812/8-10-2021 «Κώδικας Ορθής Γεωργικής Πρακτικής για την Προστασία των Νερών από τη Νιτρορύπανση Γεωργικής Προέλευσης» (Β’ 4855), όπως ισχύει. \n\n' +
              'Στην περίπτωση που η γεωργική εκμετάλλευση βρίσκεται εντός ευπρόσβλητης από τη νιτρορύπανση γεωργικής προέλευσης ζώνης, λαμβάνονται υπόψη οι πίνακες λιπαντικής αγωγής του Κεφαλαίου Β’ του Παραρτήματος της υπό στοιχεία ΠΕΝ/ΓρΕΓΥ/38552/265/25-4-2019 (Β’ 1496) κοινής υπουργικής απόφασης του Προγράμματος Δράσης των περιοχών που έχουν χαρακτηρισθεί ως ευπρόσβλητες στα νιτρικά ζώνες, όπως ισχύει. \n\n'
          },
          {
            text: 'β) Να διατηρούν στον περιβαλλοντικό φάκελο',
            font: { bold: true, underline: false },
          },
          {
            text: 'του άρθρου 7 της παρούσας τα φορολογικά παραστατικά αγοράς των λιπασμάτων βραδείας ή ελεγχόμενης αποδέσμευσης, τα οποία εφάρμοσαν στη γεωργική τους εκμετάλλευση κατά το έτος της αίτησης. Από τα φορολογικά παραστατικά πρέπει να προκύπτει η ποσότητα του προϊόντος λίπανσης που εφαρμόστηκε από τον γεωργό στο πλαίσιο της δράσης.\n\n'
          },
          {
            text: '2. Η πληροφορία ότι πρόκειται για λίπασμα βραδείας ή ελεγχόμενης αποδέσμευσης, πρέπει να αναγράφεται στην ετικέτα του προϊόντος και στα φορολογικά παραστατικά αγοράς.',
            font: { bold: true, underline: false },
          },
          {
            text: 'Σε περίπτωση που δεν είναι εφικτή η αναγραφή της ως άνω πληροφορίας επί των φορολογικών παραστατικών, δύναται να προσκομίζεται βεβαίωση του προμηθευτή για την κατηγορία στην οποία ανήκει το συγκεκριμένο προϊόν λίπανσης.'
          }
        ]
      };

      additionalInstructions2.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      additionalInstructions2.height = 500;

      const additionalInstructionsRow3 = additionalInstructionsRow2 + 25;
      const additionalInstructions3 = instructionsheet.getRow(additionalInstructionsRow3);
      instructionsheet.mergeCells(`B${additionalInstructionsRow3}:I${additionalInstructionsRow3 + 37}`);

      additionalInstructions3.getCell(2).value = {
        richText: [
          {
            text: 'Επιπρόσθετες οδηγίες προς τον παραγωγό αναφορικά με τη δράση 31.6-ΙB (ECO-06.20/21)\n\n',
            font: { bold: true, underline: true }
          },
          {
            text: '«Χρήση προϊόντων ή λιπασμάτων με παρεμποδιστές\n\n',
            font: { bold: true, underline: false }
          },
          {
            text: '1.Τα προϊόντα λίπανσης με παρεμποδιστές/αναστολείς',
            font: { bold: true, underline: false }
          },
          {
            text: 'είναι προϊόντα λίπανσης (κοκκώδη ή και υγρά) , στα οποία έχουν προστεθεί ένας ή και περισσότεροι αναστολείς αζώτου επιβραδύνοντας ή διακόπτοντας τη δραστηριότητα συγκεκριμένων μικροοργανισμών ή ενζύμων και παρατείνοντας με αυτόν τον τρόπο τον χρόνο κατά τον οποίο το άζωτο του λιπάσματος θα παραμείνει στο έδαφος σε ουρική και αμμωνιακή μορφή. Ένας αναστολέας νιτροποίησης, αναστέλλει τη βιολογική οξείδωση του αμμωνιακού αζώτου (NH3 – Ν) προς άζωτο σε μορφή νιτρώδους (NO2-), επιβραδύνοντας έτσι τον σχηματισμό αζώτου σε μορφή νιτρικού (NO3-).\n\n' +
              'Παράλληλα, εμποδίζει και την μετατροπή των νιτρικών σε αέριες μορφές του αζώτου (ΝΟx) από τα βακτήρια του εδάφους κατά τη διαδικασία της απονιτροποίησης. Αντίστοιχα, ένας αναστολέας ουρεάσης, αναστέλλει την υδρολυτική δράση του ενζύμου ουρεάση επί της ουρίας (CH4N2O), με κύριο στόχο τη μείωση της πτητικοποίησης της αμμωνίας. \n\n' +
              'Η καθυστέρηση αυτή είναι ιδιαίτερα χρήσιμη όταν η ουρία εφαρμόζεται στην επιφάνεια του εδάφους χωρίς ενσωμάτωση. Τα προϊόντα λίπανσης με αναστολέα διασφαλίζουν τη διαθεσιμότητα του αζώτου στα φυτά για μεγαλύτερο χρονικό διάστημα, καθώς περιορίζουν τις απώλειες αζώτου, και επομένως το καθιστούν διαθέσιμο στο φυτό σύμφωνα με τις πραγματικές τους ανάγκες σε θρέψη.\n\n'
          },
          {
            text: '2. Στα λιπάσματα με παρεμποδιστή / αναστολέα ',
            font: { bold: true, underline: false }
          },
          {
            text: 'που δύναται να ενισχυθούν στο πλαίσιο της παρούσας δράσης, περιλαμβάνονται οι ακόλουθες κατηγορίες προϊόντων:\n\n'
          },
          {
            text: 'αα) Κατηγορία 1α:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Προϊόντα λίπανσης της ΕΕ, σύμφωνα με τον Καν. (ΕΕ) 2019/1009 (προϊόντα λίπανσης που φέρουν σήμανση CE) με αναστολέα νιτροποίησης ή ουρεάσης ή απονίτρωσης, τα οποία ανήκουν στην Κατηγορία Λειτουργίας Προϊόντος ΚΛΠ 5 (Αναστολέας).\n\n'
          },
          {
            text: 'ββ) Κατηγορία 1β:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Προϊόντα λίπανσης της ΕΕ, σύμφωνα με τον Καν. (ΕΕ) 2019/1009 (προϊόντα λίπανσης που φέρουν σήμανση CE), τα οποία ανήκουν στις Κατηγορίες Λειτουργίας Προϊόντος ΚΛΠ 1 (Λίπασμα) με αναστολείς νιτροποίησης ή ουρεάσης ή απονίτρωσης για τους οποίους ισχύουν οι κανόνες του σημείου 4 της ΚΣΥ 1 στο παράρτημα ΙΙ μέρος ΙΙ του Καν. (ΕΕ) 2019/1009.\n\n'
          },
          {
            text: 'γγ) Κατηγορία 1γ:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Προϊόντα λίπανσης της ΕΕ, σύμφωνα με τον Καν. (ΕΕ) 2019/1009 (προϊόντα λίπανσης που φέρουν σήμανση CE), τα οποία ανήκουν στις Κατηγορίες Λειτουργίας Προϊόντος ΚΛΠ 7 (Σύμμεικτο προϊόν λίπανσης) που το ένα τουλάχιστον προϊόν λίπανσης ανήκει στην Κατηγορία 1α ή 1β.\n\n'
          },
          {
            text: 'δδ) Κατηγορία 2:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Νέου τύπου λιπάσματα με παρεμποδιστή, τα οποία κυκλοφορούν στην αγορά βάσει της υπ’ αρ. 291180/11034/19-9-2002 (Β’ 1274) κοινής υπουργικής απόφασης, όπως τροποποιήθηκε με την υπ’ αρ. 257921/15-6-2004 (Β’ 955), όμοια και ισχύει.\n\n' +
              'Τα νέου τύπου λιπάσματα με παρεμποδιστή, πρέπει να περιλαμβάνονται υποχρεωτικά στο «Μητρώο Αδειών Κυκλοφορίας Νέου Τύπου Λιπασμάτων», διαθέσιμο στην ιστοσελίδα του ΥπΑΑΤ:\n\n' +
              'http://www.minagric.gr/index.php/el/for-farmer-2/crop-production/lipasmata/278-mitroa \n\n'
          },
          {
            text: 'εε) Κατηγορία 3:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Λιπάσματα ΕΚ με αναστολείς».\n\n'
          },
          {
            text: 'Οι δεσμεύσεις των γεωργών ακολουθούν το άρθρο 75 της με αριθμ. 2207/17.06.2023 (B’ 3941/17.06.2023)υ.α. ως κάτωθι:\n\n'
          },
          {
            text: '1. Στο πλαίσιο της δράσης, οι γεωργοί δεσμεύονται: \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'α) Να κάνουν αποκλειστική χρήση των λιπασμάτων με παρεμποδιστή/αναστολέα ',
            font: { bold: true, underline: false }
          },
          {
            text: 'του άρθρου 73 της παρούσας στα αγροτεμάχια της δράσης. Για τον υπολογισμό της ποσότητας του προϊόντος λίπανσης ανά εκτάριο λαμβάνεται υπόψη η συμβουλή θρέψης που παρέχεται από την ψηφιακή εφαρμογή της παρ. 1 του άρθρου 32, σύμφωνα με τη δράση 31.6-Α καθώς και η συνιστώμενη αζωτούχος λίπανση ανά καλλιέργεια του Παραρτήματος Χ της υπ’ αρ. 1848/278812/8-10-2021 (Β’ 4855) «Κώδικας Ορθής Γεωργικής Πρακτικής για την Προστασία των Νερών από τη Νιτρορύπανση Γεωργικής Προέλευσης», όπως ισχύει. Στη περίπτωση που η γεωργική εκμετάλλευση βρίσκεται εντός ευπρόσβλητης από τη νιτρορύπανση γεωργικής προέλευσης ζώνης, λαμβάνονται υπόψη οι πίνακες λιπαντικής αγωγής του Κεφαλαίου Β’ του Παραρτήματος της υπό στοιχεία ΠΕΝ/ΓρΕΓΥ/38552/265/25-4-2019 (Β’ 1496) κοινής υπουργικής απόφασης για το Πρόγραμμα Δράσης των περιοχών που έχουν χαρακτηρισθεί ως ευπρόσβλητες στα νιτρικά ζώνες, όπως ισχύει.\n\n'
          },
          {
            text: 'β) Να διατηρούν στον περιβαλλοντικό φάκελο του άρθρου 7 της παρούσας τα φορολογικά παραστατικά αγοράς των λιπασμάτων με παρεμποδιστή/αναστολέα, τα οποία εφάρμοσαν στη γεωργική τους εκμετάλλευση κατά το έτος της αίτησης. ',
            font: { bold: true, underline: false }
          },
          {
            text: 'Από τα φορολογικά παραστατικά πρέπει να προκύπτει η ποσότητα του προϊόντος λίπανσης που εφαρμόστηκε από τον δικαιούχο της δράσης.\n\n'
          },
          {
            text: '2. Η πληροφορία ότι πρόκειται για λίπασμα με παρεμποδιστή/αναστολέα, ',
            font: { bold: true, underline: false }
          },
          {
            text: 'πρέπει να αναγράφεται στην ετικέτα του προϊόντος και στα φορολογικά παραστατικά αγοράς. Σε περίπτωση που δεν είναι εφικτή η αναγραφή της ως άνω πληροφορίας επί των φορολογικών παραστατικών, δύναται να προσκομίζεται βεβαίωση του προμηθευτή για την κατηγορία στην οποία ανήκει το συγκεκριμένο προϊόν λίπανσης.\n\n'
          },
        ]
      };

      additionalInstructions3.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      additionalInstructions3.height = 500;

      const additionalInstructionsRow4 = additionalInstructionsRow3 + 40;
      const additionalInstructions4 = instructionsheet.getRow(additionalInstructionsRow4);
      instructionsheet.mergeCells(`B${additionalInstructionsRow4}:I${additionalInstructionsRow4 + 40}`);

      additionalInstructions4.getCell(2).value = {
        richText: [
          {
            text: 'Επιπρόσθετες οδηγίες προς τον παραγωγό αναφορικά με τη δράση 31.6-ΙB (ECO-06.20/21)\n\n',
            font: { bold: true, underline: true }
          },
          {
            text: '«Χρήση προϊόντων ή λιπασμάτων με βιοδιεγέρτες\n\n',
            font: { bold: true, underline: false }
          },
          {
            text: '1.Οι βιοδιεγέρτες φυτών ',
            font: { bold: true, underline: false }
          },
          {
            text: 'είναι προϊόντα λίπανσης που διεγείρουν τις διαδικασίες θρέψης του φυτού ανεξάρτητα από την περιεκτικότητα του προϊόντος σε θρεπτικά στοιχεία, με μοναδικό σκοπό τη βελτίωση ενός ή περισσοτέρων χαρακτηριστικών του φυτού ή της ριζόσφαιρας του φυτού όπως η αντοχή στις αβιοτικές καταπονήσεις, η αποδοτικότητα της χρήσης των θρεπτικών στοιχείων, τα χαρακτηριστικά ποιότητας και η διαθεσιμότητα των θρεπτικών στοιχείων που συγκρατούνται στο έδαφος ή στη ριζόσφαιρα.\n\n' +
              'Ιδιαίτερη έμφαση δίνεται στην ιδιότητα των βιοδιεγερτών να αυξάνουν την ανθεκτικότητα των φυτών στις αβιοτικές καταπονήσεις, ιδιαιτέρως στις καταπονήσεις λόγω υδατικού και θερμικού στρες καθώς και στις δυσμενείς συνθήκες που δημιουργούνται στη ριζόσφαιρα λόγω πλημμυρικών φαινομένων και υψηλής αλατότητας εδάφους, οι οποίες αναμένεται να λαμβάνουν χώρα όλο και συχνότερα τα επόμενα έτη λόγω της κλιματικής αλλαγής.\n\n'
          },
          {
            text: '2. Στα λιπάσματα με παρεμποδιστή / αναστολέα',
            font: { bold: true, underline: false }
          },
          {
            text: 'που δύναται να ενισχυθούν στο πλαίσιο της παρούσας δράσης, περιλαμβάνονται οι ακόλουθες κατηγορίες προϊόντων:\n\n'
          },
          {
            text: 'α) Κατηγορία 1α:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Προϊόντα λίπανσης της ΕΕ, σύμφωνα με τον Καν. (ΕΕ) 2019/1009 (προϊόντα λίπανσης που φέρουν σήμανση CE) τα οποία ανήκουν στην Κατηγορία Λειτουργίας Προϊόντος ΚΛΠ 6 (Βιοδιεγέρτης φυτών) και συγκεκριμένα στην 6(Α) (Μικροβιακός διεγέρτης φυτών) και ΚΛΠ 6(Β) (Μη μικροβιακός διεγέρτης φυτών).\n\n'
          },
          {
            text: 'β) Κατηγορία 1β:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Προϊόντα λίπανσης της ΕΕ, σύμφωνα με τον Καν. (ΕΕ) 2019/1009 (προϊόντα λίπανσης που φέρουν σήμανση CE) τα οποία ανήκουν στην ΚΛΠ 7 (Σύμμεικτο προϊόν λίπανσης που περιέχει έναν ή περισσότερους βιοδιεγέρτες φυτών που ανήκουν στην ΚΛΠ 6).\n\n'
          },
          {
            text: 'γ) Κατηγορία 2:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Νέου τύπου λιπάσματα τα οποία περιέχουν ουσίες βοηθητικές της ανάπτυξης των φυτών όπως φυτορμόνες, ένζυμα, ρυθμιστές, βιταμίνες, πρωτεΐνες, αμινοξέα, πολυμερή και άλλες παρόμοιες ουσίες που συμβάλουν στην ανάπτυξη του φυτού και βελτιώνουν την ποιότητα των παραγόμενων προϊόντων, σε συνδυασμό με έναν ή και περισσότερους τύπους λιπασμάτων των ομάδων α’ – δ’ (το ελάχιστον έναν) του άρθρου 4α της αριθ. 257921/15-6-2004 ΚΥΑ (ΦΕΚ Β’ 955), τα οποία κυκλοφορούν στην αγορά βάσει της αριθ. 291180/11034/19-9-2002 ΚΥΑ (ΦΕΚ Β’ 1274), όπως τροποποιήθηκε με την αριθ. 257921/15-6-2004 όμοια (ΦΕΚ Β’ 955) και ισχύει. Τα νέου τύπου λιπάσματα με ουσίες βοηθητικές της ανάπτυξης των φυτών πρέπει να περιλαμβάνονται υποχρεωτικά στο «Μητρώο Αδειών Κυκλοφορίας Νέου Τύπου Λιπασμάτων», διαθέσιμο στην ιστοσελίδα του ΥπΑΑΤ: http://www.minagric.gr/index.php/el/for-farmer-2/crop-production/lipasmata/278-mitroa \n\n'
          },
          {
            text: 'δ) Κατηγορία 3:  \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'Προϊόντα με ουσίες βοηθητικές της ανάπτυξης των φυτών, τα οποία κυκλοφορούν στην αγορά για χρήση στη γεωργία βάσει της ΥΑ 217217/8-1-2004 (ΦΕΚ Β’ 35). Στην ετικέτα του προϊόντος πρέπει να αναγράφεται η φράση: «Ουσία βοηθητική της ανάπτυξης των φυτών/Κυκλοφορεί στην ελληνική αγορά για χρήση στη γεωργία σύμφωνα με την ΥΑ 217217/2004 (35/Β’/2004)».\n\n' +
              'Οι δεσμεύσεις των γεωργών ακολουθούν το άρθρο 79 της με αριθμ. 2207/17.06.2023 (B’ 3941/17.06.2023)υ.α. ως κάτωθι:\n\n'
          },
          {
            text: '1. Στο πλαίσιο της δράσης, οι γεωργοί δεσμεύονται: \n\n',
            font: { bold: true, underline: true }
          },
          {
            text: 'α) Να κάνουν χρήση των προϊόντων ή λιπασμάτων με βιοδιεγέρτες ',
            font: { bold: true, underline: false }
          },
          {
            text: 'του άρθρου 77 της παρούσας στα αγροτεμάχια της δράσης, ως εξής:\n\n'
          },
          {
            text: 'αα) για τα προϊόντα της Κατηγορίας 1α και 3 της παρ. 2 του άρθρου 77, για τον υπολογισμό της ποσότητας ανά εκτάριο, λαμβάνεται υπόψη η συνιστώμενη δόση και ο αριθμός εφαρμογών που αναγράφεται στην ετικέτα του προϊόντος, ανάλογα με την καλλιέργεια.'
          },
          {
            text: 'ββ) για τα προϊόντα λίπανσης της Κατηγορίας 1β και 2 της παρ. 2 του άρθρου 77, να κάνουν αποκλειστική χρήση αυτών στα αγροτεμάχια της δράσης. Για τον υπολογισμό της ποσότητας προϊόντος λίπανσης ανά εκτάριο λαμβάνεται υπόψη η συμβουλή θρέψης που παρέχεται από την ψηφιακή εφαρμογή της παρ. 1 του άρθρου 32, σύμφωνα με τη δράση 31.6-Α καθώς και η συνιστώμενη αζωτούχος λίπανση ανά καλλιέργεια του Παραρτήματος Χ της υπ’ αρ. 1848/278812/8-10-2021 (Β’ 4855) «Κώδικας Ορθής Γεωργικής Πρακτικής για την Προστασία των Νερών από τη Νιτρορύπανση Γεωργικής Προέλευσης», όπως ισχύει. Στην περίπτωση που η γεωργική εκμετάλλευση βρίσκεται εντός ευπρόσβλητης από τη νιτρορύπανση γεωργικής προέλευσης ζώνης, λαμβάνονται υπόψη οι πίνακες λιπαντικής αγωγής του Κεφαλαίου Β’ του Παραρτήματος της υπό στοιχεία ΠΕΝ/ ΓρΕΓΥ/38552/265/25-4-2019 (Β’ 1496) κοινής υπουργικής απόφασης για το Πρόγραμμα Δράσης των περιοχών που έχουν χαρακτηρισθεί ως ευπρόσβλητες στα νιτρικά ζώνες, όπως ισχύει.',

          },
          {
            text: 'β) Να διατηρούν στον περιβαλλοντικό φάκελο του άρθρου 7 τα φορολογικά παραστατικά αγοράς προϊόντων ή λιπασμάτων με βιοδιεγέρτες, τα οποία εφάρμοσαν στη γεωργική τους εκμετάλλευση κατά το έτος της αίτησης. ',
            font: { bold: true, underline: false }
          },
          {
            text: 'Από τα φορολογικά παραστατικά πρέπει να προκύπτει η ποσότητα του προϊόντος που εφαρμόστηκε από τον γεωργό στο πλαίσιο της δράσης. \n\n'
          },
          {
            text: '2. Η πληροφορία για την κατηγορία στην οποία ανήκει το λίπασμα ή το προϊόν λίπανσης με βιοδιεγέρτη ή οι ουσίες βοηθητικές της ανάπτυξης των φυτών ',
            font: { bold: true, underline: false }
          },
          {
            text: 'της παρ. 2 του άρθρου 77, πρέπει να αναγράφεται στην ετικέτα του προϊόντος και στα φορολογικά παραστατικά αγοράς. Σε περίπτωση που δεν είναι εφικτή η αναγραφή της ως άνω πληροφορίας επί των φορολογικών παραστατικών, δύναται να προσκομίζεται βεβαίωση του προμηθευτή για την κατηγορία στην οποία ανήκει το συγκεκριμένο προϊόν λίπανσης. \n\n'
          },
        ]
      };

      additionalInstructions4.getCell(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
      additionalInstructions4.height = 400;

      const columnWidths = [
        { width: 10 },
        { width: 10 },
        { width: 30 },
        { width: 50 },
        { width: 20 },
        { width: 20 },
        { width: 50 },
        { width: 80 },
        { width: 40 },
        { width: 60 },
        { width: 60 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];

      const instructionWidths = [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];

      worksheet.columns = columnWidths;
      instructionsheet.columns = instructionWidths;
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, this.farmerSurname + '_' + this.farmerVat + '_' + 'ΣΠΔ.xlsx');
      });
    })
  }
}