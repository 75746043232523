import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OtherService {

  constructor() { }

  reverseArray<T>(array:T[]): T[]{
    return array.slice().reverse()
  }

  getOneMonthDateRange(): { startDate: string; endDate: string } {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 2);

    const startDate = lastMonth.toISOString().split('T')[0];
    const endDate = today.toISOString().split('T')[0];

    return { startDate, endDate };
  }

  formatDateStart(date: any): string {
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);
    const timezoneOffset = selectedDate.getTimezoneOffset() * 60000;
    const utcStart = new Date(selectedDate.getTime() - timezoneOffset);
    return utcStart.toISOString();
  }

  formatDateEnd(date: any): string {
    const selectedDate = new Date(date);
    selectedDate.setHours(23, 59, 59, 999);
    const timezoneOffset = selectedDate.getTimezoneOffset() * 60000;
    const utcEnd = new Date(selectedDate.getTime() - timezoneOffset);
    return utcEnd.toISOString();
  }
}
