<h1 mat-dialog-title class="text-center">{{ dialogTitle | translate }}</h1>
<form class="example-form" id="form" [formGroup]="fertiliserForm">
    <div *ngIf="this.superuser && this.editData.isEdit && this.editData.row.status === 'pending'" class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.STATUS'| translate }}</mat-label>
                    <mat-select formControlName="status" [(value)]="status">
                        <mat-option value="pending">{{ 'PRODUCT.PENDING'| translate }}</mat-option>
                        <mat-option value="approved">{{ 'PRODUCT.APPROVE'| translate }}</mat-option>
                        <mat-option value="declined">{{ 'PRODUCT.DECLINE'| translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.COMMERCIAL'| translate }}</mat-label>
                    <input matInput formControlName="commercialName" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.CROP_TYPE'| translate }}</mat-label>
                    <input matInput formControlName="cropType" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center" [hidden]="this.superuser && this.editData.isEdit && this.editData.row.status === 'pending'">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.COMMERCIAL'| translate }}</mat-label>
                    <input matInput formControlName="commercialName" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.CROP_TYPE'| translate }}</mat-label>
                    <input matInput formControlName="cropType" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.TYPE'| translate }}</mat-label>
                    <input matInput formControlName="type" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">{{ 'PRODUCT.SUBSTANCE'| translate }}</mat-label>
                    <input matInput formControlName="substance" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">Efn</mat-label>
                    <input matInput type="number" min="0" formControlName="efn" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">Ef5</mat-label>
                    <input matInput type="number" min="0" formControlName="ef5" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">Efert</mat-label>
                    <input matInput type="number" min="0" formControlName="efert" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">Fvoln</mat-label>
                    <input matInput type="number" min="0" formControlName="fvoln" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="row">
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">N</mat-label>
                    <input matInput type="number" min="0" formControlName="n" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">P</mat-label>
                    <input matInput type="number" min="0" formControlName="p" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label style="font-size: 14px;">K</mat-label>
                    <input matInput type="number" min="0" formControlName="k" />
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
<div id="buttons_group" class="mt-2 mx-1 p-3 float-right">
    <div class="row">
        <div class="col-5">
            <button mat-raised-button mat-dialog-close="Close"
                style="background-color: red; color: white;">{{ closeButton | translate }}</button>
        </div>
        <div class="col-5">
            <button mat-raised-button mat-dialog-close (click)="addFertiliser()"
                style="background-color: #3fb56d; color: white;">{{ actionButton | translate }}</button>
        </div>
    </div>
</div>