import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestService } from 'src/app/services/request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-addfertiliser',
  templateUrl: './addfertiliser.component.html',
  styleUrls: ['./addfertiliser.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddfertiliserComponent implements OnInit {
  fertiliserForm!: FormGroup;
  title: any;
  dialogTitle: any;
  actionButton: any;
  closeButton: any;
  langStatus: any;
  superuser: boolean = false;
  productType: any = 'fertilisation';
  cropType: any;
  status: any;
  commercialName: any;
  type: any;
  substance: any;
  n: number = 0;
  p: number = 0;
  k: number = 0;
  ef5: number = 0;
  fvoln: number = 0;
  efn: number = 0;
  efert: number = 0;

  constructor(
    private fb: FormBuilder,
    private req: RequestService<any>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public editData: any,
    private authorizationService: AuthorizationService
  ) { }

  ngOnInit() {
    this.authorizationService.superuser$.subscribe(superuser => {
      this.superuser = superuser;
    });

    this.fertiliserForm = this.fb.group({
      productType: ['fertilisation'],
      cropType: [''],
      status: ['pending'],
      commercialName: ['', Validators.required],
      type: [''],
      substance: [''],
      n: [0],
      p: [0],
      k: [0],
      ef5: [0],
      fvoln: [0],
      efn: [0],
      efert: [0],
    });

    if (this.editData.isEdit) {
      this.fertiliserForm.controls['productType'].setValue(this.editData.row.product_type);
      this.fertiliserForm.controls['cropType'].setValue(this.editData.row.crop_type);
      this.fertiliserForm.controls['status'].setValue(this.editData.row.status);
      this.fertiliserForm.controls['commercialName'].setValue(this.editData.row.commercial_name);
      this.fertiliserForm.controls['type'].setValue(this.editData.row.type);
      this.fertiliserForm.controls['substance'].setValue(this.editData.row.substance);
      this.fertiliserForm.controls['n'].setValue(this.editData.row.N);
      this.fertiliserForm.controls['p'].setValue(this.editData.row.P);
      this.fertiliserForm.controls['k'].setValue(this.editData.row.K);
      this.fertiliserForm.controls['ef5'].setValue(this.editData.row.EF5);
      this.fertiliserForm.controls['fvoln'].setValue(this.editData.row.fvoln);
      this.fertiliserForm.controls['efn'].setValue(this.editData.row.EFn);
      this.fertiliserForm.controls['efert'].setValue(this.editData.row.Efert);
    };

    this.statusLanguage();
  }

  statusLanguage() {
    if (!this.editData.isEdit) {
      this.dialogTitle = 'PRODUCT.ADDFERT';
      this.actionButton = 'PRODUCT.ADD_BUTTON';
      this.closeButton = 'PRODUCT.CANCEL';
    } else if (this.editData.isEdit) {
      this.dialogTitle = 'PRODUCT.EDITFERT';
      this.actionButton = 'PRODUCT.UPDATE_BUTTON';
      this.closeButton = 'PRODUCT.CANCEL';
    }
  }

  addFertiliser() {
    if (!this.editData.isEdit) {
      let updatedData = {
        product_type: this.fertiliserForm.value.productType,
        crop_type: this.fertiliserForm.value.cropType,
        status: this.editData.fertChange ? 'approved' : this.fertiliserForm.value.status,
        commercial_name: this.fertiliserForm.value.commercialName,
        type: this.fertiliserForm.value.type,
        substance: this.fertiliserForm.value.substance,
        N: this.fertiliserForm.value.n,
        P: this.fertiliserForm.value.p,
        K: this.fertiliserForm.value.k,
        EF5: this.fertiliserForm.value.ef5,
        fvoln: this.fertiliserForm.value.fvoln,
        EFn: this.fertiliserForm.value.efn,
        Efert: this.fertiliserForm.value.efert,
      };

      if (!this.editData.fertChange) {
        this.req.postStorageProduct(updatedData).subscribe(res => {
          const title = "PRODUCT.FERT_TITLE_ADD";
          const text = "PRODUCT.FERT_TEXT_ADD";
          this.translate.get([title, text])
            .subscribe(translations => {
              Swal.fire({
                title: translations[title],
                text: translations[text],
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 3000,
              });
            })
        })
      }
      else {
        this.req.postRawProduct(updatedData).subscribe(res => {
          const title = "PRODUCT.FERT_TITLE_ADD";
          const text = "PRODUCT.FERT_TEXT_ADD";
          this.translate.get([title, text])
            .subscribe(translations => {
              Swal.fire({
                title: translations[title],
                text: translations[text],
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 3000,
              });
            })
        })
      }
    }
    else {
      this.updateFetiiser()
    }
  }

  updateFetiiser() {
    let updatedData = {
      product_type: this.fertiliserForm.value.productType ? this.fertiliserForm.value.productType : "",
      crop_type: this.fertiliserForm.value.cropType ? this.fertiliserForm.value.cropType : "",
      status: this.editData.fertChange ? 'approved' : this.fertiliserForm.value.status,
      commercial_name: this.fertiliserForm.value.commercialName ? this.fertiliserForm.value.commercialName : "",
      type: this.fertiliserForm.value.type ? this.fertiliserForm.value.type : "",
      substance: this.fertiliserForm.value.substance ? this.fertiliserForm.value.substance : "",
      N: this.fertiliserForm.value.n ? this.fertiliserForm.value.n : 0,
      P: this.fertiliserForm.value.p ? this.fertiliserForm.value.p : 0,
      K: this.fertiliserForm.value.k ? this.fertiliserForm.value.k : 0,
      EF5: this.fertiliserForm.value.ef5 ? this.fertiliserForm.value.ef5 : 0,
      fvoln: this.fertiliserForm.value.fvoln ? this.fertiliserForm.value.fvoln : 0,
      EFn: this.fertiliserForm.value.efn ? this.fertiliserForm.value.efn : 0,
      Efert: this.fertiliserForm.value.efert ? this.fertiliserForm.value.efert : 0
    };

    if (!this.editData.fertChange) {
      this.req.updateStorageProduct(this.editData.row._id, updatedData).subscribe({
        next: (res) => {
          const title = "PRODUCT.FERT_TITLE_UPDATE";
          const text = "PRODUCT.FERT_TEXT_UPDATE";
          this.translate.get([title, text])
            .subscribe(translations => {
              Swal.fire({
                title: translations[title],
                text: translations[text],
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 3000,
              });
            })
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
    else {
      this.req.updateRawProduct(this.editData.row._id, updatedData).subscribe({
        next: (res) => {
          const title = "PRODUCT.FERT_TITLE_UPDATE";
          const text = "PRODUCT.FERT_TEXT_UPDATE";
          this.translate.get([title, text])
            .subscribe(translations => {
              Swal.fire({
                title: translations[title],
                text: translations[text],
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                timer: 3000,
              });
            })
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
  }
}