<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
    {{ 'SIDEBAR.MANAGEMENT'| translate}} / {{ 'SIDEBAR.TITLE'| translate}}
</h1>
<div class="container mx-auto px-4">
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                A1. {{ 'FARMER.PERSONAL' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.LNAME' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="lastName" name="lastName" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.FNAME' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="firstName" name="firstName" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.CITY' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="city" name="city" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.MUN' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="municipality" name="municipality" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.TEL' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="telephone" name="telephone" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.ADDRESS' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="address" name="address" />
                    </div>
                </div>
                <div class="w-full lg:w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.POSTAL' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="postalCode" name="postalCode" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.CONTACT' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="contact" name="contact" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            GGN
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="ggn" name="ggn" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            Register Number
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="registerNumber" name="registerNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-3/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.STATUS' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="globalGapStatus" name="globalGapStatus" />
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                A2. {{ 'FARMER.GENERALINFO' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.DOY' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="doy" name="doy" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.AFM' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="vat" name="vat" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.ENTITY' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="entityType" name="entityType" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.LEGAL' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="legalStatus" name="legalStatus" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.TAXBOOK' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="taxBookCategory" name="taxBookCategory" />
                    </div>
                </div>
                <div class="w-full lg:w-8/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.COMPANYNAME' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="companyName" name="companyName" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.3RD' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="thirdPersonAdministration" name="thirdPersonAdministration" />
                    </div>
                </div>
                <div class="w-full lg:w-8/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.STARTDATE' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="startingDate" name="startingDate" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.ANOTHER' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="anotherLegalEntityAdministration" name="anotherLegalEntityAdministration" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.DOB' | translate }}
                        </label>
                        <input type="date"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="dateOfBirth" name="dateOfBirth" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.FATHER' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="fatherName" name="fatherName" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.2ND' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="secLastName" name="secLastName" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.ID' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="idNumber" name="idNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.IDTYPE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="idType" name="idType" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.AMKA' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="amka" name="amka" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.INSURANCE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="insuranceProvider" name="insuranceProvider" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.INSURANCENO' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="insuranceNumber" name="insuranceNumber" />
                    </div>
                </div>
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.INSTITUTION' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="creditInstitution" name="creditInstitution" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="creditInstitutionCode" name="creditInstitutionCode" />
                    </div>
                </div>
                <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            IBAN
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="iban" name="iban" />
                    </div>
                </div>
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.UNIT' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="regionalUnit" name="regionalUnit" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="regionalUnitCode" name="regionalUnitCode" />
                    </div>
                </div>
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.REGION' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="region" name="region" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="regionCode" name="regionCode" />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="flex-auto mt-8 p-4 lg:px-10 bg-white rounded-lg">
        <form>
            <h6 class="text-gray-400 text-md my-6 font-bold uppercase">
                B. {{ 'FARMER.SEAT' | translate }}
            </h6>
            <hr class="my-4 border-b-1 border-gray-300" />
            <div class="flex flex-wrap">
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.COMMUNITY' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatCommunity" name="seatCommunity" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatCommunityCode" name="seatCommunityCode" />
                    </div>
                </div>
                <div class="w-full lg:w-8/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.ADDRESS' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatAddress" name="seatAddress" />
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.POSTAL' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatPostalCode" name="seatPostalCode" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.TEL' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatPhoneNumber" name="seatPhoneNumber" />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            Email
                        </label>
                        <input type="email"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="email" name="email" />
                    </div>
                </div>
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.UNIT' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatRegionalUnit" name="seatRegionalUnit" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatRegionalUnitCode" name="seatRegionalUnitCode" />
                    </div>
                </div>
                <div class="w-10/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'FARMER.REGION' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatRegion" name="seatRegion" />
                    </div>
                </div>
                <div class="w-2/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            {{ 'PLOTS.CODE' | translate }}
                        </label>
                        <input type="text"
                            class="border-2 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            [(ngModel)]="seatRegionCode" name="seatRegionCode" />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-raised-button id="saveButton" class="float-right m-2" (click)="updateData()">
        <mat-icon>done</mat-icon>
        {{ 'MANAGEMENT.SAVE' | translate }}
    </button>
</div>