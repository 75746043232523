<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
  {{ 'SIDEBAR.UPLOAD'| translate}}
</h1>
<div class="container mx-auto px-4">
  <div class="flex-auto mt-2 p-2 lg:px-10 rounded-lg">
    <div class="row">
      <div class="col-sm-6">
        <div id="search" class="row justify-content-between mx-2">
          <mat-form-field>
            <mat-icon matSuffix>search</mat-icon>
            <input matInput (keyup)="applyFilter($event)" placeholder="{{'UPLOAD.UPLOAD_SEARCH' | translate}}" #input />
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-2" style="margin-left: 33%">
        <button mat-raised-button id="upload_button" (click)="uploadButton(uploadRef)">
          <span class="material-icons" style="margin-right: 2%;">file_upload</span>
          {{'UPLOAD.UPLOAD_BUTTON' | translate}}
        </button>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort id="upload-table">
        <!-- ID Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="
              background-color: #a1c6af;
              border-top-left-radius: 10px !important;
            ">
            {{'UPLOAD.TYPE' | translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.file_type.includes('pdf')">
              <img id="img_icon" src="../../../assets/pdf.png" style="width: 40px; height: 35px" />
            </ng-container>
            <ng-container *ngIf="row.file_type.includes('docx')">
              <img id="img_icon" src="../../../assets/word.png" style="width: 40px; height: 35px" />
            </ng-container>
            <ng-container *ngIf="row.file_type.includes('xls')">
              <img id="img_icon" src="../../../assets/xls.png" style="width: 40px; height: 35px" />
            </ng-container>
            <ng-container *ngIf="row.file_type.includes('csv')">
              <img id="img_icon" src="../../../assets/csv.png" style="width: 40px; height: 35px" />
            </ng-container>
            <ng-container *ngIf="row.file_type.includes('xml')">
              <img id="img_icon" src="../../../assets/csv.png" style="width: 40px; height: 35px" />
            </ng-container>
          </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="
            background-color: #a1c6af;          
            ">
            {{'UPLOAD.FILE_NAME' | translate}}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.filename }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="
            background-color: #a1c6af;          
            ">
            {{'UPLOAD.CATEGORY' | translate}}
          </th>
          <td mat-cell *matCellDef="let row">{{row.category}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="upload_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #a1c6af">
            {{'UPLOAD.DATE' | translate}}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.upload_date | date: 'MMM d, y, h:mm:ss a':'el'}}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="
              background-color: #a1c6af;
              border-top-right-radius: 10px !important;
            ">
            {{'UPLOAD.ACTION' | translate}}
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon id="delete_button" (click)="deletedFile($event)"> delete </mat-icon>
            <mat-icon id="downlod_btn" (click)="downloadFile(row)">download</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"
          (mouseover)="onRowClicked(row)" [ngClass]="{ highlightTableColor: selectedRowIndex == row.id }">
        </tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
    <ng-template #uploadRef id="matdialog">
      <mat-dialog-content>
        <div class="center" id="drop-area">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <i class="fa fa-cloud-upload text-center" id="cloud_icon" aria-hidden="true"></i>
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="true">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <div class="row">
                      <div class="col text-center mt-5">
                        <div id="drag-text">
                          <span>
                            {{'UPLOAD.DRAG_DROP' | translate}}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <button (click)="openFileSelector()" class="btn-grad">
                          {{'UPLOAD.BROWSE_BUTTON' | translate}}
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ngx-file-drop>
              </div>
            </div>
            <div class="col-6">
              <div *ngIf="langStatus === 'gr'">
                <mat-form-field>
                  <mat-label>{{'UPLOAD.SELECT_TYPE' | translate}}</mat-label>
                  <mat-select [(ngModel)]="importDataValue" (ngModelChange)="importSelect()">
                    <mat-option *ngFor="let select of importDataGR" [value]="select">
                      {{ select}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="langStatus === 'en'">
                <mat-form-field>
                  <mat-label>{{'UPLOAD.SELECT_TYPE' | translate}}</mat-label>
                  <mat-select [(ngModel)]="importDataValue" (ngModelChange)="importSelect()">
                    <mat-option *ngFor="let select of importDataEN" [value]="select">
                      {{ select}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="upload-table">
                <div *ngFor="let item of files; let i = index">
                  <div *ngIf="
                      item.fileEntry.name.includes('.pdf') ||
                        item.fileEntry.name.includes('.xlsx') ||
                        item.fileEntry.name.includes('.csv') ||
                        item.fileEntry.name.includes('.xml') ||
                        this.droppedFile.fileEntry.name.includes('.docx');
                      else wrongFile
                    ">
                    <div>
                      <strong style="font-size: small; color: #7c7c7c">
                        <div style="font-size: xx-small; color: #7c7c7c">
                          {{ item.fileEntry.name }}
                          <i (click)="deleteDroped($event)" id="uploaded_delete" class="fa-solid fa-trash-can"></i>
                        </div>
                      </strong>
                    </div>
                    <hr class="dark horizontal my-2" />
                  </div>
                  <ng-template #wrongFile> </ng-template>
                </div>
              </div>
            </div>
          </div>

        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="action-btn" align="end" style="margin-top: 5px; margin-right: 12px">
        <button mat-raised-button matDialogClose style="background-color: #eb2727; color: white">
          {{'UPLOAD.CLOSE_BUTTON' | translate}}
        </button>
        <button (click)="submitUpload($event)" mat-raised-button matDialogClose
          style="background-color: #50996a; color: white">
          {{'UPLOAD.SUBMIT_BUTTON' | translate}}
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>



</div>