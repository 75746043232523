import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RequestService } from 'src/app/services/request.service';
import { KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';
import { GridOptions } from 'ag-grid-community';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { font2 } from '../../fonts/encryption';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-farmerhistory',
    templateUrl: './farmerhistory.component.html',
    styleUrls: ['./farmerhistory.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FarmerhistoryComponent implements OnInit {
    public id: any = '';
    codes: any = [];
    code: any = '';
    public langStatus: any;
    headColumns: any[] = [];
    elArray: any;
    splitArray: any
    cropidArray: any[] = []
    gridOptions: GridOptions = {
        columnDefs: [],
        rowData: [],
        rowSelection: 'multiple',
        animateRows: true,
        pagination: true,
        paginationPageSize: 10
    };
    farmerDataMap: any[] = []

    plotColumnDefs: any[] = [];
    defaultColDef: any = {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        filterParams: {
            buttons: ['reset', 'apply'],
            filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
        }
    };
    columnDefs: any[] = [
        {
            headerName: 'Date',
            field: 'datetime',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
        },
    ];
    selected: any;
    @Input() plotCodes: any
    @Input() plotChoose: any;
    @Input() selectedItems: any
    crop_id: any;
    @Input() setAllStatus: any
    chooseCode: any[] = []
    alist: any[] = [];
    childCheck: any;
    parentCheck: any;
    @Input() parentCodeBoolean: any
    @Input() childCodeBoolean: any
    @Input() parentCode: any;
    @Input() childCode: any;
    @Input() parentBox: any;
    @Input() childBox: any;
    uncheckParent: boolean = false
    codesValues: any[] = []
    currentBoxValue: boolean = false
    @Input() afmFarmer: any
    @Input() firstName: any
    @Input() lastName: any

    constructor(
        private req: RequestService<any>,
        private keycloak: KeycloakService,
        public _location: Location,
        public translate: TranslateService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.langStatus = this.translate.store.currentLang
        this.columnsTable();
        this.getAllData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['plotChoose']) {
            this.codesValues = (changes['plotChoose'].currentValue)
            if (changes['parentBox']) {
                this.currentBoxValue = changes['parentBox'].currentValue
            }
        }
        if (!this.setAllStatus && !this.parentCodeBoolean && !this.childCodeBoolean) {
            this.plotCodes = [];
            this.cropidArray = [];
        } else if (!this.setAllStatus) {
            this.getMultiData()
        } else if (this.setAllStatus) {
            this.getAllData()
        }
    }

    getMultiData() {
        this.splitArray = [];
        this.plotCodes = [];
        const ValueAll = '?crop_usage=True';
        if (this.parentCodeBoolean == true && this.childCodeBoolean == false) {
            this.plotCodes = [];
            this.plotChoose = [];
            if (this.parentBox) {
                this.parentCode[4].forEach((el: any) => {
                    this.cropidArray.push('&crop_info_id=' + el.crop_info_id)
                });
            } else if (!this.parentBox) {
                this.cropidArray = []
                this.plotChoose.forEach((el: any) => {
                    this.cropidArray.push('&crop_info_id=' + el.crop_info_id)
                });
            }
        } else if (this.parentCodeBoolean == false && this.childCodeBoolean == true) {
            if (this.childBox) {
                this.cropidArray.push('&crop_info_id=' + this.childCode.crop_info_id)
            } else if (!this.childBox) {
                this.cropidArray = []
                this.plotChoose.forEach((el: any) => {
                    this.cropidArray.push('&crop_info_id=' + el.crop_info_id)
                });
            }
        }

        if (!this.currentBoxValue) {
            this.codesValues.forEach((el: any) => {
                this.cropidArray.push('&crop_info_id=' + el.crop_info_id)
            });
        }
        let ValueOne = ((',' + this.cropidArray).replace(/,/g, ''));
        this.splitArray = ValueAll + ValueOne
        this.req.getPlotFarmerHistory(this.splitArray).subscribe((res) => {
            this.plotCodes = res.map((element: any) => {
                return {
                    plot_code: `${element.plot_code} - ${element.crop_type}`,
                    municipal_community: element.municipal_community,
                    location: element.location,
                    regional_unit: element.regional_unit,
                    co_ownership: element.co_ownership,
                    size: element.size.toFixed(2),
                    species: element.variety
                };
            });
        });
    }

    getAllData() {
        this.req.getPlotFarmerHistory('?crop_usage=True').subscribe((res) => {
            this.plotCodes = res.map((element: any) => {
                return {
                    plot_code: `${element.plot_code} - ${element.crop_type}`,
                    municipal_community: element.municipal_community,
                    location: element.location,
                    regional_unit: element.regional_unit,
                    co_ownership: element.co_ownership,
                    size: element.size.toFixed(2),
                    species: element.variety
                };
            });
        })
    }

    columnsTable() {
        this.langStatus = this.translate.store.currentLang;
        if (this.langStatus === 'en') {
            this.plotColumnDefs = [
                { headerName: 'Plot', field: 'plot_code', resizable: true },
                { headerName: 'Municipality', field: 'municipal_community', resizable: true },
                { headerName: 'Location', field: 'location', resizable: true },
                { headerName: 'Size', field: 'size', resizable: true },
                { headerName: 'Co-Ownership', field: 'co_ownership', resizable: true },
                { headerName: 'Species', field: 'species', resizable: true },
            ]
        }
        else {
            this.plotColumnDefs = [
                { headerName: 'Αγροτεμάχιο', field: 'plot_code', resizable: true },
                { headerName: 'Δήμος', field: 'municipal_community', resizable: true },
                { headerName: 'Τοποθεσία', field: 'location', resizable: true },
                { headerName: 'Έκταση', field: 'size', resizable: true },
                { headerName: 'Συνιδιοκτησία', field: 'co_ownership', resizable: true },
                { headerName: 'Είδος', field: 'species', resizable: true },
            ]
        }
    }

    farmerData() {
        this.farmerDataMap = this.plotCodes.map((el: any) => [
            el.plot_code,
            el.municipal_community,
            el.location,
            el.co_ownership,
            el.size,
            el.species,
        ]);
    }

    exportCsv() {
        this.farmerData()
        const currentDate = new Date();
        const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
        if (this.langStatus.includes('en')) {
            var options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                showLabels: true,
                useBom: true,
                headers: ['Plot', 'Municipality', 'Location', 'Regional Unit', 'Co-Ownership', 'Size', 'Species'],
            };
            new ngxCsv(this.farmerDataMap, 'Farmer_' + dateTime, options);
        } else if (this.langStatus.includes('gr')) {
            var options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                showLabels: true,
                useBom: true,
                headers: ['Αργοτεμάχιο', 'Δήμος-Κοινότητα', 'Τοποθεσία', 'Περιφερειακή Ενότητα', 'Συνιδιοκτησία', 'Έκταση (στρέμματα)', 'Είδος'],
            };
            new ngxCsv(this.farmerDataMap, 'Παραγωγός_' + dateTime, options);
        }
    }


    exportExcel() {
        this.farmerData()
        const currentDate = new Date();
        const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy');
        const workbook = XLSX.utils.book_new();
        const worksheet: XLSX.WorkSheet = {};
        if (this.langStatus.includes('en')) {
            XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
            XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
            XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
            const headers = ['Plot Code', 'Municipal Community', 'Location', 'Co-Ownership', 'Size', 'Species'];
            XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
            XLSX.utils.sheet_add_aoa(worksheet, this.farmerDataMap, { origin: 'A6' });
            XLSX.utils.book_append_sheet(workbook, worksheet);
            XLSX.writeFile(workbook, 'Farmer_' + dateTime + '.xlsx');
        } else if (this.langStatus.includes('gr')) {
            XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
            XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
            XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
            const headers = ['Αργοτεμάχιο', 'Δήμος_Κοινότητα', 'Τοποθεσία', 'Συνιδιοκτησία', 'Έκταση', 'Είδος']
            XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
            XLSX.utils.sheet_add_aoa(worksheet, this.farmerDataMap, { origin: 'A6' });
            XLSX.utils.book_append_sheet(workbook, worksheet);
            XLSX.writeFile(workbook, 'Παραγωγός_' + dateTime + '.xlsx');

        }
    }

    exportPdf() {
        this.farmerData()
        console.log(this.farmerDataMap)
        const currentDate = new Date();
        const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
        if (this.langStatus.includes('en')) {
            this.headColumns = ['Plot', 'Municipality', 'Location', 'Regional_Unit', 'Co_Ownership', 'Size', 'Species']
        } else if (this.langStatus.includes('gr')) {
            this.headColumns = ['Αργοτεμάχιο', 'Δήμος_Κοινότητα', 'Τοποθεσία', 'Περιφερειακή_Ενότητα', 'Συνιδιοκτησία', 'Έκταση', 'Είδος']
        }
        const doc = new jsPDF();
        doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
        doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
        doc.setFont('LiberationSerif-Regular', 'normal');
        doc.setFontSize(12)
        doc.setTextColor('#5e797e')
        if (this.langStatus.includes('en')) {
            doc.setFontSize(12)
            doc.text(this.firstName, 10, 10)
            doc.text(this.lastName, 10, 15)
            doc.setFontSize(10)
            doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
            doc.setTextColor('#5e797e')
            doc.text('Farmer ', 180, 10)
        } else if (this.langStatus.includes('gr')) {
            doc.setFontSize(12)
            doc.text(this.firstName, 10, 10)
            doc.text(this.lastName, 10, 15)
            doc.setFontSize(10)
            doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
            doc.setFontSize(12)
            doc.setTextColor('#5e797e')
            doc.text('Παραγωγός ', 180, 10)
        }
        doc.addImage("./assets/logo.png", 'PNG', 90, 10, 30, 20);
        autoTable(doc, {
            theme: 'grid',
            head: [this.headColumns],
            styles: { font: "LiberationSerif-Regular", fontSize: 9 },
            body: this.farmerDataMap,
            startY: 40,
        });
        doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
        if (this.langStatus.includes('en')) {
            doc.save("Farmer_" + dateTime + ".pdf");
        } else if (this.langStatus.includes('gr')) {
            doc.save("Παραγωγός_" + dateTime + ".pdf");
        }
    }
}
