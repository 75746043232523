<div class="row" style="padding-left: 1%; padding-right: 1%;" *ngFor="let widget of widgetData">
    <ng-template #noValue>0</ng-template>
    <div *ngIf="!mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #92df9f">
                        <img src="../../../../assets/land.png" alt="">
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col col-md-2">
                    <mat-card-content>
                        <h3 class="text-left mt-xl-3 mt-lg-1 pt-2" style="font-weight: 700; color: #7ec88b;">
                            {{'HOME.PLOTS' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-12 col-md-10 pt-1 pb-1">
                    <mat-card-content>
                        <div class="row">
                            <div class="text-end pt-1 pb-0" id="title_text">
                                <p class="text-sm mb-0 text-capitalize" id="title">{{'HOME.TOTALPL' | translate }}</p>
                                <h4 class="mb-0 mt-0" style="font-weight: 600; font-size: 24px">
                                    <span style="font-size: 12px;">
                                        {{'HOME.DIFFERENT_SPECIES' | translate }}:
                                    </span>
                                    {{widget.plots_data.amount_of_species}}
                                    <br>
                                    <span style="font-size: 12px;" class="mr-2">
                                        {{'HOME.PLOTS' | translate }}:
                                    </span>
                                    {{widget.plots_data.amount_of_plots}}
                                   
                                </h4>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0 mb-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-0 pt-1">
                    <p class="mb-0" id="text_footer_plot">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="font-weight: 700; color: #7ec88b;">
                                {{ widget.plots_data.total_plots_size | number: '1.2-2' }}
                            </span>
                            {{'HOME.SIZE' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #92df9f">
                        <img src="../../../../assets/land.png" alt="">
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col col-md-2">
                    <mat-card-content>
                        <h3 class="text-left mt-xl-3 mt-lg-1" style="font-weight: 700; color: #7ec88b; margin-top: 7%;">
                            {{'HOME.PLOTS' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-12 col-md-10">
                    <mat-card-content>
                        <div class="row">
                            <div class="text-end" id="title_text_plots">
                                <p class="text-sm mb-0 text-capitalize" id="title">{{'HOME.TOTALPL' | translate }}</p>
                                <h4 class="mb-0 mt-0" style="font-weight: 600; font-size: 24px"
                                    *ngIf="widget.plots_data.amount_of_species else noValue">
                                    <span style="font-size: 12px;">
                                        {{'HOME.DIFFERENT_SPECIES' | translate }}:
                                    </span>
                                    {{widget.plots_data.amount_of_species}}
                                    <br>
                                    <span *ngIf="widget.plots_data.amount_of_plots else noValue">
                                        <span style="font-size: 12px;" class="mr-2">
                                            {{'HOME.PLOTS' | translate }}:
                                        </span>
                                        {{widget.plots_data.amount_of_plots}}
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0 mb-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-0">
                    <p class="mb-0" id="text_footer_plot">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span *ngIf="widget.plots_data.total_plots_size else noValue"
                                style="font-weight: 700; color: #7ec88b;">
                                {{widget.plots_data.total_plots_size | number: '1.2-2'}}
                            </span>
                            {{'HOME.SIZE' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="!mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #bad6be ">
                        <img src="../../../../assets/compost.png" />
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col col-md-4">
                    <mat-card-content>
                        <h3 class="text-left mt-4 pt-0" style="font-weight: 700; color: #8baf84;">
                            {{'CROP.RESIDUE' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-md-8 ml-0 pt-0">
                    <mat-card-content>
                        <div id="title_text" class="text-end pb-0">
                            <p class="text-sm pb-0 text-capitalize" id="title">
                                {{'HOME.RESIDUE_TOTAL' | translate }} <br>
                            </p>
                            <div class="text-end mb-0 mt-1 pb-1" id="title_text">
                                <h4 class="mb-0" style="font-weight: 600; font-size: 17px">
                                    <span *ngIf="widget.residue_data.total_residue_amount else noValue">
                                        {{ formatNumber(widget.residue_data.total_residue_amount) }}
                                    </span>
                                    /Kg
                                </h4>
                            </div>
                            <div class="row mr-2">
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[0]">
                                        <i class="fa-solid fa-plant-wilt" style="color: rgb(252, 106, 0);"></i>
                                        <span *ngIf="residuePiles else noValue">
                                            {{residuePiles}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[1]">
                                        <i class="fa-solid fa-recycle" style="color: #c1c100;"></i>
                                        <span *ngIf="residueCompostNonForced else noValue">
                                            {{residueCompostNonForced}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[2]">
                                        <i class="fa-solid fa-box" style="color: #0088be;"></i>
                                        <span *ngIf="residueCompostForced else noValue">
                                            {{residueCompostForced}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[3]">
                                        <i class="fa-solid fa-wheat-awn-circle-exclamation"
                                            style="color: rgb(169, 67, 13);"></i>
                                        <span *ngIf="residueGround else noValue">
                                            {{residueGround}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[4]">
                                        <i class="fa-solid fa-fire-burner" style="color: red;"></i>
                                        <span *ngIf="residueBurn else noValue">
                                            {{residueBurn}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[5]">
                                        <i class="fa-solid fa-broom" style="color: rgb(5, 159, 0);"></i>
                                        <span *ngIf="residueRemove else noValue">
                                            {{residueRemove}}
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0 mb-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-0 pt-0">
                    <p class="mb-0" id="text_footer_weather">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="color: #8baf84; font-weight: 700;"
                                *ngIf="widget.residue_data.last_residue_plot else noValue">
                                {{widget.residue_data.last_residue_plot}}
                            </span>
                            {{'HOME.PLOTS' | translate }} |
                            <span style="color:  #8baf84; font-weight: 700;"
                                *ngIf="widget.residue_data.last_residue_datetime else noValue">
                                {{widget.residue_data.last_residue_datetime | date}}
                            </span>
                            {{'HOME.RESIDUE_LAST' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #bad6be ">
                        <img src="../../../../assets/compost.png" />
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col col-md-4">
                    <mat-card-content>
                        <h3 class="text-left mt-4 pt-0" style="font-weight: 700; color: #8baf84;">
                            {{'CROP.RESIDUE' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-md-8 ml-0 pt-0" style="margin-top: -10%;">
                    <mat-card-content>
                        <div id="title_text_residues" class="text-end pb-0">
                            <p class="text-sm pb-0 text-capitalize" id="title">
                                {{'HOME.RESIDUE_TOTAL' | translate }} <br>
                            </p>
                            <div class="text-end mb-0 mt-1 pb-1" id="title_text">
                                <h4 class="mb-0" style="font-weight: 600; font-size: 17px">
                                    <span *ngIf="widget.residue_data.total_residue_amount else noValue">
                                        {{widget.residue_data.total_residue_amount}}
                                    </span>
                                    /Kg
                                </h4>
                            </div>
                            <div class="row mr-2">
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[0]">
                                        <i class="fa-solid fa-plant-wilt" style="color: rgb(252, 106, 0);"></i><br>
                                        <span *ngIf="residuePiles else noValue">
                                            {{residuePiles}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[1]">
                                        <i class="fa-solid fa-recycle" style="color: #c1c100;"></i><br>
                                        <span *ngIf="residueCompostNonForced else noValue">
                                            {{residueCompostNonForced}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[2]">
                                        <i class="fa-solid fa-box" style="color: #0088be;"></i><br>
                                        <span *ngIf="residueCompostForced else noValue">
                                            {{residueCompostForced}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[3]">
                                        <i class="fa-solid fa-wheat-awn-circle-exclamation"
                                            style="color: rgb(169, 67, 13);"></i>
                                        <span *ngIf="residueGround else noValue">
                                            {{residueGround}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[4]">
                                        <i class="fa-solid fa-fire-burner" style="color: red;"></i><br>
                                        <span *ngIf="residueBurn else noValue">
                                            {{residueBurn}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="mb-0 col-2">
                                    <h4 class="avatar" style="font-weight: 600; font-size: 14px"
                                        [title]="titleButtonsActive[5]">
                                        <i class="fa-solid fa-broom" style="color: rgb(5, 159, 0);"></i><br>
                                        <span *ngIf="residueRemove else noValue">
                                            {{residueRemove}}
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0 mb-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-0 pt-0">
                    <p class="mb-0" style="margin-top: -5%">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="color: #8baf84; font-weight: 700;"
                                *ngIf="widget.residue_data.last_residue_plot else noValue">
                                {{widget.residue_data.last_residue_plot}}
                            </span>
                            {{'HOME.PLOTS' | translate }} |
                            <span style="color:  #8baf84; font-weight: 700;"
                                *ngIf="widget.residue_data.last_residue_datetime else noValue">
                                {{widget.residue_data.last_residue_datetime | date}}
                            </span>
                            {{'HOME.RESIDUE_LAST' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="!mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #d7d292">
                        <img src="../../../../assets/harvest_fruits.png">
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col col col-md-4">
                    <mat-card-content>
                        <h3 class="text-left mt-4 pt-1" style="font-weight: 700; color: #b9b68c;">
                            {{'HOME.HARVEST' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-md-8 pt-0">
                    <mat-card-content>
                        <div id="title_text" class="text-end pb-0">
                            <p class="text-sm pb-2 text-capitalize" id="title">
                                {{'HOME.TOTALH' | translate }}
                            </p>
                            <div class="text-end mb-0 mt-1 pb-2" id="title_text">
                                <h4 class="mb-0" style="font-weight: 600; font-size: 17px" id="harvestKG">
                                    <span *ngIf="widget.harvest_data.total_harvest_amount else noValue">
                                        {{ formatNumber(widget.harvest_data.total_harvest_amount) }}
                                    </span>
                                    /Kg
                                </h4>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="row">
                            <div class="text-end pt-0 pb-0" id="title_text">
                                <h4 class="mb-0 mt-1 pt-2" style="font-weight: 600; font-size: 24px">
                                    <span style="font-size: 14px;">
                                        {{'HOME.HARVEST_PLOTS' | translate }}:
                                    </span>
                                    <span *ngIf="widget.harvest_data.amount_of_harvest_plots else noValue">
                                        {{widget.harvest_data.amount_of_harvest_plots}}
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0 mb-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-1 pt-3">
                    <p class="mb-0" id="text_footer_harvest">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="font-weight: 700; color: #b9b68c;"
                                *ngIf="widget.harvest_data.last_harvest_plot else noValue">
                                {{ widget.harvest_data.last_harvest_plot }}
                            </span>
                            {{'HOME.PLOTS' | translate }} |
                            <span style="font-weight: 700; color: #b9b68c;"
                                *ngIf="widget.harvest_data.last_harvest_datetime else noValue">
                                {{widget.harvest_data.last_harvest_datetime | date: 'mediumDate'}}
                            </span>
                            {{'HOME.LAST_HARVEST_PLOT' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #d7d292">
                        <img src="../../../../assets/harvest_fruits.png">
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col col col-md-2">
                    <mat-card-content>
                        <h3 class="text-left mt-4 pt-1" style="font-weight: 700; color: #b9b68c;">
                            {{'HOME.HARVEST' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-md-10 pt-0">
                    <mat-card-content>
                        <div id="title_text_harvest" class="text-end pb-0">
                            <p class="text-sm pb-2 text-capitalize" id="title">
                                {{'HOME.TOTALH' | translate }}
                            </p>
                            <div class="text-end mb-0 mt-1 pb-2" id="title_text_harvest">
                                <h4 class="mb-0" style="font-weight: 600; font-size: 17px" id="harvestKG">
                                    <span *ngIf="widget.harvest_data.total_harvest_amount else noValue">
                                        {{ formatNumber(widget.harvest_data.total_harvest_amount) }}
                                    </span>
                                    /Kg
                                </h4>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="row">
                            <div class="text-end pt-0 pb-0" id="title_text_harvest">
                                <h4 class="mb-0 mt-1 pt-2" style="font-weight: 600; font-size: 24px">
                                    <span *ngIf="widget.harvest_data.amount_of_harvest_plots else noValue">
                                        {{widget.harvest_data.amount_of_harvest_plots}}
                                    </span>
                                    <span style="font-size: 12px;" id="harvestPlots">
                                        {{'HOME.HARVEST_PLOTS' | translate }}
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0 mb-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-1 pt-2">
                    <p class="mb-0" id="text_footer_harvest">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="font-weight: 700; color: #b9b68c;"
                                *ngIf="widget.harvest_data.last_harvest_plot else noValue">
                                {{ widget.harvest_data.last_harvest_plot }}
                            </span>
                            {{'HOME.PLOTS' | translate }} |
                            <span style="font-weight: 700; color: #b9b68c;"
                                *ngIf="widget.harvest_data.last_harvest_datetime else noValue">
                                {{widget.harvest_data.last_harvest_datetime | date: 'mediumDate'}}
                            </span>
                            {{'HOME.LAST_HARVEST_PLOT' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="!mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #e6b49c">
                        <img src="../../../../assets/storage.png" alt="">
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col-md-3 mr-4">
                    <mat-card-content>
                        <h3 class="text-left mt-4 pt-1" style="font-weight: 700; color: #c4937c;">
                            {{'HOME.STORAGE' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-md-8 mt-2">
                    <mat-card-content>
                        <div class="row">
                            <div id="title_text" class="col text-end pt-0 pb-3">
                                <p class="text-sm pb-1 text-capitalize" id="title">
                                    {{'HOME.TOTALS' | translate }}
                                </p>
                                <div class="col">
                                    <h6 class="mb-0" style="font-weight: 700; font-size: 14px">
                                        <div class=row>
                                            <div class="col-md-6">
                                                <div class="col">
                                                    <span class="text-secondary"
                                                        style="font-size: x-small; font-weight: 600 ;"
                                                        *ngIf="sumProtectionQuant  || sumProtectionQuantInit else noValue">
                                                        {{ formatNumber(sumProtectionQuant) }}/{{sumProtectionQuantInit}}
                                                    </span>
                                                </div>
                                                <mat-progress-bar id="progress_bar_protection" mode="determinate"
                                                    [value]="sumProtectionPercent">
                                                </mat-progress-bar>
                                            </div>
                                            <div class="col-md-4 text-left pt-1"
                                                style="margin-left: -4%; color: #a1ae5d; font-size: small; font-weight: 700;">
                                                {{'HOME.PROTECT' | translate }}
                                            </div>
                                        </div>
                                    </h6>
                                    <h6 class="mb-0" style="font-weight: 700; font-size: 14px">
                                        <div class=row>
                                            <div class="col-md-6">
                                                <div class="col">
                                                    <span class="text-secondary pt-1"
                                                        style="font-size: x-small; font-weight: 600 ;"
                                                        *ngIf="sumFertilizerQuant || sumFertilizerQuantInit else noValue">
                                                        {{ formatNumber(sumFertilizerQuant) }}/{{sumFertilizerQuantInit }}
                                                    </span>
                                                </div>
                                                <mat-progress-bar id="progress_bar_fertilizer" mode="determinate"
                                                    [value]="sumFertilizerPercent">
                                                </mat-progress-bar>
                                            </div>
                                            <div class="col-md-4 text-left pt-1"
                                                style="margin-left: -4%; color: rgb(86, 168, 50); font-size: small; font-weight: 700;">
                                                {{'HOME.FERT' | translate }}
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-1 pt-1">
                    <p class="" id="text_footer_storage">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="font-weight: 700;color: #a1ae5d" *ngIf="sumProtectionPercent else noValue">
                                {{ sumProtectionPercent }}%
                            </span>
                            {{'HOME.TOTALPR' | translate }} |
                            <span style="font-weight: 700;color: rgb(86, 168, 50);"
                                *ngIf="sumFertilizerPercent else noValue">
                                {{ sumFertilizerPercent }}%
                            </span>
                            {{'HOME.TOTALF' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
    <div *ngIf="mobile" class="col-md-7 col-xl-3">
        <mat-card id="cards_carousel_navigation" class="example-card">
            <mat-card-header id="category-title">
                <div class="col">
                    <div class="icon icon-lg icon-shape shadow-success text-center border-radius-xl mt-n4 position-absolute"
                        style="background-color: #e6b49c">
                        <img src="../../../../assets/storage.png" alt="">
                    </div>
                </div>
            </mat-card-header>
            <div class="row">
                <div class="col-5">
                    <mat-card-content>
                        <h3 class="text-left mt-4 pt-1" style="font-weight: 700; color: #c4937c;">
                            {{'HOME.STORAGE' | translate }}
                        </h3>
                    </mat-card-content>
                </div>
                <div class="col-7">
                    <mat-card-content>
                        <div class="row">
                            <div id="title_text" class="col text-end pt-0 pb-3">
                                <p class="text-sm pb-1 text-capitalize">
                                    {{'HOME.TOTALS' | translate }}
                                </p>
                                <div class="col">
                                    <h6 class="mb-0" style="font-weight: 700; font-size: 14px">
                                        <div class=row>
                                            <div class="col-6">
                                                <div class="col">
                                                    <span class="text-secondary"
                                                        style="font-size: small; font-weight: 600 ;"
                                                        *ngIf="sumProtectionQuant  || sumProtectionQuantInit else noValue">
                                                        {{ sumProtectionQuant | number:'1.1-1'}}/{{sumProtectionQuantInit}}{{
                                                        sumProtectionQuant | number:'1.1-1'}}/{{sumProtectionQuantInit}}
                                                    </span>
                                                </div>
                                                <mat-progress-bar id="progress_bar_protection" mode="determinate"
                                                    [value]="sumProtectionPercent">
                                                </mat-progress-bar>
                                            </div>
                                            <div class="col-4 text-left pt-1"
                                                style="margin-left: -4%; color: #a1ae5d; font-size: small; font-weight: 700;">
                                                {{'HOME.PROTECT' | translate }}
                                            </div>
                                        </div>
                                    </h6>
                                    <h6 class="mb-0" style="font-weight: 700; font-size: 14px">
                                        <div class=row>
                                            <div class="col-6">
                                                <div class="col">
                                                    <span class="text-secondary pt-1"
                                                        style="font-size: small; font-weight: 600 ;"
                                                        *ngIf="sumFertilizerQuant || sumFertilizerQuantInit else noValue">
                                                        {{ sumFertilizerQuant | number:'1.1-1'}}/{{sumFertilizerQuantInit}}
                                                    </span>
                                                </div>
                                                <mat-progress-bar id="progress_bar_fertilizer" mode="determinate"
                                                    [value]="sumFertilizerPercent">
                                                </mat-progress-bar>
                                            </div>
                                            <div class="col-4 text-left pt-1"
                                                style="margin-left: -4%; color: rgb(86, 168, 50); font-size: small; font-weight: 700;">
                                                {{'HOME.FERT' | translate }}
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
            <div class="row">
                <hr class="dark horizontal my-0" id="hr_line">
                <mat-card-footer class="mat_footer mt-1 pt-1">
                    <p class="" id="text_footer_storage">
                        <span class="text-secondary text-sm font-weight-bolder" id="text_footer">
                            <span style="font-weight: 700;color: #a1ae5d" *ngIf="sumProtectionPercent else noValue">
                                {{ sumProtectionPercent | number:'1.1-1'}}%
                            </span>
                            {{'HOME.TOTALPR' | translate }} |
                            <span style="font-weight: 700;color: rgb(86, 168, 50);"
                                *ngIf="sumFertilizerPercent else noValue">
                                {{ sumFertilizerPercent | number:'1.1-1'}}%
                            </span>
                            {{'HOME.TOTALF' | translate }}
                        </span>
                    </p>
                </mat-card-footer>
            </div>
        </mat-card>
    </div>
</div>